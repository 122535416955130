import * as S from './ServiceMenuContainer.styled';

// components
import { ServiceMenu } from './partials/ServiceMenu';
const ServiceMenuContainer = ({
  showMenu,
  emerging,
  employees,
  employeeId,
  wantedEmployees,
  selectService,
  sectorId,
  children,
  search,
  employeeSelected,
  selectEmployee,
  order,
  multiSelect,
  toggleWantedEmployeesSelection,
  averagePrices,
}) => {
  // useEffect(() => {
  //   if (!showMenu.state) {
  //     emerging.setState(false);
  //   }
  // }, [showMenu.state]);

  return (
    <S.ServiceMenuContainer>
      <div className="layout_container">
        <ServiceMenu
          menu={showMenu}
          // emerging={emerging}
          employeeId={employeeId}
          employees={employees}
          wantedEmployees={wantedEmployees}
          selectService={selectService}
          sectorId={sectorId}
          toggleWantedEmployeesSelection={toggleWantedEmployeesSelection}
          averagePrices={averagePrices}
        />
        <S.Content>
          {/* <S.EmergingContainer show={emerging.state} showMenu={showMenu.state}>
            <EmployeeSelect
              emerging={emerging}
              search={search}
              selected={employeeSelected}
              select={selectEmployee}
              sectorId={sectorId}
              startDate={order.startTime}
              multiSelect={multiSelect}
            />
          </S.EmergingContainer> */}
          {children}
        </S.Content>
      </div>
    </S.ServiceMenuContainer>
  );
};

export default ServiceMenuContainer;
