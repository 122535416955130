import { useState, useEffect, useRef, useContext } from 'react';

// style
import * as S from './PendingOrderCard.styled';
import { colors } from 'common/colors/colors';

// icons
import { CopySVG, TrashCan, Euro } from 'icons/dynamic';
import SendSVG from 'icons/bold/SendSVG';

// components
import AppointmentsDetails from 'components/AppointmentCard/AppointmentsDetails/AppointmentsDetails';
import AppointmentsDetailsMobile from 'components/AppointmentCard/AppointmentsDetails/AppointmentsDetailsMobile/AppointmentsDetailsMobile';
import ContextTree from 'components/ContextTree/ContextTree';
import Avatar from 'components/Avatar/Avatar';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useStatusList } from 'hooks/useStatusList';
import { useObserver } from 'hooks/useObserver';
import { getDurationFromMinutes } from 'common/helpers/dateOperations';
import { fillImgPath } from 'common/helpers/imagesFunctions';
import useAppointmentCard from 'hooks/schedule/useAppointmentCard';

const PendingOrderCard = ({
  data,
  drag,
  id,
  index,
  hover,
  allowHover,
  width,
  isDragging,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { locale } = useContext(UserContext);
  const currStatus = useStatusList(data.completionStatus.toLowerCase());
  const containerRef = useRef(null);
  let container = useObserver({
    ref: containerRef,
    debounceDelay: 10,
  });
  const { getBalanceActions, getStatusActions } = useAppointmentCard({
    isPending: true,
    appointment: data,
  });
  const [hoverTime, setHoverTime] = useState(hover);
  const [displayDetails, setDisplayDetails] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setHoverTime(hover);
  }, [hover]);

  const handleClick = () => {
    if (!isMobile) return;

    setDisplayDetails(true);
  };

  const getTimeStamp = (utc) => {
    const utcDate = new Date(utc);

    return utcDate.toLocaleTimeString(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const getFunctionList = (action) => [
    {
      id: '3.1',
      label: 'Siųsti SMS priminimą',
      icon: <SendSVG />,
      onClick: () => {
        // setModal('send-sms-reminder');
        action && action();
        // handleClickAway();
      },
    },
    {
      id: '3.2',
      label: 'Siųsti SMS nuorodą',
      icon: <SendSVG />,
      onClick: () => {
        // setModal('send-sms-link');
        action && action();
        // handleClickAway();
      },
    },
    {
      id: '3.3',
      label: 'Kopijuoti užsakymą',
      icon: <CopySVG bold={isMobile} />,
      onClick: () => {
        // functions.createCopy(id);
        action && action();
        // handleClickAway();

        if (!isMobile) return;
        setDisplayDetails(false);
      },
    },
    {
      id: '3.4',
      label: 'Ištrinti užsakymą',
      icon: <TrashCan bold={isMobile} />,
      fontColor: colors.mandyRed,
      onClick: () => {
        // setModal('delete-order', id);
        action && action();
        // handleClickAway();
      },
    },
  ];

  const getAppointmentDetailsData = () => ({
    id: data.sequenceNo,
    uuid: data.id,
    duration: getDurationFromMinutes(data.durationInMinutes),
    services: data.services,
    splits: data.splits,
    wantedEmployees: data.wantedEmployees || [],
    customer: {
      name: data.customer?.name,
      lastName: data.customer?.lastName,
    },
    balance: {
      amountCovered: data.amountCovered,
      toPay: data.finalAmountWithVat - data.amountCovered,
    },
  });

  // comp
  const TooltipWrapper = (
    <S.Tooltip onMouseDown={(e) => e.stopPropagation()}>
      <AppointmentsDetails
        status={data.completionStatus}
        data={getAppointmentDetailsData()}
        type={'pending'}
        statusItems={getStatusActions}
        functionItems={getFunctionList}
        balanceItems={getBalanceActions}
      />
    </S.Tooltip>
  );

  return (
    <S.PendingOrderCard
      id={`pending-${id}`}
      onMouseDown={(e) =>
        !isMobile &&
        e.button !== 2 &&
        drag &&
        drag(
          e,
          index,
          id,
          setHoverTime,
          containerRef.current,
          getDurationFromMinutes(data.durationInMinutes),
          data,
        )
      }
      onClick={handleClick}
      data-index={index}
      ref={containerRef}
      index={index}
      width={width}
      height={container.height}
      color={currStatus?.background}
      selected={selected || isDragging}
    >
      <div className="pendingOrder_info">
        <span className="pendingOrder_info-name">{`${
          data.customer?.name || 'Vardenis'
        } ${data.customer?.lastName || 'Pavardenis'}`}</span>

        <span className="pendingOrder_info-duration">
          {getDurationFromMinutes(data.durationInMinutes)}
        </span>

        <span className="pendingOrder_info-timeStamp">
          {getTimeStamp(data.lastUpdateTimeUtc)}
        </span>
      </div>
      <div className="pendingOrder_professionals">
        {data.wantedEmployees?.map((item, i) => {
          return i > 1 ? null : (
            <div className="pendingOrder_professionals-head" key={i}>
              <Avatar imagePath={fillImgPath(item.photoUrl)} />
            </div>
          );
        })}
      </div>
      {hoverTime && (
        <div className="hover-time">
          <span>{hoverTime}</span>
        </div>
      )}

      {!isMobile && (
        <ContextTree
          key={id}
          containerId={`pending-${id}`}
          openOnContext
          fixedWidth={300}
          content={TooltipWrapper}
          setCardSelected={setSelected}
          type="schedule-body-item"
        />
      )}

      {isMobile && displayDetails && (
        <AppointmentsDetailsMobile
          status={data.completionStatus}
          data={getAppointmentDetailsData()}
          type="pending"
          close={() => setDisplayDetails(false)}
        />
      )}
    </S.PendingOrderCard>
  );
};

export default PendingOrderCard;
