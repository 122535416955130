import { useContext, useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

// Component
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import DialogBox from 'components/DialogBox/DialogBox';
import SelectProfessionals from 'components/DialogLayout/SelectProfessionals/SelectProfessionals';
import { Overlay } from 'components/Overlay/Overlay.styled';
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';

// Context
import { ScheduleContext } from 'context/ScheduleContext';

// Helper
import { SCHEDULE_TYPE } from 'common/Constants';

// Hook
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';

export const EmployeeSelection = ({ isVisible, toggleVisibility }) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { selectedProfessional, sectorId, type } = useContext(ScheduleContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const employee = useSelector(
    (state) => state.calendarView.employees?.[0]?.employee,
  );
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (
      type === SCHEDULE_TYPE.PERSONAL &&
      selectedProfessional?.value &&
      selectedProfessional?.value?.id !== searchParams.get('employeeId')
    ) {
      searchParams.set('employeeId', selectedProfessional.value.id);

      navigate({
        to: './',
        search: createSearchParams({
          ...Object.fromEntries(new URLSearchParams(searchParams)),
        }).toString(),
      });
    }
  }, [selectedProfessional]);

  if (!isVisible) {
    return '';
  }

  return (
    <ConditionalWrapper
      condition={isMobile}
      wrapper={(children) => (
        <ContentLayout hideFab>
          <SelectionPanel
            key={'select-professional'}
            closeAction={toggleVisibility}
            headerTitle={tr.professionals}
            searchTitle={tr.professionalSearch}
            arrayFilter={(value) => setSearchInput(value)}
          >
            {children}
          </SelectionPanel>
        </ContentLayout>
      )}
      secondaryWrapper={(children) => (
        <Overlay isVisible={isVisible} hide={toggleVisibility}>
          <div className="modal-wrapper">
            <DialogBox
              headerText={tr['choose-specialist']}
              close={toggleVisibility}
              cancelOverflow
            >
              {children}
            </DialogBox>
          </div>
        </Overlay>
      )}
    >
      <SelectProfessionals
        select={(prof) => {
          selectedProfessional.set(prof);
          toggleVisibility();
        }}
        selectedProf={[selectedProfessional?.value?.id || employee?.id]}
        calendarView={'i'} //TODO: should be by data from calendar?
        searchInput={searchInput}
        sectorId={sectorId}
      />
    </ConditionalWrapper>
  );
};

export default EmployeeSelection;
