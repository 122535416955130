// style
import * as S from './TableHeadingControls.styled';

// components
import FilterSVG from 'icons/FilterSVG';
import SortArrowsSVG from 'icons/SortArrowsSVG';

export const TableHeadingControls = ({ onSortClick, onFilterClick }) => (
  <S.TableHeadingControls className="controls">
    <div className="sort" onClick={onSortClick}>
      <SortArrowsSVG />
    </div>
    <div className="filter" onClick={onFilterClick}>
      <FilterSVG />
    </div>
  </S.TableHeadingControls>
);
