import React from 'react';
import { StyledSVG } from './SVG.styled';

const TrashcanBoldSVG = ({ color, ...other }) => {
  return (
    <StyledSVG
      id="Layer_1-2"
      data-name="Layer 1-2"
      xmlns="http://www.w3.org/2000/svg"
      width="19.458"
      height="20"
      viewBox="0 0 19.458 20"
      color={color ? color : 'currentColor'}
      {...other}
    >
      <g id="Group_3906" data-name="Group 3906">
        <path
          id="Path_932"
          data-name="Path 932"
          d="M25.184,40.91a.815.815,0,0,0-.815.815v9.216H12.67V41.725a.815.815,0,0,0-1.63,0v9.269a1.581,1.581,0,0,0,1.579,1.579h11.8A1.581,1.581,0,0,0,26,50.995V41.725a.815.815,0,0,0-.815-.815Z"
          transform="translate(-8.79 -32.574)"
          //   fill="#ffa35f"
        />
        <path
          id="Path_933"
          data-name="Path 933"
          d="M29.02,59.239V54.355a.815.815,0,0,0-1.63,0v4.884a.815.815,0,0,0,1.63,0Z"
          transform="translate(-21.809 -42.63)"
          //   fill="#ffa35f"
        />
        <path
          id="Path_934"
          data-name="Path 934"
          d="M45.37,59.239V54.355a.815.815,0,0,0-1.63,0v4.884a.815.815,0,0,0,1.63,0Z"
          transform="translate(-34.827 -42.63)"
          //   fill="#ffa35f"
        />
        <path
          id="Path_935"
          data-name="Path 935"
          d="M61.73,59.239V54.355a.815.815,0,0,0-1.63,0v4.884a.815.815,0,1,0,1.63,0Z"
          transform="translate(-47.853 -42.63)"
          //   fill="#ffa35f"
        />
        <path
          id="Path_936"
          data-name="Path 936"
          d="M18.643,5.8H17.208V4.617a1.581,1.581,0,0,0-1.579-1.579h-2.4a3.538,3.538,0,0,0-7.006,0h-2.4A1.581,1.581,0,0,0,2.248,4.617V5.8H.815a.815.815,0,1,0,0,1.63H18.643a.815.815,0,1,0,0-1.63ZM9.728,1.63a1.917,1.917,0,0,1,1.846,1.408H7.884A1.917,1.917,0,0,1,9.73,1.63ZM3.878,4.668h11.7V5.8H3.88V4.668Z"
          //   fill="#ffa35f"
        />
      </g>
    </StyledSVG>
  );
};

export default TrashcanBoldSVG;
