import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const ServiceCategories = styled.div`
  :not(:last-child) {
    margin-bottom: 18px;
  }

  :not(:first-child) {
    margin-top: 18px;
  }

  .category,
  .group {
    min-width: max-content;
  }

  .category {
    /* font-family: ${fontFamilies.family_Primary}; */
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    padding: 13px 0;
  }

  .group {
    /* font-family: ${fontFamilies.family_Secondary}; */
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1;
    padding: 13px 20px;
    height: 40px;
    border-radius: 20px;
    display: flex;

    :hover {
      background-color: ${colors.bg_grey_1};
    }

    :active,
    &.active {
      background-color: ${colors.bg_Primary};
      color: ${colors.text_Primary};
    }
  }
`;
