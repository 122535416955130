import styled from 'styled-components/macro';
import { colors } from '../../common/colors/colors';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family-secondary);
  color: ${colors.footer_Secondary};
  font-size: 16px;
  left: 0;
  bottom: 0;
  right: 0;
  /* margin: 40px 40px 0; */
  padding-top: 40px;
  /* padding: 40px 0; */
  /* border-top: 1px solid ${colors.table_arrows_Primary}; */
  flex-shrink: 0;

  @media (max-width: 960px) {
    display: block;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    display: none;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  height: 39px;

  svg {
    height: 35px;
  }

  @media (max-width: 960px) {
    display: block;
    height: 35px;
    margin-bottom: 20px;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;

  a {
    padding-left: 40px;
    padding-right: 20px;
    border-right: 1px solid ${colors.footer_Secondary};
    color: ${colors.footer_Secondary};

    :last-child {
      border: none;
    }
  }

  @media (max-width: 960px) {
    a {
      border: none;
      font-size: 12px;
      padding: 0 20px;
    }
  }
`;

export const LanguageBar = styled.div`
  display: flex;

  img {
    margin-right: 10px;
  }

  select {
    outline: none;
    border: 0;
    color: ${colors.footer_Secondary};
    -webkit-appearance: none;
  }

  @media (max-width: 960px) {
    display: flex;
    width: 80px;
    padding: 40px 0;
    margin: 0 auto;
  }
`;
