import { useContext, useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import ReactNumeric from '@anthony-tron/react-numeric';

// style
import * as S from './InputFieldNumber.styled';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useSystemGetCultureById } from 'services/systemService';

export const InputFieldNumber = ({
  id,
  headerId,
  label,
  state,
  decimalPlaces,
  disabled,
  placeholder,
  minimumValue,
  type,
  ...restProps
}) => {
  const { locale } = useContext(UserContext);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  // if no headerId supplied
  if (!headerId) headerId = id;

  // get culture settings
  const { isSuccess: isCultureSuccess, data: culture } =
    useSystemGetCultureById({ id: locale });

  useEffect(
    // set values asinchronously
    () => setInputValue(state?.value?.value || ''),
    [state.value, headerId],
  );

  const debouncedUpdate = useDebouncedCallback(
    (value) => state?.setValue && state.setValue(headerId, value),
    500,
  );

  const validateInput = (e, value) => {
    // if value didn't change
    if (e.target?.value === inputValue) return;

    if (e.target.value === '') {
      // if value was cleared (is empty)
      debouncedUpdate.cancel();
      state?.setValue && state.setValue(headerId, undefined);
    } else {
      // value was set
      state?.setValue &&
        debouncedUpdate({ value: value, label: e.target.value });
    }
  };

  const clearInput = (e) => {
    // set visual confirmation immediately
    inputRef.current.autonumeric.set('');
    state?.setValue && state.setValue(headerId, undefined);
    // outsource to validation logic
    validateInput(e, '');
    inputRef.current.input.focus();
  };

  // log
  // useEffect(() => {
  //   isCultureSuccess && console.log({ culture });
  // }, [isCultureSuccess, culture]);

  return (
    <S.InputFieldNumber hasValue={!!state.value}>
      {label && <label htmlFor={id}>{label}</label>}

      <div className="input-wrapper">
        <ReactNumeric
          id={id}
          ref={inputRef}
          placeholder={
            !state.value && disabled
              ? '-'
              : placeholder ||
                (0).toLocaleString(locale, {
                  minimumFractionDigits:
                    decimalPlaces?.toString() ||
                    (isCultureSuccess &&
                      culture?.numberFormatInfo?.numberDecimalDigits),
                  maximumFractionDigits:
                    decimalPlaces?.toString() ||
                    (isCultureSuccess &&
                      culture?.numberFormatInfo?.numberDecimalDigits),
                })
          }
          value={inputValue}
          onChange={validateInput}
          {...(minimumValue !== undefined && {
            minimumValue: minimumValue || '0',
          })}
          {...(isCultureSuccess &&
            culture?.numberFormatInfo && {
              decimalPlaces: +culture.numberFormatInfo.numberDecimalDigits,
              decimalCharacter: culture.numberFormatInfo.numberDecimalSeparator,
              decimalCharacterAlternative:
                culture.numberFormatInfo.numberDecimalSeparator === ','
                  ? '.'
                  : ',',
              digitGroupSeparator:
                culture.numberFormatInfo.numberGroupSeparator,
              digitalGroupSpacing:
                culture.numberFormatInfo.numberGroupSizes[0].toString(),
            })}
          {...(decimalPlaces !== undefined && { decimalPlaces })}
          disabled={disabled}
          {...(type !== undefined &&
            isCultureSuccess &&
            (type === 'currency'
              ? {
                  currencySymbol: culture.numberFormatInfo?.currencySymbol,
                  emptyInputBehavior: 'null',
                } // TODO: fetch from '/sectors', not from '/cultures'
              : type === 'percent'
                ? { suffixText: 'percentage' }
                : {}))}
          unformatOnHover={true}
          {...restProps}
        />

        {inputValue !== '' && !disabled && (
          <div className="input-wrapper__icon" onClick={clearInput}>
            <components.ClearIndicator
              getStyles={() => ({})}
              getClassNames={() => {}}
              cx={() => []}
            />
          </div>
        )}
      </div>
    </S.InputFieldNumber>
  );
};

export default InputFieldNumber;
