import { StyledSVG } from './SVG.styled';

const PriceTagSVG = ({ color, width, height, size }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={size || width || '100%'}
      height={size || height || '100%'}
      color={color || 'currentColor'}
    >
      <g>
        <path d="m18.345 30a0.91348 0.91348 0 0 1-0.49941-0.14856l-16.19-10.513a0.92296 0.92296 0 0 1-0.27183-1.2643l8.1929-12.539a3.4548 3.4548 0 0 1 1.0494-1.0368l6.2237-3.9574a3.4769 3.4769 0 0 1 3.7551 0.018965l6.5714 4.2671a3.4769 3.4769 0 0 1 1.5583 3.3284l-0.90716 7.4627a3.5275 3.5275 0 0 1-0.53418 1.4729l-8.1707 12.488a0.94825 0.94825 0 0 1-0.58159 0.40142 1.0304 1.0304 0 0 1-0.19597 0.018965zm-0.28131-1.1126m-15.431-10.431 15.614 10.149 7.9874-12.207a2.241 2.241 0 0 0 0.33821-0.94825l0.89768-7.4469a2.2126 2.2126 0 0 0-0.98934-2.1178l-6.565-4.2671a2.2126 2.2126 0 0 0-2.3864 0l-6.2268 3.9479a2.181 2.181 0 0 0-0.66694 0.66061z" />
        <path d="m20.235 11.307a2.87 2.87 0 1 1 2.4117-1.3054 2.8637 2.8637 0 0 1-2.4117 1.3054zm0-4.4726a1.6057 1.6057 0 1 0 1.3434 2.4781 1.5994 1.5994 0 0 0-0.47412-2.2126 1.5804 1.5804 0 0 0-0.85974-0.26551z" />
      </g>
    </StyledSVG>
  );
};

export default PriceTagSVG;
