import * as S from '../SVG.styled';

const EnvelopeSVG = ({ color, width, height, bold }) => {
  const content = bold ? (
    <g strokeWidth=".30566">
      <path d="m27.05 4.111h-24.101c-1.6261 0-2.9496 1.3235-2.9496 2.9496v15.879c0 1.6261 1.3235 2.9496 2.9496 2.9496h24.101c1.6261 0 2.9496-1.3235 2.9496-2.9496v-15.879c0-1.6261-1.3235-2.9496-2.9496-2.9496zm0.50433 18.828c0 0.27815-0.22619 0.50433-0.50433 0.50433h-24.101c-0.27815 0-0.50433-0.22619-0.50433-0.50433v-15.879c0-0.27815 0.22619-0.50433 0.50433-0.50433h24.101c0.27815 0 0.50433 0.22619 0.50433 0.50433z" />
      <path d="m21.582 16.42c-0.49822-0.45543-1.2715-0.42181-1.727 0.07641-0.45543 0.49822-0.42181 1.2715 0.07641 1.727l4.7438 4.3434c0.23536 0.21396 0.53184 0.32094 0.82527 0.32094 0.33011 0 0.66022-0.13449 0.90168-0.39735 0.45543-0.49822 0.42181-1.2715-0.07641-1.727z" />
      <path d="m8.4146 16.42-4.7438 4.3434c-0.49822 0.45543-0.53184 1.2287-0.076414 1.727 0.24147 0.26286 0.57158 0.39735 0.90169 0.39735 0.29343 0 0.58992-0.10698 0.82527-0.32094l4.7438-4.3434c0.49822-0.45543 0.53184-1.2287 0.07641-1.727-0.45543-0.49822-1.2287-0.53184-1.727-0.07641z" />
      <path d="m26.161 7.7269c-0.45543-0.49822-1.2287-0.53184-1.727-0.076414l-9.4356 8.6378-9.4356-8.6378c-0.49822-0.45543-1.2715-0.42181-1.727 0.076414-0.45543 0.49822-0.42181 1.2715 0.076414 1.727l10.261 9.3959c0.2323 0.21396 0.52878 0.32094 0.82527 0.32094 0.29649 0 0.59297-0.10698 0.82527-0.32094l10.261-9.3959c0.49822-0.45543 0.53184-1.2287 0.07641-1.727z" />
    </g>
  ) : (
    <g strokeWidth=".31864">
      <path d="m2.4376 25.711h25.125c1.3447 0 2.4376-1.0929 2.4376-2.4376v-16.55c0-1.3447-1.0929-2.4376-2.4376-2.4376h-25.125c-1.3447 0-2.4376 1.0929-2.4376 2.4376v16.553c0 1.3447 1.0929 2.4376 2.4376 2.4376zm-1.163-18.988c0-0.64047 0.52257-1.163 1.163-1.163h25.125c0.64047 0 1.163 0.52257 1.163 1.163v16.553c0 0.64047-0.52257 1.163-1.163 1.163h-25.125c-0.64047 0-1.163-0.52257-1.163-1.163z" />
      <path d="m20.53 16.99c-0.23898 0.2581-0.21986 0.66277 0.03824 0.90175l4.9453 4.5279c0.12108 0.11152 0.27722 0.16569 0.43016 0.16569 0.17207 0 0.34413-0.0701 0.47159-0.20712 0.23898-0.2581 0.21986-0.66277-0.03824-0.90175l-4.9453-4.5279c-0.26128-0.23898-0.66277-0.21986-0.90175 0.03824z" />
      <path d="m4.0499 22.588c0.15295 0 0.30908-0.05417 0.43016-0.16569l4.9453-4.5279c0.2581-0.23898 0.27722-0.64047 0.038237-0.90175-0.23898-0.2581-0.64047-0.27722-0.90175-0.038237l-4.9453 4.5279c-0.2581 0.23898-0.27722 0.64047-0.038237 0.90175 0.12427 0.13702 0.29634 0.20712 0.47159 0.20712z" />
      <path d="m14.568 18.542c0.12108 0.11152 0.27722 0.16569 0.43016 0.16569 0.15295 0 0.30908-0.05417 0.43016-0.16569l10.697-9.795c0.2581-0.23898 0.27722-0.64047 0.038237-0.90175-0.23898-0.26129-0.64047-0.27722-0.90175-0.038237l-10.267 9.3999-10.263-9.3999c-0.2581-0.23579-0.66277-0.21986-0.90175 0.038237-0.23898 0.2581-0.21986 0.66277 0.038237 0.90175l10.697 9.795z" />
    </g>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default EnvelopeSVG;
