import { useEffect, useState } from 'react';

// style
import * as S from './ServiceTree.styled';

// icons
import VarneleSVG from 'icons/VarneleSVG';
import { ArrowRevert, Check, Circle } from 'icons/dynamic';

// components
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import { ContentLayout } from 'components/ContentLayout/ContentLayout';
import { DialogLayout } from 'components/DialogLayout/DialogLayout';
import { ServiceGroup } from '../ServiceGroup/ServiceGroup';

export const CategoryTree = ({
  data,
  close,
  modal = true,
  toggleActivity,
  updateValue,
  contentId,
  serviceActivity,
  cancelChanges,
  confirmChanges,
  getCachedItem,
  getItem,
  isCacheEmpty,
  getDbItem,
  updateCache,
  db,
}) => {
  const [activeOption, setActiveOption] = useState();

  const findItemById = (data, id) => data?.find((item) => item.id === id);

  const type = contentId === 'categories' ? 'category' : contentId.slice(0, -1);

  // useEffect(() => console.log({ activeOption }), [activeOption]);

  const Wrapper = (children) => {
    return (
      <ContentLayout hideFab>
        <DialogLayout headerText={data?.name} getBack={close} modal>
          {children}
        </DialogLayout>
      </ContentLayout>
    );
  };

  const initialButtons = [
    {
      id: 'cancel',
      secondary: true,
      outline: true,
      borderless: false,
      icon: <ArrowRevert bold borderless={false} />,
      action: () => {
        cancelChanges();
        // console.log(`${type}: changes reverted`);
      },
    },
    {
      id: 'save',
      borderless: true,
      icon: <Check bold />,
      action: () => {
        confirmChanges();
        // console.log(`${type}: changes saved`);
      },
    },
  ];

  const CategoryTreeItems = ({ setButtons }) => {
    // setup/cleanup header buttons
    useEffect(() => {
      setButtons && setButtons({ type: 'setup', value: initialButtons });
      return () => setButtons && setButtons({ type: 'cleanup' });
    }, [setButtons]);

    // show/hide header button according to changes
    useEffect(() => {
      if (setButtons) {
        const isChanged = !isCacheEmpty();
        setButtons({ type: 'show', id: 'cancel', value: isChanged });
        setButtons({ type: 'show', id: 'save', value: isChanged });
      }
    }, [setButtons]);

    return data?.[contentId]?.map((child) => {
      const item = getItem(type, child.id);

      return (
        <S.ServiceTree key={child.id} type={type}>
          <div className="item">
            <div
              className="mark"
              onClick={() => toggleActivity(db, type, child.id)}
            >
              {('isActive' in item ? item.isActive : child.isActive) ? (
                <Check black inCircle width={16} height={16} />
              ) : (
                <Circle bold width={16} height={16} />
              )}
            </div>

            {child.name}

            <div
              className="arrow"
              onClick={() =>
                setActiveOption(() => findItemById(data[contentId], child.id))
              }
            >
              <VarneleSVG tip={'right'} height="16" width="16" />
            </div>
          </div>
        </S.ServiceTree>
      );
    });
  };

  return (
    <ConditionalWrapper condition={modal} wrapper={Wrapper}>
      <CategoryTreeItems />

      {activeOption &&
        (type === 'category' ? (
          <CategoryTree
            data={activeOption}
            contentId="groups"
            close={() => setActiveOption(undefined)}
            toggleActivity={toggleActivity}
            updateValue={updateValue}
            cancelChanges={cancelChanges}
            confirmChanges={confirmChanges}
            getDbItem={getDbItem}
            getCachedItem={getCachedItem}
            getItem={getItem}
            isCacheEmpty={isCacheEmpty}
            updateCache={updateCache}
            db={db}
          />
        ) : (
          <ServiceGroup
            serviceActivity={serviceActivity}
            activeGroup={activeOption}
            close={() => setActiveOption(undefined)}
            getDbItem={getDbItem}
            getCachedItem={getCachedItem}
            initialButtons={initialButtons}
            isCacheEmpty={isCacheEmpty}
            updateCache={updateCache}
            toggleActivity={toggleActivity}
          />
        ))}
    </ConditionalWrapper>
  );
};
