// style
import * as S from './ClientListItem.styled';

// icons
import { Arrow } from 'icons/dynamic';

// components
import Avatar from '../../../../Avatar/Avatar';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useNavigate } from 'react-router';

const ClientListItem = ({ client, limited, select, anonymous, isSelected }) => {
  const tr = useTranslate().use().global;
  const navigate = useNavigate();

  return (
    <S.ClientItem onClick={select} isSelected={isSelected}>
      <div className="container">
        <div className="innerDiv clientInfoWrapper">
          <div className="clientAvatar">
            <Avatar anonymous={anonymous} />
          </div>
          <div className="clientName">
            <span className="nameWrapper">
              <span className="firstName">
                {client ? client.name : tr.anonymous}
              </span>
              <span className="lastName"> {client?.lastName}</span>
            </span>
            {(client?.birthday || client?.phone) && (
              <span className="additionalInfo">
                {client?.birthDate} {client?.birthDate && client?.phone && '|'}{' '}
                {client?.phone}
              </span>
            )}
          </div>
        </div>
        <div className="innerDiv">
          {client && !limited && (
            <div className="clientActions">
              <div
                className="arrowRight"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/functions/tables/clients/${client.id}/general-information`,
                  );
                  console.log('click');
                }}
              >
                <Arrow black height={18} width={14} />
              </div>
              {/* <div className="more">
              <MoreSVG />
            </div> */}
            </div>
          )}
        </div>
      </div>
    </S.ClientItem>
  );
};

export default ClientListItem;
