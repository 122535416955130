import React from 'react';

// Style
import * as S from './SelectSMSDuration.styled';

// Components
import SelectList from '../CustomSelect/children/SelectList/SelectList';

const smsDuration = [
  { label: '—', value: 0 },
  { label: '1 sav.', value: '1w' },
  { label: '2 sav.', value: '2w' },
  { label: '3 sav.', value: '3w' },
  { label: '4 sav.', value: '4w' },
  { label: '5 sav.', value: '5w' },
  { label: '6 sav.', value: '6w' },
  { label: '1 mėn', value: '1m' },
  { label: '2 mėn', value: '2m' },
  { label: '3 mėn', value: '3m' },
  { label: '4 mėn', value: '4m' },
  { label: '5 mėn', value: '5m' },
  { label: '6 mėn', value: '6m' },
  { label: '7 mėn', value: '7m' },
  { label: '8 mėn', value: '8m' },
  { label: '9 mėn', value: '9m' },
  { label: '10 mėn', value: '10m' },
  { label: '11 mėn', value: '11m' },
  { label: '1 metai', value: '1y' },
  { label: '2 metai', value: '2y' },
];

const SelectSMSDuration = ({ currentlySelected, select }) => {
  return (
    <S.SelectSMSContainer>
      <SelectList
        arr={smsDuration}
        currentlySelected={(value) => currentlySelected.value === value}
        action={select}
        belongsTo={currentlySelected?.orderId}
      />
    </S.SelectSMSContainer>
  );
};

export default SelectSMSDuration;
