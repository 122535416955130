import React from 'react';
import { StyledSVG } from './SVG.styled';

const CubeSVG = ({ color }) => {
  return (
    <StyledSVG
      id="Layer_1-2"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15.993"
      viewBox="0 0 16 15.993"
      color={color ? color : 'currentColor'}
    >
      <path
        id="Path_967"
        data-name="Path 967"
        d="M15.984,3.87a.6.6,0,0,0-.029-.106s0,0,0,0a.658.658,0,0,0-.047-.1c0-.007-.008-.013-.011-.021a.821.821,0,0,0-.064-.085.186.186,0,0,0-.018-.018.523.523,0,0,0-.078-.068l-.008-.007h0a.627.627,0,0,0-.1-.059.071.071,0,0,0-.011-.007L8.269.059a.649.649,0,0,0-.54,0L.381,3.4.37,3.405a.612.612,0,0,0-.1.059h0L.262,3.47a.784.784,0,0,0-.078.068l-.018.018A.523.523,0,0,0,.1,3.641a.088.088,0,0,0-.011.021.75.75,0,0,0-.047.1.005.005,0,0,1,0,0,.784.784,0,0,0-.029.106.13.13,0,0,1,0,.024A.66.66,0,0,0,0,3.99V12.9a.65.65,0,0,0,.447.618l7.346,2.445h0a.6.6,0,0,0,.073.018l.028.007a.682.682,0,0,0,.1.008H8a.682.682,0,0,0,.1-.008.171.171,0,0,0,.028-.007.6.6,0,0,0,.073-.018h0l7.346-2.445A.652.652,0,0,0,16,12.9V3.992a.66.66,0,0,0-.008-.1.13.13,0,0,0,0-.024ZM8,1.368,13.563,3.9,8,5.749,2.434,3.9ZM1.3,4.9,7.346,6.908v7.534L1.3,12.43ZM14.693,12.43,8.65,14.442V6.906l6.042-2.011v7.534Z"
        // fill="#0f1d5b"
      />
    </StyledSVG>
  );
};

export default CubeSVG;
