import { useTranslate } from 'hooks/useTranslate';
import { useContext, useEffect, useState } from 'react';
import useStatusList from 'hooks/useStatusList';

// style
import * as S from './AppointmentsDetailsMobile.styled';
import FunctionBar from 'components/FunctionBar/FunctionBar';

// icons
import { NewSVG, SmsSVG, LinkUrlSVG, Cake, Comment } from 'icons/dynamic';
import PhoneSVG from 'icons/PhoneSVG';

// components
import DialogLayout from 'components/DialogLayout/DialogLayout';
import Overlay from 'components/Overlay/Overlay';
import Avatar from 'components/Avatar/Avatar';
import { Link } from 'components/Overrides';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';

// hooks
import useNumber from 'hooks/useNumber';
import { getDuration } from 'common/helpers/dateOperations';
import { fillImgPath } from 'common/helpers/imagesFunctions';
import { useEmployeesGet } from 'services/employeeService';

const AppointmentsDetailsMobile = ({
  status,
  data,
  functions,
  close,
  type,
}) => {
  const { id, employeeId } = data;
  const { searchParams, numberOfDays } = useContext(ScheduleContext);
  const { formatPrice } = useNumber({ price: true });
  // Vars
  const iconList = [
    { id: 'iconNewClient', icon: <NewSVG bold />, label: 'Naujas klientas' },
    { id: 'iconSms', icon: <SmsSVG bold />, label: 'Užsakymas turi pastabą' },
    {
      id: 'iconBirthday',
      icon: <Cake />,
      label: 'Šiandien gimtadienis',
    },
    {
      id: 'iconNote',
      icon: <Comment />,
      label: 'SMS generuojamas',
    },
    // { icon: <OnlineSVG bold /> },
  ];

  // Hooks
  const tr = useTranslate().use().global;
  const currentStatus = useStatusList(status.toLowerCase());
  // TODO: duplicates requests
  const { data: employee } = useEmployeesGet({
    id: employeeId,
    queryParams: {
      retry: false,
      enabled: !!employeeId,
    },
  });

  // -- state
  const [servicesPrice, setServicesPrice] = useState(null);
  const [functionBarItems, setFunctionBarItems] = useState([]);

  // -- side effects

  useEffect(() => {
    const btn = document.getElementById('service-fab');
    if (!btn) return;
    btn.style.zIndex = 1001;
  }, []);

  useEffect(() => {
    // price
    if (!data.services?.length) return;

    setServicesPrice(
      data.services.reduce((prev, curr) => prev + +curr.unitPriceWithVat, 0),
    );
  }, []);

  useEffect(() => {
    setFunctionBarItems(adaptFunctionItems(functions));
  }, [functions]);

  // Functions
  const getTimeFromDate = (date) => {
    if (!date) return;

    const time = new Date(date);

    const timeFormat = {
      hour: '2-digit',
      minute: '2-digit',
    };

    return time.toLocaleTimeString('lt', timeFormat);
  };

  const adaptFunctionItems = (items) => {
    if (!items) return;

    const newForm = items.map((item) => {
      const subItems = item?.items?.map((subItem) => {
        return {
          id: subItem.id,
          acl: 1,
          hide: () => true,
          action: subItem.onClick,
          background: subItem.color,
          color: subItem.fontColor,
          title: subItem.label,
          closeOnClick: true,
          icon: subItem.icon,
        };
      });

      return {
        id: item.id,
        acl: 1,
        icon: item.icon,
        iconTitle: item.label,
        tooltip: item.label,
        subItems,
        scope: 3,
      };
    });

    return newForm;
  };

  const startTime = getTimeFromDate(data?.time?.start);
  const endTime = getTimeFromDate(data?.time?.end);
  const duration =
    data.duration || getDuration(data?.time?.start, data?.time?.end);

  return (
    <Overlay isVisible={true}>
      <S.AppointmentsDetailsMobile statusColor={currentStatus.background}>
        <DialogLayout
          modal
          getBack={close}
          headerText={tr['order']}
          // buttons={[
          //   {
          //     id: 'edit',
          //     icon: <Pencil bold />,
          //     action: () => console.log('Edit btn'),
          //     show: true,
          //   },
          // ]}
        >
          <FunctionBar fixed functions={functionBarItems} id="service-fab" />
          <div className="details">
            <div className="details__customer-info">
              <Link to={'#'} className="full-name">
                {`${data.customer.name} ${data.customer.lastName}`}
                <LinkUrlSVG bold />
              </Link>
              <span className="phone">
                <PhoneSVG />
                <span>+37063328571</span>
              </span>
            </div>
            <Link
              to={'/functions/tables/orders/' + data.uuid}
              state={{
                toOrder: true,
                numberOfDays: numberOfDays.visible,
                searchParams: Object.fromEntries([...searchParams]),
              }}
              className="details__container order-number row"
            >
              <span className="wrapper flex-gap-row">
                <span className="label">{tr['order']}</span>
                <span>{id}</span>
              </span>
              <LinkUrlSVG bold />
            </Link>
            <div className="details__container row">
              <div className="details__status-bubble" />
              <span className="label">{currentStatus?.title}</span>
            </div>
            {!!employeeId && (
              <div className="details__container">
                <div className="details__specialist flex-gap-row">
                  <span className="label">{tr['specialist']}: </span>
                  {data.employeeId && (
                    <div className="details__avatar">
                      <Avatar
                        raised
                        imagePath={fillImgPath(employee?.employee?.photoUrl)}
                      />
                    </div>
                  )}
                  <span>
                    {employee?.employee?.firstName}{' '}
                    {employee?.employee?.lastName}
                  </span>
                </div>
                <div className="flex-gap-row">
                  <span className="label">{tr['office']}: </span>
                  <span>2</span>
                </div>
              </div>
            )}
            <div className="details__container">
              <div className="flex-gap-row">
                {type !== 'pending' && (
                  <>
                    <span className="label">{tr['time']}: </span>
                    <span>
                      {startTime} - {endTime}
                    </span>
                  </>
                )}
                <span className="label">{tr['duration']}: </span>
                <span>{duration}</span>
              </div>
            </div>
            {!!data.services.length && (
              <div className="details__container">
                <span className="label">{tr['services']}: </span>
                <ul>
                  {data.services.map((item, i) => (
                    <li key={i}>
                      <span>{item.serviceName}</span>
                    </li>
                  ))}
                </ul>
                <div className="font-bold">{formatPrice(servicesPrice)}</div>
              </div>
            )}
            <div className="details__container balance">
              <div className="flex-gap-row">
                <div>
                  <div className="label">{tr['paid']}: </div>
                  <div className="debit">
                    {formatPrice(data.balance.amountCovered)}
                  </div>
                </div>
                <div>
                  <div className="label">{tr['pay']}: </div>
                  <div className="credit">
                    {formatPrice(data.balance.toPay)}
                  </div>
                </div>
              </div>
            </div>
            <div className="details__container">
              <span className="label">{tr['note']}: </span>
              <p>
                Klientą atsiuntė klinikos „Dantukas” gyd. Vardenis Pavardenis.
              </p>
            </div>
            <div className="details__container additional-info">
              <ul>
                {iconList.map((item, i) => (
                  <li key={i}>
                    {item.icon}
                    <div>{item.label}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </DialogLayout>
      </S.AppointmentsDetailsMobile>
    </Overlay>
  );
};

export default AppointmentsDetailsMobile;
