import { useState, useEffect, useContext, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// style
import * as S from './ClientsSelect.styled';
import { ContentLayout } from 'components/ContentLayout/ContentLayout.styled';

// icons
import CreateNewBoldSVG from 'icons/CreateNewBoldSVG';

// components
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';
import SelectProfessionals from '../SelectProfessionals/SelectProfessionals';
import ClientListItem from '../CustomSelect/children/ClientListItem/ClientListItem';
import CustomerInfo from 'components/Day Functions/CustomerSearch/CustomerInfo/CustomerInfo';
import TheButton from 'components/Buttons/TheButton';
import DialogLayout from '../DialogLayout';

// contexts
import { CreateOrderContext } from '../../../context/SchedulerCreatingEventContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useFunctionBar from 'hooks/useFunctionBar';
import useModal from 'hooks/useModal';
import useObserver from 'hooks/useObserver';
import { useEmployeesGet } from 'services/employeeService';
import { toIsoString } from 'common/helpers/dateOperations';

const professionals = [];

const ClientsSelect = ({ records, employeeIds }) => {
  const tr = useTranslate().use().global;
  const navigate = useNavigate();
  const location = useLocation();
  const displayRef = useRef();
  const functionBarItems = useFunctionBar('select-client');
  const { isShowing, toggle } = useModal();
  const { professional, client, sectorId } = useContext(CreateOrderContext);
  const [displayModal, setDisplayModal] = useState(<></>);
  const [modalSelect, setModalSelect] = useState('');
  const [displayProfessionals, setDisplayProfessionals] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const scheduleTime = useSelector((state) => state.scheduleReducer.dateState);
  const {
    data: currentEmloyeeData,
    isSuccess: currentEmployeeFound,
    refetch,
  } = useEmployeesGet({
    id: professional?.currSelectedProfessional?.[0]?.id,
    queryParams: {
      retry: false,
      enabled: false,
    },
  });

  const multiSelect = location.state?.multi;
  const createOrder = location.state?.toOrder;
  let professionalIds = JSON.parse(
    sessionStorage.getItem('selectedProfessionalIds'),
  );

  functionBarItems.find((item) => item.id === 'new-client').action = () =>
    navigate('create-new-client');

  useEffect(() => {
    if (!professional?.currSelectedProfessional) return;

    setDisplayProfessionals(professional.currSelectedProfessional);
    refetch();
  }, [professional.currSelectedProfessional]);

  useEffect(() => {
    if (currentEmployeeFound && currentEmloyeeData?.employee) {
      setDisplayProfessionals([currentEmloyeeData.employee]);
    }
  }, [currentEmployeeFound]);

  useEffect(() => {
    if (!isShowing) {
      setDisplayModal(undefined);
    }
  }, [isShowing]);

  useEffect(() => {
    switch (modalSelect) {
      case 'select-professional':
        setDisplayModal(
          <SelectionPanel
            key={'select-professional'}
            closeAction={() => toggleView(null)}
            headerTitle={tr.professionals}
            searchTitle={tr.professionalSearch}
            arrayFilter={setSearchInput}
          >
            <SelectProfessionals
              select={(prof) => selectProfessionals(prof)}
              selectedProf={displayProfessionals.map((prof) => prof.id)}
              calendarView={'i'} //TODO: should be by data from calendar?
              checkDay={toIsoString(scheduleTime).split('T')[0]}
              searchInput={searchInput}
              sectorId={sectorId}
            />
          </SelectionPanel>,
        );
        break;
      case 'client-information':
        setDisplayModal(
          <DialogLayout
            key={'client-information'}
            modal
            getBack={handleCloseAction}
            headerText={
              client.selectedClient.firstName +
              ' ' +
              client.selectedClient.lastName
            }
          >
            <CustomerInfo
              customer={client.selectedClient}
              createOrder={createOrder}
            />
          </DialogLayout>,
        );
        break;
      default:
        return;
    }
  }, [modalSelect, searchInput, displayProfessionals]);

  useEffect(() => {
    if (professionalIds) {
      const filteredProf = professionals.filter((obj) => {
        return professionalIds.find((item) => item === obj.id);
      });
      // professional.setCurrSelectedProfessional(filteredProf);
    } else if (
      professional.currSelectedProfessional?.length === 0 &&
      window.innerWidth < 960 &&
      createOrder
    ) {
      toggle();
      setModalSelect('select-professional');
    }
  }, []);

  let { width } = useObserver({
    ref: displayRef,
    debounceDelay: 10,
  });

  useEffect(() => {
    if (modalSelect && width && displayRef.current.offsetWidth !== width) {
      setModalSelect(null);
      toggle();
      if (createOrder) {
        sessionStorage.getItem('selectedProfessionalIds') === null
          ? navigate(-1)
          : toggle();
      } else {
        toggle();
      }
    }
  }, [width]);

  // Functions
  const toggleView = (newView) => {
    if (newView === 'select-professional') {
      setSearchInput('');
    }

    toggle();
    setModalSelect(newView);
  };

  const selectProfessionals = (prof) => {
    const newArr = [];

    if (multiSelect) {
      prof.forEach((item) => newArr.push({ id: item.id }));
    } else {
      newArr.push({ id: prof.id });
    }

    professional.setCurrSelectedProfessional(newArr);

    handleCloseAction();
  };

  const handleCloseAction = () => {
    setModalSelect(null);
    setDisplayModal(null);
    toggle();
    return;
  };

  const navigateToCreateOrder = () => {
    navigate('/functions/tables/orders/new', {
      state: {
        ...location.state,
        employeeIds,
        selectedClient: client.selectedClient,
      },
    });
  };

  return (
    <Routes>
      <Route
        index
        element={
          <S.ClientSelectContainer
            ref={displayRef}
            toOrder={createOrder}
            hasEmployeeSelected={!!employeeIds}
          >
            <div className="clientSelectWrapper">
              {/* <div className="professional-list">
                {displayProfessionals.map((obj, i) => (
                  <ProfessionalSelectChild
                    key={i}
                    currentProfessional={obj}
                    darkTheme
                    setShowSelect={() => toggleView('select-professional')}
                  />
                ))}
              </div> */}
              <div className="client-list">
                <div className="container">
                  <h3>{tr['clientSearch']}</h3>
                </div>
                {/* {createOrder && (
                  <ClientListItem
                    anonymous
                    select={() => {
                      client.setSelectedClient({
                        id: null,
                        firstName: tr['anonymous'],
                        lastName: '',
                      });
                      navigate('create-new-order', {
                        state: location.state,
                      });
                    }}
                  />
                )} */}
                {records.map((record, i) => (
                  <ClientListItem
                    key={i}
                    client={record}
                    isSelected={client.selectedClient?.id === record.id}
                    select={() => {
                      if (!!employeeIds) {
                        client.setSelectedClient(record);
                      }

                      // setModalSelect('client-information');
                      // toggle();
                    }}
                  />
                ))}
              </div>
            </div>
            {isShowing && <ContentLayout hideFab>{displayModal}</ContentLayout>}
            {employeeIds && (
              <div className="footer">
                <TheButton
                  title={tr['create-order']}
                  icon={<CreateNewBoldSVG />}
                  disabled={!client.selectedClient?.id}
                  action={navigateToCreateOrder}
                />
              </div>
            )}
          </S.ClientSelectContainer>
        }
      />
    </Routes>
  );
};

export default ClientsSelect;
