import { useContext, useState, useEffect, useCallback, memo } from 'react';
import { useSearchParams } from 'react-router-dom';

// Style
import * as S from './DataPanel.styled';

// Context
import { ScheduleContext } from 'context/ScheduleContext';
import { pinEmployee } from 'redux/actions/calendarViewActions';
import { useDispatch, useSelector } from 'react-redux';

// Components
import DataPanelColumn from './DataPanelColumn/DataPanelColumn';
import ColumnSkeleton from './DataPanelColumn/ColumnSkeleton/ColumnSkeleton';
import {
  dateIsInThePast,
  dateIsWeekend,
  datesAreOnSameDay,
  toIsoString,
} from 'common/helpers/dateOperations';

// Hooks
import useListExcusesWithAction from 'hooks/useListExcusesWithAction';
import useTranslate from 'hooks/useTranslate';
import { useColumnLooper } from 'hooks/schedule/useColumnLooper';

const DataPanel = ({ days, scrollToHour }) => {
  // Hooks
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');

  const listOfExcusesWithAction = useListExcusesWithAction();
  const {
    dataSource,
    numberOfDays,
    loader,
    personalDays,
    showOnlyWorkingDays,
    setColumnFetching,
    currPage,
    shouldUpdateSource,
    dataSourceDate,
    dataSourceSector,
    newOrderInfo,
    appointmentsFilter,
  } = useContext(ScheduleContext);
  const tr = useTranslate().use().global;
  const dispatch = useDispatch();
  const { loop } = useColumnLooper();

  const single = numberOfDays.visible !== 7;

  // -- state
  // --- local
  const [timeIndicatorValue, setTimeIndicatorValue] = useState(null);

  // --- global
  const startHour = useSelector((state) => state.scheduleReducer.startHour);
  const endHour = useSelector((state) => state.scheduleReducer.endHour);
  const dateState = useSelector((state) => state.scheduleReducer.dateState);
  const cellDuration = useSelector(
    (state) => state.scheduleReducer.cellDuration,
  );

  const orders = useSelector((state) => state.appointments);

  useEffect(() => {
    setIntervalPosition();

    const timeInterval = setInterval(() => {
      setIntervalPosition();
    }, 30000);

    return () => clearInterval(timeInterval);
  }, [dateState, startHour, endHour]);

  // Vars
  const cellsData = [];
  for (let i = startHour; i < endHour; i++) {
    for (let j = 0; j < 60 / cellDuration; j++) {
      cellsData.push({
        hour: parseFloat(`${i.toString()}.${(cellDuration * j).toString()}`),
        text: `${i.toString().padStart(2, '0')}:${(cellDuration * j)
          .toString()
          .padStart(2, '0')}`,
      });
    }
  }

  // Functions
  const getTimeIndicatorPosition = () => {
    const endTime = new Date();
    const startTime = new Date();

    endTime.setHours(endHour, 0, 0, 0);
    startTime.setHours(startHour, 0, 0, 0);

    const currentTime = new Date();
    const totalTime = endTime - startTime;
    const elapsedTime = currentTime - startTime;

    return (elapsedTime / totalTime) * 100;
  };

  const pinCollumnToStart = (id) => dispatch(pinEmployee(id));

  const setIntervalPosition = useCallback(() => {
    const topPosition = getTimeIndicatorPosition();
    setTimeIndicatorValue(topPosition);
  }, []);

  const shouldScrollInto = useCallback(
    (day, empId) => {
      if (newOrderInfo) {
        return (
          empId === newOrderInfo.employeeId &&
          datesAreOnSameDay(day, newOrderInfo.date)
        );
      }

      if (numberOfDays.visible < personalDays.total) return;

      return datesAreOnSameDay(day, dateState);
    },
    [numberOfDays.visible, personalDays, newOrderInfo],
  );

  const checkIfPast = useCallback((date) => dateIsInThePast(date), []);

  const checkifWeekend = useCallback((date) => dateIsWeekend(date), []);

  const getWorkSchedule = useCallback(
    (days, columnDate) => {
      const currDay = days.find(
        (day) => day.day === toIsoString(columnDate).split('T')[0],
      );

      return currDay?.schedule || [];
    },
    [showOnlyWorkingDays],
  );

  return (
    <S.DataPanel id="data-panel">
      {loop({
        keyPrefix: 'column',
        skeleton: <ColumnSkeleton cells={cellsData.length} />,
        appendToEnd: loader.end,
        noData: (
          <div>
            <S.NoDataBox>
              <span>{tr['no-work-schedule-for-this-day']}</span>
              <p>{tr['no-work-schedule-for-this-day-description']}</p>
            </S.NoDataBox>
          </div>
        ),
        callback: (sourceItem, day) => {
          return (
            <DataPanelColumn
              key={
                sourceItem.employee.id +
                '-' +
                toIsoString(day.date).split('T')[0] +
                '-' +
                day.day +
                sectorId
              }
              count={day.day}
              employee={sourceItem.employee}
              workSchedule={getWorkSchedule(sourceItem.days, day.date)}
              cellsData={cellsData}
              globalOrders={orders?.employees}
              timeIndicator={timeIndicatorValue}
              pinCollumnToStart={() =>
                pinCollumnToStart(sourceItem.employee.id)
              }
              columnDay={day.date}
              listOfExcusesWithAction={listOfExcusesWithAction}
              scrollIntoView={shouldScrollInto(
                day.date,
                sourceItem.employee.id,
              )}
              isInThePast={checkIfPast(day.date)}
              isWeekend={checkifWeekend(day.date)}
              days={days.length}
              sectorId={sectorId}
              scrollToHour={scrollToHour}
            />
          );
        },
      })}
    </S.DataPanel>
  );
};

export default memo(DataPanel);
