import { useContext } from 'react';
import ClickAwayListener from 'react-click-away-listener';

// style
import * as S from './OrderRowNote.styled';

// icons
import { Check, Ex } from 'icons/dynamic';
import MessageSVG from 'icons/MessageSVG';

// components
import ButtonSquare from 'components/Buttons/ButtonSquare';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useOrderNoteStatus } from 'hooks/useOrderNoteStatus';

const OrderRowNote = ({ colSpan }) => {
  const tr = useTranslate().use().global;
  const {
    inputRef,
    isNoteDisabled,
    isEditingNote,
    inputValue,
    setInputValue,
    setIsEditingNote,
    handleClickAway,
    handleSubmit,
    handleReset,
  } = useOrderNoteStatus();
  const { isNewOrder } = useContext(OrderContext);

  return (
    <S.OrderRowNote disabled={isNoteDisabled}>
      <td colSpan={colSpan}>
        <div className="background">
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="content">
              {(isNewOrder || !isEditingNote) && (
                <div className="icon-container">
                  <MessageSVG />
                </div>
              )}
              {!isNewOrder && isEditingNote && (
                <div className="buttons-container">
                  <ButtonSquare
                    onClick={handleReset}
                    Icon={<Ex black width={10} height={10} />}
                  />
                  <ButtonSquare
                    onClick={handleSubmit}
                    Icon={<Check black width={10} height={10} />}
                  />
                </div>
              )}
              <div className="input-container">
                <input
                  disabled={isNoteDisabled}
                  ref={inputRef}
                  onFocus={() => setIsEditingNote(true)}
                  value={inputValue}
                  type={'text'}
                  placeholder={tr['note']}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </td>
    </S.OrderRowNote>
  );
};

export default OrderRowNote;
