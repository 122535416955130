import { useCallback } from 'react';
import Select, { components } from 'react-select';

// style
import * as S from 'components/InputField/InputField.styled';
import { StyledInputErrorMessage } from '../InputsCommon.styled';

// icons
import { Arrow } from 'icons/dynamic';

// hooks
import useTranslate from 'hooks/useTranslate';

// helpers
import { animateClose } from 'common/helpers/animateClose';

export default ({
  id,
  label,
  value,
  disabled,
  onChange,
  options,
  errorMessage,
  className,
  // styles,
  inPortal = true,
  ...other
}) => {
  const tr = useTranslate().use().global;

  const menuOpenCallback = useCallback(() => {
    const dropDownArrow = document.querySelector(
      `#${id} .select__dropdown-indicator`,
    );
    dropDownArrow?.classList.replace('close', 'open');
  }, []);

  const menuCloseCallback = useCallback(() => {
    if (inPortal) {
      animateClose('select__menu');
    }

    const dropDownArrow = document.querySelector(
      `#${id} .select__dropdown-indicator`,
    );
    dropDownArrow?.classList.replace('open', 'close');
  }, []);

  let reactSelectValue = null;
  const optionLabel = options.find((item) => item.value === value)?.label;

  if (optionLabel) {
    reactSelectValue = {
      value: value,
      label: optionLabel,
    };
  }

  let classNames = 'select regular-select';
  if (className) {
    classNames += ' ' + className;
  }

  return (
    <S.InputField
      disabled={disabled}
      noLabel={!label}
      className={'input-field'}
      fieldType={'select'}
      inputType={undefined}
      notClearable={undefined}
      noOptions={undefined}
      noValue={!value}
      extendOnOpen={undefined}
      menuHeight={undefined}
      stayOpen={undefined}
      prepend={undefined}
      required={undefined}
      width={undefined}
      isInvalid={!!errorMessage}
    >
      {!!label && <label htmlFor={id}>{label}</label>}
      <Select
        // menuIsOpen={true}
        id={id}
        className={classNames}
        classNamePrefix="select"
        placeholder={disabled ? '-' : tr['select']}
        isDisabled={disabled}
        options={options}
        value={reactSelectValue}
        onChange={(val) => onChange(val === null ? val : val.value)}
        onMenuOpen={menuOpenCallback}
        onMenuClose={menuCloseCallback}
        {...(inPortal && {
          menuPortalTarget: document.getElementById('portal'),
        })}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          // menu: (base) => ({
          //   ...base,
          //   ...styles?.menu,
          // }),
        }}
        components={{
          Menu: Menu,
          DropdownIndicator,
          IndicatorSeparator: null,
        }}
        {...other}
      />
      <StyledInputErrorMessage>{errorMessage}</StyledInputErrorMessage>
    </S.InputField>
  );
};

const Menu = (props) => <S.Menu {...props}></S.Menu>;

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Arrow black width={16} height={16} className="arrow" />
  </components.DropdownIndicator>
);
