import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const StyledButton = styled.button`
  opacity: 0.95;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  padding: 10px 0;
  border: ${(props) => props.theme.border};
  background-color: ${(props) => props.theme.backgroundColor};
  font-weight: bold;
  font-family: ${fontFamilies.family_Primary};
  color: ${(props) => props.theme.color};

  svg {
    padding-right: 10px;
  }
`;

export const SideMenuCleanButtonStyled = styled.button`
  position: fixed;
  font-family: var(--font-family-primary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 100px;
  bottom: 25px;
  width: 80px;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  font-weight: bold;
  border: 0;
  padding: 2px 5px 5px 5px;
  outline: none;
  border-radius: 12px;
`;

export const SvgButton = styled.button`
  border: none;
  background-color: white;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  cursor: pointer;

  div {
    pointer-events: none;
  }

  span {
    z-index: 200;

    position: absolute;
    overflow: visible;
  }

  @media screen and (min-width: 961px) {
    ::after {
      content: attr(data-text);
      position: absolute;
      color: ${colors.calendar_button_Secondary};

      top: 3px;
      left: 110%;

      width: max-content;

      font-size: 11px;
      background-color: white;

      padding: 5px 7px 5px 7px;
      border-radius: 1rem;
      text-align: center;
      font-family: ${fontFamilies.family_Secondary};

      transition: 0.2s ease-in-out;

      z-index: 1;

      display: flex;
      align-items: center;

      box-shadow: 0 0 8px rgba(0, 0, 0, 0.32);

      display: none;
    }

    :hover:after {
      display: ${(props) => (props.isTooltip ? 'flex' : 'none')};
    }
  }
`;
