import * as S from '../SVG.styled';

export default ({ color, width, height, bold }) => {
  const content = bold ? (
    <g transform="matrix(0.31867431,0,0,0.31867431,0.19598493,2.283e-7)">
      <path d="m 2,54.46 c 1.1,0 2,-0.9 2,-2 V 8 C 4,5.79 5.79,4 8,4 h 23.08 c 0,0 0,0 0,0 h 28.76 v 47.19 c 0,1.1 0.9,2 2,2 1.1,0 2,-0.9 2,-2 v -9.8 h 25.08 v 28.16 c 0,2.21 -1.79,4 -4,4 H 73.33 c -1.1,0 -2,0.9 -2,2 0,1.1 0.9,2 2,2 h 11.59 c 4.41,0 8,-3.59 8,-8 V 8 c 0,-4.41 -3.59,-8 -8,-8 H 31.08 c 0,0 0,0 0,0 H 8 C 3.59,0 0,3.59 0,8 v 44.46 c 0,1.1 0.9,2 2,2 z M 88.92,8 V 37.38 H 63.84 V 4 h 21.08 c 2.21,0 4,1.79 4,4 z" />
      <path d="m 12.64,32.68 c 0,12.19 8.65,22.11 19.28,22.11 10.63,0 19.28,-9.92 19.28,-22.11 0,-12.19 -8.65,-22.12 -19.28,-22.12 -10.63,0 -19.28,9.92 -19.28,22.12 z m 34.56,0 c 0,9.99 -6.85,18.11 -15.28,18.11 -8.43,0 -15.28,-8.13 -15.28,-18.11 0,-9.98 6.85,-18.12 15.28,-18.12 8.43,0 15.28,8.13 15.28,18.12 z" />
      <path d="m 2,94.14 h 59.84 c 1.1,0 2,-0.9 2,-2 V 76.17 c 0,-9.37 -5.95,-17.71 -14.82,-20.76 l -0.86,-0.29 c -0.64,-0.22 -1.34,-0.1 -1.88,0.32 -4.24,3.31 -9.21,5.07 -14.37,5.07 -5.16,0 -10.13,-1.75 -14.37,-5.07 C 17.01,55.02 16.3,54.9 15.66,55.12 L 14.8,55.41 C 5.94,58.46 -0.01,66.8 -0.01,76.17 v 15.97 c 0,1.1 0.9,2 2,2 z M 4,76.17 c 0,-7.61 4.8,-14.39 11.96,-16.92 4.75,3.44 10.25,5.26 15.96,5.26 5.71,0 11.2,-1.81 15.96,-5.26 7.16,2.54 11.96,9.32 11.96,16.92 V 90.14 H 4 Z" />
    </g>
  ) : (
    <g transform="matrix(0.31867431,0,0,0.31867431,0.19598493,2.283e-7)">
      <path d="m 2,54.46 c 1.1,0 2,-0.9 2,-2 V 8 C 4,5.79 5.79,4 8,4 h 23.08 c 0,0 0,0 0,0 h 28.76 v 47.19 c 0,1.1 0.9,2 2,2 1.1,0 2,-0.9 2,-2 v -9.8 h 25.08 v 28.16 c 0,2.21 -1.79,4 -4,4 H 73.33 c -1.1,0 -2,0.9 -2,2 0,1.1 0.9,2 2,2 h 11.59 c 4.41,0 8,-3.59 8,-8 V 8 c 0,-4.41 -3.59,-8 -8,-8 H 31.08 c 0,0 0,0 0,0 H 8 C 3.59,0 0,3.59 0,8 v 44.46 c 0,1.1 0.9,2 2,2 z M 88.92,8 V 37.38 H 63.84 V 4 h 21.08 c 2.21,0 4,1.79 4,4 z" />
      <path d="m 12.64,32.68 c 0,12.19 8.65,22.11 19.28,22.11 10.63,0 19.28,-9.92 19.28,-22.11 0,-12.19 -8.65,-22.12 -19.28,-22.12 -10.63,0 -19.28,9.92 -19.28,22.12 z m 34.56,0 c 0,9.99 -6.85,18.11 -15.28,18.11 -8.43,0 -15.28,-8.13 -15.28,-18.11 0,-9.98 6.85,-18.12 15.28,-18.12 8.43,0 15.28,8.13 15.28,18.12 z" />
      <path d="m 2,94.14 h 59.84 c 1.1,0 2,-0.9 2,-2 V 76.17 c 0,-9.37 -5.95,-17.71 -14.82,-20.76 l -0.86,-0.29 c -0.64,-0.22 -1.34,-0.1 -1.88,0.32 -4.24,3.31 -9.21,5.07 -14.37,5.07 -5.16,0 -10.13,-1.75 -14.37,-5.07 C 17.01,55.02 16.3,54.9 15.66,55.12 L 14.8,55.41 C 5.94,58.46 -0.01,66.8 -0.01,76.17 v 15.97 c 0,1.1 0.9,2 2,2 z M 4,76.17 c 0,-7.61 4.8,-14.39 11.96,-16.92 4.75,3.44 10.25,5.26 15.96,5.26 5.71,0 11.2,-1.81 15.96,-5.26 7.16,2.54 11.96,9.32 11.96,16.92 V 90.14 H 4 Z" />
    </g>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
