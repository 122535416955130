import { useEffect } from 'react';

// style
import * as S from './ListSelector.styled';

// icons
import { RadioSVG, Check, Circle } from 'icons/dynamic';

export const ListSelector = ({ state, type, legend }) => {
  const { current, options, setValue } = state;

  const getDefaultValue = () => {
    if (!('default' in state)) return;

    if (state.default instanceof Object) {
      return state.default;
    } else return options.find((option) => option.value === state.default);
  };

  useEffect(() => setValue(getDefaultValue()), []);

  return (
    <S.ListSelector className="list-selector">
      {legend && <legend>{legend}</legend>}

      <div className="options">
        {options?.map((option, index) => (
          <label key={index} onClick={() => setValue(option)}>
            {type === 'radio' && (
              <>
                <input
                  type={type}
                  id={option.value}
                  name={current}
                  defaultChecked={getDefaultValue()?.value === option.value}
                  onChange={() => setValue(option)}
                />
                <RadioSVG checked={current?.value === option.value} />
              </>
            )}

            {type === 'checkbox' &&
              (current?.value?.includes(option.value) ? (
                <Check black inCircle width={16} height={16} />
              ) : (
                <Circle bold width={16} height={16} />
              ))}

            <span>{option.label}</span>
          </label>
        ))}
      </div>
    </S.ListSelector>
  );
};

export default ListSelector;
