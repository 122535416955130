import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const FilterSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19.794"
      viewBox="0 0 20 19.794"
      color={color ? color : 'currentColor'}
    >
      <path
        transform="translate(-728.32 -1612)"
        d="M748.266,1612.392a.629.629,0,0,0-.58-.383H728.947a.631.631,0,0,0-.455,1.068l6.573,6.849v7.345a.632.632,0,0,0,.254.506l5.242,3.9a.631.631,0,0,0,1.007-.506v-11.246l6.573-6.849A.63.63,0,0,0,748.266,1612.392Zm-7.539,7.2v11.165l-4.821-3.587v-7.578l-6.465-6.738h17.752Z"
        // fill="#0f1d5b"
        data-name="Path 968"
      />
    </StyledSVG>
  );
};

export default FilterSVG;
