import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const ListViewSVG = ({ color }) => {
  return (
    <StyledSVG
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="16.038"
      height="13.21"
      viewBox="0 0 16.038 13.21"
      color={color}
    >
      <g id="Layer_1-2" data-name="Layer 1-2">
        <path
          id="Path_451"
          data-name="Path 451"
          d="M14.675,0H1.363A1.363,1.363,0,0,0,0,1.363v2.91A1.363,1.363,0,0,0,1.363,5.635H14.675a1.363,1.363,0,0,0,1.363-1.363V1.363A1.363,1.363,0,0,0,14.675,0Zm.681,4.273a.681.681,0,0,1-.681.681H1.363a.681.681,0,0,1-.681-.681V1.363A.681.681,0,0,1,1.363.681H14.675a.681.681,0,0,1,.681.681Z"
          // fill="#ffa35f"
        />
        <path
          id="Path_452"
          data-name="Path 452"
          d="M14.675,44.46H1.363A1.363,1.363,0,0,0,0,45.823v2.91A1.363,1.363,0,0,0,1.363,50.1H14.675a1.363,1.363,0,0,0,1.363-1.363v-2.91A1.363,1.363,0,0,0,14.675,44.46Zm.681,4.273a.681.681,0,0,1-.681.681H1.363a.681.681,0,0,1-.681-.681v-2.91a.681.681,0,0,1,.681-.681H14.675a.681.681,0,0,1,.681.681Z"
          transform="translate(0 -36.886)"
          // fill="#ffa35f"
        />
      </g>
    </StyledSVG>
  );
};

export default ListViewSVG;
