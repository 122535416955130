import { useEffect, useState } from 'react';

// hooks
import useFunctionBar from './useFunctionBar';
import useFunctionList from './useFunctionList';
import useStatusList from './useStatusList';
import { useOrderPaymentStatusPatch } from 'services/orderService';
import { ORDER_COMPLETION_STATUS } from 'common/Constants';

export const useOrderFunctions = ({
  orderId,
  order,
  isDirty,
  isNewOrder,
  setOrder,
  setModalSwitch,
  modalToggle,
}) => {
  const {
    mutate: mutatePaymentStatus,
    isSuccess: isPaymentStatusSuccess,
    data: paymentStatusData,
  } = useOrderPaymentStatusPatch();

  let functionsList = [
    'change-status',
    'client-card',
    'functions',
    // 'print',
  ];

  if (!isNewOrder) {
    functionsList = ['change-payment-status', ...functionsList];
  }

  const functions = useFunctionBar(functionsList);
  let paymentStatusList = useFunctionList(['order-paid', 'order-unpaid']);
  let folderFuncList = useFunctionList(['client-card']);
  let functionListItems = useFunctionList([
    'send-sms-reminder',
    'send-sms-link',
    'save-recommendation',
    'delete-order',
  ]);
  let statusList = useStatusList([
    ORDER_COMPLETION_STATUS.ORDERED,
    ORDER_COMPLETION_STATUS.WILL_ARRIVE,
    ORDER_COMPLETION_STATUS.WILL_NOT_ARRIVE,
    ORDER_COMPLETION_STATUS.NOT_ARRIVED,
  ]);

  const [functionBarFunctions, setFunctionBarFunctions] = useState(functions);

  useEffect(() => {
    if (isPaymentStatusSuccess) {
      const updatedOrder = paymentStatusData.data.order;
      setOrder((prev) => ({
        ...prev,
        isPaid: updatedOrder.isPaid,
        version: updatedOrder.version,
      }));
    }
  }, [isPaymentStatusSuccess]);

  useEffect(() => {
    if (!isNewOrder) {
      setFunctionBarFunctions(() => {
        let newFunctions = functions;

        if (isDirty) {
          newFunctions = newFunctions.filter(
            (func) => func.id !== 'change-payment-status',
          );
        }

        return newFunctions;
      });
    }
  }, [isDirty]);

  const updatePaymentStatus = (isPayed) =>
    mutatePaymentStatus({
      id: orderId,
      version: order.version,
      isPaid: isPayed,
    });

  const changeOrderStatus = (value) => {
    setOrder((prev) => {
      if (prev.toggled) {
        return { ...prev, status: value };
      } else {
        return prev;
      }
    });
  };

  const changePaymentStatusFunc = functionBarFunctions.find(
    (item) => item.id === 'change-payment-status',
  );
  const changeStatusFunc = functionBarFunctions.find(
    (item) => item.id === 'change-status',
  );
  const clientCardFunc = functionBarFunctions.find(
    (item) => item.id === 'client-card',
  );
  const functionsFunc = functionBarFunctions.find(
    (item) => item.id === 'functions',
  );

  if (changePaymentStatusFunc) {
    changePaymentStatusFunc.subItems = paymentStatusList.reduce(
      (prev, curr) => {
        return [
          ...prev,
          {
            ...curr,
            name: curr.title,
            action: () => updatePaymentStatus(curr.id === 'order-paid'),
          },
        ];
      },
      [],
    );
  }

  changeStatusFunc.subItems = statusList.reduce((prev, curr) => {
    return [
      ...prev,
      {
        ...curr,
        name: curr.title,
        statusColor: curr.background,
        action: () => {
          changeOrderStatus(curr.id);
        },
      },
    ];
  }, []);

  clientCardFunc.subItems = folderFuncList.reduce((prev, curr) => {
    return [
      ...prev,
      {
        ...curr,
        name: curr.title,
      },
    ];
  }, []);

  functionsFunc.subItems = functionListItems.reduce((prev, curr) => {
    return [
      ...prev,
      {
        ...curr,
        name: curr.title,
        fontColor: curr?.color,
        color: curr?.background,
        action: () => {
          if (curr.id === 'send-sms-reminder' || curr.id === 'send-sms-link') {
            setModalSwitch(curr.id);
            modalToggle();
          } else {
            console.log('other action');
          }
        },
      },
    ];
  }, []);

  return {
    functionBarFunctions,
  };
};
