import * as S from '../SVG.styled';

export default ({ color, width, height, bold }) => {
  const content = bold ? (
    <>
      <path d="m18.008 16.683c-3.8833 0-7.0407-3.5876-7.0407-7.9996 0-4.412 3.1574-8.0026 7.0407-8.0026 3.8833 0 7.0407 3.5906 7.0407 8.0026 0 4.412-3.1574 7.9996-7.0407 7.9996zm0-13.612c-2.563 0-4.651 2.5182-4.651 5.6129 0 3.0947 2.085 5.6099 4.651 5.6099 2.566 0 4.651-2.5152 4.651-5.6099 0-3.0947-2.085-5.6129-4.651-5.6129z" />
      <path d="m28.132 29.997h-20.247c-0.66016 0-1.1949-0.5347-1.1949-1.1949v-5.4038c0-3.3904 2.1537-6.4104 5.3619-7.5127l0.29274-0.09858c0.38236-0.12845 0.80354-0.05974 1.1202 0.19118 1.3412 1.0485 2.9125 1.6041 4.5435 1.6041 1.631 0 3.1992-0.55561 4.5435-1.6041 0.31664-0.24793 0.73783-0.31962 1.1202-0.19118l0.28975 0.09858c3.2112 1.1052 5.3649 4.1253 5.3649 7.5157v5.4038c0 0.66016-0.5347 1.1949-1.1949 1.1949zm-19.052-2.3897h17.857v-4.2089c0-2.2643-1.3771-4.2955-3.4561-5.1439-1.6429 1.1232-3.5219 1.7146-5.4725 1.7146-1.9506 0-3.8295-0.59146-5.4725-1.7146-2.0791 0.84835-3.4561 2.8766-3.4561 5.1439z" />
      <polygon
        transform="matrix(.29872 0 0 .29872 .6736 1.632e-7)"
        points="21.74 0 21.74 46.11 13.74 46.11 13.74 12.89 3.55 17.94 0 10.77"
      />
    </>
  ) : (
    <>
      <path d="m18.345 15.955c-3.7468 0-6.7972-3.5041-6.7972-7.8087 0-4.3046 3.0472-7.8118 6.7972-7.8118 3.7499 0 6.7972 3.5041 6.7972 7.8118 0 4.3077-3.0504 7.8087-6.7972 7.8087zm0-14.36c-3.0535 0-5.5367 2.9401-5.5367 6.5514 0 3.6113 2.4832 6.5482 5.5367 6.5482 3.0535 0 5.5367-2.9369 5.5367-6.5482 0-3.6113-2.4832-6.5514-5.5367-6.5514z" />
      <path d="m29.024 30h-21.359c-0.34663 0-0.63024-0.28361-0.63024-0.63024v-5.7005c0-3.3088 2.1019-6.252 5.231-7.3297l0.30882-0.10399c0.20168-0.06933 0.42226-0.03151 0.59243 0.10084 1.5283 1.1943 3.3182 1.8246 5.1806 1.8246 1.8624 0 3.6523-0.63024 5.1806-1.8246 0.16701-0.13235 0.39075-0.16701 0.59243-0.10084l0.30567 0.10399c3.1292 1.0777 5.2342 4.0241 5.2342 7.3297v5.7005c0 0.34663-0.28361 0.63024-0.63024 0.63024zm-20.729-1.2605h20.098v-5.0703c0-2.7636-1.7552-5.2279-4.3676-6.1323-1.689 1.2353-3.6491 1.8844-5.6816 1.8844-2.0325 0-3.9926-0.6523-5.6816-1.8844-2.6124 0.9044-4.3676 3.3686-4.3676 6.1323z" />
      <polygon
        transform="matrix(.31512 0 0 .31512 .339 .00024516)"
        points="18.85 0 18.85 42.89 14.85 42.89 14.85 6.45 1.78 12.92 0 9.34"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
