import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';

export const Login = styled.div`
  display: flex;
  font-family: ${fontFamilies.family_Secondary};
  height: 100svh;
  overflow: hidden;
  width: 100vw;

  .title-section,
  .login-section {
    overflow: auto;
    width: 50%;

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }

  .title-section {
    float: left;
  }

  .login-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    float: right;
    gap: 100px;
    padding-block: 160px;

    @media screen and (max-width: 960px) {
      background: radial-gradient(ellipse at top right, #245bb3, #0f1d5b);
      height: 100%;
      margin: 0;
      padding: 60px 40px 40px;
      width: 100%;
    }
  }

  .login-section > div {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }

  .login-section > div > svg {
    flex-shrink: 0;
    width: 200px;

    @media screen and (max-width: 960px) {
      left: 20px;
      position: absolute;
      top: 20px;
      width: 120px;
    }
  }
`;
