import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const NavBarMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .headerSearch_container {
    visibility: ${({ searchIconVisible }) =>
      searchIconVisible ? 'visible' : 'hidden'};
    display: flex;
    svg {
      fill: white;
    }

    @media screen and (min-width: 961px) {
      display: none;
    }
  }

  .search_container {
    width: 100%;
    display: flex;

    .searchExit {
      display: flex;
      align-items: center;
      padding: 5px;

      svg {
        fill: ${colors.bg_Primary};
      }
    }
  }
`;

const burgerSize = { md: 25, sm: 20 };

export const BurgerContainer = styled.div`
  cursor: pointer;
  display: none;

  @media (max-width: 960px) {
    display: block;
    height: ${burgerSize.md}px;
    width: ${burgerSize.md}px;
  }

  @media (max-width: 960px) {
    height: ${burgerSize.sm}px;
    width: ${burgerSize.sm}px;
  }

  > svg {
    height: 100%;
    width: 100%;
    fill: white;
  }
`;

export const LogoContainer = styled.div`
  svg {
    display: block;
    height: 2rem;
    margin-top: 0.5rem;
  }

  @media (max-width: 960px) {
    svg {
      height: 1.5rem;
    }
  }
`;
