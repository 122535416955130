import { useCallback, useEffect, useState } from 'react';

// style
import * as S from './ServiceGroup.styled';

// components
import { ServiceContents } from '../ServiceContents/ServiceContents';
import { ServiceTree } from '../ServiceTree/ServiceTree';
import { Message } from 'components/Message/Message';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useServicesGetGroupById } from 'services/serviceService';

export const ServiceGroup = ({
  serviceActivity,
  activeGroup,
  getDbItem,
  getCachedItem,
  initialButtons,
  isCacheEmpty,
  close,
  updateCache,
  toggleActivity,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;

  // get data from B/E
  const {
    data: dbData,
    isSuccess,
    refetch,
  } = useServicesGetGroupById({
    id: activeGroup.id,
    queryParams: { serviceActivity },
  });

  const [localCache, setLocalCache] = useState({});

  useEffect(() => {
    if (isSuccess) {
      console.log('filling');

      setLocalCache((old) => {
        const newCache = { ...dbData };

        if (newCache.services) {
          newCache.services = newCache.services.map((service) => ({
            ...service,
          }));
        }
        if (newCache.subgroups) {
          newCache.subgroups = newCache.subgroups.map((subgroup) => ({
            ...subgroup,
            ...(subgroup.services
              ? {
                  services: subgroup.services.map((service) => ({
                    ...service,
                  })),
                }
              : {}),
          }));
        }

        newCache.services?.forEach((service) => {
          service.price = '0.00';
          service.duration = '00:00';
          service.break = '00:00';
        });
        newCache.subgroups?.forEach((subgroup) => {
          subgroup.services?.forEach((service) => {
            service.price = '0.00';
            service.duration = '00:00';
            service.break = '00:00';
          });
        });

        return newCache;
      });
    }
  }, [isSuccess, dbData]);

  const getItem = useCallback(
    (type, id) => {
      return { ...getDbItem(localCache, type, id), ...getCachedItem(type, id) };
    },
    [getDbItem, getCachedItem, localCache],
  );

  const updateValue = (id, propId, value) => {
    updateCache(localCache, 'service', id, propId, value);
  };

  // // debug
  // useEffect(() => console.log({ localCache }), [localCache]);

  return (
    <S.ServiceGroup>
      {!isMobile ? (
        <>
          {isSuccess && (dbData?.subgroups || dbData?.services) && (
            <>
              <Message>
                <span>
                  {tr['employee-services-info-01']}
                  <b>{tr['employee-services-info-02']}</b>
                  {tr['employee-services-info-03']}
                </span>
              </Message>

              <div className="header">
                <span>{tr['service-name']}</span>
                <span>{tr['price']}</span>
                <span>{tr['duration']}</span>
                <span>{tr['break']}</span>
              </div>
            </>
          )}

          {isSuccess &&
            localCache?.subgroups?.map((subgroup) => (
              <ServiceContents
                className="subgroup"
                key={subgroup.id}
                contents={subgroup}
                getDbItem={(type, id) => getDbItem(localCache, type, id)}
                getItem={getItem}
                toggleActivity={(type, id) =>
                  toggleActivity(localCache, type, id)
                }
                updateValue={updateValue}
              />
            ))}

          {isSuccess &&
            localCache?.services?.map((service) => (
              <ServiceContents
                className="service"
                key={service.id}
                contents={service}
                getDbItem={(type, id) => getDbItem(localCache, type, id)}
                getItem={getItem}
                toggleActivity={(type, id) =>
                  toggleActivity(localCache, type, id)
                }
                updateValue={updateValue}
              />
            ))}
        </>
      ) : (
        <ServiceTree
          serviceActivity={serviceActivity}
          id={activeGroup.id}
          data={localCache}
          getDbItem={(type, id) => getDbItem(localCache, type, id)}
          getItem={getItem}
          toggleActivity={(type, id) => toggleActivity(localCache, type, id)}
          updateValue={updateValue}
          initialButtons={initialButtons}
          isCacheEmpty={isCacheEmpty}
          close={close}
        />
      )}
    </S.ServiceGroup>
  );
};
