import { useBlocker } from 'react-router';

// components
import DialogBox from 'components/DialogBox/DialogBox';
import Overlay from 'components/Overlay/Overlay';

// hooks
import { useLeavePage } from 'hooks/useLeavePage';
import useTranslate from 'hooks/useTranslate';

// helpers
import { DIALOG_BOX_TYPE } from 'common/Constants';

export const UnsavedChangesDialogBox = ({ condition, checkIfBypass }) => {
  const tr = useTranslate().use().global;
  useLeavePage(condition && (checkIfBypass ? !checkIfBypass() : true));

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const currentLocationSearchParams = new URLSearchParams(
      currentLocation.search,
    );
    const nextLocationSearchParams = new URLSearchParams(nextLocation.search);

    if (checkIfBypass && checkIfBypass()) {
      return false;
    }

    return (
      !!condition &&
      (currentLocation.pathname !== nextLocation.pathname ||
        currentLocationSearchParams.get('sectorId') !==
          nextLocationSearchParams.get('sectorId'))
    );
  });

  const confirmationButtons = [
    {
      id: 'cancel',
      title: tr['stay'],
      message: tr['leaving-page-with-unsaved-changes'],
      action: blocker.reset,
      inverted: true,
    },
    {
      id: 'confirm',
      title: tr['leave'],
      action: blocker.proceed,
      dontCloseAfter: true,
    },
  ];

  return (
    <Overlay isVisible={blocker.state === 'blocked'} hide={blocker.reset}>
      <div className="modal-wrapper">
        <DialogBox
          close={blocker.reset}
          headerText={tr['warning']}
          buttons={confirmationButtons}
          type={DIALOG_BOX_TYPE.CONFIRMATION}
        >
          <>{confirmationButtons[0]?.message}</>
        </DialogBox>
      </div>
    </Overlay>
  );
};
