import * as S from '../SVG.styled';

const SendMobileSVG = ({ color, width, height, sm, lg }) => {
  let content;
  let size;

  switch (true) {
    case sm:
      size = 16;
      content = (
        <>
          <path d="m12.091-2.377e-7h-8.1814c-0.65827 0-1.1941 0.53587-1.1941 1.1941v13.612c0 0.65827 0.53587 1.1941 1.1941 1.1941h8.1814c0.65827 0 1.1941-0.53587 1.1941-1.1941v-13.612c0-0.65827-0.53587-1.1941-1.1941-1.1941zm0 1.1941v10.182h-8.1814v-10.182zm-8.1814 13.612v-2.236h8.1814v2.236h-8.1814z" />
          <path d="m6.4782 3.4615h3.0421c0.32988 0 0.59707-0.26719 0.59707-0.59707 0-0.32988-0.26719-0.59707-0.59707-0.59707h-3.0421c-0.32988 0-0.59707 0.26719-0.59707 0.59707 0 0.32988 0.26719 0.59707 0.59707 0.59707z" />
        </>
      );
      break;
    case lg:
    default:
      size = 30;
      content = (
        <>
          <path d="m7.0327 30h15.935c0.9623 0 1.7444-0.78205 1.7444-1.7444v-26.511c0-0.9623-0.78205-1.7444-1.7444-1.7444h-15.935c-0.9623 0-1.7444 0.78205-1.7444 1.7444v26.511c0 0.9623 0.78205 1.7444 1.7444 1.7444zm15.935-1.1629h-15.935c-0.3198 0-0.58145-0.26165-0.58145-0.58145v-4.9365h17.098v4.9365c0 0.3198-0.26165 0.58145-0.58145 0.58145zm-15.935-27.674h15.935c0.3198 0 0.58145 0.26165 0.58145 0.58145v20.412h-17.098v-20.412c0-0.3198 0.26165-0.58145 0.58145-0.58145z" />
          <path d="m12.036 5.579h5.925c0.3198 0 0.58145-0.26165 0.58145-0.58145 0-0.3198-0.26165-0.58145-0.58145-0.58145h-5.925c-0.3198 0-0.58145 0.26165-0.58145 0.58145 0 0.3198 0.26165 0.58145 0.58145 0.58145z" />
        </>
      );
      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : size}
      height={height ? height : size}
      version="1.1"
      viewBox={`0 0 ${size} ${size}`}
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default SendMobileSVG;
