import * as S from '../SVG.styled';

const FilterSVG = ({ color, width, height, sm, lg }) => {
  let content;
  let size;

  switch (true) {
    case sm:
      size = 16;
      content = (
        <path d="m15.194 0.079h-14.386c-0.32446 0-0.61502 0.19209-0.74254 0.49072-0.12752 0.29702-0.064569 0.64085 0.15981 0.87491l4.9557 5.1655v5.5093c0 0.25343 0.12107 0.49718 0.32607 0.6473l4.0243 2.9944c0.14205 0.10654 0.31155 0.15981 0.48104 0.15981 0.1243 0 0.24859-0.02906 0.3632-0.08717 0.27442-0.13721 0.44391-0.41486 0.44391-0.72156v-8.5021l4.9557-5.1655c0.22438-0.23406 0.28733-0.57789 0.15981-0.87491-0.12752-0.29702-0.41808-0.49072-0.74254-0.49072zm-5.4851 5.8241c-0.11461 0.12107-0.17918 0.28088-0.17918 0.44714v7.7999l-3.0557-2.2744v-5.5255c0-0.16627-0.064569-0.32607-0.17918-0.44714l-4.3503-4.5327h12.113z" />
      );
      break;
    case lg:
    default:
      size = 30;
      content = (
        <path d="m10.124 23.047c0 0.29647 0.14193 0.58033 0.38163 0.76011l7.8629 5.8506c0.16716 0.123 0.36586 0.18608 0.56456 0.18608 0.14508 0 0.29017-0.03469 0.42579-0.10093 0.3217-0.16085 0.52041-0.48571 0.52041-0.84526v-16.867l9.8562-10.276c0.26178-0.2744 0.33748-0.6781 0.18924-1.025-0.14824-0.35009-0.49202-0.57402-0.8705-0.57402h-28.108c-0.37848-0.0032-0.72226 0.22393-0.8705 0.57087-0.14824 0.35009-0.0757 0.7538 0.18924 1.0282l9.8593 10.276zm-8.4369-21.633h26.626l-9.5218 9.9256c-0.11354 0.1167-0.17662 0.2744-0.17662 0.43525v16.495l-7.2321-5.3807v-11.115c0-0.16401-0.06308-0.31855-0.17662-0.43525z" />
      );
      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : size}
      height={height ? height : size}
      version="1.1"
      viewBox={`0 0 ${size} ${size}`}
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default FilterSVG;
