import React, { Fragment } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useNavigate, useLocation } from 'react-router-dom';

// Style
import * as S from './ProfessionalsWorkspace.styled';
import { colors } from '../../../common/colors/colors';

// Components
import Selection from '../Selection/Selection';

// SVG
import SimpleClockBoldSVG from 'icons/SimpleClockBoldSVG';
import CreateNewBoldSVG from 'icons/CreateNewBoldSVG';

// Hooks
import useMediaQuery from 'hooks/useMediaQuery';

const ProfessionalsWorkspace = ({
  setModalSwitch,
  selectedProfessional,
  date,
  numberOfDays,
  searchParams,
}) => {
  const sessionStorage = window.sessionStorage;

  // Hooks
  const tr = useTranslate().use().global;
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 960px)');

  const saveDataToSessionStorage = () => {
    if (selectedProfessional?.id) {
      sessionStorage.setItem(
        'selectedProfessionalIds',
        JSON.stringify([selectedProfessional.id]),
      );
    }

    if (date)
      sessionStorage.setItem('selectedProfessionalTime', +date.getTime());
  };

  // Variables
  const selectionArr = [
    {
      id: 'no-work',
      icon: <SimpleClockBoldSVG />,
      text: tr['time-off'],
      bg: colors.bg_grey_2,
      action: (e) => {
        // e.stopPropagation();
        saveDataToSessionStorage();
        navigate('no-work-time');
      },
    },
    {
      id: 'new-order',
      icon: <CreateNewBoldSVG />,
      text: tr.newOrder,
      bg: colors.calendar_greenIsh,
      action: (e) => {
        e.stopPropagation();
        saveDataToSessionStorage();
        handleLocation();
      },
    },
    {
      id: 'new-pending',
      icon: <CreateNewBoldSVG />,
      text: tr.orderInTheWaitingBar,
      bg: colors.calendar_greenIsh,
      action: (e) => {
        e.stopPropagation();
        saveDataToSessionStorage();
        handleLocation('multi-selects');
      },
    },
  ];

  // Functions
  const handleLocation = (multi) => {
    location.state = {
      searchParams,
      multi,
      toOrder: true,
      numberOfDays: numberOfDays.visible,
    };

    if (!multi && selectedProfessional?.id) {
      navigate('/functions/daily-functions/client-search', {
        state: location.state,
      });
    } else {
      setModalSwitch('select-professional');
    }
  };

  return (
    <S.WorkspaceWrapper>
      {selectionArr.map((obj, i) => {
        if (!isMobile && obj.id === 'no-work')
          return <Fragment key={i}></Fragment>;
        else
          return (
            <Selection
              key={i}
              svg={obj.icon}
              text={obj.text}
              bg={obj.bg}
              action={obj.action}
            />
          );
      })}
    </S.WorkspaceWrapper>
  );
};

export default ProfessionalsWorkspace;
