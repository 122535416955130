import styled from 'styled-components';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;

  .buttons-container {
    display: flex;
    gap: 10px;

    .button {
      width: 20px;
      height: 20px;
      background-color: ${colors.bg_grey_1};
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: ${shadow.xsm};
      border-radius: 3px;
      cursor: pointer;
    }
  }

  textarea {
    width: 100%;
    height: 25px;
    height: fit-content;
    border: none;
    background-color: ${colors.bg_grey_1};
    outline: none;
    font-size: 16px;
    font-family: ${fontFamilies.family_Secondary};
    color: ${colors.bg_Primary};
    resize: none;
    overflow: hidden;

    :focus {
      background-color: white;
    }
  }
`;
