import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Route,
  Navigate,
  Routes,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// style
import * as S from './Main.styled';
import 'react-toastify/dist/ReactToastify.css';

// components
import { SideBar } from 'components/SideBar/SideBar';
import { Header } from 'components/Header/Header';
import { UserSpace } from 'components/Gateways/UserSpace';
import { AppSpace } from 'components/Gateways/AppSpace';
import { Tooltip } from 'components/Tooltip/Tooltip';

// pages
import NotFound from 'pages/NotFound';
import Settings from 'pages/Settings/Settings';
import AnnouncementsAndSuggestions from 'pages/AnnouncementsAndSuggestions/AnnouncementsAndSuggestions';

// hooks
import { useSideBarStatus } from 'hooks/useSideBarStatus';
import { useHideOnScroll } from 'hooks/useHideOnScroll';
import configUsersStore from 'hooks/headers-store/usersStore';
import { useSectorsGetAll } from 'services/sectorService';

// context
export const MainContext = createContext();

const Main = () => {
  // Hooks
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const isSideBarOpen = useSideBarStatus();
  const [headerFixed, setHeaderFixed] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [probableSectorId, setProbableSectorId] = useState();
  const functionBarRef = useRef(null);
  // const [activeMenuItem, setActiveMenuItem] = useState();

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const {
    data: sectorsData,
    isFetching: isSectorsFetching,
    refetch: refetchSectors,
  } = useSectorsGetAll({
    queryParams: { enabled: false },
  });

  configUsersStore();

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_TABLE_STATE':
        return { ...state, tableState: action.value };
      case 'menu-item':
        return { ...state, activeMenuItem: action.value };

      case 'filter-criteria':
        return { ...state, filterCriteria: action.value };

      case 'show-search-widget':
        return { ...state, searchWidgetVisible: true };

      case 'hide-search-widget':
        return {
          ...state,
          // filterCriteria: undefined,
          searchWidgetVisible: false,
        };

      case 'search-query':
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            [action.queryId || 'global']: action.value,
          },
        };

      case 'set-search-icon-visibility':
        return {
          ...state,
          searchIconVisible: action.value,
        };

      case 'set-sector-id':
        return { ...state, currSectorId: action.value };

      case 'set-sector-data':
        return { ...state, sectorData: action.value };

      case 'set-sectors': {
        return { ...state, sectors: action.value };
      }

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {});

  const { shiftBy, navBarTop } = useHideOnScroll();

  const checkFilterStatus = useCallback(
    (fieldId) =>
      state.filterCriteria &&
      (fieldId
        ? Object.keys(state.filterCriteria).some((key) => key === fieldId)
        : Object.values(state.filterCriteria).some(
            (criteria) => !!criteria.value,
          )),
    [state.filterCriteria],
  );

  // useEffect(() => console.log(state), [state, searchInput]);

  const adjustSectorData = () => {
    let sectorId = state.currSectorId;

    if (!sectorId) {
      sectorId = searchParams.get('sectorId');
    }

    if (!sectorId) {
      sectorId = location?.state?.searchParams?.sectorId;
    }

    if (sectorId) {
      setProbableSectorId(parseInt(sectorId));

      if (!sectorsData) {
        refetchSectors();
      }
    }
  };

  useEffect(() => {
    if (sectorsData) {
      dispatch({ type: 'set-sectors', value: sectorsData });

      if (probableSectorId) {
        const sectorData = sectorsData.find(
          (sector) => sector.id === probableSectorId,
        );

        dispatch({ type: 'set-sector-data', value: sectorData });
      }
    }
  }, [sectorsData, probableSectorId]);

  return (
    <S.Main
      addToTop={
        (shiftBy ? 0 : navBarTop) + (functionBarRef?.current?.offsetHeight || 0)
      }
    >
      <MainContext.Provider
        value={{
          setState: dispatch,
          headerFixed: { position: headerFixed, setPosition: setHeaderFixed },
          searchPlaceholder: {
            placeholder: searchPlaceholder,
            setPlaceholder: setSearchPlaceholder,
          },
          searchInput: { input: searchInput, setInput: setSearchInput },
          searchWidgetVisible: state.searchWidgetVisible,
          searchIconVisible: state.searchIconVisible,
          setSearchIconVisibility: (value) =>
            dispatch({ type: 'set-search-icon-visibility', value }),

          searchQuery: state.searchQuery?.global,
          searchQueries: state.searchQuery,
          setSearchQuery: useCallback(
            (value, queryId) =>
              dispatch({ type: 'search-query', value, queryId }),
            [],
          ),

          activeMenuItem: state.activeMenuItem,
          functionBarRef,

          filterCriteria: state.filterCriteria,
          hasFilter: checkFilterStatus,
          setFilterCriteria: (value) =>
            dispatch({ type: 'filter-criteria', value }),

          currSectorId: state.currSectorId,
          setCurrSectorId: useCallback(
            (value) => dispatch({ type: 'set-sector-id', value }),
            [],
          ),
          tableState: state.tableState,
          sectors: state.sectors,
          sectorData: state.sectorData,
          probableSectorId,
          adjustSectorData,
        }}
      >
        <Header
          showBurgerMenu={showBurgerMenu}
          setShowBurgerMenu={setShowBurgerMenu}
        />

        <S.Container showSideBar={isSideBarOpen}>
          <SideBar
            showBurgerMenu={showBurgerMenu}
            setShowBurgerMenu={setShowBurgerMenu}
          />

          <Routes>
            <Route index element={<Navigate to="functions" />} />

            <Route path="dashboard/*" element={<UserSpace />} />
            <Route path="functions/*" element={<AppSpace />} />
            <Route path="settings/*" element={<Settings />} />

            <Route
              path="announcements-and-suggestions"
              element={<AnnouncementsAndSuggestions />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </S.Container>

        <Tooltip id="tooltip" variant="light" />
        <ToastContainer theme="colored" stacked />
      </MainContext.Provider>
    </S.Main>
  );
};

export default Main;
