// style
import * as S from './AnnouncementsDetails.styled';

// icons
import { Calendar, Clock } from 'icons/dynamic';
import SendMobileSVG from 'icons/bold/SendMobileSVG';
import LetterSVG from 'icons/LetterSVG';
import SittingGirlSVG from 'icons/SittingGirlSVG';

// hooks
import { useTranslate } from 'hooks/useTranslate';

const DetailsWithIcon = ({ icon, text }) => {
  return (
    <S.DetailsWithIcon>
      <div className="icon-container">{icon}</div>
      <span>{text}</span>
    </S.DetailsWithIcon>
  );
};

const DetailsGroup = ({ title, details }) => {
  return (
    <S.DetailsGroup>
      <h4>{title}</h4>

      <div className="details__list">
        {details.map((item, i) => (
          <DetailsWithIcon key={i} icon={item.icon} text={item.text} />
        ))}
      </div>
    </S.DetailsGroup>
  );
};

const AnnouncementsDetails = () => {
  const tr = useTranslate().use().global;

  const contactDetails = [
    {
      icon: <SendMobileSVG lg />,
      text: '+370 682 09111',
    },
    {
      icon: <LetterSVG lg />,
      text: 'info@serve.lt',
    },
  ];

  const workScheduleDetails = [
    {
      icon: <Calendar lg />,
      text: `${tr['working-days']}: I - V`,
    },
    {
      icon: <Clock />,
      text: `${tr['working-hours']}:  08:00 - 17:00`,
    },
  ];
  return (
    <S.AnnouncementsDetails>
      <DetailsGroup
        title={tr['servepro-customer-service']}
        details={contactDetails}
      />
      <DetailsGroup title={tr['opening-hours']} details={workScheduleDetails} />
      <div className="filler"></div>

      <div className="sitting-girl__logo">
        <SittingGirlSVG />
      </div>
    </S.AnnouncementsDetails>
  );
};

export default AnnouncementsDetails;
