import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const OrderRowEntrie = styled.div`
  background-color: ${colors.bg_grey_1};
  height: 100%;
  // but not first
  padding-top: ${(props) => !props.compItem && '5px'};

  .content {
    height: 100%;
    min-height: 26px;
    padding: 6px 0;
    background-color: white;
  }

  .title-column {
    height: 100%;
    /* padding: 0 5px; */
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    font-weight: 600;
    /* white-space: nowrap; */
    min-width: 250px;

    &__logo {
      display: flex;
      margin-right: 10px;
    }

    &__title {
      margin-right: 10px;
    }
  }

  .compItem {
    font-weight: 400;
  }

  .select-column {
    height: 100%;

    display: flex;
    align-items: center;
    padding: 0 2px;
  }

  .amount-column {
    height: 100%;
    padding: 0 2px;

    display: flex;
    align-items: center;

    input {
      width: 100%;
      background-color: ${colors.bg_grey_1};
      border-radius: 5px;
      border: none;
      outline: none;
      min-height: 30px;
      padding: 0 5px;
      font-size: 14px;
      font-family: ${fontFamilies.family_Secondary};
      color: ${colors.bg_Primary};

      -moz-appearance: textfield;
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      :disabled {
        background-color: unset;
      }
    }
  }

  .price-column {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    color: ${colors.bg_Primary};
  }

  .discount-column {
  }

  .total-price-column {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    color: ${colors.bg_Primary};
    font-weight: 900;
  }

  .red input {
    color: ${colors.mandyRed};
  }

  .danger {
    color: ${colors.warningText};
  }
`;
