import * as S from './SVG.styled';

const FabOpenSVG = ({ color, width, height, invert }) => {
  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '50'}
      height={height ? height : '50'}
      version="1.1"
      viewBox="0 0 50 50"
      color={color ? color : 'currentColor'}
    >
      <g>
        <path d="m32.511 30.747-5.7463-5.7463 5.7463-5.7463a1.248 1.248 45 0 0-1.7649-1.7649l-5.7463 5.7463-5.7463-5.7463a1.248 1.248 45 0 0-1.7649 1.7649l5.7463 5.7463-5.7463 5.7463a1.248 1.248 45 0 0 1.7649 1.7649l5.7463-5.7463 5.7463 5.7463a1.248 1.248 45 0 0 1.7649-1.7649z" />
        <path d="m17.941 32.737c-0.28716-0.12453-0.52545-0.33813-0.65502-0.58716-0.11323-0.21762-0.12533-0.71732-0.02392-0.98808 0.03956-0.10562 1.4128-1.535 3.0516-3.1765l2.9797-2.9844-2.9797-2.9844c-1.6388-1.6414-3.0121-3.0709-3.0516-3.1765-0.10155-0.27114-0.08926-0.77069 0.02431-0.98808 0.21664-0.41469 0.65819-0.69727 1.0849-0.69433 0.47576 0.0033 0.7218 0.21614 3.6956 3.1973l2.9326 2.9399 3.0022-2.9954c2.5555-2.5498 3.0443-3.0077 3.2855-3.0782 0.50784-0.14832 1.0474 0.06584 1.3693 0.5435 0.17116 0.25398 0.20876 0.74002 0.08319 1.0753-0.03956 0.10562-1.4128 1.535-3.0516 3.1765l-2.9797 2.9844 2.9797 2.9844c1.6388 1.6414 3.0121 3.0709 3.0516 3.1765 0.12557 0.33528 0.08797 0.82132-0.08319 1.0753-0.3219 0.47766-0.86144 0.69182-1.3693 0.5435-0.24118-0.07044-0.72998-0.52839-3.2855-3.0782l-3.0022-2.9954-2.9326 2.9399c-1.9504 1.9552-3.024 2.9827-3.2055 3.0679-0.32862 0.15412-0.60122 0.16072-0.92043 0.0223z" />
      </g>
    </S.StyledSVG>
  );
};

export default FabOpenSVG;
