export const setAppointments = (data) => async (dispatch) => {
  dispatch({ type: 'APPOINTMENTS_SET', payload: data });
};

export const addAppointment = (order) => async (dispatch) => {
  if (!order) return;
  dispatch({ type: 'APPOINTMENT_ADD', payload: { order } });
};

export const updateAppointment = (order, newColumn) => async (dispatch) => {
  if (!order) return;
  dispatch({
    type: 'APPOINTMENT_UPDATED',
    payload: { order, newColumn },
  });
};

export const resetAppointment = (order) => async (dispatch) => {
  if (!order) return;

  dispatch({
    type: 'APPOINTMENT_RESET',
    payload: { order },
  });
};

export const deleteAppointment = (id) => async (dispatch) => {
  if (!id) return;

  dispatch({
    type: 'APPOINTMENT_DELETED',
    payload: { id },
  });
};

export const copyAppointment = (id) => async (dispatch) => {
  if (!id) return;

  dispatch({ type: 'APPOINTMENTS_ADDED_COPY', payload: { id } });
};

export const updateAppointmentKey = (data) => (dispatch) => {
  if (!data) return;
  dispatch({ type: 'APPOINTMENT_KEY_UPDATED', payload: data });
};

// OLD
// export const updateAppointment = (data) => (dispatch) => {
//   if (!data) return;
//   dispatch({ type: 'APPOINTMENT_UPDATED', payload: data });
// };

// export const deleteAppointment = (data) => (dispatch) => {
//   dispatch({ type: 'APPOINTMENT_DELETED', payload: data });
// };
