import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const LogoSVG = ({ color, mono }) => {
  return (
    <StyledSVG
      color={color ? color : 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 809.76 159.13"
    >
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M17.62,104.84A8.81,8.81,0,0,1,0,105.47q0-.31,0-.63a8.86,8.86,0,0,1,8.81-8.9A8.86,8.86,0,0,1,17.62,104.84Z"
            fill={mono ? color : '#FFA35F'}
          />
          <path
            className="cls-2"
            d="M150.42,6.33C118,6.33,91.77,32.82,91.77,65.49s26.23,59.15,58.65,59.15a58.16,58.16,0,0,0,39.83-15.79V89.75a46.34,46.34,0,0,1-80.78-1.87L193.7,33.81h0L198,31A58.39,58.39,0,0,0,150.42,6.33ZM103.88,65.52a46.76,46.76,0,0,1,46.52-47h0a46.09,46.09,0,0,1,28.74,10.05L105.05,76.13A47.92,47.92,0,0,1,103.88,65.52Z"
          />
          <path
            className="cls-2"
            d="M467.12,33.81,471.45,31A58.42,58.42,0,0,0,423.78,6.33c-32.38,0-58.63,26.49-58.63,59.16s26.22,59.15,58.63,59.15a58.05,58.05,0,0,0,39.83-15.79V89.75a46.34,46.34,0,0,1-80.73-1.87l84.24-54.07ZM377.26,65.52a46.74,46.74,0,0,1,46.52-46.94,46.07,46.07,0,0,1,28.75,10L378.41,76.16A47,47,0,0,1,377.26,65.52Z"
          />
          <path
            className="cls-2"
            d="M220.79,27.4V9.16H208.25v114h12.54V57.44a46.75,46.75,0,0,1,37.75-39.65l2.85-12.7A58.42,58.42,0,0,0,220.79,27.4Z"
          />
          <path
            className="cls-2"
            d="M357.65,7.37,317.41,95.82l-40-88.45-11.75,6,51.52,111.3L368.66,12.82Z"
          />
          <path
            className="cls-2"
            d="M52.23,59.48c-13.84-4-23.59-5.36-28-12.39S19.79,29.15,29,22.84s27.37-6.68,41.45,6.54l7.89-10.06C71,13.39,61.45,6.63,45.46,6.63a44.21,44.21,0,0,0-26.34,8.56S7.71,24,7.71,38.69A28.61,28.61,0,0,0,17.92,61a62.69,62.69,0,0,0,17.72,8.11c10.71,3.09,24.18,5.86,28.05,13s4.24,19.88-3.35,25.52-11.41,6.3-17.3,6.3a38.27,38.27,0,0,1-22.36-7.81h0a12,12,0,0,1-8.58,10.35c2.17,1.87,12.71,10.06,30.95,10.06A37.62,37.62,0,0,0,77.45,105c3.13-7,4.32-18.39.23-26.79S66.07,63.46,52.23,59.48Z"
          />
          <path
            className="cls-2"
            d="M618.55,59.86q0,26.52-11.43,42.14T576,117.62a49,49,0,0,1-23-5.24A39.37,39.37,0,0,1,537.3,97.91v61.22h-5.66V2.1h5.66V22.33A41.59,41.59,0,0,1,553.13,5.77,44.32,44.32,0,0,1,575.78,0q19.9,0,31.34,15.25t11.43,42.83Zm-5.77-2.2q0-24.43-9.7-38.21T575.88,5.66q-14.25,0-24.21,7.39T537.3,34.28v53a36,36,0,0,0,14.42,18.19q10,6.45,24.37,6.45,17.4,0,27-13.84T612.78,57.66Z"
          />
          <path
            className="cls-2"
            d="M699.37,6.29,692,5.66q-14.47,0-24.22,9.38T655,41.27v74.25h-5.66V2.1H655V25.68A41.74,41.74,0,0,1,668.87,6.92Q678.1,0,692,0a22.56,22.56,0,0,1,8,1.17Z"
          />
          <path
            className="cls-2"
            d="M710.49,56.61a66.45,66.45,0,0,1,6.23-29.2A48.05,48.05,0,0,1,734.33,7.23,47,47,0,0,1,760.07,0a46.65,46.65,0,0,1,43.24,26.94,65.92,65.92,0,0,1,6.45,29v5q0,16.35-6.18,29.25A47.72,47.72,0,0,1,786,110.39a49.73,49.73,0,0,1-51.48.1,47.72,47.72,0,0,1-17.66-20,65.7,65.7,0,0,1-6.39-28.88Zm5.66,4.4A59.61,59.61,0,0,0,721.75,87a44.29,44.29,0,0,0,15.62,18.4,40.69,40.69,0,0,0,22.91,6.6q19.4,0,31.61-14.57T804.1,59.86V56.61a59.12,59.12,0,0,0-5.66-25.79,44.85,44.85,0,0,0-15.67-18.5,40.06,40.06,0,0,0-22.7-6.66q-19.4,0-31.66,14.78T716.15,57.66Z"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default LogoSVG;
