import axios from 'axios';
import { toast } from 'react-toastify';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { singularize } from 'common/helpers/stringOperations';
import useTranslate from 'hooks/useTranslate';

const DB_PATH = process.env.REACT_APP_API;

export const useFetch = (params = {}) => {
  const {
    id,
    endpoint,
    searchParams,
    queryParams,
    customParams,
    errorMessages,
  } = params;
  const tr = useTranslate().use().global;
  const axiosInstance = getAxiosInstance(errorMessages, tr, endpoint);

  return useQuery(
    [
      endpoint,
      { id },
      ...(searchParams ? [searchParams] : []),
      ...(customParams ? [customParams] : []),
    ],
    ({ queryKey }) => fetcher({ queryKey, axiosInstance }),
    queryParams,
  );
};

export const useFetchMulti = (arr) => {
  const tr = useTranslate().use().global;
  const queries = arr.map((el) => {
    const params = el ? el : {};
    const { id, endpoint, searchParams, queryParams, errorMessages } = params;
    const axiosInstance = getAxiosInstance(errorMessages, tr, endpoint);

    return {
      queryKey: [endpoint, { id }, ...(searchParams ? [searchParams] : [])],
      queryFn: ({ queryKey }) => fetcher({ queryKey, axiosInstance }),
      ...queryParams,
    };
  });

  return useQueries({ queries });
};

export const fetcher = ({ queryKey, axiosInstance }) => {
  const [endpoint, { id }, searchParams] = queryKey;

  return axiosInstance
    .get(
      DB_PATH +
        endpoint +
        (id !== undefined ? '/' + id : '') +
        getFormatedSearchParams(searchParams),
      {
        headers: { Authorization: 'Bearer ' + localStorage.token },
      },
    )
    .then((res) => res.data);
};

export const useGenericMutation = (params = {}) => {
  const { func, endpoint, id, searchParams, queryParams, updater } = params;
  const queryClient = useQueryClient();

  return useMutation(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([
        endpoint,
        ...(searchParams ? [searchParams] : []),
      ]);

      const previousData = queryClient.getQueryData([
        endpoint,
        ...(searchParams ? [searchParams] : []),
      ]);

      queryClient.setQueryData(
        [endpoint, { id }, ...(searchParams ? [searchParams] : [])],
        (oldData) => {
          return updater ? [oldData, data] : data;
        },
      );

      return previousData;
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, _, context) => {
      queryClient.setQueryData(
        [endpoint, ...(searchParams ? [searchParams] : [])],
        context,
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries([
        endpoint,
        ...(searchParams ? [searchParams] : []),
      ]);
      queryParams?.invalidate?.map((queryKey) => {
        queryClient.invalidateQueries(queryKey);
      });
    },
  });
};

export const usePost = (params = {}, updater) => {
  const {
    endpoint,
    id,
    searchParams,
    queryParams,
    reqObjName,
    errorMessages,
    asFormData = false,
    fileFieldName,
  } = params;
  const tr = useTranslate().use().global;
  const axiosInstance = getAxiosInstance(errorMessages, tr, endpoint);

  return useGenericMutation({
    func: (data) => {
      let objectName = null;

      if (reqObjName !== null) {
        objectName = reqObjName || singularize(endpoint);
      }

      if (asFormData) {
        var formData = new FormData();
        if (fileFieldName) {
          formData.append(fileFieldName, data[fileFieldName]);
          delete data[fileFieldName];
        }
        if (objectName) {
          formData.append(objectName, JSON.stringify(data));
        }
        data = formData;
      } else {
        if (objectName !== null) {
          data = { [objectName]: data };
        }
      }

      return axiosInstance.post(
        DB_PATH + endpoint + getFormatedSearchParams(searchParams),
        data,
        {
          headers: { Authorization: 'Bearer ' + localStorage.token },
        },
      );
    },
    endpoint,
    id,
    searchParams,
    queryParams,
    updater,
  });
};

export const useUpdate = (params = {}, updater) => {
  const { id, searchParams, endpoint, reqObjName, errorMessages, queryParams } =
    params;
  const tr = useTranslate().use().global;
  const axiosInstance = getAxiosInstance(errorMessages, tr, endpoint);

  return useGenericMutation({
    func: (data) =>
      axiosInstance.patch(
        DB_PATH + endpoint + getFormatedSearchParams(searchParams),
        { [reqObjName || singularize(endpoint)]: data },
        { headers: { Authorization: 'Bearer ' + localStorage.token } },
      ),
    endpoint,
    id,
    searchParams,
    queryParams,
    updater,
  });
};

export const useDelete = (params = {}, updater) => {
  const { searchParams, queryParams, endpoint, errorMessages } = params;
  const tr = useTranslate().use().global;
  const axiosInstance = getAxiosInstance(errorMessages, tr, endpoint);

  return useGenericMutation({
    func: ({ id, searchParams: mutationSearchParams, data }) => {
      let url = DB_PATH + endpoint;
      if (id) {
        url += '/' + id;
      }

      let deleteSearchParams = searchParams;

      if (mutationSearchParams) {
        deleteSearchParams = { ...searchParams, ...mutationSearchParams };
      }

      return axiosInstance.delete(
        url + getFormatedSearchParams(deleteSearchParams),
        {
          headers: { Authorization: 'Bearer ' + localStorage.token },
          data,
        },
      );
    },
    endpoint,
    searchParams,
    queryParams,
    updater,
    // invalidate,
  });
};

const getFormatedSearchParams = (params) =>
  params ? '?' + new URLSearchParams(params).toString() : '';

// export const useUpdate = (url, params, updater) => {
//   return useGenericMutation(
//     (data) =>
//       axios.patch(DB_PATH + url, data, {
//         headers: { Authorization: 'Bearer ' + localStorage.token },
//       }),
//     url,
//     params,
//     updater
//   );
// };

/*
  Custom errorMessage could be declared in service. Example:

  errorMessages: {
    byErrorCode: [
      {
        code: 'Orders_CannotConfirmAlreadyConfirmed',
        message: `${tr.order} ${tr.overplaps.toLowerCase()}`
      },
    ],
    byStatusCode: [
      {
        code: 404,
        message: tr.order-being-updated
      },
    ]
  }
*/
const getAxiosInstance = (errorMessages = {}, tr, endpoint) => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.response.use(
    (response) => {
      const successTranslations =
        getSuccessTranslations(tr)?.[response?.config?.method]?.[endpoint];
      const successMessage = getSuccessMessage(response, successTranslations);

      if (successMessage?.message) {
        const toastType = successMessage?.isWarning ? 'warning' : 'success';
        toast[toastType](successMessage.message);
      }

      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        window.location.href = window.location.origin;
      }

      // Custom error messages from service
      let errorMessage = getErrorMessage(error?.response, errorMessages);
      if (errorMessage) {
        toast.error(errorMessage);

        throw error;
      }

      // Initial error message deffinitions
      errorMessage = getErrorMessage(
        error?.response,
        getErrorTranslations(tr)?.[error?.config?.method]?.[endpoint],
      );
      if (errorMessage) {
        toast.error(errorMessage);

        throw error;
      }

      // in case of untranslated error from BE
      if (error?.response?.data?.errors) {
        const genericErrors = Object.values(error.response.data.errors);

        if (genericErrors?.length) {
          toast.error(genericErrors[0][0]);

          throw error;
        }
      }

      // Fallback errors
      switch (error?.response?.status) {
        case 400:
          toast.error(tr['invalid-request']);

          break;
        case 401:
          toast.error(tr['unauthorized']);

          break;
        case 404:
          toast.error(tr['not-found']);

          break;
        case 412:
          toast.error(tr['precondition-failed']);

          break;
        case 402:
          toast.error(tr['payment-required']);

          break;
        case 403:
          toast.error(tr['forbidden']);

          break;
        case 429:
          toast.error(tr['too-many-requests']);

          break;
        case 500:
          toast.error(tr['internal-server-error']);

          break;
        default:
          toast.error(tr['error']);
      }

      throw error;
    },
  );

  return axiosInstance;
};

const getMessage = (response, messages) => {
  for (let item of messages) {
    if (response?.data?.errors?.[item.code] || response?.status === item.code) {
      return item;
    }
  }
};

const getErrorMessage = (errorResponse, errorMessages) => {
  if (errorMessages?.byErrorCode?.length) {
    const messageItem = getMessage(errorResponse, errorMessages.byErrorCode);

    if (messageItem?.message) {
      return messageItem.message;
    }
  }

  if (errorMessages?.byStatusCode?.length) {
    const messageItem = getMessage(errorResponse, errorMessages.byStatusCode);

    if (messageItem?.message) {
      return messageItem.message;
    }
  }

  return false;
};

const getSuccessMessage = (successResponse, successMessages) => {
  if (successMessages?.length) {
    const messageItem = getMessage(successResponse, successMessages);

    if (messageItem) {
      return messageItem;
    }
  }

  return false;
};

const getErrorTranslations = (tr) => {
  return {
    get: {
      employees: {
        byErrorCode: [
          { code: 'employeeId', message: tr['employee-not-found'] },
        ],
        byStatusCode: [{ code: 404, message: tr['employee-not-found'] }],
      },
      customers: {
        byErrorCode: [{ code: 'customerId', message: tr['client-not-found'] }],
        byStatusCode: [{ code: 404, message: tr['client-not-found'] }],
      },
      'employees/schedules': {
        byStatusCode: [
          { code: 400, message: tr['schedule-dates-interval-too-wide'] },
        ],
      },
    },
    patch: {
      sellorders: {
        byErrorCode: [
          {
            code: 'Orders_CannotConfirmAlreadyConfirmed',
            message: tr['order-cancelation-approvement-canceled'],
          },
          {
            code: 'Orders_CannotModifyConfirmedOrder',
            message: tr['confirmed-order-can-not-be-modified'],
          },
          {
            code: 'Orders_Overlap',
            message: `${tr['order']} ${tr['overplaps'].toLowerCase()}`,
          },
          {
            code: 'Services_UnitNotFound',
            message: tr['unit-id-not-found'],
          },
        ],
        byStatusCode: [
          {
            code: 400,
            message: tr['action-not-allowed'],
          },
          {
            code: 404,
            message: tr['order-not-found-or-being-updated'],
          },
          {
            code: 412,
            message: tr['updating-updated-order'],
          },
        ],
      },
      'sellorders/status': {
        byErrorCode: [
          {
            code: 'Orders_CannotConfirmAlreadyConfirmed',
            message: tr['order-is-already-confirmed'],
          },
        ],
        byStatusCode: [
          {
            code: 404,
            message: tr['order-not-found-or-being-updated'],
          },
          {
            code: 412,
            message: tr['updating-updated-order'],
          },
        ],
      },
      'sellorders/payment': {
        byStatusCode: [
          {
            code: 404,
            message: tr['order-not-found-or-being-updated'],
          },
          {
            code: 412,
            message: tr['updating-updated-order'],
          },
        ],
      },
      'sellorders/note': {
        byStatusCode: [
          {
            code: 404,
            message: tr['order-not-found-or-being-updated'],
          },
        ],
      },
    },
    post: {
      categories: {
        byErrorCode: [
          {
            code: 'Generic_Duplicate',
            message: tr['group-duplicate-key'],
          },
        ],
        byStatusCode: [
          {
            code: 409,
            message: tr['group-duplicate-key'],
          },
        ],
      },
      groups: {
        byErrorCode: [
          {
            code: 'Generic_Duplicate',
            message: tr['group-duplicate-key'],
          },
        ],
        byStatusCode: [
          {
            code: 409,
            message: tr['group-duplicate-key'],
          },
        ],
      },
      services: {
        byErrorCode: [
          {
            code: 'Generic_NameIsEmpty',
            message: tr['service-name-cannot-be-empty'],
          },
          {
            code: 'Generic_NameIsTooLong',
            message: tr['service-name-too-long'],
          },
          {
            code: 'Generic_DbError',
            message: tr['server-error'],
          },
          {
            code: 'Services_CompositionServiceNotFound',
            message: tr['composition-service-not-found'],
          },
          {
            code: 'Services_CompositionDuplicateServiceFound',
            message: tr['composition-duplicate-service-found'],
          },
          {
            code: 'Services_CompositionCannotIncludeParentService',
            message: tr['composition-cannot-include-parent-service'],
          },
          {
            code: 'Services_CompositionMustConsistOfTwoOrMoreServices',
            message: tr['composition-must-consist-of-two-or-more-services'],
          },
          {
            code: 'Services_CompositeServiceCountOverflow',
            message: tr['composite-service-count-overflow'],
          },
          {
            code: 'Services_UnitIdAndVolumeAreRequiredForMaterial',
            message: tr['unit-id-and-volume-are-required-for-material'],
          },
          {
            code: 'Services_UnitNotFound',
            message: tr['unit-id-not-found'],
          },
          {
            code: 'Services_BreakIsNotValidForMaterial',
            message: tr['break-is-not-valid-for-material'],
          },
          {
            code: 'Services_UnitIdAndVolumeAreInvalidForTimedService',
            message: tr['unit-id-and-volume-are-invalid-for-timed-service'],
          },
          {
            code: 'Services_UnitVolumeOutOfRange',
            message: tr['unit-volume-out-of-range'],
          },
          {
            code: 'Services_UnitVolumePrecisionOutOfRange',
            message: tr['unit-volume-precision-out-of-range'],
          },
          {
            code: 'Services_BreakOutOfRange',
            message: tr['break-out-of-range'],
          },
          {
            code: 'Services_DurationOutOfRange',
            message: tr['duration-out-of-range'],
          },
          {
            code: 'Services_UnitPriceOutOfRange',
            message: tr['unit-price-out-of-range'],
          },
          {
            code: 'Services_UnitPricePrecisionOutOfRange',
            message: tr['unit-price-precision-out-of-range'],
          },
          {
            code: 'Services_VatPercentOutOfRange',
            message: tr['vat-percent-out-of-range'],
          },
          {
            code: 'Services_VatPercentPrecisionOutOfRange',
            message: tr['vat-percent-precision-out-of-range'],
          },
          {
            code: 'Services_CannotChangeType',
            message: tr['service-cannot-change-type'],
          },
          {
            code: 'Services_ParentGroupOrSubgroupNotFound',
            message: tr['parent-group-or-subgroup-not-found'],
          },
        ],
        byStatusCode: [
          {
            code: 404,
            message: tr['service-not-found-or-is-in-use'],
          },
          {
            code: 412,
            message: tr['update-updated-service-attempt'],
          },
        ],
      },
      employees: {
        byErrorCode: [
          {
            code: 'Generic_NameIsTooLong',
            message: tr['employee-name-too-long'],
          },
          {
            code: 'Employees_CannotDisableAccessToOwner',
            message: tr['employees-cannot-disable-access-to-owner'],
          },
          {
            code: 'Roles_RoleNotFound',
            message: tr['this-role-does-not-exist'],
          },
          {
            code: 'Generic_InvalidEmail',
            message: tr['invalid-email'],
          },
          {
            code: 'Generic_InvalidDate',
            message: tr['invalid-date-of-birth'],
          },
          {
            code: 'Generic_NameIsEmpty',
            message: tr['name-or-surname-has-to-be-filled'],
          },
        ],
        byStatusCode: [
          {
            code: 412,
            message: tr['update-updated-employee-attempt'],
          },
          {
            code: 404,
            message: tr['employee-not-found'],
          },
        ],
      },
      'employees/schedules': {
        byErrorCode: [{ code: 'Generic_DbError', message: tr['server-error'] }],
        // byStatusCode: [{ code: 500, message: tr['server-error'] }],
      },
      'employees/sectorSettings': {
        byErrorCode: [
          {
            code: 'Generic_NameIsTooLong',
            message: tr['serve-online-id-too-long'],
          },
          {
            code: 'Sectors_SectorNotFound',
            message: tr['sector-not-found'],
          },
          {
            code: 'Professions_ProfessionNotFound',
            message: tr['profession-not-found'],
          },
          {
            code: 'Roles_RoleNotFound',
            message: tr['this-role-does-not-exist'],
          },
        ],
        byStatusCode: [
          {
            code: 412,
            message: tr['update-updated-employee-attempt'],
          },
          {
            code: 404,
            message: tr['employee-not-found'],
          },
        ],
      },
      customers: {
        byErrorCode: [
          {
            code: 'Generic_NameIsTooLong',
            message: tr['client-name-too-long'],
          },
          {
            code: 'Generic_InvalidGuid',
            message: tr['client-id-is-invalid'],
          },
          {
            code: 'Generic_InvalidEmail',
            message: tr['invalid-email'],
          },
          {
            code: 'Generic_InvalidDate',
            message: tr['invalid-date-of-birth'],
          },
          {
            code: 'Generic_NameIsEmpty',
            message: tr['name-or-surname-has-to-be-filled'],
          },
        ],
        byStatusCode: [
          {
            code: 403,
            message: tr['you-dont-have-required-permisions'],
          },
          {
            code: 404,
            message: tr['client-not-found'],
          },
          {
            code: 412,
            message: tr['update-updated-employee-attempt'],
          },
        ],
      },
      sellorders: {
        byErrorCode: [
          {
            code: 'Orders_CannotSpanOverMultipleDays',
            message: tr['order-cannot-span-over-multiple-days'],
          },
        ],
        byStatusCode: [
          {
            code: 404,
            message: tr['order-not-found-or-being-updated'],
          },
          {
            code: 412,
            message: tr['updating-updated-order'],
          },
        ],
      },
    },
    delete: {
      services: {
        byStatusCode: [{ code: 409, message: tr['service-is-in-use'] }],
      },
      employees: {
        byErrorCode: [
          {
            code: 'Employees_CannotDelete',
            message: tr['cannot-delete-empoloyee-card-with-orders'],
          },
          {
            code: 'Employees_CannotDisableAccessToOwner',
            message: tr['cannot-delete-owner-employee-card'],
          },
        ],
        byStatusCode: [
          {
            code: 404,
            message: tr['delete-deleted-employee-attempt'],
          },
          {
            code: 412,
            message: tr['update-updated-employee-attempt'],
          },
        ],
      },
      customers: {
        byErrorCode: [
          {
            code: 'Customers_CannotDeleteUsedCustomers',
            message: tr['cannot-delete-client-card-with-orders'],
          },
        ],
        byStatusCode: [
          {
            code: 404,
            message: tr['delete-deleted-client-attempt'],
          },
          {
            code: 412,
            message: tr['update-updated-client-attempt'],
          },
        ],
      },
    },
  };
};

const getSuccessTranslations = (tr) => {
  return {
    post: {
      employees: [
        // TODO: Need BE to change create response from 200 to 201
        // {
        //   code: 200,
        //   message: tr['employee-updated'],
        // },
        // {
        //   code: 201,
        //   message: tr['employee-created'],
        // },
        {
          code: 207,
          message: tr['not-all-employee-data-updated-please-try-again'],
          isWarning: true,
        },
      ],
      'employees/sectorSettings': [
        {
          code: 200,
          message: tr['employee-sector-settings-updated'],
        },
      ],
      'employees/schedules': [
        {
          code: 207,
          message: tr['schedule-updated-partially'],
          isWarning: true,
        },
      ],
    },
    patch: {
      'sellorders/status': [
        {
          code: 200,
          message: tr['order-status-changed'],
        },
      ],
      'sellorders/payment': [
        {
          code: 200,
          message: tr['order-status-changed'],
        },
      ],
      'sellorders/note': [
        {
          code: 200,
          message: tr['order-note-changed'],
        },
      ],
    },
    delete: {
      employees: [
        {
          code: 200,
          message: tr['emoloyee-card-deleted'],
        },
        {
          code: 207,
          message: tr['not-all-employee-data-deleted-please-try-again'],
          isWarning: true,
        },
      ],

      customers: [
        {
          code: 200,
          message: tr['client-card-deleted'],
        },
      ],
    },
  };
};
