import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const BasicTableRecord = styled.tr`
  cursor: pointer;

  :hover {
    td {
      background-color: ${(props) => !props.selected && colors.bg_grey_2};
    }
  }

  td {
    height: 30px;
    padding-left: 45px;
    font-size: 14px;
    background-color: ${(props) => props.selected && colors.bg_Primary};
    color: ${(props) => (props.selected ? 'white' : colors.bg_Primary)};

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;

    &:first-child {
      border-radius: 100px 0 0 100px;
    }

    &:last-child {
      border-radius: 0 100px 100px 0;
    }
  }
`;
