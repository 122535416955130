import { useContext, useEffect, useState } from 'react';

// style
import * as S from './PendingOrdersHeader.style';

// icons
import FilterClearSVG from 'icons/FilterClearSVG';

// components
import TheButton from 'components/Buttons/TheButton';

// contexts
import { UserContext } from 'context/UserContext';
import { ScheduleContext } from 'context/ScheduleContext';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';

export const PendingOrdersHeader = ({ showPendingOrders, hideClear }) => {
  const { locale } = useContext(UserContext);
  const [timeDate, setTimeDate] = useState(new Date());

  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  const { pendingOrders, selectedProfessional, numberOfDays, personal } =
    useContext(ScheduleContext);

  useEffect(() => {
    let timeInterval;

    if (!isMobile) {
      timeInterval = setInterval(() => setTimeDate(new Date()), 30000);
    }

    return () => clearInterval(timeInterval);
  }, [isMobile]);

  const clearFilter = () => selectedProfessional.set(null);

  return (
    <S.PendingOrdersHeader
      id="pending-orders-header"
      showPendingOrders={showPendingOrders}
      numberOfDays={numberOfDays}
    >
      <div className="filler">
        <div className="container">
          <span className="filler__time">
            {timeDate.toLocaleTimeString(locale, {
              hour: 'numeric',
              minute: 'numeric',
            })}
          </span>
          <span className="filler__title">{tr['pending-orders']}</span>

          {selectedProfessional?.value?.id && !personal && (
            <div className="clear_button">
              <TheButton
                secondary
                flat
                action={clearFilter}
                title={tr['reset']}
                Icon={FilterClearSVG}
                outline
              />
            </div>
          )}
        </div>
      </div>
    </S.PendingOrdersHeader>
  );
};

export default PendingOrdersHeader;
