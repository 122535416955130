import { Fragment } from 'react';
import classNames from 'classnames';

// style
import * as S from './ServiceCategories.styled';

// icons
import { Check, Circle } from 'icons/dynamic';

export const ServiceCategories = ({
  categories,
  getItem,
  toggleActivity,
  activeGroup,
  setActiveGroup,
}) => {
  return (
    <S.ServiceCategories>
      {categories?.map((category) => {
        const item = getItem('category', category.id);

        return (
          <Fragment key={category.id}>
            <div className="category">
              <div
                className="mark"
                onClick={() => toggleActivity('category', category.id)}
              >
                {('isActive' in item ? item.isActive : category.isActive) ? (
                  <Check black inCircle width={16} height={16} />
                ) : (
                  <Circle bold width={16} height={16} />
                )}
              </div>
              {category.name}
            </div>

            {category.groups?.map((group) => {
              const item = getItem('group', group.id);

              return (
                group.categoryId === category.id && (
                  <div
                    className={classNames(
                      'group',
                      activeGroup?.catId === group.catId &&
                        activeGroup?.id === group.id &&
                        'active',
                    )}
                    key={group.id}
                    onClick={() =>
                      setActiveGroup({
                        catId: group.catId,
                        id: group.id,
                      })
                    }
                  >
                    <div
                      className="mark"
                      onClick={() => toggleActivity('group', group.id)}
                    >
                      {('isActive' in item ? item.isActive : group.isActive) ? (
                        <Check black inCircle width={16} height={16} />
                      ) : (
                        <Circle bold width={16} height={16} />
                      )}
                    </div>
                    {group.name}
                  </div>
                )
              );
            })}
          </Fragment>
        );
      })}
    </S.ServiceCategories>
  );
};

export default ServiceCategories;
