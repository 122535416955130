import * as S from '../SVG.styled';

const AuthProviderLogo = ({ provider }) => {
  let size, content;

  switch (provider) {
    case 'goggle':
    default:
      size = 14;
      content = provider === 'google' && (
        <>
          <title>btn_google_light_normal_ios</title>
          <desc>Created with Sketch.</desc>{' '}
          <g fillRule="evenodd">
            <path
              id="Shape"
              d="m13.72 7.1591c0-0.49636-0.04454-0.97364-0.12727-1.4318h-6.5927v2.7077h3.7673c-0.16227 0.875-0.65545 1.6164-1.3968 2.1127v1.7564h2.2623c1.3236-1.2186 2.0873-3.0132 2.0873-5.145z"
              fill="#4285f4"
            />
            <path
              d="m7 14c1.89 0 3.4745-0.62682 4.6327-1.6959l-2.2623-1.7564c-0.62682 0.42-1.4286 0.66818-2.3705 0.66818-1.8232 0-3.3664-1.2314-3.9168-2.8859h-2.3386v1.8136c1.1518 2.2877 3.5191 3.8564 6.2555 3.8564z"
              fill="#34a853"
            />
            <path
              d="m3.0832 8.33c-0.14-0.42-0.21955-0.86864-0.21955-1.33s0.079545-0.91 0.21955-1.33v-1.8136h-2.3386c-0.47409 0.945-0.74455 2.0141-0.74455 3.1436 0 1.1295 0.27045 2.1986 0.74455 3.1436z"
              fill="#fbbc05"
            />
            <path
              d="m7 2.7841c1.0277 0 1.9505 0.35318 2.6759 1.0468l2.0077-2.0077c-1.2123-1.1295-2.7968-1.8232-4.6836-1.8232-2.7364 0-5.1036 1.5686-6.2555 3.8564l2.3386 1.8136c0.55045-1.6545 2.0936-2.8859 3.9168-2.8859z"
              fill="#ea4335"
            />
          </g>
          <path d="m0 0h14v14h-14z" fill="none" />
        </>
      );
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      version="1.1"
      viewBox={`0 0 ${size} ${size}`}
    >
      {content}
    </S.StyledSVG>
  );
};

export default AuthProviderLogo;
