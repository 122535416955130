import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const InputValue = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  padding-inline: 10px;
  position: relative;

  &.changed {
    border: 1px solid ${colors.text_Secondary};
  }

  .reset {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    text-align: center;
    color: white;
    display: none;
  }

  &.changed .reset {
    display: block;
  }

  input {
    background-color: transparent;
    border: none;
    border-radius: 2.5px;
    color: inherit;
    text-align: ${({ isNumber }) => (isNumber ? 'right' : 'center')};
    width: 100%;

    @media screen and (max-width: 960px) {
      text-align: right;
    }

    :focus,
    :hover {
      background-color: revert;
      outline: none;
    }

    :disabled {
      -webkit-text-fill-color: ${colors.text_Secondary};
      opacity: 1;
    }

    &[type='time']::-webkit-calendar-picker-indicator,
    &[type='number']::-webkit-inner-spin-button {
      display: none;
    }
  }
`;
