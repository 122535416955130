import { colors } from 'common/colors/colors';
import { ORDER_COMPLETION_STATUS } from 'common/Constants';

// Translation
import { useTranslate } from 'hooks/useTranslate';

export const useStatusList = (contents) => {
  const tr = useTranslate().use().global;

  const status = [
    {
      // id: 1,
      id: ORDER_COMPLETION_STATUS.ORDERED,
      title: tr.ordered,
      background: colors.ordered,
      action: () => console.log(tr.ordered),
      acl: 1,
      hide: () => true,
    },
    {
      // id: 2,
      id: ORDER_COMPLETION_STATUS.WILL_ARRIVE,
      title: tr.willArrive,
      background: colors.willArrive,
      action: () => console.log(tr.willArrive),
      acl: 1,
      hide: () => true,
    },
    // {
    //   // id: 3,
    //   id: ORDER_COMPLETION_STATUS.ARRIVED_AND_WAITING,
    //   title: tr.arrived,
    //   background: colors.arrived,
    //   action: () => console.log(tr.arrived),
    //   acl: 1,
    //   hide: () => true,
    // },
    // {
    //   // id: 4,
    //   id: 'paid',
    //   title: tr.paid,
    //   background: colors.paid,
    //   action: () => console.log(tr.paid),
    //   acl: 1,
    //   hide: () => true,
    // },
    // {
    //   // id: 5,
    //   id: 'debt',
    //   title: tr.debt,
    //   background: colors.debt,
    //   action: () => console.log(tr.debt),
    //   acl: 1,
    //   hide: () => true,
    // },
    {
      // id: 6,
      id: ORDER_COMPLETION_STATUS.WILL_NOT_ARRIVE,
      title: tr.canceled,
      background: colors.canceled,
      action: () => console.log(tr.canceled),
      acl: 1,
      hide: () => true,
    },
    {
      // id: 7,
      id: ORDER_COMPLETION_STATUS.NOT_ARRIVED,
      title: tr.absent,
      background: colors.absent,
      action: () => console.log(tr.absent),
      acl: 1,
      hide: () => true,
    },
    // {
    //   // id: 8,
    //   id: 'pending',
    //   title: tr.pending,
    //   background: colors.ordered,
    //   action: () => console.log(tr.ordered),
    //   hide: () => true,
    // },
    {
      id: ORDER_COMPLETION_STATUS.CONFIRMED,
      title: tr.accomplished,
      background: colors.confirmed,
      action: () => console.log(ORDER_COMPLETION_STATUS.CONFIRMED),
      hide: () => true,
    },
  ];

  switch (contents) {
    case 'all':
      return status;
    case 'pending-orders':
      return status.filter(
        (status) => status.id !== ORDER_COMPLETION_STATUS.CONFIRMED,
      );
    default:
      break;
  }

  return Array.isArray(contents)
    ? contents.map((item) => status.find((func) => func.id === item))
    : status.find((func) => func.id === contents);
};

export default useStatusList;
