import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const FilterClearSVG = ({ color }) => {
  return (
    <StyledSVG
      id="Layer_1-2"
      data-name="Layer 1-2"
      xmlns="http://www.w3.org/2000/svg"
      width="14.929"
      height="16.678"
      viewBox="0 0 14.929 16.678"
      color={color ? color : 'currentColor'}
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(-0.002 0.198)">
        <g
          id="Layer_1-2"
          data-name="Layer 1-2"
          transform="translate(0.002 -0.198)"
        >
          <path
            id="Path_656"
            data-name="Path 656"
            d="M14.459,1.481h-.942L14.6.377a.322.322,0,0,0,.066-.446.311.311,0,0,0-.439-.067.319.319,0,0,0-.063.063L12.546,1.573h0L5.667,8.558V7.352a.321.321,0,0,0-.088-.221L.841,2.118h9.451a.319.319,0,0,0,0-.637H.473A.474.474,0,0,0,0,1.959a.482.482,0,0,0,.132.331L5.039,7.481V9.2L.316,13.985a.322.322,0,0,0,0,.451.311.311,0,0,0,.444,0L5.575,9.552h0L12.9,2.118h1.194L9.352,7.131a.321.321,0,0,0-.091.221v8.333l-3.594-2.72V11.48a.314.314,0,1,0-.628,0v1.566a.481.481,0,0,0,.188.382L9.14,16.384a.467.467,0,0,0,.281.1A.468.468,0,0,0,9.889,16V7.481L14.8,2.29a.483.483,0,0,0-.013-.676A.467.467,0,0,0,14.459,1.481Z"
            transform="translate(-0.002 0.198)"
            // fill={color}
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default FilterClearSVG;
