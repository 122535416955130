import React from 'react';

// Style
import * as S from './SVG.styled';

const ClearSearchSVG = ({ color, action }) => {
  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="25.199"
      height="25.191"
      viewBox="0 0 25.199 25.191"
      color={color}
      onClick={action}
      style={{ cursor: 'pointer' }}
    >
      <g
        id="Layer_2"
        data-name="Layer 2"
        transform="translate(-401.833 -1597.605)"
      >
        <g
          id="Layer_1-2"
          data-name="Layer 1-2"
          transform="translate(401.833 1597.605)"
        >
          <path
            id="Path_639"
            data-name="Path 639"
            d="M410.677,1614.275a9.413,9.413,0,0,0,0-10.984l3.049-3.045a1.537,1.537,0,1,0-2.136-2.209l-.038.038-13.99,13.99a6.364,6.364,0,0,1,6.1-9.6,1.537,1.537,0,0,0,.37-3.051l-.059-.006a9.425,9.425,0,0,0-8.63,14.877l-3.045,3.045a1.537,1.537,0,1,0,2.134,2.211l.038-.038,4.042-4.048,9.949-9.941a6.362,6.362,0,0,1-5.452,9.63,1.537,1.537,0,0,0,0,3.073,9.378,9.378,0,0,0,5.491-1.77l5.866,5.866a1.537,1.537,0,1,0,2.248-2.1c-.025-.026-.05-.052-.077-.077Z"
            transform="translate(-391.833 -1597.605)"
            // fill="#0f1d5b"
          />
        </g>
      </g>
    </S.StyledSVG>
  );
};

export default ClearSearchSVG;
