import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// style
import * as S from './SearchHeader.styled';

// icons
import { Ex } from 'icons/dynamic';
import SearchBoldSVG from 'icons/SearchBoldSVG';

// components
import { InputField } from 'components/InputField/InputField';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const SearchHeader = ({ exitAction, updateValue, reset }) => {
  // Variables
  const initialProfessionalValue = [
    {
      id: 'first',
      select: null,
    },
    {
      id: 'second',
      select: null,
    },
    {
      id: 'third',
      select: null,
    },
  ];

  // Hooks
  const tr = useTranslate().use().global;
  const { employees } = useSelector((state) => state.calendarView);

  const professionals = [];

  // -- state
  const [specialistsInSelect, setSpecialistsInSelect] = useState([]);
  const [professionalsFilter, setProfessionalsFilter] = useState(
    initialProfessionalValue,
  );

  const [render, setRender] = useState(true);

  useEffect(() => {
    const transformedProfessionals = professionals.map((obj) => {
      return {
        value: obj.id,
        label: obj.fieldName,
      };
    });

    setSpecialistsInSelect(transformedProfessionals);
  }, []);

  useEffect(() => {
    if (render) return;

    updateValue(professionalsFilter);
  }, [professionalsFilter]);

  useEffect(() => {
    if (reset) return;
    if (render) {
      setRender(false);
      return;
    }

    setTimeout(() => {
      setProfessionalsFilter(initialProfessionalValue);
    }, 200);
  }, [reset]);

  // Functions
  const handleSetValue = (id, obj) => {
    if (!obj) {
      updateProfessionalsFilter(id, null, null);
    } else {
      if (isNaN(obj.value)) {
        return;
      }
      updateProfessionalsFilter(id, obj.label, obj.value);
    }
  };

  const updateProfessionalsFilter = (id, label, value) => {
    setProfessionalsFilter((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { id, select: !label && !value ? null : { label, value } };
        } else return item;
      });
    });
  };

  return (
    <S.SearchProfessionalsContainer>
      <p className="label">{tr['works-together']}:</p>

      <div className="inputsWrapper">
        {professionalsFilter.map((item, i) => (
          <div className="select_wrapper" key={i}>
            <InputField
              id={item.id}
              options={specialistsInSelect}
              state={{
                type: 'select',
                value: item.select,
                setValue: handleSetValue,
              }}
              prepend={{
                icon: <SearchBoldSVG />,
              }}
            />
          </div>
        ))}
      </div>

      <div className="exitBtn" onClick={exitAction}>
        <div className="btn_wrapper">
          <Ex black width={10} height={10} />
        </div>
      </div>
    </S.SearchProfessionalsContainer>
  );
};

export default SearchHeader;
