import { StyledSVG } from './SVG.styled';

const SortDescSVG = ({ color }) => {
  return (
    <StyledSVG
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="46.5"
      height="94.14"
      viewBox="0 0 46.5 94.14"
      color={color ? color : 'currentColor'}
    >
      <path d="M24.67,.59c-.09-.09-.2-.18-.31-.25-.05-.03-.11-.06-.16-.09-.06-.03-.12-.07-.19-.1-.07-.03-.14-.04-.21-.06-.06-.02-.11-.04-.17-.05-.26-.05-.53-.05-.78,0-.06,.01-.11,.03-.17,.05-.07,.02-.14,.04-.2,.06-.07,.03-.13,.06-.19,.1-.05,.03-.11,.05-.16,.09-.11,.07-.21,.16-.31,.25L.59,21.84c-.78,.78-.78,2.05,0,2.83,.39,.39,.9,.59,1.41,.59s1.02-.2,1.41-.59L21.25,6.83V92.14c0,1.1,.9,2,2,2s2-.9,2-2V6.83l17.84,17.84c.39,.39,.9,.59,1.41,.59s1.02-.2,1.41-.59c.78-.78,.78-2.05,0-2.83L24.67,.59Z" />
    </StyledSVG>
  );
};

export default SortDescSVG;
