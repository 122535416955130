import { useState, useContext, useEffect } from 'react';
import { useOutletContext } from 'react-router';

// style
import * as S from './OrderTable.styled';

// icons
import RecommendationSVG from 'icons/bold/RecommendationSVG';
import TrashcanBoldSVG from 'icons/TrashcanBoldSVG';
import CreateNewBoldSVG from 'icons/CreateNewBoldSVG';

// components
import DialogBox from 'components/DialogBox/DialogBox';
import RecommendationModal from 'components/RecommendationModal/RecommendationModal';
import OrderDetailsFooter from '../OrderItemsMobile/OrderDetailsFooter/OrderDetailsFooter';
import DiscountModal from './partials/OrderRowEntries/OrderRowEntrie/DiscountColumn/DiscountModal/DiscountModal';
import OrderHeader from './partials/OrderHeader/OrderHeader';
import OrderRowEntries from './partials/OrderRowEntries/OrderRowEntries';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useOrderFormStatus } from 'hooks/useOrderFormStatus';
import { addMinutes } from 'common/helpers/timeCalculations';

const OrderTable = () => {
  const tr = useTranslate().use().global;

  const columns = [
    { id: 'delete', minWidth: 50 },
    { id: 'title' },
    {
      id: 'duration/capacity',
      level: 1,
      text: `${tr['duration']}/${tr['volume']}`,
      minWidth: 80,
    },
    {
      id: 'sms',
      level: 1,
      text: 'SMS',
      minWidth: 80,
    },
    {
      id: 'amount',
      level: 1,
      text: tr['amount'],
      minWidth: 50,
    },
    {
      id: 'price',
      level: 1,
      text: tr['unitPrice'],
      minWidth: 90,
    },
    {
      id: 'discount',
      level: 1,
      text: tr['discount'],
      minWidth: 90,
    },
    {
      id: 'vat',
      level: 1,
      text: tr['vat'],
      minWidth: 80,
    },
    {
      id: 'totalPrice',
      level: 1,
      text: tr['finalPrice'],
      minWidth: 100,
    },
  ];

  const { setButtons, setDisplayModal, modalToggle } = useOutletContext();
  const [modalSwitch, setModalSwitch] = useState('');

  const { order, saveDiscount, footerData, deleteService, updateServiceKey } =
    useContext(OrderContext);
  // const { setState } = useContext(FunctionsContext);
  useOrderFormStatus({ setButtons });

  //  Where to keep them? -------------------------
  const clientOrdersHistory = [
    {
      amount: '0 €',
      topic: tr.circulation,
    },
    {
      amount: '0',
      topic: tr.ofOrders,
    },
    {
      amount: '0',
      topic: tr.canceledBy,
    },
    {
      amount: '0',
      topic: tr.didntCome,
    },
  ];

  const recommendationButtons = [
    {
      title: 'Ištrinti rekomendaciją',
      icon: <TrashcanBoldSVG />,
      inverted: true,
      action: () => {
        console.log('Užsakyti paslaugas');
      },
    },
    {
      title: 'Užsakyti paslaugas',
      icon: <CreateNewBoldSVG />,
      action: () => {
        console.log('Užsakyti paslaugas');
      },
    },
  ];

  useEffect(() => {
    switch (modalSwitch) {
      case 'footer-discount':
        setDisplayModal(
          <div className="modal-wrapper" key="footer-discount">
            <DiscountModal
              onSave={saveDiscount}
              price={order.state.amountBeforeDiscountWithVat}
              discount={order.state.discountWithVat}
              close={modalToggle}
            />
          </div>,
        );
        break;

      case 'recommendation':
        setDisplayModal(
          <div className="modal-wrapper" key={'recommendation'}>
            <DialogBox
              headerIcon={<RecommendationSVG />}
              headerText={tr['recommendation']}
              close={() => modalToggle()}
              buttons={recommendationButtons}
            >
              <RecommendationModal />
            </DialogBox>
          </div>,
        );
        break;
      default:
        return;
    }
  }, [modalSwitch, order.state.discountWithVat]);

  const getToggledOrdersSavedServices = () => {
    return [].concat.apply(
      [],
      order.state.toggled ? order.state.savedProducts : [],
    );
  };

  const updateAmount = (inputValue, index, compItemId) => {
    let input = inputValue;

    const validateDecimal = (value) => {
      const RE = /^\d*\.?\d{0,2}$/;
      return RE.test(value);
    };

    if (input < 0 || input > 10000) return;
    if (!validateDecimal(input)) return;

    if (compItemId) {
      const copySavedProducts = structuredClone(
        order.state.savedProducts[index].composition,
      );

      const foundIndex = copySavedProducts.findIndex(
        (obj) => obj.id === compItemId,
      );

      copySavedProducts[foundIndex].quantity = input;

      updateServiceKey({ composition: copySavedProducts }, index);
    } else {
      const oldQuantity = order.state.savedProducts[index].quantity;

      updateServiceKey(
        { quantity: input, ...(oldQuantity > input && { discountWithVat: 0 }) },
        index,
      );
    }
  };

  return (
    <>
      <S.OrderCardWrapper>
        {/* <S.Header>
          {clientOrdersHistory.map((item, i) => (
            <SmallInfoCard key={i} amount={item.amount} topic={item.topic} />
          ))}
          <div
            className="cart-card"
            onClick={() => {
              setModalSwitch('recommendation');
              modalToggle();
            }}
          >
            <RecommendationSVG />
          </div>
        </S.Header> */}
        <S.Main>
          <S.OrdersTable>
            <div className="table-wrapper">
              <OrderHeader
                fillRows={columns.length}
                status={order.state.status}
                workSchedule={null}
                orderId={order.state.id}
                dateDisabled={false}
                time={{
                  start: order.state.startTime,
                  end: new Date(
                    addMinutes(
                      order.state.startTime,
                      order.state.durationInMinutes,
                    ),
                  ),
                }}
                servicesLength={order.state.savedProducts.length}
                displayOrderInfo={true}
                isOrderPaid={order.state.isPaid}
              />
              <table>
                <thead>
                  <tr>
                    {columns.map((obj, i) => (
                      <S.Th key={i} minWidth={obj.minWidth}>
                        {obj?.text}
                      </S.Th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!!order.state.savedProducts.length && (
                    <OrderRowEntries
                      services={order.state.savedProducts}
                      headers={columns}
                      deleteEntrie={deleteService}
                      updateServiceKey={updateServiceKey}
                      updateAmount={updateAmount}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </S.OrdersTable>
          <OrderDetailsFooter
            footerData={footerData}
            setModal={{
              toggle: modalToggle,
              setModalSwitch,
            }}
            preventOpenModal={!getToggledOrdersSavedServices().length}
          />
        </S.Main>
      </S.OrderCardWrapper>
    </>
  );
};

export default OrderTable;
