import {
  useState,
  useEffect,
  Children,
  isValidElement,
  cloneElement,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';

// style
import * as S from './SelectionPanel.styled';

// icons
import { Ex } from 'icons/dynamic';
import GoBackSVG from 'icons/GoBackSVG';
import SearchBoldSVG from 'icons/SearchBoldSVG';

// components
import { DebouncedSearch } from 'components/DebouncedSearch/DebouncedSearch';
import { TheButton } from 'components/Buttons/TheButton';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const SelectionPanel = ({
  Child,
  childProps,
  parentId,
  closeAction,
  headerTitle,
  searchTitle,
  array,
  arrayFilter,
  selectItem,
  currentlySelected,
  headerButton,
  additionalInput,
  saveCondition,
  darkTheme,
  notification,
  children,
  presetButtons,
  flashMessages,
}) => {
  // Hooks
  // -- translation
  const tr = useTranslate().use().global;

  // -- state
  const [searchHeader, setSearchHeader] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const [displayElements, setDisplayElements] = useState([]);

  const [vh, setVh] = useState(window.innerHeight * 0.01);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setup':
        return action.value;
      case 'cleanup':
        return [];
      default:
        let newState = [...state];
        let item = newState?.find((button) => button.id === action.id);

        switch (action.type) {
          case 'cleanup':
            newState = [];
            break;
          case 'show':
            item.show = action.value;
            break;

          case 'add':
            newState.push(action.value);
            break;

          case 'remove':
            newState = state.filter((button) => action.id !== button.id);
            break;

          case 'replace':
            item = action.value;
            break;

          case 'update':
            Object.entries(action.value).forEach(
              (prop) => (item[prop[0]] = prop[1]),
            );
            break;

          default:
        }

        return newState;
    }
  };

  const [buttons, dispatch] = useReducer(
    reducer,
    !!presetButtons ? presetButtons : [],
  );

  // -- side effect
  useEffect(() => {
    setDisplayElements(array);
  }, []);

  useEffect(() => {
    if (arrayFilter) arrayFilter(searchInput, array, setDisplayElements);
  }, [searchInput]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // -- Functions
  const handleResize = () => {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(() => {
      setVh(window.innerHeight * 0.01);
    }, 250);
  };

  const hydratedChildren = Children.map(children, (child) =>
    // checking isValidElement is the safe way and avoids a typescript error too
    isValidElement(child)
      ? cloneElement(child, { setButtons: dispatch }) // FIXME: Throws warning
      : child,
  );

  return (
    <S.SelectionPanelContainer
      vh={vh}
      onClick={(e) => e.stopPropagation()}
      darkTheme={darkTheme}
    >
      {!searchHeader ? (
        <div className="navigationHeader">
          <div className="navigationHeader_nav">
            <div className="getBack" onClick={() => closeAction('cleanUp')}>
              <GoBackSVG />
            </div>

            <div className="title">{headerTitle}</div>
          </div>

          <div className="navigationHeader_buttonWrapper">
            {!!buttons?.length && (
              <div className="saveButtonWrapper">
                {buttons.map(
                  (button, index) =>
                    button.show && <TheButton key={index} {...button} />,
                )}
              </div>
            )}

            {arrayFilter && (
              <div
                className="navigationHeader_search"
                onClick={() => setSearchHeader(true)}
              >
                <SearchBoldSVG />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="searchHeader">
          <div className="navigationHeader_nav">
            <DebouncedSearch
              placeholder={searchTitle}
              autofocus
              setSearchQuery={setSearchInput}
            />
          </div>

          <div className="saveButtonWrapper">
            {buttons.map(
              (button, index) =>
                button.show && <TheButton key={index} {...button} />,
            )}
          </div>

          <div
            className="searchHeader_search"
            onClick={() => closeAction('cleanUp')}
          >
            <Ex black width={10} height={10} />
          </div>
        </div>
      )}
      <div className="mainContainer">
        {flashMessages && (
          <div className="flash-messages-wrapper">{flashMessages}</div>
        )}
        {Child && (
          <Child
            arr={displayElements}
            select={selectItem}
            close={closeAction}
            currentlySelected={currentlySelected}
            additionalInput={additionalInput}
            parentId={parentId}
            notification={notification}
            {...childProps}
          />
        )}
        {hydratedChildren}
      </div>
    </S.SelectionPanelContainer>
  );
};

SelectionPanel.propTypes = {
  closeAction: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  searchTitle: PropTypes.string,
  Child: PropTypes.func,
  array: PropTypes.array,
  arrayFilter: PropTypes.func,
  selectItem: PropTypes.func,
  currentlySelected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  headerButton: PropTypes.object,
  additionalInput: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  saveCondition: PropTypes.bool,
};

export default SelectionPanel;
