import styled from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const Search_Container = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  width: 100%;

  background-color: ${(props) =>
    props.darkTheme ? colors.bg_grey_1 : 'white'};

  .searchIcon {
    height: 17.04px;
    svg {
      fill: ${colors.bg_grey_3};
      width: 17.04px;
      height: 17.04px;
    }
  }

  .searchInput {
    width: 100%;
    height: 30px;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    margin-left: 10px;
    color: ${colors.bg_Primary};
    background-color: ${(props) =>
      props.darkTheme ? colors.bg_grey_1 : 'white'};
    font-family: ${fontFamilies.family_Secondary};

    ::placeholder {
      color: ${(props) =>
        props.darkTheme ? colors.bg_grey_3 : colors.bg_grey_2};
    }
  }

  @media screen and (min-width: 961px) {
    height: 50px;
    box-shadow: ${(props) => props.raised && shadow.md};
    border-radius: 25px;
    flex-shrink: 0;
    overflow: hidden;
    padding: 0 15px;

    .searchIcon {
      height: 24px;
      svg {
        fill: ${colors.bg_Primary};
        width: 24px;
        height: 24px;
      }
    }

    .searchInput {
      font-size: 18px;
    //   margin-left: 20px;
      height: 30px;
    }

    .clearInput {
      cursor: pointer;
    }
  }
`;
