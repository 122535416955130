import React from 'react';
import { StyledSVG } from './SVG.styled';

const ShwCollumnsSVG = ({ color }) => {
  return (
    <StyledSVG
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 94.14 94.14"
      color={color ? color : 'currentColor'}
    >
      <defs></defs>
      <g id="Layer_1-2">
        <g>
          <path
            className="cls-1"
            d="M86.14,0H39.98C35.57,0,31.98,3.59,31.98,8V86.14c0,4.41,3.59,8,8,8h46.16c4.41,0,8-3.59,8-8V8C94.14,3.59,90.55,0,86.14,0Zm-25.08,90.14h-21.08c-2.21,0-4-1.79-4-4V8c0-2.21,1.79-4,4-4h21.08V90.14Zm29.08-4c0,2.21-1.79,4-4,4h-21.08V4h21.08c2.21,0,4,1.79,4,4V86.14Z"
          />
          <path
            className="cls-1"
            d="M27.3,.34c-.91-.62-2.16-.38-2.78,.53L11.14,20.62,3.57,11.05c-.69-.87-1.94-1.01-2.81-.33-.87,.69-1.01,1.94-.33,2.81l9.27,11.72c.38,.48,.96,.76,1.57,.76,.02,0,.05,0,.07,0,.64-.02,1.22-.35,1.58-.88L27.83,3.12c.62-.92,.38-2.16-.53-2.78Z"
          />
          <path
            className="cls-1"
            d="M27.3,34.41c-.91-.62-2.16-.38-2.78,.53l-13.38,19.75-7.57-9.58c-.69-.87-1.94-1.02-2.81-.33-.87,.68-1.01,1.94-.33,2.81l9.27,11.72c.38,.48,.96,.76,1.57,.76,.02,0,.05,0,.07,0,.64-.02,1.22-.35,1.58-.88l14.91-22.01c.62-.92,.38-2.16-.53-2.78Z"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default ShwCollumnsSVG;
