import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';
import { TreeMenu } from 'components/ContextTree/ContextTree.styled';

export const PendingOrderCard = styled.div`
  width: 100%;

  @media screen and (max-width: 960px) {
    border-radius: 5px;
    box-shadow: ${shadow.lg};
  }
`;

export const PendingOrderHeader = styled.div`
  padding: 0 20px;

  height: 35px;

  border-radius: 10px 10px 0 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.orderStatus && props.orderStatus};

  transition: 0.1s ease-in-out;

  .pending-title {
    font-size: 14px;
    font-weight: 600;
  }

  .icon {
    display: flex;
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {
    border-radius: 5px 5px 0 0;
    padding: 0 10px;
    height: 30px;
  }
`;

export const PendingOrderMain = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding: 5px 10px;
  border-radius: 0 0 10px 10px;
  background-color: white;

  .order__container {
    display: flex;
    flex-grow: 0;

    padding: 5px 0;

    &:last-child {
      flex-grow: 1;

      max-width: unset;
    }
  }

  .employeePic__wrapper {
    flex-grow: 0;
    display: flex;
    align-items: center;
  }

  .employeePic {
    width: 70px;
    height: 70px;
  }

  .employeeInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin: 0 20px 0 10px;
    flex-grow: 1;

    .name,
    .lastName {
      font-size: 16px;
      font-weight: 600;
    }

    .profession {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .orderDateAndTime {
    display: flex;
    align-items: center;

    font-family: ${fontFamilies.family_Secondary};
    font-size: 14px;

    white-space: nowrap;

    flex-grow: 1;

    margin: 0 20px 0 0;

    .orderDate__wrapper {
      display: flex;
      flex-direction: column;

      min-width: 80px;
      min-height: 53px;
      position: relative;

      .date {
        font-weight: 600;
        margin-bottom: 3px;
      }

      .weekday {
        font-size: 12px;
        margin-bottom: 6px;
      }

      .price {
        position: absolute;
        bottom: -5px;

        font-size: 12px;
        font-weight: 900;
        color: ${colors.discounted};

        .old-price {
          margin-left: 10px;
          color: ${colors.bg_Button_Primary};
          text-decoration: line-through;
        }
      }
    }

    .orderTime__wrapper {
      margin-left: 10px;
      min-height: 53px;

      span {
        padding: 0 2px;
      }

      .startHour {
        font-weight: 600;
      }

      .startHour,
      .endHour {
        white-space: nowrap;
      }
    }
  }

  .ordersTitleList {
    min-width: 250px;
    width: 0;
    padding-right: 20px;
    font-family: ${fontFamilies.family_Secondary};

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    flex-grow: 1;

    position: relative;

    > div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .ordersTitleList__wrapper {
      font-size: 14px;
      display: flex;
      flex-direction: column;
    }

    .ordersNote__wrapper {
      font-size: 14px;
      display: flex;

      .note {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        display: flex;
      }

      .note-bold {
        font-weight: 600;
        margin-right: 5px;
      }
    }
  }

  .cardFunctions {
    display: flex;
    align-items: center;

    ${TreeMenu} {
      /* width: 200px; */
    }

    > div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 960px) {
    padding: 10px;
    border-radius: 0 0 5px 5px;

    .order__container {
      flex-grow: 1;
      flex-wrap: wrap;
      padding: 0;
      > div {
        padding: 5px 0 5px 0;
      }
    }

    .employeePic {
      width: 40px;
      height: 40px;
    }

    .employeeInfo {
      .name,
      .lastName {
        font-size: 12px;
        font-weight: 600;
      }

      .profession {
        font-size: 10px;
        font-weight: 500;
      }
    }

    .orderDateAndTime {
      margin: 0;
      font-size: 12px;

      width: fit-content;
      flex-grow: 0;

      .orderDate__wrapper {
        .weekday {
          display: none;
        }
        .price {
          top: 20px;
        }
      }
    }

    .ordersTitleList {
      padding-right: 0;

      .ordersTitleList__wrapper {
        font-size: 12px;
      }

      .ordersNote__wrapper {
        font-size: 12px;
      }
    }

    .cardFunctions {
      border: 1px solid red;
      display: none;
    }

    .note {
      margin-top: 10px;
    }
  }
`;

export const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .tooltip {
    display: none;
    position: absolute;
    margin: 0;
    left: 50px;

    > span {
      border-radius: 10px;
      display: inline-block;
      font-size: 14px;
      line-height: 1;
      padding: 5px 10px;
      position: relative;

      top: 16px;
      left: 0;
      z-index: 1;

      max-width: 400px;

      display: flex;
      flex-wrap: wrap;

      white-space: pre-wrap;

      background-color: white;
      border: 1px solid ${colors.bg_grey_2};
      box-shadow: ${shadow.lg};
    }
  }

  :hover {
    .tooltip {
      display: unset;
    }
  }
`;

export const TextModal = styled.div`
  font-family: ${fontFamilies.family_Secondary};

  max-width: 560px;
  padding: 10px 0px;

  a {
    color: ${colors.link};
  }
`;
