import { useContext } from 'react';

// components
import { Chip } from 'components/Chip/Chip';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import { useTable } from 'hooks/useTable';
import useTranslate from 'hooks/useTranslate';
import useNumber from 'hooks/useNumber';

// helpers
import { TABLE_FILTER_TYPE } from 'common/Constants';

export const FilterChip = ({ filter, openFilters }) => {
  const tr = useTranslate().use().global;
  const {
    state: { columns },
  } = useContext(TableContext);
  const { removeFilters } = useTable();
  const { format } = useNumber();

  const filterField = filter.field.replace('From', '').replace('Till', '');
  const column = columns.find((column) => column.filter === filterField);
  let value = filter.valueStr;
  let label = column.label;

  switch (column.filterType) {
    case TABLE_FILTER_TYPE.SELECT:
      value = column.options.find(
        (option) => option.value === filter.value,
      ).label;

      break;
    case TABLE_FILTER_TYPE.INPUT_RANGE:
    case TABLE_FILTER_TYPE.DATE_RANGE:
      if (filter.field.includes('From')) {
        label += ' ' + tr['from'];
      } else if (filter.field.includes('Till')) {
        label += ' ' + tr['to'];
      }

      break;
  }

  if (column?.inputProps?.type === 'number') {
    value = format(value, {
      decimalPlaces: column?.inputProps?.decimalPlaces ?? 1,
      roundingMethod: 'D',
    });
  }

  return (
    <Chip
      onClick={openFilters}
      key={filter.field}
      onRemove={() => removeFilters([filter.field])}
    >
      {label + ': ' + value}
    </Chip>
  );
};
