import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const HumanSVG = ({ color, width, height }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '23.395'}
      height={height ? height : '25.457'}
      viewBox="0 0 71.78 94.14"
    >
      <g>
        <path
          d="m35.89 49.57c11.89 0 21.57-11.12 21.57-24.78s-9.68-24.79-21.57-24.79-21.57 11.12-21.57 24.79 9.68 24.78 21.57 24.78z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="m55.17 50.78-0.97-0.33c-0.64-0.22-1.34-0.1-1.88 0.32-4.85 3.79-10.53 5.8-16.44 5.8s-11.59-2-16.44-5.8c-0.53-0.42-1.24-0.53-1.88-0.32l-0.98 0.33c-9.91 3.43-16.58 12.77-16.58 23.27v18.09c0 1.1 0.9 2 2 2h67.78c1.1 0 2-0.9 2-2v-18.09c0-10.5-6.67-19.84-16.61-23.26z"
          fill={color ? color : 'currentColor'}
        />
      </g>
    </StyledSVG>
  );
};

export default HumanSVG;
