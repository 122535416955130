import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const Container = styled.aside`
  background-color: white;
  display: flex;
  flex-direction: column;
  left: ${(props) => (props.show ? '0' : '-280px')};
  margin-bottom: 40px;
  position: fixed;
  top: 0;
  transition: left 0.3s ease-in-out;
  width: 280px;

  .project-version {
    position: absolute;
    bottom: 2px;
    left: 6px;
    font-size: 12px;
    color: ${colors.bg_grey_3};
  }

  @media (max-width: 960px) {
    box-shadow: ${(props) =>
      props.show ? '2px 0px 3px rgb(0 0 0 / 16%)' : 'none'};
    z-index: 1001;
  }
`;

export const LogoContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.small_details};
  border-right: 1px solid ${colors.table_borders_Primary};
  display: flex;
  height: 70px;
  width: 100%;

  @media (max-width: 960px) {
    border-right: unset;
    justify-content: space-between;
    padding: 0 20px;
    position: fixed;
    width: inherit;
    z-index: 1002;
  }

  @media (max-width: 960px) {
    height: 54px;
  }

  a {
    color: unset;
    margin-top: 8px;

    svg {
      height: 2rem;
      margin-left: 20px;

      @media (max-width: 960px) {
        margin-left: unset;
      }

      @media (max-width: 960px) {
        height: 1.5rem;
      }
    }
  }

  .avatar {
    height: 40px;
    width: 40px;
  }
`;

export const MenuContainer = styled.nav`
  height: calc(100vh - 70px);
  height: calc(100dvh - 70px);
  overflow-y: scroll;
  position: relative;
  width: 100%;

  @media (max-width: 960px) {
    height: calc(100vh - 54px);
    height: calc(100dvh - 54px);
    margin-top: 54px;
    overflow-y: auto;
    position: unset;
    width: unset;
    z-index: 1001;
  }

  @-moz-document url-prefix() {
    border-right: 1px solid ${colors.table_borders_Primary};
  }
`;

export const HideButton = styled.button`
  align-items: center;
  border: none;
  border-radius: ${(props) => (props.show ? '50% 0 0 50%' : '0 50% 50% 0')};
  bottom: 40px;
  color: ${colors.button_Primary};
  display: flex;
  height: 25px;
  justify-content: center;
  left: ${(props) => (props.show ? '251px' : '280px')};
  padding: 0;
  position: absolute;
  width: 21px;

  @media (max-width: 960px) {
    display: none;
  }

  svg {
    height: 9px;
  }
`;
