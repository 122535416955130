import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const VarneleSVG = ({ color, tip, width, height }) => {
  let rotation = null;
  switch (tip) {
    case 'right':
      rotation = -90;
      break;
    case 'left':
      rotation = 90;
      break;
    case 'up':
      rotation = 180;
      break;
    default:
  }
  return (
    <StyledSVG
      color={color ? color : 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '10.45'}
      height={height ? height : '5.65'}
      viewBox="0 0 14.12 7.892"
      style={rotation && { transform: 'rotate(' + rotation + 'deg)' }}
    >
      <path d="m7.0618 7.8938a0.832 0.832 0 0 1-0.588-0.244l-6.249-6.249a0.832 0.832 0 0 1 1.176-1.176l5.661 5.66 5.661-5.66a0.832 0.832 0 0 1 1.176 1.176l-6.249 6.249a0.832 0.832 0 0 1-0.588 0.244z" />
    </StyledSVG>
  );
};

export default VarneleSVG;
