import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

// style
import * as S from './OrderRowEntrie.styled';

// icons
import { Clock } from 'icons/dynamic';
import TrashcanSVG from 'icons/TrashcanSVG';
import RulerSVG from 'icons/RulerSVG';
import CubeSVG from 'icons/CubeSVG';

// components
import ActionColumn from './ActionColumn/ActionColumn';
import IconValueColumn from './IconValueColumn/IconValueColumn';
import BasicSelect from 'components/BasicSelect/BasicSelect';
import DiscountColumn from './DiscountColumn/DiscountColumn';

// hooks
import useNumber from 'hooks/useNumber';
import useTranslate from 'hooks/useTranslate';
import { useSystemGetUnits } from 'services/systemService';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';

// -- temp data
export const smsDuration = [
  { label: '—', value: 0 },
  { label: '1 sav.', value: '1w' },
  { label: '2 sav.', value: '2w' },
  { label: '3 sav.', value: '3w' },
  { label: '4 sav.', value: '4w' },
  { label: '5 sav.', value: '5w' },
  { label: '6 sav.', value: '6w' },
  { label: '1 mėn', value: '1m' },
  { label: '2 mėn', value: '2m' },
  { label: '3 mėn', value: '3m' },
  { label: '4 mėn', value: '4m' },
  { label: '5 mėn', value: '5m' },
  { label: '6 mėn', value: '6m' },
  { label: '7 mėn', value: '7m' },
  { label: '8 mėn', value: '8m' },
  { label: '9 mėn', value: '9m' },
  { label: '10 mėn', value: '10m' },
  { label: '11 mėn', value: '11m' },
  { label: '1 metai', value: '1y' },
  { label: '2 metai', value: '2y' },
];

export const vatSelect = [
  { label: '—', value: null },
  { label: '0.00%', value: 0 },
  { label: '5.00%', value: 5.0 },
  { label: '9.00%', value: 9.0 },
  { label: '21.00%', value: 21.0 },
];
// --

const OrderRowEntrie = ({
  index,
  headerId,
  service,
  deleteEntrie,
  updateServiceKey,
  updateAmount,
  compItem,
  isComp,
}) => {
  const tr = useTranslate().use().global;
  // Hooks
  // -- state
  // --- local
  const [quantity, setQuantity] = useState(1);
  const { getUnitsById } = useSystemGetUnits();
  const { formatPrice, formatUnits } = useNumber({ price: true });

  useEffect(() => {
    if (!service.quantity && !service.compServiceCount) return;

    setQuantity(service.quantity || service.compServiceCount);
  }, [service.quantity, service.compServiceCount]);

  // Functions
  const getContent = (id, item) => {
    const isMaterial = item.unitId !== undefined;

    switch (id) {
      case 'delete':
        return (
          !compItem && (
            <ActionColumn
              icon={<TrashcanSVG />}
              action={() => deleteEntrie(index)}
            />
          )
        );

      case 'title': {
        const danger = item?.canBeSold === false;

        return (
          <div
            className={`title-column ${compItem && 'compItem'}${danger ? ' danger' : ''}`}
          >
            {item.hasComp && (
              <div className="title-column__logo">
                <CubeSVG />
              </div>
            )}
            <span
              className="title-column__title"
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                danger && tr['service-not-provided'],
              )}
            >
              {item.parentTitle && item.parentTitle + ' / '}
              {item.name}
            </span>
          </div>
        );
      }
      case 'duration/capacity':
        if (!isMaterial) {
          const danger =
            item?.unitDurationInMinutesNorm !== undefined &&
            item?.unitDurationInMinutes !== item.unitDurationInMinutesNorm;

          return (
            <IconValueColumn
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                danger ? (
                  <CenteredTooltipContent
                    title={tr['current-duration']}
                    content={getTimeFromMinutes(item.unitDurationInMinutesNorm)}
                  />
                ) : (
                  ''
                ),
              )}
              icon={<Clock />}
              value={
                <span className={danger ? 'danger' : ''}>
                  {getTimeFromMinutes(item.unitDurationInMinutes)}
                </span>
              }
            />
          );
        } else {
          const unitShortName = getUnitsById(service.unitId)?.label;

          return (
            <IconValueColumn
              icon={<RulerSVG />}
              value={
                formatUnits(item.unitVolume) +
                (!!unitShortName && ` ${unitShortName}`)
              }
            />
          );
        }
      case 'sms':
        if (!isMaterial && !compItem) {
          return (
            <div className="select-column">
              <BasicSelect
                id={id}
                options={id === 'sms' ? smsDuration : vatSelect}
                value={item[id]}
                onChange={(e) => {
                  updateServiceKey({ [id]: e }, index);
                }}
              />
            </div>
          );
        } else {
          break;
        }
      case 'vat': {
        const danger =
          item?.vatPercentNorm !== undefined &&
          item?.vatPercent !== item.vatPercentNorm;

        const vatPercentNormLabel =
          vatSelect.find(
            (vatItem) => vatItem.value === (item.vatPercentNorm ?? null),
          )?.label ?? '-';

        return (
          !compItem && (
            <div
              data-tooltip-offset={6}
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                danger ? (
                  <CenteredTooltipContent
                    title={tr['current-vat']}
                    content={vatPercentNormLabel}
                  />
                ) : (
                  ''
                ),
              )}
              className="select-column"
            >
              <BasicSelect
                warning={danger}
                id={id}
                options={id === 'sms' ? smsDuration : vatSelect}
                value={item[id]}
                onChange={(e) => {
                  updateServiceKey({ [id]: e }, index);
                }}
              />
            </div>
          )
        );
      }

      case 'amount':
        return (
          // <div className={`amount-column ${compItem && isMaterial && 'red'}`}>
          <div className={'amount-column'}>
            <input
              type={'number'}
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              onFocus={(e) => e.target.select()}
              onBlur={(e) =>
                handleAmountInputBlur(
                  e.target.value,
                  index,
                  compItem && service.id,
                )
              }
              // disabled={compItem && !isMaterial}
              disabled={compItem}
            />
          </div>
        );
      case 'price':
        const danger =
          item?.unitPriceWithVatNorm !== undefined &&
          item?.unitPriceWithVat !== item.unitPriceWithVatNorm;

        return (
          !compItem && (
            <div
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                danger ? (
                  <CenteredTooltipContent
                    title={tr['current-price']}
                    content={
                      item.unitPriceWithVatNorm === undefined
                        ? '-'
                        : formatPrice(item.unitPriceWithVatNorm, true)
                    }
                  />
                ) : (
                  ''
                ),
              )}
              className={`price-column${danger ? ' danger' : ''}`}
            >
              {formatPrice(item.unitPriceWithVat, true)}
            </div>
          )
        );

      case 'discount':
        return (
          !compItem && (
            <DiscountColumn
              service={item}
              index={index}
              saveChange={updateServiceKey}
            />
          )
        );

      case 'totalPrice':
        return (
          !compItem && (
            <div className="total-price-column">
              {formatPrice(item?.finalAmountWithVat || 0)}
            </div>
          )
        );
      default:
        return;
    }
  };

  const handleAmountInputBlur = (value, index, compItem) => {
    if (+value <= 0 || value === '') {
      service.quantity === 1
        ? setQuantity(1)
        : updateAmount(1, index, compItem);
    } else {
      updateAmount(+value, index, compItem);
    }
  };

  return (
    <S.OrderRowEntrie compItem={compItem}>
      <div className="content">{getContent(headerId, service)}</div>
    </S.OrderRowEntrie>
  );
};

const CenteredTooltipContent = ({ title, content }) => {
  return (
    <div className="centered">
      <div>{title + ':'}</div>
      <div>{content}</div>
    </div>
  );
};

export default OrderRowEntrie;
