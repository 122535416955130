import { StyledSVG } from './SVG.styled';

const ItemRemoveSVG = ({ color, width, height }) => {
  const colors = color || 'currentColor';

  return (
    <StyledSVG
      width={width ? width : '20'}
      height={height ? height : '20'}
      version="1.0"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      color={colors}
    >
      <g transform="translate(-739 -253)" data-name="Group 3582">
        <g transform="translate(20)" data-name="Group 3152">
          <g transform="translate(739 253)" data-name="Group 1687">
            <g
              transform="translate(-20)"
              fill="none"
              stroke={colors ? colors : '#8d92a3'}
              data-name="Ellipse 85"
            >
              <circle cx="10" cy="10" r="10" stroke="none" />
              <circle cx="10" cy="10" r="9.5" fill="none" />
            </g>
          </g>
          <g transform="translate(723 253.18)" data-name="Layer 1-2">
            <path
              transform="translate(-572.74 -1019.7)"
              d="m584.04 1028.6h-10.59c-0.389 0-0.7 0.526-0.7 1.175s0.316 1.175 0.7 1.175h10.59c0.389 0 0.7-0.526 0.7-1.175s-0.311-1.175-0.7-1.175z"
              fill={colors ? colors : '#8d92a3'}
              data-name="Path 373"
            />
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

export default ItemRemoveSVG;
