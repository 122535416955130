import React from 'react';
import { StyledSVG } from './SVG.styled';

const MessageSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="15.977"
      height="15.182"
      viewBox="0 0 15.977 15.182"
      color={color}
    >
      <g
        id="Group_756"
        data-name="Group 756"
        transform="translate(-292.892 -2428.641)"
      >
        <path
          id="Path_468"
          data-name="Path 468"
          d="M309.952,2444.563h9.623a.339.339,0,1,0,0-.679h-9.623a.339.339,0,1,0,0,.679Z"
          transform="translate(-13.883 -12.656)"
        />
        <path
          id="Path_469"
          data-name="Path 469"
          d="M309.952,2459.63h9.623a.339.339,0,1,0,0-.679h-9.623a.339.339,0,1,0,0,.679Z"
          transform="translate(-13.883 -25.166)"
        />
        <path
          id="Path_470"
          data-name="Path 470"
          d="M309.952,2474.7h4.723a.339.339,0,0,0,0-.679h-4.723a.339.339,0,0,0,0,.679Z"
          transform="translate(-13.883 -37.676)"
        />
        <path
          id="Path_471"
          data-name="Path 471"
          d="M292.892,2428.641v15.182l3.9-3.516h12.08v-11.666Zm15.3,10.987H296.528l-2.957,2.668V2429.32H308.19Z"
          transform="translate(0 0)"
        />
      </g>
    </StyledSVG>
  );
};

export default MessageSVG;
