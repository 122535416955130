// style
import * as S from './BasicTable.styled';

// components
import Loader from 'components/Loader/Loader';
import { BasicTableHead } from './partials/BasicTableHead/BasicTableHead';
import { BasicTableRecord } from './partials/BasicTableRecord/BasicTableRecord';

const BasicTable = ({ columns, data, select, isLoading, navigation }) => (
  <S.BasicTable>
    <table>
      <BasicTableHead columns={columns} />
      <tbody>
        {(data?.records || []).map((record) => (
          <BasicTableRecord
            key={record.id}
            selected={select.selected.id === record.id}
            onClick={() => select.setSelected(record)}
            columns={columns}
            record={record}
            navigation={navigation}
          />
        ))}
      </tbody>
    </table>
    {isLoading && <Loader />}
  </S.BasicTable>
);

export default BasicTable;
