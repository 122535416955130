import { cloneElement, forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';

// style
import * as S from './FunctionBarItem.styled';

// components
import { ContextTree } from 'components/ContextTree/ContextTree';

// icons
import { Arrow } from 'icons/dynamic';

// context
import { UserContext } from 'context/UserContext';

// hooks and helpers
import ClickAwayListener from 'react-click-away-listener';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { FunctionBarSubItem } from '../FunctionBarSubItem/FunctionBarSubItem';

export const FunctionBarItem = ({
  id,
  isActive,
  setActive,
  action,
  closeOnClick,
  closeMenu,
  tooltip,
  icon,
  iconTitle,
  scope, // identifier for bitwise validation: 1 - desktop, 2 - mobile, 3 - both
  subItems,
  color,
}) => {
  const showOnMobile = (2 & scope) === 2;
  const showOnDesktop = (1 & scope) === 1;
  const { userAcl } = useContext(UserContext);

  const Action = forwardRef((props, ref) =>
    cloneElement(action, { ref, ...props }),
  );

  const isMobile = useMediaQuery('(max-width: 960px)');

  const processedIcon = icon && cloneElement(icon, { sm: isMobile }); // add prop for bold icon if isMobile

  const adjustSubItems = () => {
    if (!subItems) return;
    const filtered = subItems.filter((item) => {
      if (!item.hide) return true;
      return item?.hide && item.hide();
    });

    return (close) =>
      filtered.map((item) => {
        return {
          id: item.id,
          icon: item?.icon,
          items: item?.items,
          label: item.name,
          color: item?.color,
          statusColor: item?.statusColor,
          fontColor: item?.fontColor,
          onClick: () => {
            item.action();
            setActive(null);
            close();
          },
        };
      });
  };

  const filteredFunctions = adjustSubItems();

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (isMobile) return;
        setActive(null);
      }}
    >
      <S.FunctionBarItem
        onClick={(e) => {
          e.stopPropagation();

          setActive((old) => {
            if (old === id || (closeOnClick && !subItems)) return null;
            else return id;
          });
          action && !subItems && typeof action === 'function' && action();

          isMobile && closeOnClick && closeMenu();
        }}
        data-text={tooltip}
        showOnMobile={showOnMobile}
        showOnDesktop={showOnDesktop}
        className={isActive && 'active'}
        hasDropdown={!!subItems}
        showDropdown={isActive}
        id={id}
        color={color}
      >
        <div>
          {processedIcon} <span>{iconTitle}</span>
          {subItems && (
            <span>
              <Arrow black width={16} height={16} />
            </span>
          )}
        </div>

        {isActive &&
          (isMobile
            ? subItems && (
                <div className="dropdown">
                  {subItems.map(
                    (item, key) =>
                      item.hide &&
                      item.hide() &&
                      item.acl >= userAcl && (
                        <FunctionBarSubItem
                          key={key}
                          item={item}
                          setActive={setActive}
                          closeMenu={closeMenu}
                        />
                      ),
                  )}
                </div>
              )
            : // : null)
              action &&
              typeof action === 'object' && (
                <div onClick={(e) => e.stopPropagation()}>
                  <Action />
                </div>
              ))}

        {!isMobile && subItems && (
          <ContextTree
            containerId={id}
            items={filteredFunctions}
            open={isActive || null}
            preferedSide={'left'}
            openOnClick
          />
        )}
      </S.FunctionBarItem>
    </ClickAwayListener>
  );
};

FunctionBarItem.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  tooltopText: PropTypes.string,
  icon: PropTypes.node,
};

export default FunctionBarItem;
