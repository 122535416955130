import * as S from '../SVG.styled';

export default ({ color, width, height, bold, sm, md }) => {
  const content = bold ? (
    <path d="m29.97 7.2616c-0.01223-0.06724-0.03056-0.13448-0.05501-0.19866 0 0 0-0.0061-0.0031-0.0061-0.02445-0.06418-0.05501-0.12225-0.08863-0.18032-0.0061-0.01223-0.01528-0.02445-0.02139-0.03973-0.03668-0.05501-0.07641-0.11002-0.11919-0.15893-0.0092-0.01223-0.02139-0.02139-0.03362-0.03362-0.04584-0.04584-0.09169-0.09169-0.1467-0.12836-0.0061-0.0031-0.0092-0.0092-0.01528-0.01223-0.06113-0.04279-0.12225-0.07946-0.18949-0.11003-0.0061-0.0031-0.01223-0.0092-0.02139-0.01223l-13.771-6.2653c-0.32091-0.1467-0.69072-0.1467-1.0116 0l-13.778 6.2623s-0.01528 0.0092-0.02139 0.01223c-0.06418 0.03056-0.12836 0.06724-0.18643 0.11002-0.0061 0.0031-0.0092 0.0092-0.01528 0.01223-0.05196 0.03973-0.10086 0.08252-0.1467 0.12836-0.01222 0.01223-0.02139 0.02139-0.03362 0.03362-0.04584 0.0489-0.08558 0.10391-0.11919 0.15893-0.0092 0.01222-0.01528 0.02445-0.02139 0.03973-0.03362 0.05807-0.06418 0.11919-0.08863 0.18032 0 0 0 0.0031-0.0031 0.0061-0.02445 0.06418-0.03973 0.13142-0.05501 0.19866 0 0.01528-0.0061 0.03056-0.0092 0.04584-0.0092 0.06113-0.01528 0.12225-0.01528 0.18338v16.702c0 0.52568 0.33619 0.99328 0.83742 1.1583l13.775 4.5844h0.0061c0.04584 0.01528 0.09169 0.02445 0.13753 0.03362 0.01834 0.0031 0.03362 0.0092 0.05196 0.01223 0.06112 0.0092 0.12531 0.01528 0.18643 0.01528h0.0061 0.0061c0.06113 0 0.12531-0.0061 0.18643-0.01528 0.01834 0 0.03362-0.0092 0.05196-0.01223 0.04584-0.0092 0.09169-0.01834 0.13753-0.03362h0.0061l13.775-4.5844c0.49817-0.16504 0.83742-0.63264 0.83742-1.1583v-16.699c0-0.06113-0.0061-0.12225-0.01528-0.18338 0-0.01528-0.0061-0.03056-0.0092-0.04584zm-14.973-4.6914 10.434 4.7433-10.434 3.4719-10.434-3.4719zm-12.552 6.6168 11.33 3.7714v14.126l-11.33-3.7714zm25.104 14.126-11.33 3.7714v-14.129l11.33-3.7714v14.126z" />
  ) : (
    <path d="m29.979 7.0324s-0.0096-0.03186-0.01275-0.0478c-0.0096-0.02868-0.01912-0.05736-0.03186-0.08603-0.0064-0.01593-0.01275-0.02868-0.02231-0.04461-0.01593-0.02868-0.03505-0.05417-0.05417-0.08285-0.0096-0.0096-0.01593-0.02231-0.02549-0.03186-0.03186-0.03505-0.06373-0.06691-0.10196-0.09559-0.0032 0-0.0064-0.0032-0.0096-0.0064-0.03186-0.0223-0.06373-0.04142-0.09878-0.05735l-14.361-6.5225c-0.16888-0.07647-0.36006-0.07647-0.52894 0l-14.361 6.5289c-0.03505 0.01593-0.0701 0.03505-0.09878 0.05736 0 0-0.0064 0-0.0096 0.0064-0.03824 0.02868-0.07329 0.06054-0.10515 0.09559-0.0096 0.0096-0.01593 0.0223-0.02231 0.03186-0.01912 0.02549-0.03824 0.05417-0.05417 0.08285-0.0064 0.01275-0.01593 0.02868-0.02231 0.04461-0.01275 0.02868-0.0223 0.05736-0.03186 0.08603-0.0032 0.01593-0.0096 0.03186-0.01275 0.0478-0.0064 0.03505-0.0096 0.0701-0.01275 0.10515v0.02549 17.414c0 0.27403 0.17525 0.51938 0.43654 0.60542l14.361 4.7796h0.0032c0.06373 0.01912 0.12746 0.03186 0.19437 0.03186h0.0032 0.0032c0.06692 0 0.13064-0.0096 0.19437-0.03186h0.0032l14.361-4.7796c0.26128-0.08603 0.43654-0.33139 0.43654-0.60542v-17.417-0.02549c0-0.03505-0.0064-0.0701-0.01275-0.10515zm-14.982-5.6941 12.618 5.7355-12.618 4.1997-12.618-4.1997zm-13.724 6.7106 13.087 4.3558v16.072l-13.087-4.3558zm27.448 16.072-13.087 4.3558v-16.072l13.087-4.3558z" />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={sm ? 16 : md ? 30 : width ? width : 30}
      height={sm ? 16 : md ? 30 : height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
