import { useState } from 'react';

// Components
import Overlay from 'components/Overlay/Overlay';
import SetServiceSelectModal from 'components/SetServiceSelectModal/SetServiceSelectModal';
import { ServiceSelectContext } from 'context/ServiceSelectContext';

export const ServiceSelector = ({
  data,
  close,
  breadCrumbs,
  name,
  searchParams,
  showServiceAmountSelectionModal = true,
}) => {
  // Hooks
  // -- state
  const [selectedServiceList, setSelectedServiceList] = useState(
    data.state || [],
  );

  // functions
  const saveToState = (arr) =>
    data.setState('composition', { value: arr, label: arr });

  return (
    <ServiceSelectContext.Provider
      value={{
        showServiceAmountSelectionModal: showServiceAmountSelectionModal,
      }}
    >
      <Overlay isVisible={true} hide={close}>
        <div className="modal-wrapper">
          <SetServiceSelectModal
            close={close}
            breadCrumbs={breadCrumbs}
            name={name}
            selectedServices={{
              state: selectedServiceList,
              setState: setSelectedServiceList,
            }}
            save={saveToState}
            searchParams={searchParams}
          />
        </div>
      </Overlay>
    </ServiceSelectContext.Provider>
  );
};

export default ServiceSelector;
