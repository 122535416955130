import React from 'react';
import PropTypes from 'prop-types';

// Style
import * as S from './SelectVatRate.styled';

// Components
import SelectList from '../CustomSelect/children/SelectList/SelectList';
import { vatSelect } from 'pages/Order/partials/OrderTable/partials/OrderRowEntries/OrderRowEntrie/OrderRowEntrie';

const SelectVatRate = ({ select, currentlySelected }) => {
  return (
    <S.VarRateContainer>
      <SelectList
        arr={vatSelect}
        currentlySelected={(value) => currentlySelected.value === value}
        action={select}
        belongsTo={currentlySelected.orderId}
      />
    </S.VarRateContainer>
  );
};

SelectVatRate.propTypes = {
  arr: PropTypes.array,
  select: PropTypes.func,
  currentlySelected: PropTypes.object,
};

export default SelectVatRate;
