import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const DayViewSVG = ({ color }) => {
  return (
    <StyledSVG
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="16.101"
      height="10.918"
      viewBox="0 0 16.101 10.918"
      color={color}
    >
      <g id="Layer_1-2" data-name="Layer 1-2">
        <path
          id="Path_448"
          data-name="Path 448"
          d="M14.732,15.38H1.368A1.368,1.368,0,0,0,0,16.748H0v2.921a1.368,1.368,0,0,0,1.368,1.368H14.732A1.368,1.368,0,0,0,16.1,19.669V16.748A1.368,1.368,0,0,0,14.732,15.38Zm.684,4.289a.684.684,0,0,1-.684.684H1.368a.684.684,0,0,1-.684-.684V16.748a.684.684,0,0,1,.684-.684H14.732a.684.684,0,0,1,.684.684Z"
          transform="translate(0 -12.75)"
          //   fill="#0f1d5b"
        />
        <path
          id="Path_449"
          data-name="Path 449"
          d="M15.759,59.84H.342a.342.342,0,0,0,0,.684H15.759a.342.342,0,0,0,0-.684Z"
          transform="translate(0 -49.606)"
          //   fill="#0f1d5b"
        />
        <path
          id="Path_450"
          data-name="Path 450"
          d="M.342.684H15.759a.342.342,0,1,0,0-.684H.342a.342.342,0,0,0,0,.684Z"
          //   fill="#0f1d5b"
        />
      </g>
    </StyledSVG>
  );
};

export default DayViewSVG;
