import { useState, useRef, useEffect, useContext } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { ScheduleContext } from 'context/ScheduleContext';
import { toast } from 'react-toastify';
import { updateAppointment } from 'redux/actions/appointmentsAction';

// style
import * as S from './AppointmentWrapper.styled';

// icons
import { CopySVG, TrashCan, Euro } from 'icons/dynamic';
import SendSVG from 'icons/bold/SendSVG';

// components
import AppointmentCard from 'components/AppointmentCard/AppointmentCard';
import AppointmentsDetails from 'components/AppointmentCard/AppointmentsDetails/AppointmentsDetails';
import ContextTree from 'components/ContextTree/ContextTree';
import AppointmentsDetailsMobile from 'components/AppointmentCard/AppointmentsDetails/AppointmentsDetailsMobile/AppointmentsDetailsMobile';
import Overlay from 'components/Overlay/Overlay';
import PaperPlaneSVG from 'icons/PaperPlaneSVG';
import TrashcanBoldSVG from 'icons/TrashcanBoldSVG';
import Presets from 'components/DialogBox/DialogPresets/DialogPresets';
import MultiAppointments from 'components/AppointmentCard/MultiAppointmentsCard/MultiAppointmentsCard';
import DialogLayout from 'components/DialogLayout/DialogLayout';
import TimeOff from 'components/DialogLayout/children/TimeOff/TimeOff';
// hooks
import useModal from 'hooks/useModal';
import useMediaQuery from 'hooks/useMediaQuery';
import { useDispatch } from 'react-redux';
import { useContextMenuItems } from 'hooks/useContextMenuItems';
import useTranslate from 'hooks/useTranslate';
import { formatToIsoWithoutMilliseconds } from 'common/helpers/dateOperations';
import { colors } from 'common/colors/colors';
import { listOfExcuses } from 'common/helpers/tempData';
import { SCHEDULE_CELL_HEIGHT } from 'common/Constants';
import {
  getAppointmentHeight,
  getAppointmentPosition,
  getColumnOrders,
  getOrderTimeIntervals,
  willOrderOverlap,
} from 'common/helpers/scheduleFunctions';
import useAppointmentCard from 'hooks/schedule/useAppointmentCard';

const AppointmentWrapper = ({
  id,
  selected,
  topPosition,
  height,
  multiBooking,
  parentHeight,
  updateAppointmentPosition,
  updateAppointmentHeight,
  updateToPendingOrders,
  dragging,
  widths,
  getHoverTime,
  timeOff,
  functions,
  data,
  globalOrders,
  hourInterval,
  startHour,
  notOverlapingStatuses,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;
  const { isShowing, toggle } = useModal();
  const dispatch = useDispatch();
  const { dataSource, cache, setScheduleScrollTargetNode } =
    useContext(ScheduleContext);
  const serviceCardMenuItems = useContextMenuItems([
    isMobile && 'open-folder',
    isMobile && 'change-status',
    isMobile && 'balance-status',
    'functions',
  ]);
  const timeOffMenuItems = useContextMenuItems(['edit', 'delete-timeOff']);
  const { getBalanceActions, getStatusActions } = useAppointmentCard({
    appointment: data,
  });
  const [containerTop, setContainerTop] = useState(topPosition);
  const [containerHeight, setContainerHeight] = useState(height);
  const [containerLeft, setContainerLeft] = useState(null);
  const [focusing, setFocusing] = useState(0);
  const [displayContextMenu, setDisplayContextMenu] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(false);
  const [displayModal, setDisplayModal] = useState(null);
  const [modalSwitch, setModalSwitch] = useState('');
  const [draggable, setDraggable] = useState(selected);
  const [hoverTime, setHoverTime] = useState(null);
  const containerRef = useRef(null);
  const scrollTimer = useRef(null);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const hasBreak = !!data.services.find((item) => item.serviceId === 1);
  let isAppointmentValid = true;
  const modalButtons = {
    send: [
      {
        title: 'Siųsti',
        icon: <PaperPlaneSVG />,
        action: () => {
          console.log('sms');
        },
      },
    ],
    delete: [
      {
        title: 'Trinti',
        icon: <TrashcanBoldSVG />,
        action: () => {
          functions.delete(id);
        },
      },
    ],
  };

  useEffect(() => {
    setContainerTop(topPosition);
    setContainerHeight(height);
  }, [topPosition, height]);

  useEffect(() => {
    if (!modalSwitch) return;

    switch (modalSwitch) {
      case 'send-sms-link':
        setDisplayModal(
          <Presets
            preset={'smsLink'}
            close={handleCloseAction}
            buttons={modalButtons.send}
          />,
        );
        break;
      case 'send-sms-reminder':
        setDisplayModal(
          <Presets
            preset={'smsReminder'}
            close={handleCloseAction}
            buttons={modalButtons.send}
          />,
        );
        break;
      case 'delete-order':
        setDisplayModal(
          <Presets
            preset={'deleteOrder'}
            close={handleCloseAction}
            buttons={modalButtons.delete}
          />,
        );
        break;
      case 'delete-timeOff':
        setDisplayModal(
          <Presets
            preset={'deleteTimeOff'}
            close={handleCloseAction}
            buttons={modalButtons.delete}
          />,
        );
        break;
      default:
        return;
    }
  }, [modalSwitch]);

  // Functions
  const setOrderIndication = (isOverlaping, elements) => {
    const indicationClass = isAppointmentValid ? 'warning' : 'danger';

    elements.forEach((element) => {
      element.classList.remove('warning', 'danger');

      if (isOverlaping) {
        element.classList.add(indicationClass);
      }
    });
  };

  const resizeAppointment = (e, height, direction) => {
    document.body.style.cursor = 'ns-resize';

    const startPosition = e.pageY;

    handleResizeHeight(startPosition, height, direction);
  };

  const handleResizeHeight = (startPosition, height, direction) => {
    let newHeight = calculateNewDivHeight(parentHeight, height, 0);
    let newTop = containerTop;

    const excludeOverlaping = notOverlapingStatuses.includes(
      data.completionStatus,
    );

    const ordersElements = document.querySelectorAll(`#order-${id}`);

    setFocusing(1);

    const cells = document.querySelectorAll('.dataPanelCell');

    if (cells) {
      for (const cell of cells) {
        cell.classList.add('dragging');
      }
    }

    const initialContainerHeight = containerHeight;
    const initialContainerTop = containerTop;
    const initialContainerLeft = containerLeft;

    const onMouseMove = (e) => {
      const columnDiv =
        e.target.closest('#collumn') ||
        e.target.closest('.collumn-body')?.querySelector('#collumn');

      const diff =
        direction === 'up' ? startPosition - e.pageY : e.pageY - startPosition;
      newHeight = calculateNewDivHeight(parentHeight, height, diff);

      setContainerHeight((prev) => {
        if (newHeight > 0) {
          if (direction === 'up' && prev !== newHeight) {
            setContainerTop((x) => {
              newTop = x - (newHeight - prev) / 2;
              return newTop;
            });
          }

          return newHeight;
        }

        return prev;
      });

      if (columnDiv && !excludeOverlaping) {
        const newOrder = getAppointmentHeight(
          data,
          newHeight,
          newTop,
          hourInterval,
          startHour,
        );
        if (newOrder) {
          let columnOrders = getColumnOrders(
            globalOrders,
            newOrder.updatedOrder.employeeId,
            formatToIsoWithoutMilliseconds(
              new Date(newOrder.updatedOrder.startTimeUtc),
            ).split('T')[0],
          );

          columnOrders = columnOrders.filter(
            (order) =>
              order.id !== newOrder.updatedOrder.id &&
              !notOverlapingStatuses.includes(order.completionStatus),
          );

          const isOverlaping = willOrderOverlap(
            columnOrders,
            newOrder.newIntervals,
          );

          const targetEmployeeSchedule = dataSource.find(
            (schedule) => schedule.employee.id === data.employeeId,
          );

          isAppointmentValid =
            !isOverlaping ||
            (isOverlaping &&
              targetEmployeeSchedule.employee.sectorSettings
                .allowOrdersOverlap);

          setOrderIndication(isOverlaping, ordersElements);
        }
      }
    };

    const onMouseUp = () => {
      document.body.style.cursor = 'auto';
      document.body.removeEventListener('mousemove', onMouseMove);

      console.log('changing height');

      if (isAppointmentValid) {
        updateAppointmentHeight(data, newHeight, newTop);
      } else {
        setContainerHeight(initialContainerHeight);
        setContainerTop(initialContainerTop);
        setContainerLeft(initialContainerLeft);
        toast.error(`${tr['order']} ${tr['overplaps'].toLowerCase()}`);
      }

      // updateAppointmentPosition(id, newHeight, newTop, undefined, direction);
      setFocusing(0);

      if (cells) {
        for (const cell of cells) {
          cell.classList.remove('dragging');
        }
      }
    };

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp, { once: true });
  };

  const calculateNewDivHeight = (
    containerHeight,
    divHeight,
    heightIncrease,
  ) => {
    const diff = divHeight + heightIncrease;

    return (
      ((Math.round(
        (diff > 0 ? diff : SCHEDULE_CELL_HEIGHT) / SCHEDULE_CELL_HEIGHT,
      ) *
        SCHEDULE_CELL_HEIGHT) /
        containerHeight) *
      100
    );
  };

  const dragAppointment = (event, elementID) => {
    if (event.button !== 0) return;

    document.body.style.cursor = 'grab';

    let newTop;
    let newLeft;
    let newWidth;
    let dataId;

    const excludeOverlaping = notOverlapingStatuses.includes(
      data.completionStatus,
    );

    const checkColumn = saveToOtherCollumn(event);
    dataId = checkColumn ? checkColumn : dataId;

    const draggableElement = document.getElementById(elementID);
    const startColumn =
      event.target.closest('#collumn') ||
      event.target.closest('.collumn-body')?.querySelector('#collumn');

    const ordersElements = document.querySelectorAll(`#order-${id}`);

    // multiBooking.width = 100;
    // multiBooking.left = 0;

    const mousePositionInElement = {
      top: event.clientY - containerRef.current.getBoundingClientRect().top,
      bottom:
        containerRef.current.getBoundingClientRect().bottom - event.clientY,
    };

    setFocusing(1);

    dragging.setState((prev) => {
      return {
        ...prev,
        moving: true,
        appId: id,
        ...(!!timeOff && { timeOff: true }),
      };
    });

    const cells = document.querySelectorAll('.dataPanelCell');

    if (cells) {
      for (const cell of cells) {
        cell.classList.add('dragging');
      }
    }

    const scheduleDiv =
      event.target.closest('#schedule') ||
      event.target.closest('.collumn-body')?.querySelector('#schedule');

    const scrollSchedule = (diffToScheduler, tillBottom) => {
      if (diffToScheduler <= 20) {
        if (!scrollTimer.current) {
          scrollTimer.current = setInterval(() => {
            scheduleDiv.scrollBy({
              top: -20,
              behavior: 'smooth',
            });

            setContainerTop(100 * (scheduleDiv.scrollTop / parentHeight));
          }, 100);
        }
      } else if (tillBottom <= 20) {
        if (!scrollTimer.current) {
          scrollTimer.current = setInterval(() => {
            scheduleDiv.scrollBy({
              top: +20,
              behavior: 'smooth',
            });

            setContainerTop((prev) => {
              const x = (SCHEDULE_CELL_HEIGHT / parentHeight) * 100;

              if (prev + x + height > 100) {
                return prev;
              } else {
                return prev + x;
              }
            });
          }, 120);
        }
      } else if (scrollTimer.current) {
        clearInterval(scrollTimer.current);
        scrollTimer.current = null;
      }
    };

    const initialContainerTop = containerTop;
    const initialContainerLeft = containerLeft;

    const onMouseMove = (e) => {
      const isParentPosition = event.target
        .closest('#collumn')
        .getBoundingClientRect();

      // const cellX = (widths.parentWidth * 100) / widths.appointmentWidth;

      // const mousePositionX = e.clientX - isParentPosition.left + 41;
      const mousePositionY = e.clientY - isParentPosition.top;

      const elementPositionY =
        containerRef.current.getBoundingClientRect().top - isParentPosition.top;

      const queueDiv = e.target.closest('#pending');
      const columnDiv =
        e.target.closest('#collumn') ||
        e.target.closest('.collumn-body')?.querySelector('#collumn');

      if (queueDiv && !timeOff) {
        isAppointmentValid = true;
        ordersElements.forEach((element) => {
          element.classList.remove('warning');
          element.classList.remove('danger');
        });

        setFocusing(5);

        const x =
          Math.floor(
            (mousePositionY - mousePositionInElement.top) /
              SCHEDULE_CELL_HEIGHT,
          ) * SCHEDULE_CELL_HEIGHT;

        newTop = 100 * (x / parentHeight);

        const diffToCenter =
          queueDiv.getBoundingClientRect().width / 2 -
          isParentPosition.width / 2;

        const divDiff =
          queueDiv.getBoundingClientRect().left -
          isParentPosition.left +
          diffToCenter;

        newLeft = (100 * divDiff) / widths.appointmentWidth;

        draggableElement.style.left =
          queueDiv.getBoundingClientRect().left -
          startColumn.getBoundingClientRect().left +
          5 +
          'px';
        draggableElement.style.width =
          queueDiv.getBoundingClientRect().width - 10 + 'px';
      } else newLeft = null;

      // handling hover effect by elements top position
      if (columnDiv) {
        draggableElement.style.left =
          columnDiv.getBoundingClientRect().left -
          startColumn.getBoundingClientRect().left +
          'px';

        draggableElement.style.width =
          columnDiv.getBoundingClientRect().width + 'px';

        // ----
        setHoverTime(getHoverTime(elementPositionY));
        setFocusing(1);

        // NEW TOP
        const x =
          Math.floor(
            (mousePositionY - mousePositionInElement.top) /
              SCHEDULE_CELL_HEIGHT,
          ) * SCHEDULE_CELL_HEIGHT;

        newTop = 100 * (x / parentHeight);
        if (newTop + height > 100) newTop = 100 - height;

        const diffToScheduler =
          e.clientY -
          scheduleDiv.getBoundingClientRect().top -
          // 160 -
          110 -
          mousePositionInElement.top;

        const diffToBottom =
          scheduleDiv.getBoundingClientRect().bottom -
          e.clientY -
          mousePositionInElement.bottom;

        scrollSchedule(diffToScheduler, diffToBottom);

        if (!excludeOverlaping) {
          const columnData = columnDiv.dataset.id;
          const employeeId = columnData?.split('--')[0];
          const columnDate = columnData?.split('--')[1];

          const newPosition = getAppointmentPosition(
            data,
            newTop,
            employeeId,
            new Date(columnDate),
            hourInterval,
            startHour,
          );

          if (newPosition) {
            const newIntervals = getOrderTimeIntervals(
              newPosition.updatedOrder,
              newPosition.updatedOrder.durationInMinutes,
            );

            let columnOrders = getColumnOrders(
              globalOrders,
              employeeId,
              columnDate,
            );

            columnOrders = columnOrders.filter(
              (order) =>
                order.id !== data.id &&
                !notOverlapingStatuses.includes(order.completionStatus),
            );

            const isOverlaping = willOrderOverlap(columnOrders, newIntervals);
            const targetEmployeeSchedule = dataSource.find(
              (schedule) => schedule.employee.id === employeeId,
            );

            isAppointmentValid =
              !isOverlaping ||
              (isOverlaping &&
                targetEmployeeSchedule.employee.sectorSettings
                  .allowOrdersOverlap);

            setOrderIndication(isOverlaping, ordersElements);
          } else {
            isAppointmentValid = true;
            ordersElements.forEach((element) => {
              element.classList.remove('warning');
              element.classList.remove('danger');
            });
          }
        }
      } else {
        setHoverTime(null);
      }

      if (columnDiv || queueDiv) setContainerLeft(newLeft);
      // if (newTop >= 0 && newTop + height <= 100 && !scrollTimer.current) {
      if (newTop >= 0 && newTop + height <= 100) {
        draggableElement.style.top = `calc( ${newTop}% + 1px)`;
      } else if (newTop < 0) {
        draggableElement.style.top = 0;
      }

      const checkColumn = saveToOtherCollumn(e);
      dataId = checkColumn ? checkColumn : dataId;
    };

    const onMouseUp = (e) => {
      if (newTop) setContainerTop(newTop);

      const queueDiv = e.target.closest('#pending');

      setHoverTime(null);

      dragging.setState((prev) => {
        return { moving: false };
      });

      if (cells) {
        for (const cell of cells) {
          cell.classList.remove('dragging');
        }
      }

      if (scrollTimer.current) {
        clearInterval(scrollTimer.current);
        scrollTimer.current = null;
      }

      if (isAppointmentValid) {
        if (queueDiv && !timeOff) {
          updateToPendingOrders(data);
        } else if (dataId) {
          updateAppointmentPosition(data, newTop, dataId);
        } else {
          updateAppointmentPosition(data, newTop < 0 ? 0 : newTop);
        }
      } else {
        toast.error(`${tr['order']} ${tr['overplaps'].toLowerCase()}`);
        setContainerTop(initialContainerTop);
        setContainerLeft(initialContainerLeft);
      }

      draggableElement.style.left = '';
      draggableElement.style.width = '';
      draggableElement.style.top = '';

      setContainerLeft(null);
      setFocusing(0);
      setDraggable(false);

      document.body.style.cursor = 'auto';
      document.body.removeEventListener('mousemove', onMouseMove);
    };

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp, { once: true });
  };

  const saveToOtherCollumn = (e) => {
    const isParent = e.target.closest('#collumn');
    const isSibling = e.target
      .closest('.collumn-body')
      ?.querySelector('#collumn');

    if (!isParent && !isSibling) return null;

    return isParent ? isParent.dataset.id : isSibling.dataset.id;
  };

  const handleCardClick = () => {
    toogleService();

    if (!isMobile) return;

    setDisplayDetails(true);
  };

  const handleCloseAction = () => {
    setModalSwitch(null);
    setDisplayModal(null);
    toggle();
    return;
  };

  // TODO: Move to useAppointmentCard hook
  const getFunctionList = (action) => {
    return [
      {
        id: '3.1',
        label: 'Siųsti SMS priminimą',
        icon: <SendSVG />,
        onClick: () => {
          setModal('send-sms-reminder');
          action && action();
          handleClickAway();
        },
      },
      {
        id: '3.2',
        label: 'Siųsti SMS nuorodą',
        icon: <SendSVG />,
        onClick: () => {
          setModal('send-sms-link');
          action && action();
          handleClickAway();
        },
      },
      {
        id: '3.3',
        label: 'Kopijuoti užsakymą',
        icon: <CopySVG bold={isMobile} />,
        onClick: () => {
          functions.createCopy(id);
          action && action();
          handleClickAway();

          if (!isMobile) return;
          setDisplayDetails(false);
        },
      },
      {
        id: '3.4',
        label: 'Ištrinti užsakymą',
        icon: <TrashCan bold={isMobile} />,
        fontColor: colors.mandyRed,
        onClick: () => {
          setModal('delete-order', id);
          action && action();
          handleClickAway();
        },
      },
    ];
  };

  const handleClickAway = (e) => {
    if (isMobile) return;

    functions.raiseHead(false);
    setDisplayContextMenu(false);
    setFocusing(0);
    setDraggable(false);
  };

  const setModal = (modal) => {
    setModalSwitch(modal);
    toggle();
  };

  // setting up context menu items
  const serviceCardItems = (close) => {
    return serviceCardMenuItems.map((item) => {
      switch (item.id) {
        case 'change-status':
          return { ...item, items: getStatusActions(close) };
        case 'balance-status':
          return {
            ...item,
            items: getBalanceActions(),
          };
        case 'functions':
          return {
            ...item,
            items: getFunctionList(close),
          };

        default:
          return item;
      }
    });
  };

  const timeOffItems = (close) => {
    return timeOffMenuItems.map((item) => {
      switch (item.id) {
        case 'edit':
          return { ...item, items: listOfExcusesWithAction(close) };
        case 'delete-timeOff':
          return {
            ...item,
            icon: isMobile && item.icon,
            onClick: () => {
              setModal('delete-timeOff', id);
              close && close();
              handleClickAway();
            },
          };
        default:
          return item;
      }
    });
  };

  const listOfExcusesWithAction = (close) => {
    const arr = listOfExcuses.map((item) => {
      return {
        ...item,
        onClick: () => {
          cache.setState([]);
          toast.success(tr['order-updated-successfully']);

          console.log('update order');
          // dispatch(
          //   updateAppointment({ id, excuseId: item.id, title: item.label })
          // );

          close();
          handleClickAway();
        },
      };
    });

    arr.push({
      id: 22,
      label: 'Kita...',
      onClick: () => {},
      items: [
        {
          id: 222,
          input: true,
          onKeyPress: (e) => {
            if (e.key === 'Enter') {
              cache.setState([]);
              toast.success(tr['order-updated-successfully']);
              console.log('update order');
              // dispatch(
              //   updateAppointment({ id, excuseId: null, title: e.target.value })
              // );
              close();
              handleClickAway();
            }
          },
        },
      ],
    });

    return arr;
  };

  const handleContextMenu = (e) => {
    e.preventDefault();

    functions.raiseHead(true);
    setDisplayContextMenu(true);
    setFocusing(1);
    setDraggable(false);
  };

  const getTimeForDetails = () => {
    const start = data.startTimeUtc;

    const end = new Date(
      new Date(start).getTime() + data.durationInMinutes * 60000,
    ).toISOString();

    return {
      start,
      end,
    };
  };

  const toogleService = () => {
    if (!!isMobile) return;

    setDraggable((prev) => !prev);
    if (!draggable) {
      setDisplayContextMenu(false);
    }
  };

  const resizeWithHandle = (e, direction) => {
    e.stopPropagation();
    resizeAppointment(
      e,
      containerRef.current.getBoundingClientRect().height + 4,
      direction,
    );
  };

  // Comp
  const TooltipWrapper = (
    <S.Tooltip>
      <AppointmentsDetails
        status={data.completionStatus}
        data={{
          id: data.sequenceNo,
          uuid: data.id,
          time: getTimeForDetails(),
          services: data.services,
          note: data.note,
          customer: data.customer,
          balance: {
            amountCovered: data.amountCovered,
            toPay: data.finalAmountWithVat - data.amountCovered,
          },
        }}
        statusItems={getStatusActions}
        functionItems={getFunctionList}
        balanceItems={getBalanceActions}
      />
    </S.Tooltip>
  );

  return (
    <>
      <ClickAwayListener mouseEvent="mousedown" onClickAway={handleClickAway}>
        <S.AppointmentWrapper
          id={id + '-id'}
          ref={(node) => {
            containerRef.current = node;

            if (selected) {
              setScheduleScrollTargetNode(node);
            }
          }}
          topPosition={containerTop}
          height={containerHeight}
          multiBooking={multiBooking}
          left={containerLeft}
          focusing={focusing + 1}
          moving={dragging.state?.moving && dragging.state?.appId === id}
          onContextMenu={handleContextMenu}
          onMouseDown={(e) =>
            draggable && !displayContextMenu && dragAppointment(e, id + '-id')
          }
          drag={draggable}
          isSafari={isSafari}
          onClick={handleCardClick}
          contextMenuVisible={displayContextMenu}
        >
          {!isMobile && (
            <ContextTree
              key={id}
              items={timeOff && timeOffItems}
              containerId={id + '-id'}
              parentId={'schedule'}
              openOnContext
              openOnDoubleClick
              fixedWidth={timeOff ? 200 : 300}
              open={displayContextMenu}
              content={TooltipWrapper}
              setCardSelected={(value) => !value && handleClickAway()}
              type="schedule-body-item"
            />
          )}

          {hasBreak ? (
            <MultiAppointments
              id={id}
              timeOff={timeOff}
              displayData={{
                status: data.completionStatus,
                firstName: data.customer?.name,
                lastName: data.customer?.lastName,
                services: data.services,
                splits: data.splits,
              }}
              hoverDisplay={draggable}
              order={data}
            />
          ) : (
            <AppointmentCard
              id={id}
              timeOff={timeOff}
              displayData={{
                status: data.completionStatus,
                firstName: data.customer?.name,
                lastName: data.customer?.lastName,
                services: data.services,
              }}
              hoverDisplay={draggable}
            />
          )}

          {(draggable || displayContextMenu) && (
            <>
              <S.DragHandle onMouseDown={(e) => resizeWithHandle(e, 'up')} />
              <S.DragHandle
                bottom
                onMouseDown={(e) => resizeWithHandle(e, 'down')}
              />
            </>
          )}

          {isMobile &&
            displayDetails &&
            (!timeOff ? (
              <AppointmentsDetailsMobile
                status={data.completionStatus}
                data={{
                  id: data.sequenceNo,
                  uuid: data.id,
                  time: getTimeForDetails(),
                  services: data.services,
                  customer: data.customer,
                  employeeId: data.employeeId,
                  balance: {
                    amountCovered: data.amountCovered,
                    toPay: data.finalAmountWithVat - data.amountCovered,
                  },
                }}
                functions={serviceCardItems()}
                close={() => setDisplayDetails(false)}
              />
            ) : (
              <Overlay isVisible={true}>
                <DialogLayout
                  headerText={'Nedarbo laikas'}
                  exitFunc={() => setDisplayDetails(false)}
                  darkTheme
                  Child={TimeOff}
                  childProps={{
                    data: {
                      ...timeOff,
                      id: id,
                      // professionals: [currentProfessionals[0].id],
                    },
                    saveFunc: (x) => dispatch(updateAppointment(x)),
                    functions: timeOffItems(),
                  }}
                />
              </Overlay>
            ))}

          {hoverTime && (
            <div className="hover-time">
              <span>{hoverTime}</span>
            </div>
          )}
        </S.AppointmentWrapper>
      </ClickAwayListener>

      <Overlay isVisible={isShowing} hide={toggle}>
        {displayModal}
      </Overlay>
    </>
  );
};

export default AppointmentWrapper;
