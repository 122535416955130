import * as S from '../SVG.styled';

export default ({ color, width, height, bold }) => {
  // NOTE: don't have regular svg

  const content = (
    <path d="M 27.236608,0 H 14.196482 C 12.67543,0 11.43902,1.23641 11.43902,2.757462 V 6.614944 H 1.636687 c -0.901364,0 -1.630757,0.729393 -1.630757,1.630757 v 11.044673 c 0,0.901363 0.729393,1.630757 1.630757,1.630757 h 0.945839 v 3.904922 c 0,0.658232 0.397312,1.2542 1.008105,1.506226 0.20162,0.08302 0.412136,0.124531 0.622652,0.124531 0.423997,0 0.842064,-0.166041 1.15339,-0.477367 l 5.055347,-5.058312 h 1.017 v 6.321407 C 11.43902,28.76359 12.67543,30 14.196482,30 h 13.040126 c 1.521052,0 2.757462,-1.23641 2.757462,-2.757462 V 2.757462 C 29.99407,1.23641 28.75766,0 27.236608,0 Z M 5.84404,20.888516 v -1.598142 c 0,-0.901364 -0.729393,-1.630757 -1.630757,-1.630757 H 3.267444 V 9.876458 h 16.802728 v 7.783159 H 9.745997 c -0.415102,0 -0.812413,0.163075 -1.114845,0.444751 h -0.003 l -0.03558,0.03262 v 0 z m 20.888516,5.84997 H 14.700534 v -5.817355 h 7.000395 c 0.901364,0 1.630757,-0.729394 1.630757,-1.630757 V 8.245701 c 0,-0.901364 -0.729393,-1.630757 -1.630757,-1.630757 h -7.000395 v -3.35343 h 12.032022 z" />
  );
  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
