import React from 'react';

// Style
import * as S from './Search.styled';

// SVG
import SearchBoldSVG from 'icons/SearchBoldSVG';
import ClearSearchSVG from 'icons/ClearSearchSVG';

export const Search = ({
  darkTheme,
  placeholder,
  state,
  raised,
  autofocus,
}) => {
  return (
    <S.Search_Container darkTheme={darkTheme} raised={raised}>
      {state?.input ? (
        <div
          className="searchIcon clearInput"
          onClick={() => state?.setInput('')}
        >
          <ClearSearchSVG />
        </div>
      ) : (
        <div className="searchIcon">
          <SearchBoldSVG />
        </div>
      )}
      <input
        className="searchInput"
        type="text"
        placeholder={placeholder}
        value={state?.input}
        onChange={(e) => state?.setInput(e.target.value)}
        autoFocus={autofocus}
      />
    </S.Search_Container>
  );
};

export default Search;
