import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';
import { OrderRowEntrie } from './partials/OrderRowEntries/OrderRowEntrie/OrderRowEntrie.styled';
import { OrderRowBreak } from './partials/OrderRowBreak/OrderRowBreak.styled';
import { shadow } from 'common/styles/shadows';

export const OrderCardWrapper = styled.section`
  padding: 40px;
  max-width: 1460px;
`;

export const Header = styled.div`
  height: 80px;
  background-color: ${colors.bg_grey_1};
  width: 100%;
  border-radius: 10px;

  display: flex;
  align-items: center;
  padding: 10px;

  > div {
    margin-right: 10px;
  }

  .cart-card {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    svg {
      height: 30px;
      width: 22px;
      fill: ${colors.bg_Primary};
    }
  }
`;

export const Main = styled.div`
  /* margin-top: 20px; */
`;

export const Text = styled.div`
  font-family: ${fontFamilies.family_Secondary};

  max-width: 560px;
  padding: 10px 0px;

  a {
    color: ${colors.link};
  }
`;

export const OrdersTable = styled.div`
  overflow: auto;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: ${colors.bg_grey_1};

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: white;
    border: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.bg_Primary};
  }

  .table-wrapper {
    height: calc(100vh - 300px);
    min-width: 100%;
    width: fit-content;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background-color: white;
      border: none;
      margin-top: 50px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${colors.bg_Primary};
    }
  }

  table {
    width: 100%;
    height: 1px;
  }

  thead {
    /* box-shadow: ${shadow.sm}; */
    border-radius: 10px 10px 0 0;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 20px;
  }

  thead tr {
    color: white;
    font-size: 12px;
    font-family: ${fontFamilies.family_SecondaryCondensed};
    height: 36px;

    th {
      text-align: left;
      /* box-shadow: ${shadow.sm}; */
      color: ${colors.text_Secondary};
      font-weight: 400;
    }

    th:first-of-type {
      border-radius: 10px 0 0 0;
    }

    th:last-of-type {
      border-radius: 0 10px 0 0;
    }
  }

  tbody {
    tr:first-of-type {
      ${OrderRowEntrie} {
        padding-top: 0;
      }
    }
    ${OrderRowBreak} {
      :last-of-type {
        .content {
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }
`;

export const Th = styled.th`
  width: ${(props) => props.minWidth && props.minWidth}px;
  min-width: ${(props) => props.minWidth && props.minWidth}px;
  white-space: nowrap;
`;

export const Filler = styled.tr`
  th {
    border-right: 1px solid ${colors.bg_grey_2};
    border-left: 1px solid ${colors.bg_grey_2};
    height: ${(props) => props.height && props.height}px;
  }
`;

export const EmptyOrder = styled.tr`
  td {
    height: 50px;
  }

  span {
    display: block;
    text-align: center;
    margin: 0 auto;
    color: ${colors.bg_grey_3};
  }
`;
