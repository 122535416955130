import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const SortArrowsSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="23.444"
      height="20"
      viewBox="0 0 23.444 20"
      color={color ? color : 'currentColor'}
    >
      <g transform="translate(-2196.4 -1860.3)" data-name="Group 4091">
        <g transform="translate(2196.4 1860.3)" data-name="Layer 2">
          <g data-name="Layer 1-2">
            <path
              transform="translate(-2196.4 -1860.3)"
              d="M2203.22,1879.9v-18.134l5.265,5.263a.425.425,0,0,0,.6-.6l-5.988-5.988a.373.373,0,0,0-.066-.053.235.235,0,0,0-.032-.019l-.042-.021-.04-.013a.223.223,0,0,0-.038,0,.426.426,0,0,0-.166,0,.163.163,0,0,0-.036,0l-.042.013c-.015,0-.03.015-.042.021a.221.221,0,0,0-.032.019.305.305,0,0,0-.064.053l-5.99,5.988a.421.421,0,0,0,.589.6l5.276-5.263V1879.9a.425.425,0,1,0,.85,0Z"
              // fill="#0f1d5b"
              data-name="Path 969"
            />
          </g>
        </g>
        <g transform="translate(2207 1860.3)" data-name="Layer 2">
          <g transform="translate(0)" data-name="Layer 1-2">
            <path
              transform="translate(-2246.4 -1860.3)"
              d="M2252.355,1860.75v18.134l-5.265-5.263a.425.425,0,0,0-.6.6l5.988,5.988a.365.365,0,0,0,.066.053.238.238,0,0,0,.032.019l.042.021.04.013a.223.223,0,0,0,.038,0,.421.421,0,0,0,.166,0,.179.179,0,0,0,.036,0l.043-.013c.015,0,.03-.015.042-.021a.222.222,0,0,0,.032-.019.293.293,0,0,0,.064-.053l5.99-5.988a.421.421,0,0,0-.589-.6l-5.276,5.263V1860.75a.425.425,0,0,0-.85,0Z"
              // fill="#0f1d5b"
              data-name="Path 970"
            />
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

export default SortArrowsSVG;
