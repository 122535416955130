import * as S from '../SVG.styled';
export default ({ color, width, height, bold }) => {
  const content = bold ? (
    <path d="m26.44 0h-18.268c-1.1867 0-2.1521 0.96536-2.1521 2.1521v2.4595h-2.4595c-1.1867 0-2.1521 0.96536-2.1521 2.1521v21.084c0 1.1867 0.96536 2.1521 2.1521 2.1521h18.268c1.1867 0 2.1521-0.96536 2.1521-2.1521v-21.084c0-1.1867-0.96536-2.1521-2.1521-2.1521h-13.349v-2.1521h17.653v20.777c0 0.67944 0.55032 1.2298 1.2298 1.2298 0.67944 0 1.2298-0.55032 1.2298-1.2298v-21.084c0-1.1867-0.96536-2.1521-2.1521-2.1521zm-4.919 27.54h-17.653v-20.469h17.653z" />
  ) : (
    <path d="m26.929 0h-19.049c-0.8848 0-1.6029 0.7181-1.6029 1.6029v3.2058h-3.2058c-0.8848 0-1.6029 0.7181-1.6029 1.6029v21.985c0 0.8848 0.7181 1.6029 1.6029 1.6029h19.049c0.8848 0 1.6029-0.7181 1.6029-1.6029v-21.985c0-0.8848-0.7181-1.6029-1.6029-1.6029h-14.561v-3.2058c0-0.17632 0.14426-0.32058 0.32058-0.32058h19.049c0.17632 0 0.32058 0.14426 0.32058 0.32058v21.985c0 0.35264 0.28852 0.64116 0.64116 0.64116 0.35264 0 0.64116-0.28852 0.64116-0.64116v-21.985c0-0.8848-0.7181-1.6029-1.6029-1.6029zm-4.4881 6.4116v21.985c0 0.17632-0.14426 0.32058-0.32058 0.32058h-19.049c-0.17632 0-0.32058-0.14426-0.32058-0.32058v-21.985c0-0.17632 0.14426-0.32058 0.32058-0.32058h19.049c0.17632 0 0.32058 0.14426 0.32058 0.32058z" />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
