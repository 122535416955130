import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const GoBackSVG = ({ color }) => {
  return (
    <StyledSVG
      color={color ? color : 'currentColor'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="29.706"
      height="23.68"
      viewBox="0 0 29.706 23.68"
    >
      <g
        id="Group_2452"
        data-name="Group 2452"
        transform="translate(-40.095 -22)"
      >
        <g
          id="Layer_1-2"
          data-name="Layer 1-2"
          transform="translate(40.095 22)"
        >
          <path
            id="Path_399"
            data-name="Path 399"
            d="M663,1217.167H641.782l9.16-9.716a.818.818,0,0,0-.053-1.108.709.709,0,0,0-.994,0L639.473,1217.4a.557.557,0,0,0-.093.117.442.442,0,0,0-.033.059l-.037.078a.483.483,0,0,0-.022.078.358.358,0,0,0,0,.067.834.834,0,0,0,0,.306.618.618,0,0,0,0,.071.112.112,0,0,0,.022.078l.037.075a.452.452,0,0,0,.033.059.687.687,0,0,0,.093.121l10.422,11.05a.707.707,0,0,0,1.047,0,.818.818,0,0,0,0-1.106l-9.16-9.716H663c3.019,0,4.44,2.452,4.5,4.707s-1.236,4.668-4.177,4.809h-.444a.786.786,0,0,0,0,1.569h.511c3.933-.188,5.653-3.444,5.59-6.394C668.912,1220.316,666.84,1217.167,663,1217.167Z"
            transform="translate(-639.274 -1206.141)"
            // fill="#fff"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default GoBackSVG;
