import styled from 'styled-components/macro';

export const StyledSelection = styled.div`
  border-radius: 100px;
  background-color: white;

  padding: 8px 20px;

  display: inline-block;

  background-color: ${(props) => props.bg && props.bg};

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;

  div {
    display: inline-block;

    svg {
      /* height: 15px; */
    }
  }

  span {
    font-size: 16px;
    margin-left: 10px;
    font-weight: 600;
  }

  @media screen and (min-width: 961px) {
    span {
      font-size: 20px;
    }
  }
`;
