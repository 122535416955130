import React from 'react';

// Style
import * as S from './SVG.styled';

const SmartphoneSVG = ({ color }) => {
  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="10.447"
      height="16.103"
      viewBox="0 0 10.447 16.103"
      color={color}
    >
      <g
        id="Group_1366"
        data-name="Group 1366"
        transform="translate(-158.435 -2826.276)"
      >
        <path
          id="Path_74"
          data-name="Path 74"
          d="M167.856,2826.276h-8.4a1.027,1.027,0,0,0-1.026,1.026v14.051a1.027,1.027,0,0,0,1.026,1.026h8.4a1.027,1.027,0,0,0,1.026-1.026V2827.3A1.027,1.027,0,0,0,167.856,2826.276Zm-8.4.684h8.4a.342.342,0,0,1,.342.342v10.821h-9.079V2827.3A.342.342,0,0,1,159.461,2826.96Zm8.4,14.735h-8.4a.342.342,0,0,1-.342-.342v-2.546H168.2v2.546A.342.342,0,0,1,167.856,2841.695Z"
          //   fill="#0f1d5b"
        />
        <path
          id="Path_75"
          data-name="Path 75"
          d="M178.064,2840.772h3.168a.342.342,0,1,0,0-.684h-3.168a.342.342,0,1,0,0,.684Z"
          transform="translate(-15.99 -11.451)"
          //   fill="#0f1d5b"
        />
      </g>
    </S.StyledSVG>
  );
};

export default SmartphoneSVG;
