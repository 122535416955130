import { useEffect, useState } from 'react';

// style
import * as S from './DiscountModal.styled';

// icons
import { Check, TrashCan } from 'icons/dynamic';

// components
import { BaseInput } from 'components/Inputs';
import DialogBox from 'components/DialogBox/DialogBox';
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import TheButton from 'components/Buttons/TheButton';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';
import useNumber from 'hooks/useNumber';

const DiscountModal = ({
  onSave,
  price,
  quantity = 1,
  discount,
  setButtons,
  close,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { currencySymbol, currencySymbolPlacement, formatPrice } = useNumber({
    price: true,
  });
  const productBasePrice = price * quantity;
  const [discountState, setDiscountState] = useState({
    precentage: (discount * 100) / productBasePrice || 0,
    cash: discount,
    price: productBasePrice - discount,
  });

  const isSaveDisabled =
    discountState.price > productBasePrice || discountState.price < 0;

  const discountButtons = [
    {
      id: 'discount-discard',
      title: tr['discard-discount'],
      icon: <TrashCan bold />,
      action: () => onSave(0),
      inverted: true,
    },
    {
      id: 'discount-save',
      title: tr['save'],
      icon: <Check bold inCircle />,
      action: () => onSave(discountState.cash.toFixed(4)),
      disabled: isSaveDisabled,
    },
  ];
  useEffect(() => {
    if (!setButtons) return;

    setButtons({
      id: 'save',
      type: 'update',
      value: {
        action: () => onSave(discountState.cash.toFixed(4)),
        secondary: isSaveDisabled,
        disabled: isSaveDisabled,
      },
    });
  }, [discountState, setButtons]);

  const handleDiscountChange = (type, val) =>
    setDiscountState(() => {
      switch (type) {
        case 'precentage': {
          const discountInCash = (val * productBasePrice) / 100;

          return {
            precentage: val,
            cash: discountInCash,
            price: productBasePrice - discountInCash,
          };
        }
        case 'cash':
          return {
            precentage: (val * 100) / productBasePrice,
            cash: val,
            price: productBasePrice - val,
          };
        case 'price': {
          const cashDiscount = productBasePrice - val;

          return {
            precentage: (cashDiscount * 100) / productBasePrice,
            cash: cashDiscount,
            price: val,
          };
        }
      }
    });

  return (
    <ConditionalWrapper
      condition={!isMobile}
      wrapper={(children) => (
        <DialogBox
          close={close}
          headerText={tr['apply-discount']}
          buttons={discountButtons}
        >
          {children}
        </DialogBox>
      )}
    >
      <S.DiscountModal>
        <div className="block">
          <div className="item">
            <span>{tr['percentage-discount']}</span>
            <BaseInput
              type="number"
              value={discountState.precentage}
              onChange={(val) => handleDiscountChange('precentage', val)}
              decimalPlaces={2}
              currencySymbol="%"
              currencySymbolPlacement="s"
            />
          </div>
          <div className="item">
            <span>{tr['cash-discount']}</span>
            <BaseInput
              type="number"
              value={discountState.cash}
              onChange={(val) => handleDiscountChange('cash', val)}
              currencySymbol={currencySymbol}
              currencySymbolPlacement={currencySymbolPlacement}
              decimalPlaces={4}
            />
          </div>
          <div className="item">
            <span>{tr['other-price']}</span>
            <BaseInput
              type="number"
              value={discountState.price}
              onChange={(val) => handleDiscountChange('price', val)}
              currencySymbol={currencySymbol}
              currencySymbolPlacement={currencySymbolPlacement}
              decimalPlaces={4}
            />
          </div>
        </div>
        <div className="block result">
          <div className="item">
            <span className="value">{formatPrice(productBasePrice, true)}</span>
            <span>{tr['price']}</span>
          </div>
          <div className="item">
            <span className="value">
              {formatPrice(discountState.cash, true)}
            </span>
            <span>{tr['discount']}</span>
          </div>
          <div className="item">
            <span className="value">
              {formatPrice(discountState.price, true)}
            </span>
            <span>{tr['new-price']}</span>
          </div>
        </div>
        {isMobile && (
          <TheButton
            icon={<TrashCan bold />}
            title={tr['discard-discount']}
            action={() => handleDiscountChange('cash', 0)}
            outline
          />
        )}
      </S.DiscountModal>
    </ConditionalWrapper>
  );
};

export default DiscountModal;
