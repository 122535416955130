import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const HeaderSearchSVG = ({ color }) => {
  return (
    <StyledSVG
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 23 23"
    >
      <path
        id="Path_406"
        data-name="Path 406"
        d="M197.943,1621.96l-5.763-5.763a9.282,9.282,0,1,0-2.135,2.135l5.763,5.763a1.51,1.51,0,1,0,2.135-2.135ZM178.4,1610.8a6.248,6.248,0,1,1,6.248,6.248A6.255,6.255,0,0,1,178.4,1610.8Z"
        transform="translate(-175.385 -1601.537)"
      />
    </StyledSVG>
  );
};

export default HeaderSearchSVG;
