import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';

export const OrderRowNote = styled.tr`
  td {
    padding: 0 5px;

    .background {
      /* margin-top: 10px; */
      /* height: 43px; */
      background-color: ${colors.bg_grey_1};
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 0 0 5px 5px;
    }

    .content {
      height: 38px;
      background-color: ${({ disabled }) =>
        disabled ? colors.bg_grey_4 : '#fff'};

      margin: 0 5px;
      border-radius: 5px;

      display: flex;
      align-items: center;

      .buttons-container {
        padding-left: 10px;
        padding-right: 6px;
        display: flex;
        gap: 10px;
      }
    }

    .icon-container {
      height: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
        fill: ${colors.bg_grey_3};
      }
    }

    .input-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      input {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        font-family: ${fontFamilies.family_Secondary};
        color: ${colors.bg_Primary};
        margin-right: 5px;

        &:disabled {
          background-color: ${colors.bg_grey_4};
        }

        ::placeholder {
          color: ${colors.bg_grey_3};
        }
      }
    }
  }
`;
