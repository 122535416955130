import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const AppointmentsDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilies.family_Secondary};

  .details__head {
    .details__customer-info {
      display: flex;
      flex-direction: column;
      background-color: ${colors.bg_grey_1};
      padding: 10px;
      border-radius: 5px;
    }

    .details__full-name {
      font-size: 1rem;
      font-family: ${fontFamilies.family_Primary};
      font-weight: 900;

      display: flex;
      align-items: center;

      gap: 5px;

      width: 100%;

      color: ${colors.bg_Primary};

      span {
        overflow: hidden;
        text-overflow: ellipsis;

        /* white-space: initial; */
      }
    }

    .details__phone {
      font-weight: 400;
      margin-top: 3px;
      font-size: 0.875rem;
    }
  }

  .details__link {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .link_icon {
    width: 15px;
    height: 15px;
    margin-top: 3px;
    display: flex;
    align-items: center;
  }

  .function_icon {
    width: 15px;
    height: 15px;

    display: flex;
    align-items: center;

    position: relative;
  }

  .details__body {
    background-color: white;

    font-size: 14px;

    line-height: 1.3;
    letter-spacing: 0px;

    width: 100%;
    padding: 0 10px;
  }

  .detail__orderId-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 1rem;
    font-family: ${fontFamilies.family_Primary};
    font-weight: 900;

    color: ${colors.bg_Primary};

    .details__link {
      display: flex;
      width: 95%;
    }

    .details__orderId {
      display: flex;
      gap: 5px;

      max-width: 90%;

      span {
        overflow: hidden;
        text-overflow: ellipsis;

        /* white-space: initial; */
      }
    }

    .order__buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .details__status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    cursor: pointer;

    .status {
      display: flex;
      align-items: center;

      width: 95%;

      .status-color {
        background-color: ${(props) => props.statusColor};
        width: 20px;
        height: 20px;
        border-radius: 50%;

        flex-shrink: 0;

        transition: background-color 300ms ease-in-out;
      }

      .status-label {
        margin-left: 5px;
        font-weight: 600;

        overflow: hidden;
        text-overflow: ellipsis;

        /* white-space: initial; */
      }
    }
  }

  .arrow {
    display: flex;
    align-items: center;
  }

  .label_value-container {
    display: flex;

    span:first-child {
      font-weight: 600;
    }

    span:last-child {
      margin-left: 5px;
      font-weight: 400;
    }
  }

  .details__span {
    span {
      font-weight: 900;
    }
  }

  .multi {
    span {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .detail__wrapper {
    padding: 10px 0;
    border-bottom: 1px solid ${colors.bg_grey_5};

    .services__ul {
      margin: 0px 15px;
      padding: 0;
      white-space: pre-wrap;

      font-size: 0.75rem;

      list-style-type: disc;
    }

    .services_price {
      margin-top: 5px;
      display: block;
      font-weight: 600;
    }
  }

  .last {
    border: none;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .professionals__container {
    margin-left: 10px;

    display: flex;
    gap: 5px;

    .specialist__avatar {
      width: 20px;
      height: 20px;
    }
  }

  .around {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
  }

  .double {
    display: flex;
    gap: 20px;
  }

  .details__card {
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: 600;
    }

    &-debt {
      font-weight: 600;
      color: ${colors.discount};
    }
  }

  .details__note {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    span {
      font-weight: 900;
    }

    p {
      margin: 0;
      padding: 0;

      white-space: pre-wrap;
    }
  }

  .icon__wrapper {
    padding: 10px 0;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: flex;

      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        white-space: pre-wrap;
      }
    }

    .list__icon {
      width: 16px;
      height: 16px;

      margin-right: 10px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
