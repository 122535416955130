import * as S from '../SVG.styled';

export default ({ color, width, height, bold }) => {
  // NOTE: don't have regular svg

  const content = (
    <g transform="scale(0.29661855)">
      <path
        d="M 50.57,0 C 22.69,0 0,22.69 0,50.57 c 0,27.88 22.69,50.57 50.57,50.57 27.88,0 50.57,-22.69 50.57,-50.57 C 101.14,22.69 78.46,0 50.57,0 Z m 0,90.14 C 28.75,90.14 11,72.39 11,50.57 11,28.75 28.75,11 50.57,11 c 21.82,0 39.57,17.75 39.57,39.57 0,21.82 -17.75,39.57 -39.57,39.57 z"
        id="path6"
      />
      <path
        d="m 63.67,22.56 c -3.04,0 -5.5,2.46 -5.5,5.5 v 25.33 l -16,-26.41 c -1.28,-2.11 -3.81,-3.11 -6.18,-2.45 -2.38,0.66 -4.02,2.83 -4.02,5.3 v 43.25 c 0,3.04 2.46,5.5 5.5,5.5 3.04,0 5.5,-2.46 5.5,-5.5 V 49.52 l 16,26.41 c 1.01,1.67 2.82,2.65 4.7,2.65 0.49,0 0.99,-0.07 1.48,-0.2 2.38,-0.66 4.02,-2.83 4.02,-5.3 V 28.06 c 0,-3.04 -2.46,-5.5 -5.5,-5.5 z"
        id="path8"
      />
    </g>
  );
  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
