import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

// style
import * as S from './Login.styled';
import { colors } from 'common/colors/colors';

// components
import { Intro } from './Intro/Intro';
import { LoginForm } from './LoginForm/LoginForm';
import { AuthProviders } from './AuthProviders/AuthProviders';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';
import { UserDetails } from './UserDetails/UserDetails';
import { BusinessTypeSelector } from './BusinessTypeSelector/BusinessTypeSelector';
import { BusinessDetails } from './BusinessDetails/BusinessDetails';

// icons
import LogoSVG from 'icons/LogoSVG';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useRestoreAppUserCredentials } from 'hooks/useRestoreAppUserCredentials';

const Login = () => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const location = useLocation();
  // useRestoreAppUserCredentials();

  const stages = useMemo(
    () => [
      'intro',
      'select-auth',
      'select-lng',
      'fill-basic-info',
      'detail-lng',
      'select-business-type',
      'fill-business-info',
    ],
    [],
  );
  const [stage, setStage] = useState(
    stages[location?.state?.newCompany ? 5 : 0],
  );

  // removes 'newCompany' key from location state -> redirects to dashboard if 'reload page' requested during company creation process
  useEffect(
    () => () => {
      delete location?.state?.newCompany;
    },
    [],
  );

  // useEffect(() => console.log({ stage }), [stage]);

  // LOGIN flow:
  // 1. <intro>
  // 2. <select-auth>
  //         |
  //         --------------------------------------------
  //         |                                          |
  // 3. (a. new user)                             (b. returning user)
  // 4. <select-lng>                                    |
  // 5. <fill-basic-info>                         B/E returns basic info
  //         |                                          |
  //         ----------------------------------------------------------------------------
  //         |                                          |                               |
  // 6. (a. create-business-account)              (b. select-existing-account)     (c. try app)
  // 7. <select-business-type> (private/company)        |                               |
  // 8. <select-business-area> (healthcare/etc.)        |                               |
  // 9. <detail-info>                            B/E returns detailed info             |                               |
  // 10. wait for confiramtion by email                 |                               |
  //                                                    |                               |
  //                                                    ---------------------------------
  //                                                    |
  // 11.                                    continue to main interface

  const getTitle = useCallback(() => {
    switch (stage) {
      case 'intro':
      case 'select-auth':
        return tr['sign-in-to-the-app'];
      case 'select-lng':
      case 'select-lng-auto':
      case 'fill-basic-info':
      case 'fill-basic-info-auto':
        return tr['create-account'];
      case 'select-business-type':
      case 'select-business-type-auto':
      case 'fill-business-data':
      case 'fill-business-data-auto':
        return tr['create-new-business-account'];
      default:
    }
  }, [stage, tr]);

  const getDescription = useCallback(() => {
    switch (stage) {
      case 'intro':
      case 'select-auth':
        return tr['sign-in-or-sign-up'];
      case 'select-lng':
      case 'select-lng-auto':
        return tr['select-preferred-language'];
      case 'fill-basic-info':
      case 'fill-basic-info-auto':
        return tr['input-your-data'];
      case 'select-business-type':
      case 'select-business-type-auto':
        return tr['select-business-type'];
      case 'fill-business-data':
      case 'fill-business-data-auto':
        return tr['fill-business-data'];
      default:
    }
  }, [stage, tr]);

  return (
    <S.Login>
      {((stage === 'intro' && isMobile) || !isMobile) && (
        <div className="title-section">
          <Intro setStage={setStage} />
        </div>
      )}

      {((stage !== 'intro' && isMobile) || !isMobile) && (
        <div className="login-section">
          <div>
            <LogoSVG
              color={isMobile ? colors.text_Primary : colors.text_Secondary}
            />

            <LoginForm
              title={getTitle()}
              description={getDescription()}
              stage={stage}
              {...(['fill-basic-info', 'fill-business-data'].includes(
                stage,
              ) && {
                nextBtnTitle: tr['complete-registration'],
              })}
            >
              {['intro', 'select-auth'].includes(stage) ? (
                <AuthProviders setStage={setStage} />
              ) : stage === 'select-lng' ? (
                <LanguageSelector setStage={setStage} />
              ) : stage === 'select-lng-auto' ? (
                <LanguageSelector setStage={setStage} auto />
              ) : stage === 'fill-basic-info' ? (
                <UserDetails setStage={setStage} />
              ) : stage === 'fill-basic-info-auto' ? (
                <UserDetails setStage={setStage} auto />
              ) : stage === 'select-business-type' ? (
                <BusinessTypeSelector setStage={setStage} />
              ) : stage === 'select-business-type-auto' ? (
                <BusinessTypeSelector setStage={setStage} auto />
              ) : stage === 'fill-business-data' ? (
                <BusinessDetails setStage={setStage} />
              ) : stage === 'fill-business-data-auto' ? (
                <BusinessDetails setStage={setStage} auto />
              ) : (
                console.log('all remaining stages')
              )}
            </LoginForm>
          </div>
        </div>
      )}
    </S.Login>
  );
};

export default Login;
