import React from 'react';
import { StyledSVG } from './SVG.styled';

const SheetSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="22.944"
      height="30"
      viewBox="0 0 22.944 30"
      color={color ? color : 'currentColor'}
    >
      <g
        id="Layer_2"
        data-name="Layer 2"
        transform="translate(-1376.815 -2672.293)"
      >
        <g
          id="Layer_1-2"
          data-name="Layer 1-2"
          transform="translate(1376.815 2672.293)"
        >
          <path
            id="Path_959"
            data-name="Path 959"
            d="M1399.122,2672.293h-12.141a.792.792,0,0,0-.147.042h-.032a.622.622,0,0,0-.137.086l-9.618,7.922a.652.652,0,0,0-.118.131l-.026.042a.589.589,0,0,0-.054.121v.042a.641.641,0,0,0-.035.156v20.822a.637.637,0,0,0,.637.637h21.67a.637.637,0,0,0,.637-.637V2672.93A.637.637,0,0,0,1399.122,2672.293Zm-12.706,1.989v5.915h-7.186Zm12.068,26.737h-20.4v-19.548h8.964a.637.637,0,0,0,.637-.637v-7.266h10.793Z"
            transform="translate(-1376.815 -2672.293)"
            // fill="#8d92a3"
          />
          <path
            id="Path_960"
            data-name="Path 960"
            d="M1387.772,2719.813a.637.637,0,1,0,0,1.275h15.1a.637.637,0,1,0,0-1.275Z"
            transform="translate(-1383.846 -2704.67)"
            // fill="#8d92a3"
          />
          <path
            id="Path_961"
            data-name="Path 961"
            d="M1402.871,2729.613h-15.1a.637.637,0,1,0,0,1.275h15.1a.637.637,0,1,0,0-1.275Z"
            transform="translate(-1383.846 -2711.347)"
            // fill="#8d92a3"
          />
          <path
            id="Path_962"
            data-name="Path 962"
            d="M1395.319,2739.413h-7.546a.637.637,0,1,0,0,1.275h7.546a.637.637,0,1,0,0-1.275Z"
            transform="translate(-1383.846 -2718.024)"
            // fill="#8d92a3"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default SheetSVG;
