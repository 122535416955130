import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';

export const ServiceGroup = styled.div`
  .info {
    display: flex;

    svg {
      align-self: start;
      margin-right: -4px;
    }
  }

  .header {
    display: grid;
    flex: 1;
    font-family: ${fontFamilies.family_Primary};
    font-size: 0.875rem;
    font-weight: 600;
    grid-gap: 10px;
    grid-template-columns: minmax(min-content, 100%) 100px 70px 70px;
    margin: 20px 10px 10px 26px;
    align-items: start;
  }
`;
