import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';

// style
import * as S from './Order.styled';

// icons
import PaperPlaneSVG from 'icons/PaperPlaneSVG';

// components
import DialogLayout from 'components/DialogLayout/DialogLayout';
import FunctionBar from 'components/FunctionBar/FunctionBar';
import OrderItemsMobile from './partials/OrderItemsMobile/OrderItemsMobile';
import OrderLayout from 'layouts/OrderLayout/OrderLayout';
import Overlay from 'components/Overlay/Overlay';
import DialogBox from 'components/DialogBox/DialogBox';
import SelectProfessionals from 'components/DialogLayout/SelectProfessionals/SelectProfessionals';
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import { useOrderFunctions } from 'hooks/useOrderFunctions';
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';
import useOrder from 'hooks/order/useOrder';
import useModal from 'hooks/useModal';
import {
  useEmployeesGet,
  useEmployeesGetMultiple,
} from 'services/employeeService';
import {
  useServicesGetOders,
  useServicesGetPrices,
} from 'services/orderService';
import { useServicesGetServiceById } from 'services/serviceService';
import {
  generateNewService,
  modifyOrderCopy,
} from 'common/helpers/orderFunctions';
import { getMinutesBetweenDates } from 'common/helpers/timeCalculations';
import { areObjectsIdentical } from 'common/helpers/objectFunctions';
import { ORDER_COMPLETION_STATUS } from 'common/Constants';

const FOOTER_INITIAL = {
  totalDuration: 0,
  totalPrice: 0,
  priceToPay: 0,
  totalVat: 0,
  discount: 0,
};

const Order = () => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const scheduleTime = useSelector((state) => state.scheduleReducer.cell);
  const getPrices = useServicesGetPrices();
  const { toggle, isShowing } = useModal();
  const isNewOrder = orderId === 'new';
  const [showMenu, setShowMenu] = useState(window.innerWidth > 1400);
  const [emerging, setEmerging] = useState(false);
  const [searchProfessionalInput, setSearchProfessionalInput] = useState('');
  const [employeeId, setEmployeeId] = useState();
  const [wantedEmployeeIds, setWantedEmployeeIds] = useState([]);
  const [isWantedEmployeesDialogDisabled, setIsWantedEmployeesDialogDisabled] =
    useState(true);
  const [wantedEmployees, setWantedEmployees] = useState(null);
  const [employees, setEmployees] = useState();
  const [sectorId, setSectorId] = useState();
  const [client, setClient] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [footerData, setFooterData] = useState(FOOTER_INITIAL);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productsSaveCondition, setProductsSaveCondition] = useState(false);
  const [contextSaveFunc, setContextSaveFunc] = useState(
    () => () => console.log('default'),
  );
  const [contextSaveCond, setContextSaveCond] = useState(false);
  const [order, setOrder] = useState({
    id: '0000',
    savedProducts: [],
    status: ORDER_COMPLETION_STATUS.ORDERED,
    toggled: true,
    startTime: scheduleTime?.time || new Date(),
    endTime: new Date(new Date().setHours(0, 0, 0, 0)),
    durationInMinutes: 0,
    amountBeforeDiscountWithVat: 0,
    finalAmountVat: 0,
    discountWithVat: 0,
    finalAmountWithVat: 0,
    sendVisitReminder: true,
    note: null,
  });
  const [isFormDisabled, setIsFormDisabled] = useState(!isNewOrder);
  const [isDirty, setIsDirty] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState({});
  const [lastOrderPricesWithDiscount, setLastOrderPricesWithDiscount] =
    useState({ services: {} });
  const [displayModal, setDisplayModal] = useState(null);
  const [modalSwitch, setModalSwitch] = useState('');
  const [wantedEmployeeSearchInput, setWantedEmployeeSearchInput] =
    useState('');

  const multiSelect = location.state?.multi === 'multi-selects';

  const { data: orderData, refetch } = useServicesGetOders(true, {
    id: orderId,
    queryParams: {
      retry: false,
      enabled: orderId !== 'new',
    },
  });
  const { saveOrder } = useOrder({
    sectorId,
    selectedEmployees: employees,
    order,
    multiSelect,
    // numberOfDays: location.state?.numberOfDays,
    location,
    orderId,
    refetch,
    setIsFormDisabled,
  });
  const selectedServiceBE = useServicesGetServiceById({
    id: selectedId?.id,
    queryParams: { enabled: !!selectedId, refetchOnWindowFocus: false },
  });
  const { data: currentEmloyeeData, refetch: refetchEmployee } =
    useEmployeesGet({
      id: employeeId,
      searchParams: {
        sectorId: sectorId,
      },
      queryParams: {
        retry: false,
        enabled: !!employeeId && sectorId !== undefined,
      },
    });

  const wantedEmployeesData = useEmployeesGetMultiple(
    wantedEmployeeIds?.map((id) => ({
      id: id,
      queryParams: {
        retry: false,
      },
    })),
  );

  const smsModalButtons = [
    {
      title: 'Siųsti',
      icon: <PaperPlaneSVG />,
      action: () => {
        console.log('sms');
      },
    },
  ];

  const menuVisibilityListener = () => {
    if (showMenu && window.innerWidth < 1400) {
      setShowMenu(false);
    } else if (!showMenu && window.innerWidth > 1400) {
      setShowMenu(true);
    }
  };

  useEffect(() => {
    window.removeEventListener('resize', menuVisibilityListener);
    window.addEventListener('resize', menuVisibilityListener);
  }, [showMenu]);

  useEffect(() => {
    setIsDirty(() => {
      if (isNewOrder) {
        return !!order.savedProducts.length;
      } else if (
        Object.keys(order).length &&
        Object.keys(defaultFormValues).length
      ) {
        const defaultOrder = structuredClone(defaultFormValues);
        const currentOrder = structuredClone(order);
        delete defaultOrder.version;
        delete defaultOrder.isPaid;
        delete currentOrder.version;
        delete currentOrder.isPaid;

        return !areObjectsIdentical(defaultOrder, currentOrder);
      }
    });
  }, [order]);

  const fetchingAtLeastOne =
    wantedEmployeesData.find((item) => item.isFetching) !== undefined;

  useEffect(() => {
    if (!wantedEmployeeIds.length) {
      setWantedEmployees(null);
    } else if (wantedEmployeesData[0]?.isSuccess) {
      if (wantedEmployeesData.length) {
        const newWantedEmployees = [];

        for (let context of wantedEmployeesData) {
          if (context?.data?.employee) {
            newWantedEmployees.push(context.data.employee);
          }
        }

        if (newWantedEmployees?.length) {
          setWantedEmployees(newWantedEmployees);
        }
      }
    }
  }, [wantedEmployeesData.length, fetchingAtLeastOne]);

  useEffect(() => {
    setOrder((prev) => ({
      ...prev,
      wantedEmployeeIds: wantedEmployeeIds,
    }));
  }, [wantedEmployeeIds]);

  useEffect(() => {
    setIsWantedEmployeesDialogDisabled(true);
  }, [fetchingAtLeastOne]);

  useEffect(() => {
    if (
      wantedEmployees !== null &&
      order.status !== ORDER_COMPLETION_STATUS.CONFIRMED
    ) {
      setIsWantedEmployeesDialogDisabled(false);
    }
  }, [wantedEmployees, order.status]);

  useEffect(() => {
    if (order.status === ORDER_COMPLETION_STATUS.CONFIRMED) {
      setIsWantedEmployeesDialogDisabled(true);
    }
  }, [order.status]);

  // Set employeeId in case of new order creation
  useEffect(() => {
    if (orderId === 'new') {
      if (location?.state?.employeeIds) {
        setWantedEmployeeIds(location?.state?.employeeIds.sort());

        if (multiSelect) {
          setEmployeeId(null);

          if (!location?.state?.employeeIds?.length) {
            setIsWantedEmployeesDialogDisabled(false);
          }
        } else {
          setEmployeeId(location.state.employeeIds[0]);
          setIsWantedEmployeesDialogDisabled(false);
        }
      }

      if (location?.state?.searchParams?.sectorId) {
        setSectorId(location.state.searchParams.sectorId);
      }

      if (location?.state?.selectedClient) {
        const customer = location?.state?.selectedClient;
        setClient({
          ...customer,
          firstName: customer?.name,
        });

        setOrder((prev) => {
          return {
            ...prev,
            customer,
          };
        });
      }

      if (location.state.time) {
        setOrder((prev) => {
          return {
            ...prev,
            startTime: location.state.time,
            endTime: location.state.time,
          };
        });
      }
    }
  }, []);

  // Set employeeId in case of existing order edit
  useEffect(() => {
    if (orderData) {
      setEmployeeId(orderData?.order?.employeeId ?? null);
    }

    let orderWantedEmployeeIds = [];

    if (orderData?.order?.wantedEmployees?.length) {
      orderWantedEmployeeIds = orderData.order.wantedEmployees
        .map((employee) => employee.id)
        .sort();
      setWantedEmployeeIds(orderWantedEmployeeIds);
    }

    if (!wantedEmployeeIds.length && !orderData?.order?.employeeId) {
      setIsWantedEmployeesDialogDisabled(false);
    }

    if (orderData?.order?.sectorId) {
      setSectorId(orderData.order.sectorId);
    }

    if (orderData?.order?.customer) {
      const customer = orderData?.order?.customer;
      setClient({
        ...customer,
        firstName: customer?.name,
      });
    }

    if (orderData?.order) {
      const actualOrder = orderData.order;
      const startTime = new Date(actualOrder.startTimeUtc);
      const endTime = new Date(
        new Date(startTime).getTime() + actualOrder.durationInMinutes * 60000,
      );

      const order = {
        ...actualOrder,
        id: actualOrder.sequenceNo,
        startTime,
        endTime,
        wantedEmployeeIds: orderWantedEmployeeIds,
        savedProducts: [],
        status: actualOrder.completionStatus.toLowerCase(),
        toggled: true,
      };

      // setLastOrderPricesWithDiscount((prev) => ({
      //   ...prev,
      //   discountWithVat: order.discountWithVat,
      // }));

      const updatedOrder = actualOrder.services.reduce(
        (accumulatorOrder, service) =>
          addToOrder(service, false, accumulatorOrder),
        order,
      );

      setOrder(updatedOrder);
      setDefaultFormValues(updatedOrder);
      setIsFormDisabled(false);
    }
  }, [orderData]);

  useEffect(() => {
    if (employeeId) {
      refetchEmployee();
    }
  }, [employeeId]);

  useEffect(() => {
    if (currentEmloyeeData?.employee) {
      setEmployees([currentEmloyeeData.employee]);
    }
  }, [currentEmloyeeData]);

  useEffect(() => {
    if (!selectedServiceBE.data) return;

    addToOrder(selectedServiceBE.data);
  }, [selectedServiceBE.data]);

  useEffect(() => {
    if (getPrices.isSuccess) {
      const orderCopy = structuredClone(order);
      const servicesCopy = structuredClone(order.savedProducts);
      const data = getPrices.data.data;
      const calcPrices = data.services;

      // console.log(data);
      // setLastOrderPricesWithDiscount((prev) => ({
      //   ...prev,
      //   discountWithVat: data.discountWithVat,
      // }));
      setOrder(() => {
        orderCopy.savedProducts = servicesCopy.map((service, i) => {
          const calculated = calcPrices.find(
            (item) => item.serviceId === service.serviceId,
          );

          if (service.serviceId === 1) {
            return service;
          } else {
            setLastOrderPricesWithDiscount((prev) => ({
              ...prev,
              services: {
                ...prev.services,
                [service.id]: service.discountWithVat,
              },
            }));

            return {
              ...service,
              ...calculated,
            };
          }
        });

        const endTime = getEndTime(
          orderCopy.startTime,
          orderCopy.savedProducts,
        );

        const updatedFields = {
          endTime,
          durationInMinutes:
            getMinutesBetweenDates(orderCopy.startTime, endTime) || 15,
          amountBeforeDiscountWithVat: data.amountBeforeDiscountWithVat,
          finalAmountVat: data.finalAmountVat,
          discountWithVat: data.discountWithVat,
          finalAmountWithVat: data.finalAmountWithVat,
        };

        return { ...orderCopy, ...updatedFields };
      });
    } else if (getPrices.isError) {
      setOrder((prev) => {
        const savedProducts = structuredClone(prev.savedProducts);
        savedProducts.map((item) => {
          if (item.id in lastOrderPricesWithDiscount.services) {
            item.discountWithVat =
              lastOrderPricesWithDiscount.services[item.id];
          }

          return item;
        });

        return { ...prev, savedProducts };
      });
    }
  }, [getPrices.isSuccess, getPrices.isError, getPrices.data]);

  useEffect(() => {
    const orderCopy = structuredClone(order);

    setFooterData({
      totalDuration: orderCopy.durationInMinutes,
      totalPrice: orderCopy.amountBeforeDiscountWithVat,
      priceToPay: orderCopy.finalAmountWithVat,
      totalVat: orderCopy.finalAmountVat,
      discount: orderCopy.discountWithVat,
    });
  }, [order]);

  useEffect(() => {
    setSearchProfessionalInput('');

    switch (modalSwitch) {
      case 'select-employee':
        setDisplayModal(
          <ConditionalWrapper
            condition={isMobile}
            wrapper={(children) => (
              <SelectionPanel
                key={'select-professional'}
                closeAction={handleCloseAction}
                headerTitle={tr['choose-specialist']}
                searchTitle={tr['search']}
                arrayFilter={setWantedEmployeeSearchInput}
              >
                {children}
              </SelectionPanel>
            )}
            secondaryWrapper={(children) => (
              <DialogBox
                headerText={tr['choose-specialist']}
                close={toggle}
                cancelOverflow
              >
                {children}
              </DialogBox>
            )}
          >
            <SelectProfessionals
              select={(employee) => {
                setWantedEmployeeIds(
                  employee.map((employee) => employee.id).sort(),
                );
                handleCloseAction();
              }}
              selectedProf={wantedEmployeeIds}
              calendarView={'i'} //TODO: should be by data from calendar?
              sectorId={sectorId}
              multi
              confirmationText={tr['choose']}
              searchInput={wantedEmployeeSearchInput}
            />
          </ConditionalWrapper>,
        );

        break;
      case 'send-sms-link':
        setDisplayModal(
          <div className="modal-wrapper" key={'send-sms-link'}>
            <DialogBox
              close={() => handleCloseAction()}
              headerText={'SMS zinute'}
              buttons={smsModalButtons}
            >
              <S.Text>
                Sveiki, pas mus galite užsiregistruoti internetu, paspaude
                nuorodą{' '}
                <a href="//serve.lt" target="_blank" rel="noreferrer noopener">
                  serveonline/Manodantukasklinika
                </a>
              </S.Text>
            </DialogBox>
          </div>,
        );
        break;
      case 'send-sms-reminder':
        setDisplayModal(
          <div className="modal-wrapper" key={'send-sms-reminder'}>
            <DialogBox
              close={() => handleCloseAction()}
              headerText={'SMS zinute'}
              buttons={smsModalButtons}
            >
              <S.Text>
                Sveiki, 2021-09-01 18:00, laukiame Jūsų pas specialistą Joną
                Jonaitį. Vilniaus odontologijos centre, adresu: Dariaus ir
                Girėno g. 245 - 96, Vilnius. Administratorė: +370 684 45678.
                Prašome patvirtinti savo atvykimą žinute įraše žodį TAIP arba
                NE. Dėkojame.
              </S.Text>
            </DialogBox>
          </div>,
        );
        break;
      default:
        return;
    }
  }, [modalSwitch, wantedEmployeeIds, wantedEmployeeSearchInput]);

  const addToOrder = (service, override, orderFromBE) => {
    if (!service) return;
    const breakId = 1;

    const orderToModify = orderFromBE || order;
    let updatedOrder;
    if (Array.isArray(service)) {
      const arr = [];
      service.forEach((item) => {
        const el = item.id === 1 ? item : generateNewService(item);
        if (el) arr.push(el);
      });

      updatedOrder = modifyOrderCopy(orderToModify, arr, breakId, override);
    } else {
      const newService = generateNewService(service);
      updatedOrder = modifyOrderCopy(
        orderToModify,
        newService,
        breakId,
        override,
      );
    }

    setIsFormDisabled(false);

    if (!updatedOrder) return;

    setOrder(updatedOrder);

    setLastOrderPricesWithDiscount((prev) => {
      const services = structuredClone(prev.services);

      services[service.serviceId] = service.discountWithVat;

      return { ...prev, services: services };
    });

    if (!orderFromBE) {
      calculateOrderPrices(updatedOrder.savedProducts);
    }

    setSelectedId(null);

    return updatedOrder;
  };

  const getEndTime = (startTime, services) => {
    const serviceCopy = structuredClone(services);

    if (!serviceCopy.length) return new Date(startTime);

    const lastIsBreak = serviceCopy.at(-1).serviceId === 1;

    if (lastIsBreak) serviceCopy.pop();

    const totalDurationInMinutes = getDuration(serviceCopy) || 15;

    const newEnd = new Date(startTime);
    newEnd.setMinutes(newEnd.getMinutes() + totalDurationInMinutes);

    return newEnd;
  };

  const getDuration = (services) =>
    services.reduce(
      (acc, curr) => acc + (curr.unitDurationInMinutes * curr.quantity || 0),
      0,
    );

  const calculateOrderPrices = (services) => {
    const updatedServices = services
      .filter((item) => item.serviceId !== 1)
      .map((service) => {
        const keyNeeded = [
          'serviceId',
          'quantity',
          'unitPriceWithVat',
          'discountWithVat',
        ];

        return {
          ...Object.fromEntries(
            Object.entries(service).filter(([key]) => keyNeeded.includes(key)),
          ),
          ...(service.vat && { vatPercent: service.vat.value }),
        };
      });

    getPrices.mutate(updatedServices);
  };

  const updateServiceKey = (keyValue, index) => {
    const orderCopy = structuredClone(order);
    const newServices = orderCopy.savedProducts.map((service, i) => {
      if (i === index) {
        return { ...service, ...keyValue };
      }
      return service;
    });
    setOrder(() => {
      return { ...orderCopy, savedProducts: newServices };
    });

    calculateOrderPrices(newServices);
  };

  const saveDiscount = (discount) => {
    const orderCopy = structuredClone(order);

    const updatedServices = orderCopy.savedProducts.map((service) => {
      if (service.unitPriceWithVat === undefined) return service;

      const servicePrice = service.amountBeforeDiscountWithVat;
      const fullOrderPrice = orderCopy.amountBeforeDiscountWithVat;

      const discountForItem = +(
        (servicePrice / fullOrderPrice) *
        discount
      ).toFixed(4);

      return { ...service, discountWithVat: discountForItem };
    });

    setOrder({ ...orderCopy, savedProducts: updatedServices });
    calculateOrderPrices(updatedServices);
  };

  const deleteService = (index) => {
    const orderCopy = structuredClone(order);

    const serviceToDelete = orderCopy.savedProducts.find(
      (el, i) => i === index,
    );

    setLastOrderPricesWithDiscount((prev) => {
      delete prev.services[serviceToDelete.id];

      return prev;
    });
    let hasBreak;

    if (serviceToDelete.breakInMinutes) hasBreak = true;

    const filteredServices = orderCopy.savedProducts.filter((serv, i) => {
      return hasBreak ? i !== index && i !== index + 1 : i !== index;
    });

    orderCopy.savedProducts = filteredServices;

    orderCopy.endTime = getEndTime(
      orderCopy.startTime,
      orderCopy.savedProducts,
    );

    if (orderCopy.savedProducts.length && orderCopy.savedProducts[0].id === 1) {
      orderCopy.savedProducts.shift();
    }

    if (!orderCopy.savedProducts.length) {
      setIsFormDisabled(true);
    }

    setOrder(orderCopy);
    calculateOrderPrices(orderCopy.savedProducts);
  };

  const selectEmployee = (employee) => {
    if (multiSelect) {
      setEmployees((prev) => {
        const alreadyExist = prev.find((obj) => obj.id === employee.id);

        return alreadyExist
          ? prev.filter((obj) => obj.id !== employee.id)
          : employees.length === 3
            ? prev
            : [...prev, employee];
      });
    } else {
      setEmployees([employee]);
      setEmerging(false);
    }

    setSearchProfessionalInput('');
  };

  const employeeSelected = (id) => {
    return employees?.find((obj) => obj.id === id);
  };

  const exitOrderCreation = () => {
    let pathname = '/functions/daily-functions/calendar/day';

    switch (location.state?.numberOfDays) {
      case 0:
        pathname = '/functions/daily-functions/calendar/day';

        break;
      case 7:
        pathname = '/functions/daily-functions/calendar/week';

        break;
      // case 30:
      // pathName = '/functions/daily-functions/calendar/month';
    }

    const navigateObj = { pathname };

    const params = new URLSearchParams(location?.state?.searchParams);

    if (params.size) {
      navigateObj.search = params.toString();
    }

    navigate(navigateObj);
  };

  const handleCloseAction = () => {
    setWantedEmployeeSearchInput('');
    setModalSwitch(null);
    setDisplayModal(null);
    toggle();

    return;
  };

  const toggleWantedEmployeesSelection = isWantedEmployeesDialogDisabled
    ? null
    : () => {
        setModalSwitch('select-employee');
        toggle();
      };

  useEffect(() => {
    console.log(order);
  }, [order]);

  const orderContext = {
    currOrder: { order, setOrder },
    order: {
      state: order,
      setState: setOrder,
    },
    saveFunc: { contextSaveFunc, setContextSaveFunc },
    saveCond: { contextSaveCond, setContextSaveCond },
    products: {
      selectedProducts,
      setSelectedProducts,
      productsSaveCondition,
      setProductsSaveCondition,
    },
    professional: employees?.[0],
    footerData,
    orderId,
    client,
    sectorId,
    isDirty,
    isFormDisabled,
    employeeId,
    wantedEmployees,
    isNewOrder,
    setIsFormDisabled,
    saveOrder,
    saveDiscount,
    deleteService,
    updateServiceKey,
    addToOrder,
    refetch,
    toggleWantedEmployeesSelection,
  };

  const { functionBarFunctions } = useOrderFunctions({
    orderId,
    order,
    isDirty,
    isNewOrder,
    setOrder,
    setModalSwitch,
    modalToggle: toggle,
  });

  return (
    <OrderContext.Provider value={orderContext}>
      <OrderLayout
        isMobile={isMobile}
        showMenu={{ state: showMenu, setState: setShowMenu }}
        emerging={{ state: emerging, setState: setEmerging }}
        employees={employees}
        employeeId={employeeId}
        wantedEmployees={wantedEmployees}
        search={{
          state: searchProfessionalInput,
          setState: setSearchProfessionalInput,
        }}
        employeeSelected={employeeSelected}
        selectEmployee={selectEmployee}
        multiSelect={multiSelect}
        toggleWantedEmployeesSelection={toggleWantedEmployeesSelection}
      >
        <DialogLayout
          headerText={`${client?.firstName || ''} ${client?.lastName || ''}`}
          exitFunc={exitOrderCreation}
          darkTheme
          {...(!isMobile && {
            // getBack: () =>
            //   navigate('../', {
            //     state: location.state,
            //   }),
            navSticky: true,
            menuBurger: {
              state: !showMenu,
              toggle: () => setShowMenu(!showMenu),
            },
          })}
        >
          <FunctionBar
            functions={functionBarFunctions}
            fixed
            fabMarginY={120}
          />
          {isMobile ? (
            <OrderItemsMobile
              setDisplayModal={setDisplayModal}
              modalToggle={toggle}
              modalIsShowing={isShowing}
            />
          ) : (
            <OrderWrapper setDisplayModal={setDisplayModal} toggle={toggle} />
          )}
        </DialogLayout>
      </OrderLayout>
      <Overlay isVisible={isShowing} hide={handleCloseAction}>
        {displayModal}
      </Overlay>
    </OrderContext.Provider>
  );
};

const OrderWrapper = ({ setButtons, setDisplayModal, toggle }) => {
  // const { orderId } = useContext(OrderContext);
  // const tr = useTranslate().use().global;

  // const tabs = [
  //   {
  //     name: tr['orderCard'],
  //     acl: 1,
  //     route: `${orderId}`, // + (orderId ? `/${orderId}` : '')
  //   },
  //   {
  //     name: tr['order-information'],
  //     acl: 1,
  //     route: `${orderId}/information`,
  //   },
  // ];

  return (
    <div>
      <Outlet context={{ setButtons, setDisplayModal, modalToggle: toggle }} />
    </div>
  );
};

export default Order;
