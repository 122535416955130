// style
import * as S from './BasicTableHead.styled';

export const BasicTableHead = ({ columns }) => (
  <S.BasicTableHead>
    <tr>
      {columns.map((column, i) => (
        <th key={i}>{column.label}</th>
      ))}
    </tr>
  </S.BasicTableHead>
);
