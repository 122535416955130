import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';

// style
import * as S from './ServiceContents.styled';

// icons
import { Check, Circle, SetSVG } from 'icons/dynamic';

// components
import { InputValue } from '../InputValue/InputValue';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const ServiceContents = ({
  contents,
  getDbItem,
  getCachedItem,
  getItem,
  toggleActivity,
  updateValue,
  disabled,
  ...props
}) => {
  const tr = useTranslate().use().global;

  const isGroup = !!contents.services;
  const isSet = contents.hasComp;

  const type = props.className?.replace(' last', '');

  const dbItem = getDbItem(type, contents.id);

  return (
    <S.ServiceContents
      isGroup={isGroup}
      isSet={isSet}
      selectable={!disabled}
      {...props}
    >
      <div
        className="mark"
        onClick={(e) => {
          e.stopPropagation();
          !disabled && toggleActivity(type, contents.id);
        }}
      >
        {getItem(type, contents.id).isActive ? (
          <Check black inCircle width={16} height={16} />
        ) : (
          <Circle bold width={16} height={16} />
        )}
      </div>

      <div className="details">
        <div className="title">
          {isSet && (
            <div
              className="icon"
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <>
                  {contents.composition?.map((service) => (
                    <div key={service.id}>{service.name}</div>
                  ))}
                </>,
              )}
            >
              <SetSVG sm />
            </div>
          )}

          {contents.name}
        </div>

        {!isGroup && (
          <>
            <InputValue
              className={classNames('price', props.className)}
              type={'number'}
              units="€"
              defaultValue={dbItem.price}
              value={getItem(type, contents.id).price}
              updateValue={(value) => updateValue(contents.id, 'price', value)}
              disabled={disabled || !contents.isActive}
              tooltipTitle={tr['base-price']}
              tooltipContents={dbItem.price}
            />

            <InputValue
              className={classNames('duration', props.className)}
              type={'time'}
              defaultValue={dbItem.duration}
              value={getItem(type, contents.id).duration}
              updateValue={(value) =>
                updateValue(contents.id, 'duration', value)
              }
              disabled={disabled || !contents.isActive}
              tooltipTitle={tr['base-duration']}
              tooltipContents={dbItem.duration}
            />

            <InputValue
              className={classNames('break', props.className)}
              type={'time'}
              defaultValue={dbItem.break}
              value={getItem(type, contents.id).break}
              updateValue={(value) => updateValue(contents.id, 'break', value)}
              disabled={disabled || !contents.isActive}
              tooltipTitle={tr['base-break']}
              tooltipContents={dbItem.break}
            />
          </>
        )}

        {!!contents.services && !isSet && (
          <div className="subgroup">
            {contents.services.map((service, index) => (
              <ServiceContents
                className={classNames(
                  'service',
                  index === contents.services?.length - 1 && 'last',
                )}
                key={service.id}
                contents={service}
                getDbItem={getDbItem}
                getItem={getItem}
                toggleActivity={toggleActivity}
                updateValue={updateValue}
                disabled={disabled}
              />
            ))}
          </div>
        )}
      </div>
    </S.ServiceContents>
  );
};

export default ServiceContents;
