import styled from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';

export const SearchWidget = styled.div`
  @media screen and (max-width: 960px) {
    align-items: center;
    box-shadow: ${shadow.search};
    background-color: white;
    display: flex;
    height: 55px;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 5;
  }

  > div:first-child {
    margin: 0 10px 0 20px;
  }

  .searchInput {
    height: unset;
  }

  .searchIcon,
  .close-button {
    flex-shrink: 0;
  }

  .searchIcon {
    height: 20px;
    width: 20px;
  }

  .close-button {
    height: 25px;
    margin-right: 20px;
    width: 25px;
    padding: 5px;
  }
`;
