import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const SimpleClockBoldSVG = ({ color }) => {
  return (
    <StyledSVG
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      color={color ? color : 'currentColor'}
    >
      <g id="Layer_1-2" data-name="Layer 1-2">
        <path
          id="Path_940"
          data-name="Path 940"
          d="M8,0a8,8,0,1,0,8,8h0A8.01,8.01,0,0,0,8,0ZM8,14.4A6.4,6.4,0,1,1,14.4,8,6.4,6.4,0,0,1,8,14.4Z"
          //   fill="#0f1d5b"
        />
        <path
          id="Path_941"
          data-name="Path 941"
          d="M42.767,23.008l-3.438-1.625-1.267-4.207a.8.8,0,1,0-1.531.46L38,22.524l4.082,1.929a.813.813,0,0,0,.342.077.8.8,0,0,0,.34-1.521Z"
          transform="translate(-30.666 -13.954)"
          //   fill="#0f1d5b"
        />
      </g>
    </StyledSVG>
  );
};

export default SimpleClockBoldSVG;
