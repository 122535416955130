import styled, { css } from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';
import { fontFamilies } from 'common/fonts/fonts';
import { DIALOG_BOX_TYPE } from 'common/Constants';
// Mobile first

export const DialogBox = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: ${shadow.xl};
  margin: auto;
  max-height: calc(100vh - 40px);
  overflow: hidden;

  @media screen and (min-width: 961px) {
    max-height: calc(100vh - 100px);
  }

  ${({ type }) => {
    switch (type) {
      case DIALOG_BOX_TYPE.CONFIRMATION:
        return css`
          font-family: ${fontFamilies.family_Secondary};
          line-height: 1.25;
          width: 600px;
        `;
    }
  }}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 30px 10px;
  position: relative;

  .title-container {
    align-items: center;
    display: flex;
    font-family: ${fontFamilies.family_Primary};

    .icon {
      align-items: center;
      display: flex;
      height: 40px;
      margin-right: 20px;
      width: 40px;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .title {
      font-size: 18px;
      font-weight: 900;
    }
  }

  .exit-icon {
    position: absolute;
    right: 20px;
    top: 18px;
    padding: 6px;
  }

  @media screen and (min-width: 961px) {
    padding: 50px 50px 30px;

    .title-container {
      .title {
        font-size: 2rem;
      }
    }

    .exit-icon {
      cursor: pointer;
    }
  }
`;

export const Main = styled.div`
  max-height: ${({ height }) => height}px;
  overflow: ${(props) => !props.cancelOverflow && 'auto'};
  padding: 0 30px;

  @media screen and (min-width: 961px) {
    padding: 0 50px;
  }
`;

export const Footer = styled.div`
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
  padding: 10px 30px 30px;

  @media screen and (min-width: 961px) {
    padding: ${(props) =>
      props.buttons ? '30px 50px 50px' : '20px 50px 50px'};
  }

  .button-group {
    display: flex;
    gap: 10px;
    margin-left: auto;
  }
`;
