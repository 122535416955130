import React from 'react';
import { StyledSVG } from './SVG.styled';

const DashWithBordersSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      color={color}
    >
      <g
        id="Group_1737"
        data-name="Group 1737"
        transform="translate(-834 -302)"
      >
        <g id="Group_1388" data-name="Group 1388" transform="translate(83 -56)">
          <g
            id="Mygtukas_plius"
            data-name="Mygtukas plius"
            transform="translate(1 93)"
          >
            <g
              id="Rectangle_196"
              data-name="Rectangle 196"
              transform="translate(750 265)"
              fill="#fff"
              //   stroke="#0f1d5b"
              strokeWidth="1"
            >
              <rect width="20" height="20" rx="5" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="19"
                height="19"
                rx="4.5"
                fill="none"
              />
            </g>
            <g
              id="Layer_2"
              data-name="Layer 2"
              transform="translate(182.257 -753.567)"
            >
              <g
                id="Layer_1-2"
                data-name="Layer 1-2"
                transform="translate(572.743 1028.567)"
              >
                <path
                  id="Path_373"
                  data-name="Path 373"
                  d="M582.156,1028.567H573.33a.587.587,0,1,0,0,1.175h8.825a.587.587,0,1,0,0-1.175Z"
                  transform="translate(-572.743 -1028.567)"
                  //   fill="#0f1d5b"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

export default DashWithBordersSVG;
