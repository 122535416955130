import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const LightBulbSVG = ({ color }) => {
  return (
    <StyledSVG
      color={color ? color : 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      width="28.966"
      height="30"
      viewBox="0 0 28.966 30"
    >
      <g
        id="Group_300"
        data-name="Group 300"
        transform="translate(-6883.618 -3955.522)"
      >
        <path
          id="Path_359"
          data-name="Path 359"
          d="M6918.922,3980.215a7.753,7.753,0,0,0-5.891-2.084,7.821,7.821,0,0,0-5.359,12.925,6.125,6.125,0,0,1,1.518,4.007v1.289a.636.636,0,0,0,.637.637h7.518a.638.638,0,0,0,.639-.637v-1.287a5.876,5.876,0,0,1,1.443-3.928,7.825,7.825,0,0,0-.5-10.922Zm-.447,10.074a7.144,7.144,0,0,0-1.768,4.775v.649h-6.242v-.651a7.393,7.393,0,0,0-1.83-4.843,6.547,6.547,0,0,1,9.416-9.073,6.547,6.547,0,0,1,.424,9.143Z"
          transform="translate(-15.086 -15.39)"
          fill="#0f1d5b"
        />
        <path
          id="Path_360"
          data-name="Path 360"
          d="M6923.17,4038.992h-5.248a1.7,1.7,0,1,0,0,3.4h5.248a1.7,1.7,0,1,0,0-3.4Zm0,2.126h-5.248a.426.426,0,0,1,0-.852h5.248a.426.426,0,1,1,0,.852Z"
          transform="translate(-22.213 -56.871)"
          fill="#0f1d5b"
        />
        <path
          id="Path_361"
          data-name="Path 361"
          d="M6927.906,3960.284a.637.637,0,0,0,.639-.638v-3.487a.637.637,0,1,0-1.273,0v3.487A.635.635,0,0,0,6927.906,3960.284Z"
          transform="translate(-29.743 0)"
          fill="#0f1d5b"
        />
        <path
          id="Path_362"
          data-name="Path 362"
          d="M6899.143,3971.657a.623.623,0,0,0,.449.188.638.638,0,0,0,.449-1.088l-2.465-2.467a.638.638,0,0,0-.9.9Z"
          transform="translate(-8.769 -8.573)"
          fill="#0f1d5b"
        />
        <path
          id="Path_363"
          data-name="Path 363"
          d="M6888.381,3999.4a.638.638,0,0,0-.639-.638h-3.486a.638.638,0,0,0,0,1.275h3.486A.637.637,0,0,0,6888.381,3999.4Z"
          transform="translate(-0.001 -29.463)"
          fill="#0f1d5b"
        />
        <path
          id="Path_364"
          data-name="Path 364"
          d="M6898.852,4022l-2.467,2.466a.637.637,0,0,0,.449,1.088.645.645,0,0,0,.453-.187l2.465-2.467a.637.637,0,0,0-.9-.9Z"
          transform="translate(-8.57 -45.165)"
          fill="#0f1d5b"
        />
        <path
          id="Path_365"
          data-name="Path 365"
          d="M6950.994,4022.286a.638.638,0,0,0-.9.9l2.467,2.466a.637.637,0,0,0,.9-.9Z"
          transform="translate(-45.162 -45.362)"
          fill="#0f1d5b"
        />
        <path
          id="Path_366"
          data-name="Path 366"
          d="M6963.7,3999.174h-3.486a.637.637,0,1,0,0,1.274h3.486a.637.637,0,0,0,0-1.274Z"
          transform="translate(-51.748 -29.741)"
          fill="#0f1d5b"
        />
        <path
          id="Path_367"
          data-name="Path 367"
          d="M6950.832,3972.134a.633.633,0,0,0,.449-.187l2.465-2.467a.637.637,0,0,0-.9-.9l-2.465,2.467a.637.637,0,0,0,.451,1.088Z"
          transform="translate(-45.359 -8.77)"
          fill="#0f1d5b"
        />
      </g>
    </StyledSVG>
  );
};

export default LightBulbSVG;
