import * as S from '../SVG.styled';

const OpenFolderSVG = ({ color, width, height, sm, lg }) => {
  let content;
  let size;

  switch (true) {
    case sm:
      size = 16;
      content = (
        <path d="m15.832 5.5618c-0.1826-0.27064-0.48584-0.43204-0.81191-0.43204h-2.1553v-1.2211c0-0.53964-0.44019-0.97983-0.97983-0.97983h-6.5474v-0.51682c0-0.53964-0.44019-0.97983-0.97983-0.97983h-3.3781c-0.53964 0.0016303-0.97983 0.44182-0.97983 0.98146v11.174c0 0.53964 0.44019 0.97984 0.97983 0.97984h11.013c0.40106 0 0.75811-0.23966 0.9081-0.61138l3.0275-7.4784c0.12228-0.30161 0.08641-0.64398-0.09619-0.91462zm-11.799-2.8237v0.51519c0 0.53964 0.44019 0.97983 0.97983 0.97983h6.5491v0.89669h-7.3969c-0.40106 0-0.75648 0.23966-0.9081 0.61138l-1.9531 4.8242v-7.8273zm7.7409 10.525h-10.155l2.7667-6.8295h10.154l-2.7651 6.8279z" />
      );
      break;
    case lg:
    default:
      size = 30;
      content = (
        <path d="m1.278 27.2h21.528c0.52266 0 0.98794-0.31232 1.1823-0.79673l5.9181-14.618c0.15935-0.39518 0.11473-0.84135-0.12429-1.1951-0.23902-0.35375-0.6342-0.56408-1.0581-0.56408h-4.8505v-3.0244c0-0.70431-0.57364-1.278-1.278-1.278h-13.436v-1.6445c0-0.70431-0.57365-1.278-1.278-1.278h-6.6033c-0.70431 0-1.278 0.57365-1.278 1.278v21.843c0 0.70431 0.57365 1.278 1.278 1.278zm27.449-15.893-5.9213 14.618h-21.222l5.9213-14.625h21.222zm-27.449-7.2311h6.6065v1.6476c0 0.70431 0.57365 1.278 1.278 1.278h13.439v3.0276h-15.096c-0.52265 0-0.98795 0.31232-1.1823 0.79673l-5.0481 12.464v-19.214z" />
      );
      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : size}
      height={height ? height : size}
      version="1.1"
      viewBox={`0 0 ${size} ${size}`}
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default OpenFolderSVG;
