import { useContext, useEffect } from 'react';

// style
import * as S from './SideBarFilter.styled';

// icons
import FilterClearSVG from 'icons/FilterClearSVG';

// components
import { TheButton } from 'components/Buttons/TheButton';
import { InputField } from 'components/InputField/InputField';

// hooks and helpers
import { useTranslate } from 'hooks/useTranslate';
import { useFormState } from 'hooks/useFormState';

// context
import { MainContext } from 'pages/Main/Main';

export const SideBarFilter = ({ fields, criterias, basicFeature }) => {
  const tr = useTranslate().use().global;

  const { setState } = useContext(MainContext);

  const fieldList = fields.filter(
    (field) =>
      !['firstName', 'lastName', 'age'].includes(field.id) &&
      !(field.hideFilter || field.hide),
  );

  // const initValues = (initData) => {
  //   return fieldList.reduce((acc, field) => {
  //     return {
  //       ...acc,
  //       [field.id]: {
  //         type: field.type,
  //         value:
  //           initData !== undefined && initData !== null && initData[field.id]
  //             ? initData[field.id].type === 'range'
  //               ? {
  //                   from: initData[field.id].from?.value && {
  //                     value: initData[field.id].from?.value,
  //                     label: initData[field.id].from?.label,
  //                   },
  //                   to: initData[field.id].to?.value && {
  //                     value: initData[field.id].to?.value,
  //                     label: initData[field.id].to?.label,
  //                   },
  //                 }
  //               : {
  //                   value: initData[field.id]?.value,
  //                   label: initData[field.id]?.label,
  //                 }
  //             : null,
  //         ...(field.filterType && { filterType: field.filterType }),
  //         // field.id === 'age' ? { value: undefined, label: '-' } : undefined,
  //       },
  //     };
  //   }, {});
  // };

  // const reducer = (state, action) => {
  //   switch (action.id) {
  //     case 'setup':
  //       return initValues(criterias);
  //     case 'clear':
  //       if (action.value) {
  //         const newState = { ...state };
  //         if (Array.isArray(action.value))
  //           action.value.forEach(
  //             (headerId) => (newState[headerId].value = undefined)
  //           );
  //         return newState;
  //       } else {
  //         return initValues();
  //       }
  //     default:
  //       let newState = { ...state };

  //       const requiredField = fields.find((field) => field.id === action.id);

  //       if (requiredField?.update) {
  //         newState = requiredField?.update(
  //           action.value,
  //           action.id,
  //           state,
  //           fields
  //         );
  //       } else newState[action.id].value = action.value;

  //       return newState;
  //   }
  // };

  // const [state, dispatch] = useReducer(reducer, {});

  // // setup initial state
  // useEffect(() => {
  //   dispatch({ id: 'setup' });
  // }, []);

  // // change value function
  // const setValue = (headerId, value) => {
  //   dispatch({ id: headerId, value: value });
  // };

  const updateContext = () => {
    // console.log('update context');
    setState({ type: 'filter-criteria', value: state });
  };

  const { state, options, clearFields } = useFormState(fieldList, {
    auxUpdateFn: updateContext,
  });

  // set filter criteria for the first render
  useEffect(
    () => setState({ type: 'filter-criteria', value: state }),
    [setState],
  );

  return (
    <S.SideBarFilter>
      {basicFeature && <div className="spacer"></div>}

      <div className="criterias">
        {fieldList.map((criteria, index) => {
          return (
            <InputField
              // stayOpen
              key={index}
              id={criteria.id}
              label={criteria.name}
              // state={{
              //   // type: criteria.type,
              //   type: state[criteria.id]?.type,
              //   value: state[criteria.id]?.value,
              //   setValue: setValue,
              // }}
              // options={criteria.options}
              state={state[criteria.id]}
              options={options[criteria.id]}
            />
          );
        })}
      </div>

      {state &&
        Object.values(state).some((value) => !!value.value) && (
          <TheButton
            secondary
            raised
            action={() => clearFields()}
            title={tr['reset']}
            Icon={FilterClearSVG}
            // width="fit-content"
            width="50%"
            height="25px"
          />
        )}
    </S.SideBarFilter>
  );
};

export default SideBarFilter;
