import { useCallback, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// style
import * as S from './NavBarMobile.styled';
import { colors } from 'common/colors/colors';

// icons
import { Ex, BurgerMenu } from 'icons/dynamic';
import HeaderSearchSVG from 'icons/HeaderSearchSVG';
import LogoSVG from 'icons/LogoSVG';

// components
import { Search } from 'components/Search/Search';

// contexts
import { MainContext } from 'pages/Main/Main';

export const NavBarMobile = ({ showBurgerMenu, setShowBurgerMenu }) => {
  const {
    headerFixed,
    searchPlaceholder,
    searchInput,
    searchIconVisible,
    setState,
  } = useContext(MainContext);

  const handleResize = useCallback(() => {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(() => {
      if (headerFixed.position) {
        headerFixed.setPosition(false);
        searchInput.setInput('');
      }
    }, 1);
  }, [headerFixed, searchInput]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [headerFixed.position, handleResize]);

  return (
    <S.NavBarMobile searchIconVisible={searchIconVisible}>
      {headerFixed?.position ? (
        <div className="search_container">
          <Search
            placeholder={searchPlaceholder.placeholder}
            state={{
              input: searchInput.input,
              setInput: searchInput.setInput,
            }}
          />
          <div
            className="searchExit"
            onClick={() => {
              headerFixed.setPosition(false);
              searchInput.setInput('');
            }}
          >
            <Ex black width={10} height={10} />
          </div>
        </div>
      ) : (
        <>
          <S.BurgerContainer onClick={() => setShowBurgerMenu(!showBurgerMenu)}>
            <BurgerMenu bold color="#fff" />
          </S.BurgerContainer>
          <S.LogoContainer>
            <NavLink to="/" replace={true}>
              <LogoSVG color={colors.header_logo_and_text_Primary} />
            </NavLink>
          </S.LogoContainer>
          <div
            id="header-search"
            className="headerSearch_container"
            // onClick={() => headerFixed.setPosition(!headerFixed.position)}
            onClick={() => setState({ type: 'show-search-widget' })}
          >
            <HeaderSearchSVG />
          </div>
        </>
      )}
    </S.NavBarMobile>
  );
};

export default NavBarMobile;
