import { useCallback, useContext, useEffect, useState } from 'react';
import ProfessionalHeader from '../ProfessionalHeader/ProfessionalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchedule } from 'redux/actions/scheduleActions';
import { Navigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { ScheduleContext } from 'context/ScheduleContext';

export const DataPanelHeader = ({
  numberOfDays,
  personalDays,
  headerData,
  headerMenu,
  pinToStart,
  searchParams,
}) => {
  const dispatch = useDispatch();
  const sectorId = searchParams.get('sectorId');

  const APP_ROOT = process.env.REACT_APP_APP_ROOT;

  // local
  const [shouldNavigateToPersonal, setShouldNavigateToPersonal] =
    useState(false);
  const [shouldNavigateToMonthly, setShouldNavigateToMonthly] = useState(false);
  const { selectedProfessional } = useContext(ScheduleContext);

  const toggleSelectedProfessionals = useCallback(
    (id) => {
      const newSelectedValue = {
        id: selectedProfessional?.value?.id === id ? null : id,
      };
      selectedProfessional.set(newSelectedValue);
      dispatch(
        updateSchedule({
          selectedProfessional: newSelectedValue,
        }),
      );
    },
    [selectedProfessional?.value?.id, sectorId],
  );

  const selected = selectedProfessional?.value?.id === headerData.id;
  // const checktIfHeadToggled = (id) => selectedProfessional?.value?.id === id;

  return (
    // <ClickAwayListener
    //   mouseEvent="mousedown"
    //   onClickAway={() => dispatch(setPendingOrdersProfesional(null))}
    // >
    <div className="column-head">
      {numberOfDays === 1 ? (
        <ProfessionalHeader
          id={headerData.id}
          pic={headerData.photoUrl}
          firstName={headerData.firstName}
          lastName={headerData.lastName}
          position={headerData.profession} // Missing
          prioritize={headerData.pinned}
          headerMenu={headerMenu}
          pinToStart={pinToStart}
          select={() => {
            toggleSelectedProfessionals(headerData.id);
          }}
          selected={selected}
          // selected={checktIfHeadToggled(headerData.id)}
          {...(numberOfDays < 24 && {
            openPersonal: () => setShouldNavigateToPersonal(true),
            openMonthly: () => setShouldNavigateToMonthly(true),
          })}
        />
      ) : (
        <div className="sticky-head">
          <ProfessionalHeader
            id={headerData.id}
            pic={headerData.pic}
            firstName={headerData.firstName}
            lastName={headerData.lastName}
            position={headerData.profession}
            prioritize={headerData.pinned}
            headerMenu={headerMenu}
            pinToStart={pinToStart}
            select={() => {
              toggleSelectedProfessionals(headerData.id);
            }}
            selected={selected}
            // selected={checktIfHeadToggled(headerData.id)}
            {...(numberOfDays < personalDays.total && {
              openPersonal: () => setShouldNavigateToPersonal(true),
              openMonthly: () => setShouldNavigateToPersonal(true),
            })}
            variant="multi"
          />
        </div>
      )}

      {shouldNavigateToPersonal && (
        <Navigate
          to={{
            pathname: APP_ROOT + 'functions/daily-functions/calendar/personal',
            search: createSearchParams({
              ...Object.fromEntries(new URLSearchParams(searchParams)),
              employeeId: headerData.id,
            }).toString(),
          }}
          state={{ numberOfDays }}
        />
      )}
      {shouldNavigateToMonthly && (
        <Navigate
          to={{
            pathname: APP_ROOT + 'functions/daily-functions/calendar/month',
            search: createSearchParams({
              ...Object.fromEntries(new URLSearchParams(searchParams)),
              employeeId: headerData.id,
            }).toString(),
          }}
          state={{ numberOfDays }}
        />
      )}
    </div>
    // </ClickAwayListener>
  );
};

export default DataPanelHeader;
