import * as S from '../SVG.styled';

const RecommendationSVG = ({ color, width, height, sm, lg }) => {
  let content;
  let size;

  switch (true) {
    case sm:
      size = 16;
      content = (
        <>
          <path d="m6.0412 5.5618-0.18341 1.0699c-0.053092 0.3089 0.072398 0.61297 0.32499 0.79638 0.14319 0.10297 0.3089 0.15606 0.47783 0.15606 0.13032 0 0.25902-0.030568 0.37969-0.094922l0.96048-0.50518 0.96048 0.50518c0.27672 0.1448 0.60493 0.12066 0.85752-0.061136 0.25259-0.18341 0.37647-0.48909 0.32499-0.79799l-0.18341-1.0699 0.77707-0.75777c0.22363-0.2188 0.30246-0.53736 0.20593-0.83499s-0.34912-0.51-0.65802-0.55505l-1.0731-0.15606-0.48105-0.97335c-0.13836-0.27994-0.4183-0.4537-0.73042-0.4537-0.31212 0-0.59206 0.17376-0.73042 0.4537l-0.48105 0.97335-1.0731 0.15606c-0.3089 0.045048-0.56149 0.25742-0.65802 0.55505s-0.017697 0.6178 0.20593 0.83499l0.77707 0.75777zm1.1793-1.0651c0.26546-0.038612 0.49392-0.20593 0.61297-0.44565l0.16732-0.33786 0.16732 0.33786c0.11905 0.23972 0.34751 0.40704 0.61297 0.44565l0.37325 0.054701-0.26868 0.26224c-0.19145 0.18663-0.27994 0.4553-0.23489 0.72237l0.064354 0.37164-0.33303-0.17536c-0.2365-0.12549-0.52127-0.12549-0.75777 0l-0.33303 0.17536 0.064354-0.37004c0.045048-0.26546-0.04183-0.53414-0.23489-0.72237l-0.27029-0.26224 0.37325-0.054701z" />
          <path d="m13.341-1.6075e-7h-10.681c-0.35556 0-0.64354 0.28798-0.64354 0.64354v14.713c0 0.35556 0.28798 0.64354 0.64354 0.64354h10.681c0.35556 0 0.64354-0.28798 0.64354-0.64354v-14.713c0-0.35556-0.28798-0.64354-0.64354-0.64354zm-0.64354 14.713h-9.3941v-13.426h9.3941z" />
          <path d="m4.9778 10.89h6.0493c0.35556 0 0.64354-0.28798 0.64354-0.64354 0-0.35556-0.28798-0.64354-0.64354-0.64354h-6.0493c-0.35556 0-0.64354 0.28798-0.64354 0.64354 0 0.35556 0.28798 0.64354 0.64354 0.64354z" />
          <path d="m4.9746 12.694h3.6955c0.35556 0 0.64354-0.28798 0.64354-0.64354 0-0.35556-0.28798-0.64354-0.64354-0.64354h-3.6955c-0.35556 0-0.64354 0.28798-0.64354 0.64354 0 0.35556 0.28798 0.64354 0.64354 0.64354z" />
        </>
      );
      break;
    case lg:
    default:
      size = 30;
      content = (
        <>
          <path d="m11.846 10.017-0.41488 2.4138c-0.06286 0.36459 0.08486 0.72604 0.38345 0.9429 0.16972 0.12258 0.36773 0.18544 0.56574 0.18544 0.15401 0 0.30802-0.03772 0.44945-0.11315l2.1687-1.1409 2.1687 1.1409c0.32687 0.17287 0.71661 0.14458 1.0152-0.07229 0.29859-0.21687 0.44631-0.57831 0.38345-0.9429l-0.41488-2.4138 1.7538-1.7098c0.26401-0.25773 0.3583-0.63489 0.24516-0.9869-0.11315-0.35202-0.41173-0.60346-0.77632-0.65689l-2.4264-0.35202-1.0843-2.197c-0.16344-0.33002-0.49345-0.53745-0.86433-0.53745-0.37088 0-0.70089 0.2043-0.86433 0.53745l-1.0843 2.197-2.4264 0.35202c-0.36459 0.053431-0.66317 0.30487-0.77632 0.65689-0.11315 0.35202-0.022001 0.72918 0.24515 0.9869zm1.5369-2.483c0.3143-0.044002 0.58774-0.24201 0.72604-0.52803l0.88947-1.8009 0.88947 1.8009c0.14144 0.28601 0.41173 0.48088 0.72604 0.52803l1.9895 0.28916-1.4395 1.4018c-0.2263 0.22315-0.33002 0.5406-0.27658 0.85176l0.33944 1.9801-1.7789-0.93347c-0.27973-0.14772-0.61603-0.14772-0.8989 0l-1.7789 0.93347 0.33944-1.9801c0.053432-0.31116-0.050288-0.63174-0.27658-0.8549l-1.4395-1.4018 1.9864-0.28916z" />
          <path d="m26.062 29.371v-28.743c0-0.34573-0.28287-0.6286-0.6286-0.6286h-20.866c-0.34573 0-0.6286 0.28287-0.6286 0.6286v28.743c0 0.34573 0.28287 0.6286 0.6286 0.6286h20.866c0.34573 0 0.6286-0.28287 0.6286-0.6286zm-1.2572-0.6286h-19.609v-27.486h19.609z" />
          <path d="m9.0959 20.015h11.818c0.34573 0 0.6286-0.28287 0.6286-0.6286s-0.28287-0.6286-0.6286-0.6286h-11.818c-0.34573 0-0.6286 0.28287-0.6286 0.6286s0.28287 0.6286 0.6286 0.6286z" />
          <path d="m9.0896 23.541h7.2195c0.34573 0 0.6286-0.28287 0.6286-0.6286 0-0.34573-0.28287-0.6286-0.6286-0.6286h-7.2195c-0.34573 0-0.6286 0.28287-0.6286 0.6286 0 0.34573 0.28287 0.6286 0.6286 0.6286z" />
        </>
      );
      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : size}
      height={height ? height : size}
      version="1.1"
      viewBox={`0 0 ${size} ${size}`}
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default RecommendationSVG;
