import { useState } from 'react';

// style
import * as S from './DiscountColumn.styled';

// components
import Overlay from 'components/Overlay/Overlay';
import DiscountModal from './DiscountModal/DiscountModal';

// hooks
import useModal from 'hooks/useModal';
import useNumber from 'hooks/useNumber';

const DiscountColumn = ({ service, index, saveChange }) => {
  const { formatPrice } = useNumber({ price: true });

  // Hooks
  const { isShowing, toggle } = useModal();

  // Functions
  const saveDiscount = (discount) => {
    saveChange({ discountWithVat: discount }, index);
  };

  return (
    <>
      <S.DiscountColumn>
        <span onClick={toggle}>{formatPrice(service.discountWithVat)}</span>
        <Overlay isVisible={isShowing} hide={() => toggle()}>
          <div className="modal-wrapper">
            <DiscountModal
              onSave={saveDiscount}
              price={service.unitPriceWithVat}
              discount={service.discountWithVat}
              quantity={service.quantity}
              close={toggle}
            />
          </div>
        </Overlay>
      </S.DiscountColumn>
    </>
  );
};

export default DiscountColumn;
