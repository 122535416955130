import * as S from '../SVG.styled';

export default ({ color, width, height, bold }) => {
  const content = bold ? (
    <path d="m26.372 2.4972h-3.6159c-0.10698-0.89251-0.87112-1.5894-1.7911-1.5894h-1.3357c-0.5624-0.57157-1.3418-0.90779-2.164-0.90779h-0.34845c-0.80693 0-1.568 0.31482-2.1365 0.88028l-0.02751 0.02751h-1.3327c-0.92308 0-1.6842 0.69384-1.7911 1.5894h-3.6159c-1.1798 0-2.1396 0.95976-2.1396 2.1396v2.4452h-2.4452c-1.1798 0-2.1396 0.95976-2.1396 2.1396v18.639c0 1.1798 0.95976 2.1396 2.1396 2.1396h18.162c1.1798 0 2.1396-0.95976 2.1396-2.1396v-18.639c0-1.1798-0.95976-2.1396-2.1396-2.1396h-13.275v-2.1396h3.3225c0.14671 0.84972 0.8864 1.4977 1.7789 1.4977h7.3479c0.88946 0 1.6322-0.64799 1.7789-1.4977h3.3225v18.33c0 0.6755 0.54712 1.2226 1.2226 1.2226 0.6755 0 1.2226-0.54712 1.2226-1.2226v-18.636c0-1.1798-0.95976-2.1396-2.1396-2.1396zm-4.8905 25.055h-17.551v-18.024h3.35 0.01223 0.01223 14.176v18.028zm-1.1554-23.557h-6.0703v-0.64188h1.2899c0.4432 0 0.85278-0.24147 1.0698-0.62965 0.01834-0.03362 0.04585-0.06724 0.07947-0.10087 0.06724-0.06724 0.2109-0.17728 0.4218-0.17728h0.34845c0.25064 0 0.42792 0.15283 0.51044 0.29648 0.21701 0.37901 0.62354 0.61131 1.0606 0.61131h1.2899z" />
  ) : (
    <path d="m22.077 29.997c0.87945 0 1.5932-0.71376 1.5932-1.5932v-19.428c0-0.87945-0.71376-1.5932-1.5932-1.5932h-14.473v-3.1864c0-0.17525 0.14339-0.31864 0.31864-0.31864h4.3909v0.31546c0 0.68826 0.55762 1.2459 1.2459 1.2459h7.6601c0.68826 0 1.2459-0.55762 1.2459-1.2459v-0.31546h4.3909c0.17525 0 0.31864 0.14339 0.31864 0.31864v19.428c0 0.3505 0.28678 0.63728 0.63728 0.63728 0.3505 0 0.63728-0.28678 0.63728-0.63728v-19.428c0-0.87945-0.71376-1.5932-1.5932-1.5932h-4.3909v-0.41105c0-0.68826-0.55762-1.2459-1.2459-1.2459h-1.676c-0.47477-0.59267-1.2013-0.94636-1.9724-0.94636h-0.36325c-0.67233 0-1.3064 0.26128-1.7812 0.73606-0.06691 0.06692-0.13383 0.13702-0.19118 0.2103h-1.6729c-0.68826 0-1.2459 0.55762-1.2459 1.2459v0.41105h-4.3909c-0.87945 0-1.5932 0.71375-1.5932 1.5932v3.1864h-3.1896c-0.87945 0-1.5932 0.71375-1.5932 1.5932v19.431c0 0.87945 0.71376 1.5932 1.5932 1.5932h18.934zm-8.4886-27.776h1.9819c0.23261 0 0.44291-0.12427 0.55762-0.3282 0.05098-0.08922 0.11471-0.17525 0.19118-0.24854 0.23898-0.23898 0.55762-0.36962 0.88901-0.36962h0.36325c0.4461 0 0.86352 0.23898 1.0866 0.62772 0.11471 0.19756 0.32501 0.31864 0.55125 0.31864h1.9819v1.9437h-7.6028zm-10.764 26.183v-19.428c0-0.17525 0.14339-0.31864 0.31864-0.31864h3.8109 0.01275 0.01275 15.097c0.17525 0 0.31864 0.14339 0.31864 0.31864v19.431c0 0.17525-0.14339 0.31864-0.31864 0.31864h-18.934c-0.17525 0-0.31864-0.14339-0.31864-0.31864z" />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
