import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useServicesSellOrders } from 'services/orderService';
import { formatToIsoWithoutMilliseconds } from 'common/helpers/dateOperations';

const useOrder = ({
  sectorId,
  selectedEmployees,
  order,
  multiSelect,
  location,
  orderId,
  refetch,
  setIsFormDisabled,
}) => {
  const navigate = useNavigate();
  const tr = useTranslate().use().global;

  const { mutate, isSuccess, data, isError, error } = useServicesSellOrders();

  const [isSubmiting, setIsSubmiting] = useState(false);

  const generateServiceList = (services) => {
    return services.map((service) => {
      const isBreak = service.unitPriceWithVat === undefined; // TOOD: change validation method

      if (isBreak) return service;

      const keyNeeded = [
        'serviceId',
        'unitDurationInMinutes',
        'unitPriceWithVat',
        'quantity',
        'discountWithVat',
        'unitId',
        'unitVolume',
        'vatPercent',
      ];

      return {
        ...Object.fromEntries(
          Object.entries(service).filter(([key]) => keyNeeded.includes(key)),
        ),
        ...(service.sms && { smsPeriod: service.sms.value }),
      };
    });
  };

  const saveOrder = useCallback(() => {
    if (isSubmiting) {
      return;
    }

    const orderCopy = structuredClone(order);
    const serviceList = generateServiceList(orderCopy.savedProducts);
    const employeeId = selectedEmployees?.[0]?.id;
    const orderToSend = {
      sectorId,
      employeeId: employeeId,
      wantedEmployeeIds: order.wantedEmployeeIds,
      startTimeUtc: formatToIsoWithoutMilliseconds(orderCopy.startTime),
      sendVisitReminder: orderCopy.sendVisitReminder,
      customerId: orderCopy.customer.id,
      durationInMinutes: orderCopy.durationInMinutes || 15,
      completionStatus: order.status,
      services: serviceList,
      note: orderCopy.note,
    };

    if (orderId !== 'new') {
      orderToSend.id = orderId;
    }

    setIsFormDisabled(true);
    mutate(orderToSend);
    setIsSubmiting(true);
  }, [order, multiSelect, selectedEmployees, sectorId, isSubmiting]);

  useEffect(() => {
    if (isSuccess) {
      const responseOrderId = data?.data?.order?.id;

      if (orderId === 'new' && responseOrderId) {
        navigate('/functions/tables/orders/' + responseOrderId, {
          state: location.state,
        });
        toast.success(tr['order-created']);
      } else {
        refetch();
        toast.success(tr['order-updated']);
      }
      setIsSubmiting(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      if (error?.response?.status === 412) {
        refetch();
      }
      if (error?.response?.status === 404) {
        navigate('/functions/daily-functions/calendar/');
      }
    }
  }, [isError]);

  return {
    saveOrder,
  };
};

export default useOrder;
