import PropTypes from 'prop-types';

// style
import * as S from './SelectProfessionalsHeads.styled';

// components
import { Avatar } from 'components/Avatar/Avatar';

// icons
import CrossBoldSVG from 'icons/CrossBoldSVG';

export const SelectProfessionalsHeads = ({
  pic,
  fullName,
  currentProfessional,
  action,
  deleteAction,
  small,
  grayedOut,
}) => {
  return (
    <S.ProfessionalHead
      currentProfessional={currentProfessional}
      small={small}
      grayedOut={grayedOut}
    >
      {/* <div className="head_image" onClick={action}>
        <img src={pic} alt={fullName} />
      </div> */}
      <div className="head_image" onClick={action}>
        <Avatar fullName={fullName} imagePath={pic} />
      </div>
      <div className="head_fullName">{fullName}</div>
      {deleteAction && (
        <div className="delete-head" onClick={deleteAction}>
          <CrossBoldSVG />
        </div>
      )}
    </S.ProfessionalHead>
  );
};

SelectProfessionalsHeads.propTypes = {
  pic: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  // currentProfessional: PropTypes.bool,
  action: PropTypes.func,
  deleteAction: PropTypes.func,
};

export default SelectProfessionalsHeads;
