import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const OrderRowBreak = styled.tr`
  td {
    color: ${colors.bg_Primary};
    font-family: ${fontFamilies.family_Secondary};
    font-size: 0.875rem;
    padding: 0 5px;

    .content {
      align-items: center;
      background-color: ${colors.bg_grey_1};
      display: flex;
      height: 43px;
      padding: 5px 20px 0px 5px;
    }

    .icon-container {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 40px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    .text-container {
    }

    .input-container {
      align-items: center;
      background-color: white;
      border: none;
      border-radius: 5px;
      display: flex;
      font-weight: 900;
      height: 26px;
      margin-left: 10px;
      padding: 0 5px;
    }

    .input-container input {
      font-weight: 900;

      ::placeholder {
        font-weight: 400;
      }

      :focus-within::placeholder {
        background-color: ${colors.bg_grey_2};
        font-weight: 900;
      }
    }
  }
`;
