import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const BasicTable = styled.div`
  table {
    width: 100%;
    margin: 0 auto;
    overflow: scroll;
    font-family: ${fontFamilies.family_Secondary};
  }
`;
