import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const ScheduleDayCardContainer = styled.div`
  position: relative;
  z-index: ${(props) => props.isOfficeSelectorVisible && 1};

  > label {
    display: inline-block;
    font-size: 0.75rem;
    margin-bottom: 3px;
    color: ${({ currentDay, currentMonth }) =>
      currentDay ? colors.button_Secondary : !currentMonth && colors.bg_grey_3};
    font-weight: ${({ currentDay }) => currentDay && 900};
  }

  button {
    margin-top: 10px;
    height: 25px;
    width: fit-content;

    svg {
      height: 12px;
      width: 12px;
    }
  }
`;

export const Selector = styled.div`
  border: 1px solid
    ${(props) => (props.isSelected ? colors.bg_grey_3 : 'transparent')};
  /* border: 1px solid
    ${(props) =>
    props.isSelected ? colors.bg_Button_Secondary : 'transparent'}; */
  border-radius: 5px;
  margin: ${({ isTemplate }) => !isTemplate && '-2px'};
  padding: ${({ isTemplate }) => !isTemplate && '1px'};
  position: relative;
  min-width: ${({ locale }) =>
    ['en', 'en-US'].includes(locale) ? 173 : 120}px;

  :hover {
    border-color: hsl(0, 0%, 70%);
  }
`;

export const Badge = styled.div`
  align-items: center;
  background-color: ${({ hasValue }) =>
    hasValue ? colors.bg_Button_Primary : colors.button_Primary};
  border: 1px solid
    ${({ hasValue }) => (hasValue ? 'transparent' : colors.bg_grey_3)};
  /* border: 1px solid
    ${({ hasValue }) =>
    hasValue ? 'transparent' : colors.bg_Button_Secondary}; */
  border-radius: 10px;
  color: ${colors.button_Primary};
  cursor: pointer;
  display: flex;
  font-family: ${fontFamilies.family_Primary};
  font-size: 0.65rem;
  font-weight: 700;
  height: 18px;
  justify-content: center;
  min-width: 18px;
  padding-bottom: 1px;
  padding-inline: 5px;
  position: absolute;
  right: 3px;
  top: 3px;
  transform: translate(8px, -50%);

  ${Selector}:hover & {
    border-color: inherit;
  }
`;

export const ScheduleDayCard = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.isWeekend ? colors.bg_grey_2 : colors.bg_grey_1};
  border-radius: 5px;
  font-size: 0.8125rem;
  justify-items: center;
  padding: 5px;
  /* width: fit-content; */
  width: 100%;
`;

export const Range = styled.div`
  align-items: center;
  background-color: ${({ isChanged }) =>
    isChanged ? colors.info : colors.bg_Secondary};
  border: 1px solid transparent;
  border-color: ${({ hasErrors }) =>
    hasErrors ? colors.mandyRed : 'transparent'};
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  /* padding: 10px; */
  padding: 9px;

  // first row
  :first-child {
    margin-bottom: 5px;
  }

  // hours/minutes divider
  > span {
    color: ${({ isSelected, hasValues }) =>
      isSelected &&
      typeof hasValues === 'number' &&
      ((1 & hasValues) === 1 || (2 & hasValues) === 2)
        ? colors.bg_grey_3
        : colors.bg_grey_2};
    margin-inline: 5px;
  }

  :first-child > span {
    color: ${({ hasValues }) =>
      typeof hasValues === 'number' &&
      (1 & hasValues) === 1 &&
      colors.text_Secondary};
  }

  :last-child > span {
    color: ${({ hasValues }) =>
      typeof hasValues === 'number' &&
      (2 & hasValues) === 2 &&
      colors.text_Secondary};
  }
`;

export const Value = styled.div`
  align-items: center;
  color: ${(props) =>
    props.hasValue
      ? colors.text_Secondary
      : props.isSelected
        ? colors.bg_grey_3
        : colors.bg_grey_2};
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  line-height: 1.25;
`;
