import styled from 'styled-components/macro';

export const ListSelector = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;

  legend {
    margin-bottom: 20px;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .options > label {
    display: flex;
    font-weight: 700;
    justify-content: left;

    > :nth-child(2) {
      margin-right: 15px;
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    appearance: none;
  }
`;
