// style
import * as S from './FlashMessage.styled';

// icons
import { InfoSVG } from 'icons/dynamic';

export const FlashMessage = ({ children, type }) => {
  return (
    <S.FlashMessage type={type}>
      <InfoSVG width={15} height={15} />
      {children}
    </S.FlashMessage>
  );
};
