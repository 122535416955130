import { useCallback, useContext, useEffect, useState } from 'react';

// style
import * as S from './SectorSelectorItem.styled';

// components
import { Checkbox } from 'components/Checkbox/Checkbox';
import { InputField } from 'components/InputField/InputField';
import { InputFieldGroup } from '../../ServiceDetails/ServiceGeneralData/ServiceGeneralData.styled';
import { InputFieldNumber } from 'components/InputFieldNumber/InputFieldNumber';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useNumber from 'hooks/useNumber';

export const SectorSelectorItem = ({
  data,
  updateSectorProp,
  disabled,
  isService,
  disabledByParent,
}) => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);
  const { currencySymbol, currencySymbolPlacement } = useNumber({
    price: true,
  });

  const getVatAmount = (fullPrice, vatPercent) =>
    parseFloat((fullPrice * (vatPercent / 100)).toFixed(4)) || undefined;

  const formState = useCallback(
    (propId, type) => {
      return {
        type: type,
        value: ![null, undefined].includes(data[propId])
          ? {
              value: data[propId],
              label: data[propId],
            }
          : undefined,
        setValue: (headerId, value) =>
          updateSectorProp(data.id, headerId, value?.value),
      };
    },
    [data, updateSectorProp],
  );

  // set VAT amount field
  const [vatAmount, setVatAmount] = useState();

  // update VAT amount
  useEffect(
    () =>
      setVatAmount(getVatAmount(data['unitPriceWithVat'], data['vatPercent'])),
    [data, data.unitPriceWithVat, data.vatPercent],
  );

  return (
    <S.SectorSelectorItem>
      {disabled ? (
        !disabledByParent && data.name
      ) : (
        <Checkbox
          text={data.name}
          value={{
            state: data.isAssigned,
            action: () =>
              updateSectorProp(data.id, 'isAssigned', !data.isAssigned),
          }}
          disabled={disabledByParent}
        />
      )}

      {isService && (!disabledByParent || (disabledByParent && !disabled)) && (
        <InputFieldGroup disabled={disabled}>
          <InputFieldNumber
            id={`unitPriceWithVat-${data.id}`}
            headerId={'unitPriceWithVat'}
            type="currency"
            label={tr['price-with-vat']}
            state={formState('unitPriceWithVat', 'search')}
            decimalPlaces={4}
            disabled={disabled}
            currencySymbol={currencySymbol}
            currencySymbolPlacement={currencySymbolPlacement}
          />

          <InputField
            id={`vatPercent-${data.id}`}
            headerId={'vatPercent'}
            label={tr['vat']}
            state={formState('vatPercent', 'select')}
            // TODO: field data must be linked to /sectors endpoint
            options={[
              { value: 0, label: '-' },
              { value: 0.0, label: '00.00 %' },
              { value: 21.0, label: '21.00 %' },
            ]}
            disabled={disabled}
            placeholder={(0).toLocaleString(locale, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            })}
          />

          <InputFieldNumber
            id={`totalVat-${data.id}`}
            type="currency"
            label={tr['total-vat']}
            state={{
              type: 'search',
              value:
                vatAmount !== undefined
                  ? { value: vatAmount, label: vatAmount }
                  : undefined,
            }}
            decimalPlaces={4}
            disabled
            currencySymbol={currencySymbol}
            currencySymbolPlacement={currencySymbolPlacement}
          />
        </InputFieldGroup>
      )}
    </S.SectorSelectorItem>
  );
};
