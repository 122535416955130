import * as S from '../SVG.styled';

export default ({ color, width, height, checked }) => {
  if (!color) {
    color = 'currentColor';
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 16}
      height={height ? height : 16}
      version="1.1"
      viewBox="0 0 16 16"
      color={color ? color : 'currentColor'}
    >
      <circle cx="8" cy="8" r="7.5" fill="none" stroke={color} />
      {checked && <circle cx="8" cy="8" r="4.5" stroke={color} />}
    </S.StyledSVG>
  );
};
