import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const DiscountColumn = styled.div`
  height: 100%;
  padding: 0 2px;
  display: flex;
  align-items: center;
  span {
    display: block;
    width: 100%;
    background-color: ${colors.bg_grey_1};
    border-radius: 5px;
    min-height: 30px;
    padding: 0 5px;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    color: ${colors.bg_Primary};

    display: flex;
    align-items: center;

    cursor: pointer;
  }
`;
