import React from 'react';
import { StyledSVG } from './SVG.styled';

const CrossBoldSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="6.4"
      height="6.4"
      viewBox="0 0 6.4 6.4"
      color={color ? color : 'currentColor'}
    >
      <path
        id="Path_612"
        data-name="Path 612"
        d="M376.136,2115.782a.47.47,0,0,0-.662,0l-2.4,2.4-2.4-2.4a.468.468,0,0,0-.662.663l2.4,2.4-2.4,2.4a.471.471,0,0,0,.662.669l2.4-2.406,2.4,2.4a.471.471,0,1,0,.662-.669l-2.4-2.392,2.4-2.4A.47.47,0,0,0,376.136,2115.782Z"
        transform="translate(-369.876 -2115.645)"
        // fill="#1c1f50"
      />
    </StyledSVG>
  );
};

export default CrossBoldSVG;
