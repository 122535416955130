import { useState } from 'react';
import Select, { components } from 'react-select';

// style
import * as S from './BasicSelect.styled';

// icons
import { Arrow } from 'icons/dynamic';

// hooks
import { animateClose } from 'common/helpers/animateClose';

const BasicSelect = ({
  id,
  options,
  value,
  onChange,
  label,
  bright,
  warning,
}) => {
  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <Arrow bold width={10} />
    </components.DropdownIndicator>
  );

  // Hooks
  // -- state
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <S.BasicSelect
      menuIsOpen={menuIsOpen}
      bright={bright}
      isWarning={!!warning}
    >
      {label && <span className="select-label">{label}</span>}
      <Select
        id={id}
        classNamePrefix="select"
        value={value}
        isSearchable={false}
        options={options}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => {
          animateClose('select__menu');
          setMenuIsOpen(false);
        }}
        components={{
          DropdownIndicator,
        }}
        onChange={(x) => onChange(x)}
      />
    </S.BasicSelect>
  );
};

export default BasicSelect;
