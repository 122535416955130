// icons
import { Calendar, Ellipsis, MoneySVG } from 'icons/dynamic';
import PinSVG from 'icons/PinSVG';
import TrashcanSVG from 'icons/TrashcanSVG';
import InterchangeSVG from 'icons/bold/InterchangeSVG';

// hooks
import { colors } from 'common/colors/colors';

export const useContextMenuItems = (contents) => {
  const items = [
    {
      id: 'edit',
      label: 'Redaguoti',
      items: [],
    },
    {
      id: 'delete-timeOff',
      icon: <TrashcanSVG />,
      label: 'Ištrinti nedarbo priežastį',
      fontColor: colors.mandyRed,
      onClick: () => {},
    },
    {
      id: 'change-status',
      label: 'Pakeisti būseną',
      icon: <InterchangeSVG />,
      onClick: () => {},
      items: [],
    },
    {
      id: 'functions',
      label: 'Funkcijos',
      icon: <Ellipsis inCircle bold />,
      onClick: () => {},
      items: [],
    },
    {
      id: 'pin',
      label: 'Prisegti',
      icon: <PinSVG />,
      onClick: () => {},
    },
    {
      id: 'individual-scheduler',
      label: 'Individualus kalendorius',
      icon: <Calendar />,
      onClick: () => {},
    },
    {
      id: 'monthly-scheduler',
      label: 'Mėnesio kalendorius',
      icon: <Calendar />,
      onClick: () => {},
    },
    {
      id: 'balance-status',
      label: 'Balansas',
      icon: <MoneySVG bold margin="2px 0 0 0" />,
      onClick: () => {},
    },
  ];

  const generateArray = (array) => {
    return contents.reduce((acc, curr) => {
      const found = items.find((func) => func.id === curr);
      if (found) return [...acc, found];

      return acc;
    }, []);
  };

  return Array.isArray(contents)
    ? generateArray(contents)
    : items.find((func) => func?.id === contents);
};
