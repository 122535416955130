import {
  useState,
  useRef,
  useEffect,
  useReducer,
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// style
import * as S from './DialogLayout.styled';

// icons
import { Ex, BurgerMenu } from 'icons/dynamic';
import GoBackSVG from 'icons/GoBackSVG';

// components
import { TheButton } from 'components/Buttons/TheButton';

export const DialogLayout = ({
  Child,
  childProps,
  headerText,
  getBack,
  exit,
  exitFunc,
  navSticky,
  menuBurger,
  darkTheme,
  footer,
  children,
  modal,
  buttons: presetButtons,
}) => {
  // Hooks
  const navigate = useNavigate();
  const footerRef = useRef(null);

  // buttons' settings reducer
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setup':
        return action.value;
      case 'cleanup':
        return [];
      default:
        let newState = [...state];
        let item = newState?.find((button) => button.id === action.id);

        switch (action.type) {
          case 'cleanup':
            newState = [];
            break;
          case 'show':
            item.show = action.value;
            break;

          case 'add':
            newState.push(action.value);
            break;

          case 'remove':
            newState = state.filter((button) => action.id !== button.id);
            break;

          case 'replace':
            item = action.value;
            break;

          case 'update':
            Object.entries(action.value).forEach(
              (prop) => (item[prop[0]] = prop[1]),
            );
            break;

          default:
        }

        return newState;
    }
  };

  // buttons' state
  const [buttons, dispatch] = useReducer(reducer, presetButtons || []);

  // fix for soft buttons area in safari
  const [vh, setVh] = useState(window.innerHeight * 0.01);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(() => {
      setVh(window.innerHeight * 0.01);
    }, 250);
  };

  const hydratedChildren = Children.map(children, (child) =>
    // checking isValidElement is the safe way and avoids a typescript error too
    isValidElement(child) && typeof child.type === 'function'
      ? cloneElement(child, { setButtons: dispatch }) // FIXME: Throws warning
      : child,
  );

  return (
    <S.DialogLayout innerHeight={vh} navSticky={navSticky}>
      <S.Header navSticky={navSticky} modal={modal}>
        <div className="header__info-container">
          {menuBurger?.state && (
            <div className="header__burger-menu" onClick={menuBurger.toggle}>
              <BurgerMenu bold width={25} />
            </div>
          )}

          {getBack && (
            <TheButton
              className="header__back-button"
              icon={<GoBackSVG />}
              action={getBack}
              transparent
            />
          )}

          <div className="header__title">{headerText}</div>
        </div>

        <S.Buttons exit={exit}>
          {buttons?.map(
            (button, index) =>
              button.show && <TheButton key={index} {...button} />,
          )}

          {(exit || exitFunc) && (
            <TheButton
              className="header__close-button"
              action={() => (exitFunc ? exitFunc() : navigate(-1))}
              icon={<Ex black width={14} height={14} />}
              transparent={!modal}
              outline={modal}
            />
          )}
        </S.Buttons>
      </S.Header>

      <S.Main navSticky={navSticky} innerHeight={vh}>
        <S.Child darkTheme={darkTheme}>
          {Child && (
            <Child
              setButtons={dispatch}
              // closeAction={() => navigate(-1)}
              closeAction={() => (exitFunc ? exitFunc() : navigate(-1))}
              {...childProps}
            />
          )}
          {hydratedChildren}
        </S.Child>

        {footer && <S.Footer ref={footerRef}>{footer && footer}</S.Footer>}
      </S.Main>
    </S.DialogLayout>
  );
};

DialogLayout.propTypes = {
  headerText: PropTypes.string,
  Child: PropTypes.func,
  closeAction: PropTypes.func,
  footer: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default DialogLayout;
