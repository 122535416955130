import { useContext } from 'react';
import { useSelector } from 'react-redux';

// style
import * as S from './TimePanel.styled';

// context
import { UserContext } from 'context/UserContext';
import { ScheduleContext } from 'context/ScheduleContext';

// helpers
import { SCHEDULE_TYPE } from 'common/Constants';

export const TimePanel = ({ scrollToHour, elementRef }) => {
  const { locale } = useContext(UserContext);
  // Hooks
  // -- state
  // -- global
  const startHour = useSelector((state) => state.scheduleReducer.startHour);
  const endHour = useSelector((state) => state.scheduleReducer.endHour);
  const cellDuration = useSelector(
    (state) => state.scheduleReducer.cellDuration,
  );

  const { numberOfDays, type, setScheduleScrollTargetNode } =
    useContext(ScheduleContext);

  const timeCells = [];
  for (let i = startHour; i < endHour; i++) {
    const time = new Date();
    time.setHours(i, 0, 0, 0);

    timeCells.push({
      hour: i,
      string: time.toLocaleString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      }),
    });
  }

  return (
    <S.TimePanel
      cellDuration={60 / cellDuration}
      locale={locale}
      stickyHeader={numberOfDays?.visible > 1}
      ref={elementRef}
    >
      <div className="time__cell-container">
        {timeCells.map((item, i) => (
          <div
            className="time__cell"
            key={i}
            ref={(node) =>
              type !== SCHEDULE_TYPE.PERSONAL &&
              item.hour === scrollToHour &&
              setScheduleScrollTargetNode(node)
            }
          >
            <span>{item.string}</span>
            <div className="time__cell-border" />
          </div>
        ))}
      </div>
    </S.TimePanel>
  );
};

export default TimePanel;
