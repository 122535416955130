import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// style
import * as S from './Services.styled';

// icons
import { ArrowRevert, Check } from 'icons/dynamic';
import ArrowSVG from 'icons/ArrowSVG';

// components
import { TheButton } from 'components/Buttons/TheButton';
import { ServiceCategories } from './ServiceCategories/ServiceCategories';
import { ServiceGroup } from './ServiceGroup/ServiceGroup';
import { CategoryTree } from './ServiceTree/CategoryTree';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useLeavePage } from 'hooks/useLeavePage';
import { useTranslate } from 'hooks/useTranslate';
import { useServicesGetCategories } from 'services/serviceService';

export const Services = ({ disabled, title }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const navigate = useNavigate();
  const tr = useTranslate().use().global;
  const { employeeId } = useParams();

  // get search params
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId'); // current sector tab

  const serviceActivity = true;

  const {
    data: dbData,
    isSuccess,
    refetch,
  } = useServicesGetCategories({
    searchParams: { serviceActivity, employeeId, sectorId },
    queryParams: {
      retry: false,
      staleTime: 1000 * 60 * 60,
    },
  });

  const [categories, setCategories] = useState([]);
  const [activeGroup, setActiveGroup] = useState();

  useEffect(() => {
    isSuccess && setCategories(dbData);
  }, [isSuccess, dbData]);

  const initialCache = useCallback(
    () => ({
      category: [],
      group: [],
      subgroup: [],
      service: [],
    }),
    [],
  );

  // cache of changes
  const [cache, setCache] = useState(initialCache());

  const getDbItem = (array, type, id) => {
    let changedItem;

    switch (type) {
      case 'category':
        changedItem = array.find((category) => category.id === id);
        break;
      case 'group':
        array.some((category) => {
          changedItem = category.groups?.find((group) => group.id === id);
          return changedItem;
        });
        break;
      case 'subgroup':
        changedItem = array.subgroups?.find((subgroup) => subgroup.id === id);
        break;
      case 'service':
        changedItem = array.services?.find((service) => service.id === id);

        if (!changedItem) {
          array.subgroups?.some((subgroup) => {
            changedItem = subgroup.services?.find(
              (service) => service.id === id,
            );
            return changedItem;
          });
        }

        break;
      default:
        return undefined;
    }

    return changedItem;
  };

  const getCachedItem = (type, id) =>
    cache[type].find((item) => item.id === id);

  const getItem = (type, id) =>
    getCachedItem(type, id) || getDbItem(categories, type, id);

  const isCacheEmpty = useCallback(
    () => Object.values(cache).every((value) => value.length === 0),
    [cache],
  );

  // warning upon leaving page if changes are not saved
  useLeavePage(!isCacheEmpty());

  const updateCache = (db, type, id, propId, value) => {
    const changedItem = getCachedItem(type, id);
    const dbItem = getDbItem(db, type, id);

    setCache((old) => {
      const newCache = { ...old };

      if (changedItem) {
        changedItem[propId] = value;

        if (changedItem?.[propId]?.toString() === dbItem[propId].toString()) {
          delete changedItem[propId];

          if (Object.keys(changedItem).length === 1 && 'id' in changedItem) {
            newCache[type] = newCache[type].filter((item) => item.id !== id);
          }
        }
      } else {
        if (value.toString() !== dbItem[propId].toString()) {
          newCache[type].push({ id: dbItem.id, [propId]: value });
        } else return old;
      }

      return newCache;
    });
  };

  const toggleActivity = (db, type, id) => {
    const requiredObj = getCachedItem(type, id)?.hasOwnProperty('isActive')
      ? getCachedItem(type, id).isActive
      : getDbItem(db, type, id).isActive;
    const newValue = !requiredObj;
    updateCache(db, type, id, 'isActive', newValue);
    console.log(`${type} ${id} activity changed to: ${newValue}`);
  };

  const cancelChanges = useCallback(() => {
    refetch();
    setCache(initialCache);
    setActiveGroup({ ...activeGroup });
    console.log('undo changes (refetch from B/E)');
  }, [refetch, activeGroup, initialCache]);

  const confirmChanges = useCallback(() => {
    // TODO: implement service mutations
    console.log('save changes to B/E');
  }, []);

  // |- BUTTONS ->

  // receive props through outlet context
  const { setButtons } = useOutletContext();

  // setup/cleanup header buttons
  useEffect(() => {
    setButtons({
      type: 'setup',
      value: [
        {
          id: 'cancel',
          secondary: true,
          inverted: true,
          outline: true,
          icon: <ArrowRevert bold />,
          ...(!isMobile && { title: tr['cancel'] }),
          action: cancelChanges,
        },
        {
          id: 'save',
          borderless: true,
          icon: <Check bold />,
          ...(!isMobile && { title: tr['save'] }),
          action: confirmChanges,
        },
      ],
    });

    return () => setButtons({ type: 'cleanup' });
  }, [setButtons, isMobile]);

  // show/hide header button according to changes
  useEffect(() => {
    const isChanged = !isCacheEmpty();
    setButtons({ type: 'show', id: 'cancel', value: isChanged });
    setButtons({ type: 'show', id: 'save', value: isChanged });
  }, [setButtons, isCacheEmpty, isMobile]);

  // -> BUTTONS -|

  // log
  useEffect(() => console.log({ categories, cache }), [categories, cache]);

  return (
    <S.Services>
      {isMobile && (
        <S.Controls>
          <TheButton
            secondary
            inverted
            raised
            icon={<ArrowSVG />}
            action={() => navigate(-1)}
          />
          <div className="back-button-text">{title}</div>
        </S.Controls>
      )}

      {!isMobile ? (
        <>
          <S.Section>
            <ServiceCategories
              categories={categories}
              getItem={getItem}
              toggleActivity={(type, id) =>
                toggleActivity(categories, type, id)
              }
              activeGroup={activeGroup}
              setActiveGroup={setActiveGroup}
            />
          </S.Section>

          <S.Section>
            {activeGroup?.id && (
              <ServiceGroup
                serviceActivity={serviceActivity}
                activeGroup={activeGroup}
                getDbItem={getDbItem}
                getCachedItem={getCachedItem}
                toggleActivity={toggleActivity}
                updateCache={updateCache}
              />
            )}
          </S.Section>
        </>
      ) : (
        <CategoryTree
          modal={false}
          db={categories}
          data={{ categories }}
          serviceActivity={serviceActivity}
          toggleActivity={toggleActivity}
          type={'category'}
          contentId="categories"
          cancelChanges={cancelChanges}
          confirmChanges={confirmChanges}
          getDbItem={getDbItem}
          getCachedItem={getCachedItem}
          getItem={getItem}
          isCacheEmpty={isCacheEmpty}
          updateCache={updateCache}
        />
      )}
    </S.Services>
  );
};

export default Services;
