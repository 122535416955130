import StyledSVG from './SVG.styled';

const PhoneSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="15.926"
      height="15.93"
      viewBox="0 0 15.926 15.93"
      color={color ? color : 'currentColor'}
    >
      <g
        id="Layer_2"
        data-name="Layer 2"
        transform="translate(-3365.97 -2419.826)"
      >
        <g
          id="Layer_1-2"
          data-name="Layer 1-2"
          transform="translate(3365.97 2419.826)"
        >
          <path
            id="Path_645"
            data-name="Path 645"
            d="M3377.488,2435.755a5.859,5.859,0,0,1-1.718-.27,18.68,18.68,0,0,1-9.53-9.532,4.747,4.747,0,0,1,.667-4.478,4.441,4.441,0,0,1,3.161-1.65h.048a2.406,2.406,0,0,1,1.7.722,4.738,4.738,0,0,1,.927,1.323,9.1,9.1,0,0,1,.646,1.761,1.039,1.039,0,0,1-.275.966l-.783.8a12.413,12.413,0,0,0,4,4l.784-.784.024-.022a1.04,1.04,0,0,1,.945-.253,9.212,9.212,0,0,1,1.762.647,4.733,4.733,0,0,1,1.323.927,2.358,2.358,0,0,1,.719,1.794,4.391,4.391,0,0,1-1.646,3.116A4.342,4.342,0,0,1,3377.488,2435.755Zm-7.355-14.339a2.863,2.863,0,0,0-1.984,1.053,3.123,3.123,0,0,0-.388,3.022,17.316,17.316,0,0,0,8.475,8.472,3.129,3.129,0,0,0,3.022-.388,2.818,2.818,0,0,0,1.053-1.966.819.819,0,0,0-.277-.6,3.246,3.246,0,0,0-.888-.606,7.42,7.42,0,0,0-1.126-.441l-.974.972a.8.8,0,0,1-.911.159,13.364,13.364,0,0,1-5.5-5.5.8.8,0,0,1,.159-.911l.974-.972a7.5,7.5,0,0,0-.441-1.128,3.207,3.207,0,0,0-.606-.886A.85.85,0,0,0,3370.132,2421.417Zm1.86,2.068"
            transform="translate(-3365.97 -2419.826)"
            // fill="#0f1d5b"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default PhoneSVG;
