import * as S from '../SVG.styled';

const StickyColumnShowSVG = ({ color, width, height, sm, lg }) => {
  let content;
  let size;

  switch (true) {
    case sm:
      size = 16;
      content = (
        <>
          <path d="m15.523 11.085c-0.98519-1.1017-2.5482-2.4166-4.3936-2.4166-1.8454 0-3.4084 1.3149-4.3936 2.4166-0.63623 0.71115-0.63623 1.7852 0 2.498 0.98519 1.1033 2.5482 2.4166 4.3936 2.4166 1.8454 0 3.4084-1.3149 4.3936-2.4166 0.63624-0.71115 0.63624-1.7852 0-2.498zm-0.96734 1.6273c-0.6622 0.74044-1.9899 1.9837-3.4279 1.9837-1.438 0-2.764-1.2433-3.4263-1.9837-0.19314-0.21644-0.19314-0.54353 0-0.75997 0.6622-0.74044 1.9882-1.9837 3.4263-1.9837 1.438 0 2.764 1.2433 3.4279 1.9837 0.19314 0.21644 0.19314 0.5419 0 0.75834z" />
          <ellipse cx="11.129" cy="12.334" rx="1.8892" ry="1.8942" />
          <path d="m1.5273 3.5834h12.874c0.84236 0 1.5273-0.68674 1.5273-1.5313v-0.52075c0-0.84459-0.68493-1.5313-1.5273-1.5313h-12.874c-0.84236 0.0016273-1.5273 0.68836-1.5273 1.533v0.52075c0 0.84459 0.68493 1.5313 1.5273 1.5313zm-0.22885-2.0521c0-0.12693 0.10225-0.22945 0.22885-0.22945h12.874c0.1266 0 0.22885 0.10252 0.22885 0.22945v0.52075c0 0.12693-0.10225 0.22945-0.22885 0.22945h-12.874c-0.1266 0-0.22885-0.10252-0.22885-0.22945z" />
          <path d="m14.401 4.3352h-12.876c-0.84074 0-1.5257 0.68674-1.5257 1.5313v0.52075c0 0.84459 0.68493 1.5313 1.5273 1.5313h12.874c0.84236 0 1.5273-0.68674 1.5273-1.5313v-0.52075c0-0.84459-0.68493-1.5313-1.5273-1.5313zm0.22885 2.0504c0 0.12693-0.10225 0.22945-0.22885 0.22945h-12.876c-0.1266 0-0.22885-0.10252-0.22885-0.22945v-0.52075c0-0.12693 0.10225-0.22945 0.22885-0.22945h12.876c0.1266 0 0.22885 0.10252 0.22885 0.22945z" />
          <path d="m4.5997 8.6688h-3.0724c-0.84236 0-1.5273 0.68674-1.5273 1.5313v0.52075c0 0.84459 0.68493 1.5313 1.5273 1.5313h3.0724c0.84236 0 1.5273-0.68674 1.5273-1.5313v-0.52075c0-0.84459-0.68493-1.5313-1.5273-1.5313zm0.22885 2.0504c0 0.12693-0.10225 0.22946-0.22885 0.22946h-3.0724c-0.1266 0-0.22885-0.10252-0.22885-0.22946v-0.52075c0-0.12693 0.10225-0.22945 0.22885-0.22945h3.0724c0.1266 0 0.22885 0.10252 0.22885 0.22945z" />
        </>
      );
      break;
    case lg:
    default:
      size = 30;
      content = (
        <>
          <path d="m29.234 21.395c-1.8183-2.0325-4.7111-4.4716-8.0608-4.4716-3.3498 0-6.2331 2.4391-8.064 4.4716a2.9842 2.9842 0 0 0 0 3.9926c1.8309 2.0388 4.7111 4.4716 8.064 4.4716s6.2426-2.4328 8.0608-4.4716a2.9842 2.9842 0 0 0 0-3.9926zm-0.94537 3.1512c-1.355 1.5126-4.0966 4.0525-7.1218 4.0525s-5.7668-2.5399-7.1249-4.0525a1.73 1.73 0 0 1 0-2.3099c1.3582-1.5126 4.0966-4.0525 7.1249-4.0525s5.7668 2.5399 7.1218 4.0525a1.7237 1.7237 0 0 1 0.0063 2.3099z" />
          <path d="m21.173 20.356a3.0378 3.0378 0 1 0 3.0346 3.0346 3.0378 3.0378 0 0 0-3.0346-3.0346z" />
          <path d="m2.7164 6.8367h24.624a2.521 2.521 0 0 0 2.521-2.521v-1.6544a2.521 2.521 0 0 0-2.521-2.521h-24.624a2.521 2.521 0 0 0-2.521 2.521v1.6544a2.521 2.521 0 0 0 2.521 2.521zm-1.2605-4.1754a1.2605 1.2605 0 0 1 1.2605-1.2605h24.624a1.2605 1.2605 0 0 1 1.2605 1.2605v1.6544a1.2605 1.2605 0 0 1-1.2605 1.2605h-24.624a1.2605 1.2605 0 0 1-1.2605-1.2605z" />
          <path d="m2.7164 15.342h24.624a2.521 2.521 0 0 0 2.521-2.521v-1.6512a2.521 2.521 0 0 0-2.521-2.521h-24.624a2.521 2.521 0 0 0-2.521 2.521v1.6544a2.521 2.521 0 0 0 2.521 2.5178zm-1.2605-4.1722a1.2605 1.2605 0 0 1 1.2605-1.2605h24.624a1.2605 1.2605 0 0 1 1.2605 1.2605v1.6544a1.2605 1.2605 0 0 1-1.2605 1.2605h-24.624a1.2605 1.2605 0 0 1-1.2605-1.2605z" />
          <path d="m9.0598 17.157h-6.5388a2.521 2.521 0 0 0-2.521 2.521v1.6544a2.521 2.521 0 0 0 2.521 2.521h6.5388a2.521 2.521 0 0 0 2.521-2.521v-1.6544a2.521 2.521 0 0 0-2.521-2.521zm1.2605 4.1754a1.2605 1.2605 0 0 1-1.2605 1.2605h-6.5388a1.2605 1.2605 0 0 1-1.2605-1.2605v-1.6544a1.2605 1.2605 0 0 1 1.2605-1.2605h6.5388a1.2605 1.2605 0 0 1 1.2605 1.2605z" />
        </>
      );
      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : size}
      height={height ? height : size}
      version="1.1"
      viewBox={`0 0 ${size} ${size}`}
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default StickyColumnShowSVG;
