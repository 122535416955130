import * as S from '../SVG.styled';

export default ({ color, width, height }) => {
  const content = (
    <g>
      <path d="m19.136 26.106h-7.9669a0.63735 0.63735 0 0 1-0.63735-0.63735v-1.4563a0.63735 0.63735 0 0 1 0.49394-0.62142l0.79031-0.18164c0.01912-1.383 0.02868-2.8394 0.02868-3.7317v-1.3703c0-1.3289-0.01912-2.1479-0.06055-3.0465l-1.0357-0.17527a0.63735 0.63735 0 0 1-0.53219-0.63735v-1.16a0.63735 0.63735 0 0 1 0.4557-0.61186l6.6284-1.9598a0.63735 0.63735 0 0 1 0.51625 0.07011l0.51306 0.31867a0.63735 0.63735 0 0 1 0.30274 0.55449l-0.08604 4.2384v3.7795c0 0.90504 0 2.3741 0.02868 3.7667l0.69152 0.1434a0.63735 0.63735 0 0 1 0.50669 0.63735v1.4563a0.63735 0.63735 0 0 1-0.63735 0.6246zm-7.3295-1.2747h6.6922v-0.29318l-0.68196-0.1434a0.63735 0.63735 0 0 1-0.50669-0.61185c-0.02868-1.5392-0.03824-3.2792-0.03824-4.2989v-3.7986l0.07967-3.8623-5.8509 1.74v0.15615l1.0102 0.1689a0.63735 0.63735 0 0 1 0.53219 0.59592c0.05417 1.1217 0.08604 2.03 0.08604 3.6233v1.3703c0 1.3225-0.01593 2.9924-0.03824 4.2511a0.63735 0.63735 0 0 1-0.49395 0.60867l-0.78075 0.18164z" />
      <path d="m15.143 10.073c-1.8292 0-3.26-1.3576-3.26-3.088s1.4308-3.0911 3.26-3.0911c1.8292 0 3.2632 1.3576 3.2632 3.0911 0 1.7336-1.434 3.088-3.2632 3.088zm0-4.9044c-1.1313 0-1.9853 0.78075-1.9853 1.8164s0.85405 1.8133 1.9853 1.8133 1.9885-0.78075 1.9885-1.8133c0-1.0325-0.85405-1.8164-1.9885-1.8164z" />
      <path d="m15 30a15 15 0 1 1 15-15 15.016 15.016 0 0 1-15 15zm0-28.725a13.725 13.725 0 1 0 13.725 13.725 13.741 13.741 0 0 0-13.725-13.725z" />
    </g>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 20}
      height={height ? height : 20}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
