import * as S from '../SVG.styled';

const SendSVG = ({ color, width, height, sm, lg }) => {
  let content;
  let size;

  switch (true) {
    case sm:
      size = 16;
      content = (
        <path d="m15.615 0.12515c-0.25631-0.15976-0.58086-0.16628-0.84549-0.021192l-14.348 7.8983c-0.27795 0.15324-0.44272 0.45482-0.41941 0.76782 0.024965 0.313 0.23301 0.58524 0.53259 0.69609l2.5531 0.94062c0.34285 0.12552 0.72732-0.04402 0.85714-0.38147 0.12982-0.33745-0.044937-0.7124-0.38946-0.83955l-1.5395-0.56731 12.591-6.93-0.86546 12.52-7.0219-4.0934 6.2047-5.0732c0.28294-0.23149 0.31955-0.6423 0.084881-0.9178-0.23634-0.2755-0.65575-0.313-0.93702-0.08314l-6.7606 5.528c-0.01165 0.00978-0.021637 0.019562-0.033287 0.029344-0.18807 0.17606-0.27961 0.42222-0.25132 0.67653 0.00666 0.05217 0.018308 0.1027 0.033287 0.15161l0.94368 4.9297c0.066574 0.30648 0.31123 0.55101 0.62246 0.62274 0.061581 0.013042 0.12316 0.021193 0.18474 0.021193 0.25132 0 0.49597-0.11248 0.65409-0.313l1.9639-2.4714 4.3356 2.5268c0.13148 0.07662 0.27628 0.11411 0.42274 0.11411 0.13148 0 0.26297-0.03097 0.38446-0.09292 0.25631-0.13205 0.42108-0.37984 0.44105-0.66186l0.98529-14.238c0.019972-0.2967-0.12649-0.58035-0.3828-0.74011zm-8.5115 13.894-0.46602-2.4323 1.6394 0.9553z" />
      );
      break;
    case lg:
    default:
      size = 30;
      content = (
        <path d="m29.238 0.14762c-0.28674-0.19116-0.66905-0.19116-0.95579-0.03186l-27.495 15.42c-0.44603 0.25488-0.60533 0.86021-0.35046 1.3062 0.09558 0.19116 0.28674 0.3186 0.47789 0.41417l4.9064 1.8479c0.35046 0.09558 0.70091-0.09558 0.79649-0.41417 0.09558-0.3186-0.06372-0.63719-0.35045-0.76463l-4.2373-1.593 26.316-14.815-1.816 26.698-15.133-9.0162 14.687-14.018c0.25488-0.25488 0.25488-0.63719 0.03186-0.89207-0.22302-0.25488-0.63719-0.25488-0.89207-0.03186l-15.006 14.305c-0.22302 0.19116-0.3186 0.50975-0.28674 0.79649 0 0.06372 0.03186 0.1593 0.06372 0.22302l1.7841 9.6534c0.06372 0.35045 0.35046 0.63719 0.70091 0.73277 0.06372 0.03186 0.1593 0.03186 0.22302 0.03186 0.28674 0 0.57347-0.12744 0.76463-0.35046l4.1099-5.2887 8.7932 5.225c0.44603 0.25488 1.0514 0.09558 1.3062-0.35045 0.06372-0.12744 0.09558-0.25488 0.12744-0.38232l1.8797-27.813c0-0.38231-0.1593-0.73277-0.44603-0.89207zm-16.344 28.1-1.4018-7.5188 4.9701 2.9629z" />
      );
      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : size}
      height={height ? height : size}
      version="1.1"
      viewBox={`0 0 ${size} ${size}`}
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default SendSVG;
