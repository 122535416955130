import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const TrashcanSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="14.021"
      height="14.327"
      viewBox="0 0 14.021 14.327"
      color={color ? color : 'currentColor'}
    >
      <g
        id="Group_2373"
        data-name="Group 2373"
        transform="translate(-1744 -257)"
      >
        <g id="Layer_1-2" data-name="Layer 1-2" transform="translate(1744 257)">
          <path
            id="Path_383"
            data-name="Path 383"
            d="M1102.017,2052.343a.306.306,0,0,0-.307.306v7.738a.267.267,0,0,1-.267.267h-8.878a.267.267,0,0,1-.273-.261s0,0,0-.006v-7.738a.307.307,0,0,0-.613,0v7.738a.88.88,0,0,0,.88.879h8.884a.88.88,0,0,0,.88-.879v-7.738A.306.306,0,0,0,1102.017,2052.343Z"
            transform="translate(-1089.994 -2046.939)"
            // fill="#e8e8e8"
          />
          <path
            id="Path_384"
            data-name="Path 384"
            d="M1108.683,2065.48v-4.291a.307.307,0,1,0-.613,0v4.291a.307.307,0,1,0,.613,0Z"
            transform="translate(-1103.872 -2054.17)"
            // fill="#e8e8e8"
          />
          <path
            id="Path_385"
            data-name="Path 385"
            d="M1125.033,2065.48v-4.291a.307.307,0,1,0-.613,0v4.291a.307.307,0,1,0,.613,0Z"
            transform="translate(-1117.717 -2054.17)"
            // fill="#e8e8e8"
          />
          <path
            id="Path_386"
            data-name="Path 386"
            d="M1141.393,2065.48v-4.291a.306.306,0,1,0-.613,0v4.291a.306.306,0,1,0,.613,0Z"
            transform="translate(-1131.57 -2054.17)"
            // fill="#e8e8e8"
          />
          <path
            id="Path_387"
            data-name="Path 387"
            d="M1094.394,2021.441h-1.379v-1.192a.881.881,0,0,0-.88-.881h-2.09a2.36,2.36,0,0,0-2.354-2.285h0a2.36,2.36,0,0,0-2.356,2.285h-2.084a.882.882,0,0,0-.886.877v1.2h-1.379a.307.307,0,0,0,0,.613h13.408a.307.307,0,0,0,0-.613Zm-6.7-3.745a1.747,1.747,0,0,1,1.742,1.672h-3.486A1.747,1.747,0,0,1,1087.691,2017.7Zm-4.713,2.553a.267.267,0,0,1,.265-.268h8.886a.267.267,0,0,1,.267.267h0v1.192h-9.417Z"
            transform="translate(-1080.68 -2017.083)"
            // fill="#e8e8e8"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default TrashcanSVG;
