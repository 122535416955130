import { useCallback, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';

// style
import * as S from './InputValue.styled';

export const InputValue = ({
  type,
  units,
  defaultValue,
  value,
  updateValue,
  disabled,
  tooltipTitle,
  tooltipContents,
  ...props
}) => {
  const format = useCallback(
    (value) => {
      switch (type) {
        case 'number':
          return Number.parseFloat(value).toFixed(2);
        default:
          return value;
      }
    },
    [type],
  );

  const [localValue, setLocalValue] = useState(
    format(value) || format(defaultValue),
  );

  useEffect(() => {
    setLocalValue(format(value) || format(defaultValue));
  }, [value, defaultValue, format]);

  const isNumber = type === 'number';
  const changed = () =>
    localValue !== undefined &&
    (isNumber ? +localValue !== +defaultValue : localValue !== defaultValue);

  const updateLocalValue = (e) => setLocalValue(e.target.value);

  const updateParentValue = (scopedValue) => {
    if (changed()) {
      const updatedValue = scopedValue?.target?.value
        ? scopedValue?.target?.value
        : scopedValue || localValue;
      setLocalValue(format(updatedValue));
      updateValue(format(updatedValue));
    }
  };

  return (
    <S.InputValue
      className={classNames(props.className, changed() && 'changed')}
      isNumber={isNumber}
      data-tooltip-id="tooltip"
      data-tooltip-html={
        changed()
          ? ReactDOMServer.renderToStaticMarkup(
              <div className="centered">
                {tooltipTitle}
                {tooltipTitle && tooltipContents && <br />}
                {tooltipContents}
              </div>,
            )
          : null
      }
    >
      <input
        type={type}
        {...(isNumber && {
          min: '0.00',
          max: '999999.99',
          step: '0.01',
          inputMode: 'numeric',
        })}
        value={localValue}
        onChange={updateLocalValue}
        onBlur={updateParentValue}
        disabled={disabled}
      />

      {units}
      <div className="reset" onClick={() => updateParentValue(defaultValue)}>
        &ndash;
      </div>
    </S.InputValue>
  );
};

export default InputValue;
