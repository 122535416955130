import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const WeekViewSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="15.993"
      height="13.346"
      viewBox="0 0 15.993 13.346"
      color={color}
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(122 100)">
        <g
          id="Layer_1-2"
          data-name="Layer 1-2"
          transform="translate(-122 -100)"
        >
          <path
            id="Path_447"
            data-name="Path 447"
            d="M14.616,0H1.377A1.377,1.377,0,0,0,0,1.377V11.969a1.377,1.377,0,0,0,1.377,1.377H14.616a1.377,1.377,0,0,0,1.377-1.377V1.377A1.377,1.377,0,0,0,14.616,0ZM5.005,12.657H1.377a.688.688,0,0,1-.688-.688V1.377A.688.688,0,0,1,1.377.688H5.005Zm5.294,0H5.694V.688H10.3Zm5.005-.688a.688.688,0,0,1-.688.688H10.988V.688h3.628a.688.688,0,0,1,.688.688Z"
            // fill="#0f1d5b"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default WeekViewSVG;
