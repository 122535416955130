// style
import * as S from './SetList.styled';

// icons
import { Ex } from 'icons/dynamic';
import CubeSVG from 'icons/CubeSVG';

// components
import { SetListItem } from './SetListItem/SetListItem';
import { TheButton } from 'components/Buttons/TheButton';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const SetList = ({
  close,
  breadCrumbs,
  name,
  selectedServices,
  save,
}) => {
  const tr = useTranslate().use().global;

  // Functions
  const removeService = (id) => {
    selectedServices.setState((prev) => prev.filter((item) => item.id !== id));
  };

  const updateService = (id, obj) => {
    selectedServices.setState((prev) => {
      return prev.map((item) => {
        if (item.id !== id) return item;

        return { ...item, ...obj };
      });
    });
  };

  const updateQuantity = (id, quan) => {
    updateService(id, {
      compServiceCount: +quan,
    });
  };

  const handleSaveButton = () => {
    selectedServices.state.forEach((service) => {
      if (isNaN(service.compServiceCount)) service.compServiceCount = 1;
    });
    save(selectedServices.state);
    close();
  };

  return (
    <S.SetList>
      <div className="list__header">
        <div className="list__title-wrapper">
          <div className="list__setIcon-wrapper">
            <CubeSVG />
          </div>

          <h2>{name}</h2>
        </div>

        <div className="list__exitIcon-wrapper" onClick={close}>
          <Ex black width={12} height={12} />
        </div>
      </div>

      <div className="list__path-wrapper">
        {breadCrumbs.map((crumb, index) => {
          const last = breadCrumbs.length - 1 !== index;
          return (
            <div key={index} className={!last ? 'last' : ''}>
              {crumb} {last && ' | '}
            </div>
          );
        })}
      </div>

      <div className="list__list-wrapper">
        <div className="list__label-container">
          <span>{tr['services']}</span>
          <span>{`${tr['amount']} ${tr['pcs']}`}</span>
        </div>

        <div className="list__list-container">
          {selectedServices.state.length ? (
            selectedServices.state.map((service) => (
              <SetListItem
                service={service}
                id={service.id}
                key={service.id}
                name={service.name}
                parentName={
                  service.subgroupName ||
                  service.groupName ||
                  service.categoryName
                }
                durationInMinutes={service.baseDurationInMinutes}
                price={service.avgUnitPriceWithVat}
                count={service.compServiceCount}
                deleteAction={() => removeService(service.id)}
                updateQuantity={updateQuantity}
                hasComp={service.hasComp} // or should i pass composition array?
                {...(service.unitId !== undefined &&
                  service.unitVolume !== undefined && {
                    unit: { id: service.unitId, volume: service.unitVolume },
                  })}
              />
            ))
          ) : (
            <div className="list__list-container-empty">
              {tr['empty-fill-in']}
            </div>
          )}
        </div>
      </div>

      <div className="list__save-button">
        <TheButton title={tr['save']} primary action={handleSaveButton} />
      </div>
    </S.SetList>
  );
};

export default SetList;
