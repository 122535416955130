import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';

// style
import * as S from './ServiceCard.styled';

// icons
import { Arrow } from 'icons/dynamic';
import DashWithBordersSVG from 'icons/DashWithBordersSVG';
import CubeSVG from 'icons/CubeSVG';

// components
import CustomSelect from 'components/DialogLayout/CustomSelect/CustomSelect';
import TitleAndValueChild from 'components/DialogLayout/CustomSelect/children/TitleAndValueChild/TitleAndValueChild';
import ServiceCardComposition from './ServiceCardComposition/ServiceCardComposition';
import { FlashMessage } from 'components/FlashMessage/FlashMessage';

// hooks
import useNumber from 'hooks/useNumber';
import { useTranslate } from 'hooks/useTranslate';
import { useSystemGetUnits } from 'services/systemService';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';
import { FLASH_MESSAGE_TYPE } from 'common/Constants';
import { vatSelect } from '../../OrderTable/partials/OrderRowEntries/OrderRowEntrie/OrderRowEntrie';

const ServiceCard = ({
  service,
  deleteService,
  openModal,
  setModalContent,
  opened,
  updateServiceKey,
}) => {
  const tr = useTranslate().use().global;
  const { getUnitsById } = useSystemGetUnits();
  const { formatPrice, formatUnits } = useNumber({ price: true });
  const [quantity, setQuantity] = useState(1);
  const [compositionIsOpened, setCompositionIsOpened] = useState(false);

  const composition = !!service.composition?.length;
  const unitShortName = getUnitsById(service.unitId)?.label;

  // -- side effect
  useEffect(() => {
    if (!service.quantity && !service.compServiceCount) return;

    setQuantity(service.quantity || service.compServiceCount);
  }, [service.quantity, service.compServiceCount]);

  // Functions
  const handleAmountChange = (e) => {
    let input = e.target.value;

    const validateDecimal = (value) => {
      const RE = /^\d*\.?\d{0,2}$/;
      return RE.test(value);
    };

    if (input < 0 || input > 10000) return;
    if (!validateDecimal(input)) return;

    setQuantity(input);
  };

  const handleAmountBlur = (e) => {
    if (+quantity <= 0 || quantity === '') {
      setQuantity(1);
      updateServiceKey('quantity', 1, service.index);
    } else {
      updateServiceKey('quantity', quantity, service.index);
    }
  };

  const openSelection = (prompt, flashMessages) => {
    setModalContent({ content: prompt, flashMessages });
    opened.setOpenedProduct(service);
    openModal();
  };

  const titleDanger = service?.canBeSold === false;
  const durationDanger =
    service?.unitDurationInMinutesNorm !== undefined &&
    service?.unitDurationInMinutes !== service.unitDurationInMinutesNorm;
  const vatDanger =
    service?.vatPercentNorm !== undefined &&
    service?.vatPercent !== service.vatPercentNorm;
  const priceDanger =
    service?.unitPriceWithVatNorm !== undefined &&
    service?.unitPriceWithVat !== service.unitPriceWithVatNorm;

  const vatPercentNormLabel =
    vatSelect.find(
      (vatItem) => vatItem.value === (service.vatPercentNorm ?? null),
    )?.label ?? '-';

  return (
    <S.StyledServiceCard compositionIsOpened={compositionIsOpened}>
      <div className="serviceHeader">
        <div className="deleteAndPath">
          <div
            className="productPath"
            // onClick={() => setCompositionIsOpened((prev) => !prev)}
          >
            {composition && (
              <div className={`comp_icon${titleDanger ? ' danger' : ''}`}>
                <CubeSVG />
              </div>
            )}
            <span>{service.parentTitle && service.parentTitle + ' / '} </span>
            <span
              className={titleDanger ? 'danger' : ''}
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                titleDanger ? tr['service-not-provided'] : '',
              )}
            >
              {service.name}
            </span>
            {/* {composition && (
              <div className="comp_arrow">
                <Arrow bold width={16} height={16} />
              </div>
            )} */}
          </div>
        </div>
        <div className="deleteBtn" onClick={() => deleteService(service.index)}>
          <DashWithBordersSVG />
        </div>
      </div>
      {composition && (
        <div className="comp_container">
          <div className="inner">
            <ServiceCardComposition composition={service.composition} />
          </div>
        </div>
      )}
      <div className="serviceInfoForm">
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild title={`${tr['duration']}/${tr['volume']}`}>
              {service?.unitId !== null && service?.unitId !== undefined ? (
                formatUnits(service.unitVolume) +
                (!!unitShortName && ` ${unitShortName}`)
              ) : (
                <span
                  className={durationDanger ? 'danger' : ''}
                  data-tooltip-id="tooltip"
                  data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    durationDanger ? (
                      <>
                        {`${tr['current-duration']}: ${getTimeFromMinutes(service.unitDurationInMinutesNorm)}`}
                      </>
                    ) : (
                      ''
                    ),
                  )}
                >
                  {getTimeFromMinutes(service.unitDurationInMinutes)}
                </span>
              )}
            </TitleAndValueChild>
            <TitleAndValueChild
              title={tr.sms}
              editable
              arrow
              action={() => openSelection('select-sms')}
            >
              {service.sms.label}
            </TitleAndValueChild>
          </div>
        </CustomSelect>
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild warning={priceDanger} title={tr.unitPrice}>
              <span
                data-tooltip-id="tooltip"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  priceDanger ? (
                    <>
                      {`${tr['current-price']}: ${
                        service?.unitPriceWithVatNorm === undefined
                          ? '-'
                          : formatPrice(service?.unitPriceWithVatNorm, true)
                      }`}
                    </>
                  ) : (
                    ''
                  ),
                )}
              >
                {formatPrice(service.unitPriceWithVat, true)}
              </span>
            </TitleAndValueChild>
            <TitleAndValueChild title={tr.amount} editable>
              <input
                type="number"
                value={quantity}
                onChange={handleAmountChange}
                onBlur={handleAmountBlur}
                onFocus={(e) => e.target.select()}
              />
            </TitleAndValueChild>
          </div>
        </CustomSelect>
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild
              warning={vatDanger}
              title={tr.vatRate}
              // children={service.vat.label}
              editable
              arrow
              action={() =>
                openSelection(
                  'select-vat',
                  vatDanger && (
                    <FlashMessage type={FLASH_MESSAGE_TYPE.WARNING}>
                      {tr['current-vat']}: {vatPercentNormLabel}
                    </FlashMessage>
                  ),
                )
              }
            >
              {service.vat.label}
            </TitleAndValueChild>
          </div>
        </CustomSelect>
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild
              title={tr.discount}
              editable
              arrow
              action={() => openSelection('select-discount')}
            >
              {formatPrice(service.discountWithVat)}
            </TitleAndValueChild>
          </div>
        </CustomSelect>
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild
              title={tr.finalPrice}
              // children={formatPrice(service?.finalAmountWithVat || 0)}
              bold
            >
              {formatPrice(service?.finalAmountWithVat || 0)}
            </TitleAndValueChild>
          </div>
        </CustomSelect>
      </div>
    </S.StyledServiceCard>
  );
};

export default ServiceCard;
