import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// style
import * as S from './SelectProfessionals.styled';

// icons
import CreateNewBoldSVG from 'icons/CreateNewBoldSVG';

// component
import { Loader } from 'components/Loader/Loader';
import { SelectProfessionalsHeads } from './SelectProfessionals_heads/SelectProfessionalsHeads';
import { TheButton } from 'components/Buttons/TheButton';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { DebouncedSearch } from 'components/DebouncedSearch/DebouncedSearch';

// context
import { MainContext } from 'pages/Main/Main';

// hook
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSearchEmployees } from 'hooks/useSearchEmployees';
import { fillImgPath } from 'common/helpers/imagesFunctions';
import { useTranslate } from 'hooks/useTranslate';

export const SelectProfessionals = ({
  select,
  selectedProf,
  multi,
  calendarView,
  checkDay,
  searchInput = '',
  sectorId,
  confirmationText,
}) => {
  const tr = useTranslate().use().global;
  const location = useLocation();
  const multiSelect = location.state?.multi === 'multi-selects' || multi;

  const isMobile = useMediaQuery('(max-width: 960px)');

  const [selected, setSelected] = useState([]);
  const [displayArr, setDisplayArr] = useState([]);
  const [searchQuery, setSearchQuery] = useState();

  useEffect(() => setSearchQuery(searchInput), [searchInput, setSearchQuery]);

  const [displayOnlyWorkingEmployees, setDisplayOnlyWorkingEmployees] =
    useState(true);

  const employeesBE = useSearchEmployees({
    sectorId,
    calendarView,
    searchQuery,
    checkDay,
    displayOnlyWorkingEmployees,
    setDisplayArr,
  });

  useEffect(() => {
    if (!employeesBE.data) return;

    const newArr = [];

    selectedProf.forEach((item) => {
      const find = employeesBE.data.employees.find((element) => {
        return element.id === item;
      });

      if (find) newArr.push(find);
    });

    setSelected(newArr);
  }, [employeesBE.data, selectedProf]);

  // Functions
  const selectProfessional = (selectedProfessional) => {
    if (multiSelect) {
      let exist = selected.find((obj) => obj.id === selectedProfessional.id);
      if (exist) {
        setSelected((prev) => prev.filter((item) => item.id !== exist.id));
      } else {
        if (selected.length === 3) return;

        setSelected((prev) => [...prev, selectedProfessional]);
      }
    } else {
      select({ ...selectedProfessional });
    }
  };

  const checkForToggled = (item) => {
    let exist = selected.filter((obj) => obj?.id === item.id);
    return exist.length;
  };

  return (
    <S.Wrapper>
      {!isMobile && (
        <DebouncedSearch autofocus raised setSearchQuery={setSearchQuery} />
      )}

      <S.SelectProfessionals selectedFooter={selected.length}>
        {calendarView !== 'i' && (
          <div className="checkBox">
            <Checkbox
              text="Dirba"
              value={{
                state: displayOnlyWorkingEmployees,
                action: () => setDisplayOnlyWorkingEmployees((prev) => !prev),
              }}
            />
            <Checkbox
              text="Nedirba"
              value={{
                state: !displayOnlyWorkingEmployees,
                action: () => setDisplayOnlyWorkingEmployees((prev) => !prev),
              }}
            />
          </div>
        )}

        {!employeesBE.isFetching ? (
          <S.SelectProfessionalsContainer grid={!!displayArr.length}>
            {displayArr.length ? (
              displayArr.map((item) => (
                <SelectProfessionalsHeads
                  grayedOut={!item?.isWorking}
                  key={item?.id}
                  pic={fillImgPath(item?.photoUrl)}
                  fullName={`${item.firstName} ${item.lastName}`}
                  currentProfessional={checkForToggled(item)}
                  action={() => {
                    selectProfessional(item);
                  }}
                />
              ))
            ) : (
              <p className="empty-message">{tr['empty']}</p>
            )}
          </S.SelectProfessionalsContainer>
        ) : (
          <div className="employee__loader">
            <Loader />
          </div>
        )}

        {employeesBE.data?.hasMore && (
          <p className="has_more">{tr['has-more']}</p>
        )}
      </S.SelectProfessionals>

      {multiSelect ? (
        <S.Footer>
          <div className="selected-container">
            {selected.map((item, i) => (
              <SelectProfessionalsHeads
                key={item?.id}
                pic={fillImgPath(item?.photoUrl)}
                fullName={`${item.firstName} ${item.lastName}`}
                currentProfessional={selected.id === item?.id}
                deleteAction={() => {
                  selectProfessional(item);
                }}
                small
              />
            ))}
          </div>

          <TheButton
            title={confirmationText ? confirmationText : tr['create-order']}
            Icon={confirmationText ? null : CreateNewBoldSVG}
            action={() => select(selected)}
            primary
            raised
          />
        </S.Footer>
      ) : null}
    </S.Wrapper>
  );
};

SelectProfessionals.propTypes = {
  select: PropTypes.func.isRequired,
  close: PropTypes.func,
  currentlySelected: PropTypes.array,
};

export default SelectProfessionals;
