import React from 'react';
import { StyledSVG } from './SVG.styled';

const PaperPlaneSVG = ({ color }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="15.672"
      height="16"
      viewBox="0 0 15.672 16"
      color={color ? color : 'currentColor'}
    >
      <g
        id="Layer_1-2"
        data-name="Layer 1-2"
        transform="translate(0.003 -0.002)"
      >
        <path
          id="Path_926"
          data-name="Path 926"
          d="M15.292.127a.807.807,0,0,0-.828-.021L.41,8A.81.81,0,0,0,.521,9.468l2.5.941a.652.652,0,1,0,.458-1.221L1.972,8.621,14.3,1.691l-.848,12.52L6.578,10.117l6.077-5.073a.652.652,0,1,0-.835-1L5.2,9.571,5.167,9.6a.807.807,0,0,0-.246.677.928.928,0,0,0,.033.152l.924,4.93a.818.818,0,0,0,.61.623A.856.856,0,0,0,6.668,16a.811.811,0,0,0,.641-.313l1.924-2.471,4.247,2.527a.805.805,0,0,0,.414.114A.811.811,0,0,0,14.7,15.1L15.667.866a.813.813,0,0,0-.375-.74ZM6.955,14.022,6.5,11.589l1.606.955Z"
          transform="translate(0 0)"
          //   fill="#fff"
        />
      </g>
    </StyledSVG>
  );
};

export default PaperPlaneSVG;
