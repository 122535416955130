import * as S from '../SVG.styled';

export default ({ color, width, height, bold, margin }) => {
  const content = bold ? (
    <>
      <path d="m13.527 11.336c0.70613 0 1.397 0.25983 1.9931 0.74893 0.52272 0.42796 1.2931 0.35154 1.721-0.16813 0.42796-0.52272 0.35154-1.2931-0.16813-1.721-1.0393-0.85286-2.2651-1.3053-3.546-1.3053-2.5983 0-4.8237 1.8861-5.7194 4.5425h-0.57163c-0.67557 0-1.2227 0.54718-1.2227 1.2227 0 0.67556 0.54718 1.2227 1.2227 1.2227h0.14979c0 0.05502-0.0031 0.11005-0.0031 0.16507 0 0.15896 0.0061 0.31486 0.01528 0.47076h-0.16201c-0.67557 0-1.2227 0.54718-1.2227 1.2227 0 0.67556 0.54718 1.2227 1.2227 1.2227h0.68168c0.95985 2.4944 3.1119 4.2368 5.6093 4.2368 1.2686 0 2.4852-0.44324 3.5184-1.2808 0.52272-0.4249 0.60526-1.1952 0.18035-1.721-0.4249-0.52272-1.1952-0.60526-1.721-0.18035-0.59303 0.47993-1.2747 0.73365-1.9778 0.73365-1.1738 0-2.2193-0.70308-2.8979-1.7913h2.9529c0.67557 0 1.2227-0.54718 1.2227-1.2227 0-0.67556-0.54718-1.2227-1.2227-1.2227h-3.7355c-0.01223-0.1559-0.01834-0.3118-0.01834-0.47076 0-0.05502 0.0061-0.11005 0.0061-0.16507h5.2425c0.67557 0 1.2227-0.54718 1.2227-1.2227 0-0.67557-0.54718-1.2227-1.2227-1.2227h-4.625c0.66334-1.2625 1.7944-2.097 3.0783-2.097z" />
      <path d="m17.626 1.5789c-2.5555 0-4.9949 0.77033-7.0797 2.2254-5.9609 0.88649-10.546 6.0373-10.546 12.24 0 6.8229 5.5513 12.377 12.377 12.377 2.6258 0 5.0622-0.82535 7.0675-2.2254 6.0404-0.88955 10.555-6.0954 10.555-12.24 0-6.826-5.5513-12.377-12.374-12.377zm-15.18 14.465c0-5.4748 4.4538-9.9317 9.9317-9.9317s9.9317 4.4538 9.9317 9.9317-4.4538 9.9317-9.9317 9.9317-9.9317-4.4569-9.9317-9.9317zm20.625 6.2176c1.0668-1.828 1.6813-3.9525 1.6813-6.2176 0-5.6032-3.7447-10.347-8.8618-11.864 0.56858-0.10088 1.1463-0.1559 1.7332-0.1559 5.4748 0 9.9317 4.4538 9.9317 9.9317 0 3.4451-1.7669 6.5203-4.4844 8.3085z" />
    </>
  ) : (
    <>
      <path d="m12.266 28.354c2.6546 0 5.1147-0.85086 7.1256-2.2913 6.058-0.81581 10.609-6.0134 10.609-12.151 0-6.7655-5.5003-12.266-12.263-12.266-2.5844 0-5.051 0.7935-7.1415 2.2945-5.9751 0.81581-10.596 5.9528-10.596 12.148 0 6.7623 5.5003 12.266 12.266 12.266zm16.46-14.442c0 4.7419-3.0274 8.8528-7.3423 10.366 1.9535-2.1734 3.1453-5.0446 3.1453-8.1899 0-6.4213-4.9618-11.702-11.252-12.218 1.3926-0.62142 2.8999-0.94965 4.4614-0.94965 6.0612 0 10.991 4.9299 10.991 10.991zm-16.46-8.8145c6.0612 0 10.991 4.9299 10.991 10.991 0 6.0612-4.9299 10.991-10.991 10.991-6.0612 0-10.991-4.9299-10.991-10.991 0-6.0612 4.9299-10.991 10.991-10.991z" />
      <path d="m6.265 17.854c0 0.35054 0.28681 0.63735 0.63735 0.63735h1.1663c0.82537 2.5749 2.9286 4.4168 5.392 4.4168 1.1727 0 2.304-0.41109 3.2664-1.1918 0.27406-0.22307 0.31549-0.6246 0.09241-0.89548-0.21988-0.27406-0.6246-0.31549-0.89547-0.09242-0.73295 0.59273-1.5838 0.90822-2.4634 0.90822-1.7814 0-3.3174-1.2843-4.044-3.1421h4.1013c0.35054 0 0.63735-0.28681 0.63735-0.63735s-0.28681-0.63735-0.63735-0.63735h-4.4583c-0.06055-0.36329-0.09242-0.74251-0.09242-1.1281 0-0.27406 0.02231-0.54493 0.05418-0.80943h6.0548c0.35054 0 0.63735-0.28681 0.63735-0.63735s-0.28681-0.63735-0.63735-0.63735h-5.7776c0.66922-2.0268 2.2817-3.4608 4.1619-3.4608 0.88592 0 1.7463 0.31868 2.4825 0.92416 0.27087 0.22307 0.67559 0.18483 0.89548-0.08923 0.22307-0.27087 0.18483-0.6724-0.08923-0.89548-0.96877-0.7935-2.1064-1.2141-3.2919-1.2141-2.5653 0-4.7451 1.9917-5.4908 4.7355h-1.0676c-0.35054 0-0.63735 0.28681-0.63735 0.63735s0.28681 0.63735 0.63735 0.63735h0.83493c-0.02549 0.2645-0.04462 0.53537-0.04462 0.80943 0 0.3856 0.03505 0.76163 0.08604 1.1281h-0.87635c-0.35054 0-0.63735 0.28681-0.63735 0.63735z" />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
      margin={margin}
    >
      {content}
    </S.StyledSVG>
  );
};
