import { StyledSVG } from './SVG.styled';

const SortAscSVG = ({ color }) => {
  return (
    <StyledSVG
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="46.5"
      height="94.14"
      viewBox="0 0 46.5 94.14"
      color={color ? color : 'currentColor'}
    >
      <path d="M45.92,69.48c-.78-.78-2.05-.78-2.83,0l-17.84,17.84V2c0-1.1-.9-2-2-2s-2,.9-2,2V87.31L3.41,69.48c-.78-.78-2.05-.78-2.83,0-.78,.78-.78,2.05,0,2.83l21.25,21.25c.09,.09,.2,.18,.31,.25,.05,.03,.1,.06,.15,.08,.06,.03,.12,.07,.19,.1,.07,.03,.13,.04,.2,.06,.06,.02,.11,.04,.17,.05,.13,.03,.26,.04,.39,.04s.26-.01,.39-.04c.06-.01,.11-.03,.17-.05,.07-.02,.14-.04,.2-.06,.07-.03,.13-.07,.19-.1,.05-.03,.11-.05,.15-.08,.11-.07,.21-.16,.31-.25l21.25-21.25c.78-.78,.78-2.05,0-2.83Z" />
    </StyledSVG>
  );
};

export default SortAscSVG;
