import styled from 'styled-components/macro';
import { colors } from '../../../common/colors/colors';
import { TheButton } from 'components/Buttons/TheButton.styled';

export const ClientSelectContainer = styled.div`
  width: 100vw;

  .container {
    margin: 0 20px;
  }

  .clientSelectWrapper {
    padding: 20px 0;
    padding-bottom: ${({ hasEmployeeSelected }) =>
      hasEmployeeSelected ? '95' : '20'}px;
  }

  .professional-list {
    > div {
      margin-bottom: 20px;
    }
  }

  .client-list {
    h3 {
      margin-top: 0;
    }

    > div:last-child {
      border-bottom: 1px solid ${colors.bg_grey_1};
    }
  }

  .footer {
    height: 75px;
    width: 100%;
    background-color: ${colors.bg_grey_1};
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 20px;

    ${TheButton} {
      height: 35px;
    }
  }
`;
