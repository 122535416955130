import { useNavigate } from 'react-router';
import { useTranslate } from 'hooks/useTranslate';

// Style
import * as S from './SimpleHeader.styled';

// SVG
import LogoSVG from 'icons/LogoSVG';
import GoBackSVG from 'icons/GoBackSVG';

const SimpleHeader = () => {
  const tr = useTranslate().use().global;
  const navigate = useNavigate();

  return (
    <S.SimpleHeader>
      <div className="logo__container">
        <LogoSVG color={'white'} />
      </div>
      <div
        className="nav__container"
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="nav__back-logo">
          <GoBackSVG />
        </div>
        <div className="nav__back-text">{tr['back']}</div>
      </div>
    </S.SimpleHeader>
  );
};

export default SimpleHeader;
