import React from 'react';

// Style
import { StyledSVG } from './SVG.styled';

const SearchBoldSVG = ({ color }) => {
  return (
    <StyledSVG
      color={color || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      width="25.24"
      height="25.24"
      viewBox="0 0 25.24 25.24"
    >
      <g
        id="Group_2355"
        data-name="Group 2355"
        transform="translate(-20.956 -20.998)"
      >
        <path
          id="Path_605"
          data-name="Path 605"
          d="M200.14,1623.949l-6.324-6.325a10.185,10.185,0,1,0-2.343,2.343l6.324,6.324a1.657,1.657,0,0,0,2.343-2.342ZM178.7,1611.706a6.856,6.856,0,1,1,6.856,6.856A6.864,6.864,0,0,1,178.7,1611.706Z"
          transform="translate(-154.429 -1580.539)"
          //   fill="#0f1d5b"
        />
      </g>
    </StyledSVG>
  );
};

export default SearchBoldSVG;
