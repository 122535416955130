import { useState, useContext } from 'react';

// style
import * as S from './FunctionWidget.styled';

// components
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import { Overlay } from 'components/Overlay/Overlay';
import { FunctionBarItem } from '../FunctionBarItem/FunctionBarItem';

// icons
import FabClosedSVG from 'icons/FabClosedSVG';
import FabOpenSVG from 'icons/FabOpenSVG';

// context
import { UserContext } from 'context/UserContext';

// hooks and helpers
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useModal } from 'hooks/useModal';

export const FunctionWidget = ({ functions, fabMarginY = 20, id }) => {
  // Hooks
  // --- global
  const { userAcl } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 960px)');

  // --- local
  const { isShowing, toggle } = useModal(false);
  const [selectedFunctionId, selectFunction] = useState();

  const filteredFunctions = functions.reduce((acc, func) => {
    const newFunc = { ...func };
    newFunc.title = newFunc.name;

    return newFunc.acl <= userAcl && (newFunc.hide ? newFunc.hide() : true)
      ? [...acc, newFunc]
      : acc;
  }, []);

  const [menuVisible, setMenuVisible] = useState();

  const hideMenu = () => {
    setMenuVisible(false);
    selectFunction(null);
  };

  const fab = (
    <S.FloatingActionButton
      secondary
      raised
      inverted
      icon={menuVisible ? <FabOpenSVG /> : <FabClosedSVG />}
      action={() => setMenuVisible((old) => !old)}
      marginY={fabMarginY}
      isVisible={menuVisible}
      id={id}
    />
  );

  return (
    filteredFunctions && (
      <S.FunctionWidget>
        <ConditionalWrapper
          condition={isMobile}
          wrapper={(children) => (
            <Overlay isVisible={menuVisible} hide={hideMenu} passThrough={fab}>
              {children}
            </Overlay>
          )}
        >
          <S.FunctionList isVisible={menuVisible}>
            {filteredFunctions.map((item) => {
              return (
                <FunctionBarItem
                  id={item.id}
                  key={item.id}
                  icon={item.icon}
                  tooltip={item.tooltip}
                  isActive={selectedFunctionId === item.id || item.isActive}
                  setActive={selectFunction}
                  action={item.action}
                  iconTitle={item.iconTitle}
                  scope={item.scope}
                  closeOnClick={item.closeOnClick}
                  closeMenu={hideMenu}
                  subItems={item.subItems}
                  color={item.color}
                />
              );
            })}
          </S.FunctionList>
        </ConditionalWrapper>
      </S.FunctionWidget>
    )
  );
};
