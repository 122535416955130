import { memo } from 'react';
import * as S from '../SVG.styled';

export default ({ color, width, height, bold }) => {
  const content = bold ? (
    <g>
      <path
        d="m22.892 29.168c-0.3546 0-0.7122-0.0855-1.0394-0.2598l-6.8531-3.6039-6.8532 3.6009c-0.75806 0.39735-1.6567 0.33315-2.3506-0.17115-0.69081-0.50445-1.0332-1.3389-0.88644-2.1825l1.3083-7.6296-5.5418-5.3982c-0.61134-0.59912-0.82837-1.4733-0.56549-2.2895 0.26593-0.81308 0.9537-1.3969 1.8004-1.5192l7.6601-1.1126 3.4266-6.9418c0.37904-0.76724 1.1463-1.2441 2.0021-1.2441 0.85593 0 1.6232 0.47685 2.0021 1.2441l3.4266 6.9418 7.6602 1.1126c0.84675 0.12227 1.5375 0.70611 1.8003 1.5192 0.26595 0.81309 0.04905 1.6904-0.56535 2.2864l-5.5449 5.4042 1.3083 7.6296c0.14355 0.8436-0.19575 1.6782-0.8865 2.1825-0.39135 0.28425-0.84975 0.42795-1.3113 0.42795zm-7.8924-6.3335c0.35763 0 0.71223 0.0855 1.0392 0.2568l6.569 3.4541-1.2533-7.3148c-0.1254-0.7245 0.1161-1.4612 0.64185-1.9746l5.3157-5.1812-7.3422-1.0668c-0.7245-0.10394-1.3542-0.55938-1.6812-1.2196l-3.2829-6.6545-3.283 6.6545c-0.32402 0.66026-0.9537 1.1157-1.6812 1.2196l-7.3422 1.0668 5.3126 5.1812c0.52575 0.51045 0.76724 1.2501 0.64191 1.9746l-1.2533 7.3148 6.5689-3.4511c0.32402-0.17115 0.68165-0.2568 1.0393-0.2568z"
        strokeWidth="1.5"
      />
    </g>
  ) : (
    <g>
      <path
        d="m6.7731 29.131c-0.34736 0-0.69473-0.1083-0.99109-0.32504-0.52264-0.38234-0.78077-1.0134-0.67241-1.6539l1.3831-8.069c0.022305-0.13395-0.02232-0.2709-0.11791-0.36659l-5.8637-5.7138c-0.46208-0.45253-0.6278-1.1154-0.42703-1.7304 0.20077-0.61505 0.7234-1.058 1.3639-1.1504l8.104-1.1759c0.13384-0.01911 0.25176-0.10516 0.31231-0.22626l3.6234-7.3424c0.28681-0.57999 0.8668-0.94328 1.5138-0.94328 0.64679 0 1.2268 0.36011 1.5136 0.9401l3.6235 7.3424c0.06045 0.12109 0.17835 0.20713 0.3123 0.22626l8.1008 1.1759c0.64049 0.09241 1.1632 0.53539 1.3639 1.1504 0.20085 0.61505 0.0351 1.2779-0.42704 1.7304l-5.8637 5.7139c-0.0987 0.0957-0.1434 0.23265-0.1179 0.36644l1.3831 8.069c0.1083 0.63734-0.1467 1.2715-0.67244 1.6509-0.52259 0.38234-1.2046 0.43019-1.7782 0.12735l-7.2468-3.8113c-0.12105-0.06375-0.26445-0.06375-0.38555 0l-7.2468 3.8113c-0.24856 0.13065-0.51944 0.1944-0.78713 0.1944zm8.2283-27.422c-0.07654 0-0.26774 0.022305-0.37291 0.23263l-3.6234 7.3424c-0.24538 0.50032-0.72021 0.8445-1.2715 0.92416l-8.104 1.1759c-0.22945 0.03187-0.30912 0.20715-0.33462 0.28363-0.022308 0.07329-0.060548 0.26131 0.10516 0.42385l5.8637 5.7139c0.39835 0.38564 0.58 0.94648 0.48758 1.4946l-1.3831 8.069c-0.038249 0.2295 0.10197 0.36014 0.1657 0.40469 0.063749 0.0447 0.22945 0.14025 0.4366 0.03195l7.2468-3.8114c0.49076-0.25815 1.0803-0.25815 1.571 0l7.2468 3.8114c0.20715 0.1083 0.37289 0.01275 0.43649-0.03195 0.06375-0.04455 0.204-0.1752 0.16575-0.40469l-1.383-8.069c-0.09255-0.54809 0.0891-1.1058 0.48434-1.4946l5.8637-5.7139c0.16575-0.16254 0.1275-0.35056 0.10515-0.42385-0.0255-0.0733-0.10515-0.24858-0.33464-0.28363l-8.1008-1.1759c-0.55139-0.079664-1.0261-0.42385-1.2715-0.92416l-3.6233-7.3424c-0.102-0.20715-0.29324-0.22945-0.37289-0.22945z"
        strokeWidth="1.5"
      />
    </g>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
      stroke={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
