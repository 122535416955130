import { useState, useEffect, useContext } from 'react';

// style
import * as S from './TimeAndDateChild.styled';

// icons
import { Arrow } from 'icons/dynamic';

// components
import SchedulerCalendar from '../../../../Calendar/SchedulerCalendar/SchedulerCalendar';
import InputFieldTime from 'components/InputFieldTime/InputFieldTime';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { addMinutes } from 'common/helpers/timeCalculations';
import { getDurationInMinutes } from 'common/helpers/dateOperations';
import { OrderContext } from 'context/OrderContext';

const options = {
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h23',
};

const TimeAndDateChild = ({
  selectedTime,
  durationInMinutes,
  darkTheme,
  arrow,
  disabled,
}) => {
  const { locale } = useContext(UserContext);
  const { order } = useContext(OrderContext);

  const [startInput, setStartInput] = useState('');
  const [endInput, setEndInput] = useState('');

  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    setStartInput(selectedTime.toLocaleString(locale, options));
    const endTime = addMinutes(selectedTime, durationInMinutes);
    setEndInput(endTime.toLocaleString(locale, options));
  }, [selectedTime, durationInMinutes, locale]);

  const getDate = (propDate) => {
    if (!propDate) return;

    return (
      <>
        <span className="date-select__format">
          {propDate.toLocaleDateString(locale, { dateStyle: 'short' })}
        </span>
        {propDate.toLocaleDateString(locale, { weekday: 'short' })}
      </>
    );
  };

  const handleStartInput = (value) => {
    // setStartInput(value);

    let hours = value.split(':')[0];
    let minutes = value.split(':')[1];

    const startTime = new Date(
      new Date(selectedTime).setHours(hours, minutes, 0, 0),
    );

    order.setState((prev) => ({
      ...prev,
      startTime,
      durationInMinutes: durationInMinutes > 0 ? durationInMinutes : 15,
    }));
  };

  const handleEndInput = (value) => {
    // setEndInput(value);

    let hours = value.split(':')[0];
    let minutes = value.split(':')[1];

    const endTime = new Date(
      new Date(selectedTime).setHours(hours, minutes, 0, 0),
    );

    const duration = getDurationInMinutes(selectedTime, endTime);

    if (duration >= 15) {
      order.setState((prev) => ({
        ...prev,
        durationInMinutes: duration,
      }));
    } else {
      const x = new Date(selectedTime);
      const minimumTime = new Date(
        x.setHours(x.getHours(), x.getMinutes() + 15, 0, 0),
      );

      setEndInput(minimumTime.toLocaleString(locale, options));
    }
  };

  return (
    <S.TimeAndDateContainer
      darkTheme={darkTheme}
      disabled={disabled}
      openCalendor={showCalendar}
    >
      <div className="date-select-wrapper">
        <div
          className="date-select"
          onClick={(e) => {
            if (!disabled) {
              setShowCalendar((prev) => !prev);
              if (
                window.innerHeight -
                  e.target.getBoundingClientRect().top -
                  120 <
                276
              ) {
                setTimeout(() => {
                  window.scrollTo(0, window.window.innerHeight / 2 - 150);
                }, 300);
              }
            }
          }}
        >
          {selectedTime ? getDate(selectedTime) : getDate(new Date())}
        </div>

        <div className="time-select">
          <InputFieldTime
            className="time-input"
            value={startInput}
            onChange={handleStartInput}
          />

          <span className="long-dash">&#8212;</span>

          <InputFieldTime
            className="time-input"
            value={endInput}
            onChange={handleEndInput}
          />

          {arrow && (
            <div className="time-picker-icon" onClick={arrow}>
              <Arrow black height={18} width={14} />
            </div>
          )}
        </div>
      </div>

      <div className="calendar-wrapper">
        <div className="date-select__open-calendar">
          <SchedulerCalendar
            selectDay={(date) => {
              const y = new Date(selectedTime);
              const startTime = new Date(
                new Date(date).setHours(y.getHours(), y.getMinutes(), 0, 0),
              );
              order.setState((prev) => ({
                ...prev,
                startTime,
              }));
              setShowCalendar(false);
            }}
            selected={selectedTime}
          />
        </div>
      </div>
    </S.TimeAndDateContainer>
  );
};

export default TimeAndDateChild;
