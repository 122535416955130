import { useContext, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pinEmployee } from 'redux/actions/calendarViewActions';

// style
import * as S from './EmployeesHeader.styled';
import { colors } from 'common/colors/colors';

// icons
import { VerticalEllipsis } from 'icons/dynamic';

// components
import DataPanelHeader from '../../DataPanel/DataPanelHeader/DataPanelHeader';
import EmployeeSelectButton from 'components/ScheduleCustom/MonthlyView/ScheduleMonth/ProfessionalSelect/EmployeeSelectButton';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';
import { UserContext } from 'context/UserContext';

// hooks
import { useColumnLooper } from 'hooks/schedule/useColumnLooper';
import useMediaQuery from 'hooks/useMediaQuery';
import { SCHEDULE_TYPE } from 'common/Constants';

export const EmployeesHeader = () => {
  const {
    compare,
    personalDays,
    numberOfDays,
    columnWidth,
    searchParams,
    personal,
    cellWidth,
    toggledEmployee,
    loader,
    days,
    type,
    pendingOrders,
    defaultColumnWidth,
  } = useContext(ScheduleContext);
  const { locale } = useContext(UserContext);
  const dispatch = useDispatch();
  const { loop } = useColumnLooper();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [pendingOrdersHeaderWidth, setPendingOrdersHeaderWidth] = useState(0);

  useLayoutEffect(() => {
    if (isMobile && personal) {
      const pendingOrdersElement = document.getElementById('pending');
      setPendingOrdersHeaderWidth(
        pendingOrders.state && pendingOrdersElement
          ? pendingOrdersElement.offsetWidth
          : 0,
      );
    }
  }, [pendingOrders.state]);

  const pinCollumnToStart = (id) => dispatch(pinEmployee(id));

  const EmployeeSkeleton = ({ index }) => {
    return (
      <S.EmployeeCellSkeleton
        type={type}
        hidden={type === SCHEDULE_TYPE.WEEK ? index !== 0 : false}
        defaultColumnWidth={defaultColumnWidth}
      >
        <div className="employee">
          <div className="actions">
            <VerticalEllipsis width={16} height={16} color={colors.bg_grey_5} />
          </div>
        </div>
        <div className="name"></div>
      </S.EmployeeCellSkeleton>
    );
  };

  return (
    <S.EmployeesHeader
      locale={locale}
      pendingOrdersHeaderWidth={pendingOrdersHeaderWidth}
    >
      {type === SCHEDULE_TYPE.PERSONAL ? (
        <div className="employee-selection">
          <EmployeeSelectButton />
        </div>
      ) : (
        <>
          <div className="filler"></div>
          {loop({
            keyPrefix: 'employee',
            skeleton: (index) => <EmployeeSkeleton index={index} />,
            appendToEnd: loader.end,
            callback: (sourceItem, day) => (
              <S.Cell
                width={cellWidth.value}
                columnWidth={columnWidth}
                defaultColumnWidth={defaultColumnWidth}
              >
                <S.Employee
                  hiddenHeader={
                    (numberOfDays.visible > 1 && day.day > 0) || personal
                  }
                  stickyHeader={numberOfDays.visible > 1}
                  numberOfDays={days.length}
                  locale={locale}
                  type={type}
                >
                  <DataPanelHeader
                    numberOfDays={numberOfDays.visible}
                    personalDays={personalDays}
                    headerData={sourceItem.employee}
                    headerMenu={{
                      state: toggledEmployee.value,
                      setState: toggledEmployee.set,
                    }}
                    pinToStart={!compare && pinCollumnToStart}
                    searchParams={searchParams}
                  />
                </S.Employee>
              </S.Cell>
            ),
          })}
        </>
      )}
    </S.EmployeesHeader>
  );
};

export default EmployeesHeader;
