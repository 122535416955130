import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const TitleAndValue = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  color: ${({ isWarning }) => (isWarning ? colors.warningText : 'unset')};

  .titleContainer {
    align-items: center;
    color: ${colors.bg_grey_3};
    color: ${(props) =>
      props.textType === 'primary' ? colors.bg_Primary : colors.bg_grey_3};
    display: flex;
    font-family: ${(props) =>
      props.textType === 'primary'
        ? fontFamilies.family_Primary
        : fontFamilies.family_SecondaryCondensed};
    font-size: 14px;

    span {
      padding-bottom: ${(props) => props.textType === 'primary' && '2px'};
    }
  }

  .iconContainer {
    align-items: center;
    display: flex;
    margin-right: 5px;

    svg {
      fill: ${colors.bg_Primary};
      height: 14px;
      width: 14px;
    }
  }

  .valueContainer {
    align-items: center;
    background-color: ${(props) => props.editable && colors.bg_grey_1};
    border-radius: 3px;
    display: flex;
    font-size: 14px;
    font-weight: ${(props) => props.bold && 600};
    height: 26px;
    justify-content: ${(props) =>
      props.justifyValue ? props.justifyValue : 'flex-end'};
    /* padding: ${(props) => props.editable && '0 10px'}; */
    /* width: ${(props) => props.inputSize === 'S' && '70px'}; */
    width: ${(props) => props.editable && '100px'};
  }

  .valueContainer .input-container {
    background-color: unset;
    border: unset;
    height: unset;
  }

  .valueContainer .input-container input {
    font-weight: 900;

    ::placeholder {
      font-weight: 400;
    }

    :focus-within::placeholder {
      font-weight: 900;
    }
  }

  input {
    background-color: transparent;
    border: none;
    font-weight: ${(props) => props.bold && 600};
    outline: none;
    text-align: right;
    width: 100%;
  }

  .arrow {
    display: flex;
    margin-left: ${(props) => props.editable && '5px'};
  }
`;
