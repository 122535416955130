import { Fragment, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

// style
import * as S from './ServiceTree.styled';

// icons
import { Check, Circle, SetSVG } from 'icons/dynamic';
import VarneleSVG from 'icons/VarneleSVG';

// components
import { ContentLayout } from 'components/ContentLayout/ContentLayout';
import { DialogLayout } from 'components/DialogLayout/DialogLayout';
import { InputValue } from '../InputValue/InputValue';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const ServiceTree = ({
  data,
  close,
  disabled,
  contentId,
  getDbItem,
  getItem,
  toggleActivity,
  updateValue,
  initialButtons,
  isCacheEmpty,
  setButtons,
  ...props
}) => {
  const tr = useTranslate().use().global;

  const [activeOption, setActiveOption] = useState();

  const findItemById = (data, id) => data?.find((item) => item.id === id);

  // useEffect(() => console.log({ activeOption }), [activeOption]);

  const ServiceTreeItems = ({ setButtons }) => {
    // setup/cleanup header buttons
    useEffect(() => {
      setButtons({ type: 'setup', value: initialButtons });
      return () => setButtons({ type: 'cleanup' });
    }, [setButtons]);

    // show/hide header button according to changes
    useEffect(() => {
      if (isCacheEmpty) {
        const isChanged = !isCacheEmpty();
        setButtons({ type: 'show', id: 'cancel', value: isChanged });
        setButtons({ type: 'show', id: 'save', value: isChanged });
      }
    }, [setButtons]);

    return ['subgroups', 'services'].map((itemType) => (
      <Fragment key={itemType}>
        {data?.[itemType]?.map((service) => {
          const type = itemType.slice(0, -1);
          const dbItem = getDbItem(type, service.id);
          const item = getItem(type, service.id);

          // console.log({ itemType, item });

          return (
            <S.ServiceTree
              key={service.id}
              isFinal={itemType === 'services'}
              type={service.hasComp ? 'set' : type}
            >
              <div className="item">
                <div
                  className="mark"
                  onClick={() => toggleActivity(type, service.id)}
                >
                  {item.isActive ? (
                    <Check black inCircle width={16} height={16} />
                  ) : (
                    <Circle bold width={16} height={16} />
                  )}
                </div>

                {service.hasComp && (
                  <div className="contents">
                    <div
                      className="icon"
                      data-tooltip-id="tooltip"
                      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        <>
                          {service.composition.map((service) => (
                            <div key={service.id}>{service.name}</div>
                          ))}
                        </>,
                      )}
                    >
                      <SetSVG sm />
                    </div>
                  </div>
                )}

                {service.name}

                {!service.hasComp && service.services && (
                  <div
                    className="arrow"
                    onClick={() => {
                      console.log({ type: service, data });
                      setActiveOption(() =>
                        findItemById(data?.[itemType], service.id),
                      );
                    }}
                  >
                    <VarneleSVG tip={'right'} height="16" width="16" />
                  </div>
                )}
              </div>

              {itemType === 'services' && (
                <div className="card">
                  <div className="price">
                    <span>{tr['price']}</span>

                    <InputValue
                      type={'number'}
                      units="€"
                      defaultValue={item.price}
                      updateValue={(value) =>
                        updateValue(service.id, 'price', value)
                      }
                      disabled={disabled || !service.isActive}
                      tooltipTitle={tr['base-price']}
                      tooltipContents={dbItem.price}
                    />
                  </div>

                  <div className="duration">
                    <span>{tr['duration']}</span>

                    <InputValue
                      type={'time'}
                      defaultValue={item.duration}
                      updateValue={(value) =>
                        updateValue(service.id, 'duration', value)
                      }
                      disabled={disabled || !service.isActive}
                      tooltipTitle={tr['base-duration']}
                      tooltipContents={dbItem.duration}
                    />
                  </div>

                  <div className="break">
                    <span>{tr['break']}</span>

                    <InputValue
                      type={'time'}
                      defaultValue={item.break}
                      updateValue={(value) =>
                        updateValue(service.id, 'break', value)
                      }
                      disabled={disabled || !service.isActive}
                      tooltipTitle={tr['base-break']}
                      tooltipContents={dbItem.break}
                    />
                  </div>
                </div>
              )}
            </S.ServiceTree>
          );
        })}
      </Fragment>
    ));
  };

  return (
    <ContentLayout hideFab>
      <DialogLayout headerText={data?.name} getBack={close} modal {...props}>
        <ServiceTreeItems />

        {activeOption && (
          <ServiceTree
            data={activeOption}
            close={() => setActiveOption(undefined)}
            toggleActivity={toggleActivity}
            updateValue={updateValue}
            getDbItem={getDbItem}
            getItem={getItem}
            isCacheEmpty={isCacheEmpty}
            initialButtons={initialButtons}
          />
        )}
      </DialogLayout>
    </ContentLayout>
  );
};

export default ServiceTree;
