import { useContext, useRef } from 'react';

// style
import * as S from './AppointmentsDetails.styled';

// icons
import { LinkUrlSVG, Arrow, VerticalEllipsis } from 'icons/dynamic';

// components
import Avatar from 'components/Avatar/Avatar';
import ContextTree from 'components/ContextTree/ContextTree';
import { Link } from 'components/Overrides';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useStatusList from 'hooks/useStatusList';
import useTooltipWhenOverflows from 'hooks/useTooltipWhenOverflows';
import useNumber from 'hooks/useNumber';
import { getDuration } from 'common/helpers/dateOperations';
import { fillImgPath } from 'common/helpers/imagesFunctions';

const AppointmentsDetails = ({
  status,
  data,
  type,
  statusItems,
  functionItems,
  balanceItems,
}) => {
  const tr = useTranslate().use().global;
  const { searchParams, numberOfDays } = useContext(ScheduleContext);
  const { formatPrice } = useNumber({ price: true });

  // Vars
  const clientName = `${data.customer?.name || 'Vardenis'} ${
    data.customer?.lastName || 'Pavardenis'
  }`;
  const orderId = tr['order'] + ' ' + data.id;

  // Hooks
  // -- refs
  const clientNameRef = useRef();
  const orderTitleRef = useRef();

  // -- state
  const currentStatus = useStatusList(status.toLowerCase());

  useTooltipWhenOverflows(clientNameRef, clientName);
  useTooltipWhenOverflows(orderTitleRef, orderId);

  // Functions
  const getTimeFromDate = (date) => {
    if (!date) return;

    const time = new Date(date);

    const timeFormat = {
      hour: '2-digit',
      minute: '2-digit',
    };

    return time.toLocaleTimeString('lt', timeFormat);
  };

  const startTime = getTimeFromDate(data?.time?.start);
  const endTime = getTimeFromDate(data?.time?.end);

  const duration =
    data.duration || getDuration(data?.time?.start, data?.time?.end);

  return (
    <S.AppointmentsDetails statusColor={currentStatus?.background}>
      <div className="details__head">
        <div className="details__customer-info">
          <Link to={'#'} className={'details__link'}>
            <div className="details__full-name">
              <span ref={clientNameRef}>{clientName}</span>
              <div className="link_icon">
                <LinkUrlSVG bold />
              </div>
            </div>
          </Link>
          <span className="details__phone">+37063328571</span>
        </div>
      </div>
      <div className="details__body">
        <div className="detail__orderId-wrapper detail__wrapper">
          <Link
            to={'/functions/tables/orders/' + data.uuid}
            className={'details__link'}
            state={{
              toOrder: true,
              numberOfDays: numberOfDays.visible,
              searchParams: Object.fromEntries([...searchParams]),
            }}
          >
            <div className="details__orderId">
              <span ref={orderTitleRef}>{orderId}</span>
            </div>
            <div className="link_icon">
              <LinkUrlSVG bold />
            </div>
          </Link>

          <div className="order__buttons">
            <div className="function_icon" id="function_button">
              <VerticalEllipsis />
              <ContextTree
                items={functionItems}
                containerId={'function_button'}
                openOnClick
                fixedWidth={200}
              />
            </div>
          </div>
        </div>
        {type === 'pending' && !!data.wantedEmployees.length && (
          <div className="detail__wrapper flex">
            <span className="details__span">
              <span>Pageidauja pas:</span>
            </span>
            <div className="professionals__container">
              {data.wantedEmployees?.map((element, i) => (
                <div className="specialist__avatar" key={i}>
                  <Avatar
                    imagePath={fillImgPath(element.photoUrl)}
                    raised
                    tooltip={`${element.firstName} ${element.lastName}`}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div>
          <div
            className="detail__wrapper details__status-container"
            id="status"
          >
            <div className="status">
              <div className="status-color" />
              <span className="status-label">{currentStatus?.title}</span>
            </div>

            <div className="arrow">
              <Arrow height={12} width="auto" black />
            </div>
            <ContextTree
              items={statusItems}
              containerId={'status'}
              openOnClick
              fixedWidth={200}
            />
          </div>
        </div>
        {/* </ClickAwayListener> */}
        <div className="detail__wrapper multi">
          {type !== 'pending' && (
            <span className="details__span">
              <span>{tr['time']}:</span> {startTime} - {endTime}
            </span>
          )}
          <span className="details__span">
            <span>{tr['duration']}:</span> {duration}
          </span>
        </div>
        <div className="detail__wrapper">
          <span className="details__span">
            <span>{tr['services']}:</span>
          </span>

          <ul className="services__ul">
            {data?.services?.length
              ? data.services.map((item, i) => (
                  <li key={i}>{item.serviceName}</li>
                ))
              : ''}
          </ul>
          <span className="services_price">
            {data?.services?.length &&
              formatPrice(
                data.services.reduce(
                  (prev, curr) => prev + +curr.finalAmountWithVat,
                  0,
                ),
              )}
          </span>
        </div>
        <div id="balance" className="detail__wrapper around">
          <div className="double">
            <div className="details__card">
              <span className="details__card-title">Sumokėta</span>
              <span>{formatPrice(data.balance.amountCovered)}</span>
            </div>
            <div className="details__card">
              <span className="details__card-title">Mokėti</span>
              <span className="details__card-debt">
                {formatPrice(data.balance.toPay)}
              </span>
            </div>
          </div>
          <div className="arrow">
            <Arrow height={12} width="auto" black />
          </div>
          <ContextTree
            items={balanceItems}
            containerId={'balance'}
            openOnClick
            fixedWidth={200}
          />
        </div>
        <div className="detail__wrapper last">
          <div className="details__note">
            <span>{tr['note']}:</span>
            <p>{data.note || tr['none']} </p>
          </div>
        </div>
      </div>
    </S.AppointmentsDetails>
  );
};

export default AppointmentsDetails;
