import { useCallback, useEffect, useState } from 'react';

// style
import * as S from './ServiceMenu.styled';

// components
import { EmployeesSelector } from 'pages/Order/partials/EmployeesSelector/EmployeesSelector';
import { CategoryTree } from 'components/Cards/Services/CategoryTree/CategoryTree';
import { DebouncedSearch } from 'components/DebouncedSearch/DebouncedSearch';
import { Service } from 'components/Cards/Services/CategoryTree/Service/Service';

// icons
import { BurgerMenu, Pencil } from 'icons/dynamic';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import {
  useServicesGetCategories,
  useServicesGetServices,
} from 'services/serviceService';

export const ServiceMenu = ({
  menu,
  // emerging,
  search,
  employees,
  employeeId,
  wantedEmployees,
  selectService,
  sectorId,
  toggleWantedEmployeesSelection,
  averagePrices,
}) => {
  const searchByEmployeeAndSector = {
    sectorId: sectorId,
    ...(employeeId !== null && { employeeId }),
    serviceActivity: true,
    assignedToEmpOnly: 1,
    includeEmpty: false,
  };

  // Hooks
  const tr = useTranslate().use().global;

  // -- state
  // --- local
  const [categories, setCategories] = useState([]);
  const [foundServices, setFoundServices] = useState([]);

  const [searchQuery, setSearchQuery] = useState(null);

  // -- api
  const categoriesBE = useServicesGetCategories({
    queryParams: {
      enabled: sectorId !== undefined && employeeId !== undefined,
    },
    searchParams: searchByEmployeeAndSector,
  });

  const searchService = useServicesGetServices({
    queryParams: {
      enabled: !!searchQuery,
      retry: true,
      refetchOnWindowFocus: false,
    },
    searchParams: {
      searchPhrase: searchQuery,
      ...searchByEmployeeAndSector,
    },
  });

  // functions
  // TODO: could be inside component
  const convertData = useCallback((data) => {
    if (data instanceof Array) {
      for (let i = 0; i < data.length; i++) {
        convertData(data[i]);
      }
    } else if (data instanceof Object) {
      data = setItemType(data);

      for (let prop in data) {
        if (
          !['assignedToSectorIds', 'sectors'].includes(prop) &&
          (data[prop] instanceof Object || data[prop] instanceof Array)
        ) {
          convertData(data[prop]);
        }
      }
    }
    return data;
  }, []);

  const setItemType = (data) => {
    if ('baseDurationInMinutes' in data || 'unitId' in data) {
      data.type = 'service';
    } else if ('groupId' in data) data.type = 'subgroup';
    else if ('categoryId' in data) data.type = 'group';
    else data.type = 'category';

    if (data.hasComp) data.type = 'set';

    return data;
  };

  const addValueToObject = useCallback((arr, id, type, newValue) => {
    if (!arr) return;

    if (arr instanceof Array) {
      arr.forEach((i, index) => {
        if (i.type === type && i.id === id) {
          arr[index] = { ...i, ...newValue };
        } else {
          if (i.type === 'category' && 'groups' in i)
            addValueToObject(i['groups'], id, type, newValue);
          else if (i.type === 'group') {
            if ('subgroups' in i) {
              addValueToObject(i['subgroups'], id, type, newValue);
            }
            if ('services' in i) {
              addValueToObject(i['services'], id, type, newValue);
            }
          }
        }
      });
    } else {
      if (arr.type === type && arr.id === id) {
        arr = { ...arr, ...newValue };
        return;
      }
      for (let prop in arr) {
        if (prop instanceof Array || prop instanceof Object) {
          addValueToObject(prop, id, type, newValue);
        }
      }
    }
  }, []);

  // -- side effects
  useEffect(() => {
    categoriesBE.isSuccess && setCategories(convertData(categoriesBE.data));
  }, [categoriesBE.isSuccess, categoriesBE.data, convertData]);

  useEffect(() => {
    if (searchService.isSuccess) {
      setFoundServices(searchService.data.services);
    }
  }, [searchService]);

  return (
    // <S.ServiceMenu show={menu.state} showEmerging={emerging.state}>
    <S.ServiceMenu show={menu.state}>
      <div className="menu__header">
        <div
          className="header_burger"
          onClick={() => menu.setState((prev) => !prev)}
        >
          <BurgerMenu bold width={25} />
        </div>
        <h2>{tr['services']}</h2>
      </div>
      <div className="menu__content">
        <div className="content_actions">
          <EmployeesSelector
            employee={employees?.[0]}
            wantedEmployees={wantedEmployees}
            toggleWantedEmployeesSelection={toggleWantedEmployeesSelection}
          />
          <DebouncedSearch
            setSearchQuery={setSearchQuery}
            placeholder={tr.serviceSearch}
            darkTheme
          />
        </div>

        <div className="services__wrapper">
          {searchQuery ? (
            foundServices.length ? (
              <ul>
                {foundServices.map((service, index) => (
                  <Service
                    key={service.id}
                    service={service}
                    serviceAction={(item) => selectService(item)}
                    noIndent
                    search
                    averagePrices={averagePrices}
                  />
                ))}
              </ul>
            ) : (
              <div className="services__wrapper-empty">Tuščia</div>
            )
          ) : categories.length ? (
            <CategoryTree
              categories={categories}
              setCategories={setCategories}
              convertData={convertData}
              addValueToObject={() => {}}
              serviceAction={(item) => selectService(item)}
              searchParams={searchByEmployeeAndSector}
              averagePrices={averagePrices}
            />
          ) : categoriesBE.isLoading ? (
            <div className="services__wrapper-empty">Krauna...</div>
          ) : (
            <div className="services__wrapper-empty">Tuščia</div>
          )}
        </div>
      </div>
    </S.ServiceMenu>
  );
};
