import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';
import { dropdownRollIn, dropdownRollOut } from 'common/styles/animations';

export const BasicSelect = styled.div`
  width: 100%;
  filter: ${(props) => props.menuIsOpen && `drop-shadow(${shadow.lg})`};
  z-index: ${(props) => props.menuIsOpen && 1};

  .select__placeholder {
    color: ${colors.filterGrey};
  }

  .select__single-value {
    color: ${({ isWarning }) =>
      isWarning ? colors.warningText : colors.bg_Primary};
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
  }

  .select__value-container {
    padding: 0 0 0 5px;
  }

  .select__control {
    border: none;
    box-shadow: none;
    cursor: pointer;
    background-color: ${(props) => (props.bright ? 'white' : colors.bg_grey_1)};

    border-radius: ${(props) =>
      props.menuIsOpen ? '5px 5px 0 0' : '5px'} !important;
    min-height: 30px;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicator {
    padding: 0;
    padding-right: 5px;

    svg {
      fill: ${colors.bg_Primary};
      transform: rotate(90deg);
    }
  }

  .select__menu {
    margin-top: 0;
    background-color: ${(props) => (props.bright ? 'white' : colors.bg_grey_1)};

    box-shadow: none;

    border-radius: 0 0 5px 5px;
    padding-bottom: 5px;

    transform-origin: top;
    animation: ${dropdownRollIn} 0.2s ease-in-out;

    /* box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16); */
  }

  .select__menu--close {
    animation: ${dropdownRollOut} 0.1s ease-in-out;
  }

  .select__menu-list {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  .select__option {
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    /* font-weight: 500; */
    padding: 0 5px;
    height: 25px;
    margin-bottom: 5px;

    display: flex;
    align-items: center;
  }

  .select__option--is-focused {
    background-color: white;
    cursor: pointer;
  }

  .select__option--is-selected {
    background-color: ${colors.bg_grey_2};
    color: inherit;
  }

  .select-label {
    margin-left: 10px;
    font-size: 14px;
    color: ${colors.bg_grey_3};
  }
`;
