import { useContext } from 'react';

// style
import * as S from './SearchWidget.styled';

// icons
import { Ex } from 'icons/dynamic';

// components
import { DebouncedSearch } from 'components/DebouncedSearch/DebouncedSearch';

// contexts
import { MainContext } from 'pages/Main/Main';

export const SearchWidget = ({ queryId }) => {
  const { setState } = useContext(MainContext);

  return (
    <S.SearchWidget>
      <DebouncedSearch autofocus queryId={queryId} />
      <div
        className="close-button"
        onClick={() => setState({ type: 'hide-search-widget' })}
      >
        <Ex black width={14} height={14} />
      </div>
    </S.SearchWidget>
  );
};

export default SearchWidget;
