import * as S from './SVG.styled';

const FabClosedSVG = ({ color, width, height, invert }) => {
  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '50'}
      height={height ? height : '50'}
      version="1.1"
      viewBox="0 0 50 50"
      color={color ? color : 'currentColor'}
    >
      <g>
        <circle cx="16.481" cy="25" r="2.1245" />
        <circle cx="24.979" cy="25" r="2.1245" />
        <circle cx="33.519" cy="25" r="2.1245" />
      </g>
    </S.StyledSVG>
  );
};

export default FabClosedSVG;
