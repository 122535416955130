import { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchedule } from 'redux/actions/scheduleActions';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

// style
import * as S from './CustomerSearch.styled';

// components
import Footer from 'components/Footer/Footer';
import ClientsSelect from 'components/DialogLayout/ClientsSelect/ClientsSelect';
import FunctionBar from 'components/FunctionBar/FunctionBar';
import BasicTable from 'components/Tables/BasicTable/BasicTable';
import CustomerInfo from './CustomerInfo/CustomerInfo';
import SearchWidget from 'components/SearchWidget/SearchWidget';
import { DebouncedSearch } from 'components/DebouncedSearch/DebouncedSearch';

// contexts
import { CreateOrderContext } from 'context/SchedulerCreatingEventContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useFunctionBar from 'hooks/useFunctionBar';
import useMediaQuery from 'hooks/useMediaQuery';
import useFunctionList from 'hooks/useFunctionList';
import { useClientsFindGet } from 'services/clientService';

const CustomerSearch = () => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const functionBarFunctions = useFunctionBar(['new-client']); //, 'functions'
  // let functionsListItems = useFunctionList([
  //   'write-an-email',
  //   'deactivate-record',
  // ]);
  const { searchWidgetVisible, searchQueries, setSearchIconVisibility } =
    useContext(MainContext);
  const { ['clients']: searchPhrase } = {
    ...(searchQueries && { ...searchQueries }),
  };
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');
  const { data, isLoading } = useClientsFindGet({
    searchParams: {
      sectorId,
      ...(searchPhrase && { searchPhrase: searchPhrase }),
    },
    queryParams: {
      refetchOnWindowFocus: false,
    },
  });
  const selectedProfessional = useSelector(
    (state) => state.scheduleReducer.selectedProfessional,
  );
  const [functionBarItems, setFunctionBarItems] =
    useState(functionBarFunctions);
  const [currSelectedProfessional, setCurrSelectedProfessional] =
    useState(null);
  const [selectedClient, setSelectedClient] = useState({});
  const [orderTime, setOrderTime] = useState(new Date());

  if (data?.customers?.length) {
    const clients = data?.customers?.map((item) => {
      if (item?.phonePrefix && item?.phoneNumber) {
        item.phone = item.phonePrefix + item.phoneNumber;
      }
      if (item?.birthday) {
        item.birthDate = new Date(item?.birthday).getFullYear();
      }

      return item;
    });

    data.customers = clients;
  }

  const columns = [
    {
      id: 'name',
      label: tr['firstName'],
    },
    {
      id: 'lastName',
      label: tr['lastName'],
    },
    {
      id: 'city',
      label: tr['city'],
    },
    {
      id: 'birthday',
      label: tr['dateOfBirth'],
    },
    {
      id: 'phone',
      label: tr['phone-number'],
    },
  ];

  const multiSelect =
    location.state?.multi === 'multi-selects' && location.state?.multi;

  // functionsListItems = functionsListItems.reduce((prev, curr) => {
  //   return [
  //     ...prev,
  //     {
  //       ...curr,
  //       name: curr.title,
  //       color: curr?.background,
  //       fontColor: curr?.color,
  //       action: () => console.log('do somethin'),
  //     },
  //   ];
  // }, []);

  useEffect(() => {
    let professionalsIds =
      JSON.parse(sessionStorage.getItem('selectedProfessionalIds')) ||
      (selectedProfessional?.id && [selectedProfessional?.id]);

    if (!professionalsIds) return;

    setCurrSelectedProfessional(() => {
      return professionalsIds.map((id) => {
        return { id };
      });
    });

    return () => {
      sessionStorage.removeItem('selectedProfessionalIds');
      sessionStorage.removeItem('selectedClient');
      sessionStorage.removeItem('selectedProfessionalTime');

      // not necessary??
      dispatch(
        updateSchedule({
          cell: null,
          // selectedProfessional: null,
        }),
      );
    };
  }, []);

  useEffect(() => {
    updateFunctionBarItem('new-client', 'action', () =>
      navigate('/functions/tables/clients/new/general-information'),
    );
    // updateFunctionBarItem('functions', 'hide', () => false);
  }, []);

  useEffect(() => {
    if (Object.keys(selectedClient).length) {
      sessionStorage.setItem('selectedClient', selectedClient.id);
      // updateFunctionBarItem('functions', 'hide', () => true);
      // updateFunctionBarItem('functions', 'subItems', functionsListItems);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (!currSelectedProfessional) return;
    // if (currSelectedProfessional.length !== 0) {

    sessionStorage.setItem(
      'selectedProfessionalIds',
      JSON.stringify(currSelectedProfessional.map((employee) => employee.id)),
    );
    // }
  }, [currSelectedProfessional]);

  useEffect(() => {
    setSearchIconVisibility(true);

    return () => {
      setSearchIconVisibility(false);
    };
  }, []);

  // Functions
  const updateFunctionBarItem = (id, key, value) => {
    setFunctionBarItems((prev) => {
      const newArr = [...prev];
      newArr.find((item) => item.id === id)[key] = value;
      return newArr;
    });
  };

  return (
    <CreateOrderContext.Provider
      value={{
        professional: { currSelectedProfessional, setCurrSelectedProfessional },
        client: { selectedClient, setSelectedClient },
        time: { orderTime, setOrderTime },
        sectorId: location.state?.searchParams?.sectorId,
      }}
    >
      <Routes>
        <Route
          index
          element={
            <S.CustomerSearchContainer>
              <div className="customer-search-content">
                <FunctionBar
                  functions={functionBarItems}
                  fabMarginY={currSelectedProfessional ? 96 : 20}
                />
                {isMobile ? (
                  <>
                    {searchWidgetVisible && (
                      <SearchWidget queryId={'clients'} />
                    )}
                    <ClientsSelect
                      records={data?.customers || []}
                      employeeIds={currSelectedProfessional?.map(
                        (item) => item.id,
                      )}
                    />
                  </>
                ) : (
                  <div className="content__layout">
                    <S.Header>
                      <h2>{tr.clientSearch}</h2>
                    </S.Header>
                    <S.Main>
                      <div className="client-select-wrapper">
                        <DebouncedSearch
                          placeholder={tr.clientSearch}
                          raised
                          queryId={'clients'}
                        />
                        <div className="client-select__table-container">
                          <BasicTable
                            columns={columns}
                            isLoading={isLoading}
                            data={{
                              records: data?.customers || [],
                            }}
                            select={{
                              selected: selectedClient,
                              setSelected: currSelectedProfessional
                                ? setSelectedClient
                                : () => null,
                            }}
                            navigation={(data) =>
                              navigate(
                                `/functions/tables/clients/${data.id}/general-information`,
                              )
                            }
                          />
                        </div>
                      </div>
                      {Object.keys(selectedClient).length !== 0 && (
                        <CustomerInfo
                          customer={selectedClient}
                          createOrder={!!currSelectedProfessional}
                          multiSelect={multiSelect}
                          employeeIds={currSelectedProfessional?.map(
                            (item) => item.id,
                          )}
                          employeeId={currSelectedProfessional?.[0]?.id}
                          selectedClient={selectedClient}
                        />
                      )}
                    </S.Main>
                  </div>
                )}
              </div>
              <Footer content={2} />
            </S.CustomerSearchContainer>
          }
        />
        {/* <Route
          path="new-client/*"
          element={
            <ClientDetails
              buttonHandler={() =>
                console.log(
                  'Save button pressed, performing additional actions',
                )
              }
            />
          }
        /> */}
      </Routes>
    </CreateOrderContext.Provider>
  );
};

export default CustomerSearch;
