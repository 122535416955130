import { useRef, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';

// style
import * as S from './OrderHeader.styled';

// icons
import { Euro } from 'icons/dynamic';

// components
import { BaseInputDate } from 'components/Inputs';
import Checkbox from 'components/Checkbox/Checkbox';
import InputFieldTime from 'components/InputFieldTime/InputFieldTime';
import Badge from 'components/Badge/Badge';

// contexts
import { OrderContext } from 'context/OrderContext';
import { UserContext } from 'context/UserContext';

// hooks
import useStatusList from 'hooks/useStatusList';
import useTranslate from 'hooks/useTranslate';
import { colors } from 'common/colors/colors';
import { toSentenceCase } from 'common/helpers/stringOperations';
import {
  addMinutes,
  getMinutesBetweenDates,
} from 'common/helpers/timeCalculations';

const OrderHeader = ({
  fillRows,
  status,
  workSchedule,
  orderId,
  dateDisabled,
  toggled,
  time,
  functions,
  servicesLength,
  displayOrderInfo,
  isOrderPaid,
}) => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);
  const { order, employeeId } = useContext(OrderContext);
  let currStatus = useStatusList([status])[0];
  const location = useLocation();
  const multiSelect =
    location.state?.multi === 'multi-selects' && location.state;

  // Hooks
  // --ref
  const availableTimesRef = useRef(null);

  // -- state
  // --- local
  // const [showAvailableTimes, setShowAvailableTimes] = useState(false);

  // const [startTimeInput, setStartTimeInput] = useState('--:--');
  const [startTimeInput, setStartTimeInput] = useState();

  // const [endTimeInput, setEndTimeInput] = useState('--:--');
  const [endTimeInput, setEndTimeInput] = useState();

  const [orderDuration, setOrderDuration] = useState([]);

  // --- global

  // -- side effect

  // TODO: not needed
  useEffect(() => {
    let start = new Date(order.state.startTime);
    start.setHours(start.getHours(), start.getMinutes(), 0, 0);

    let end = addMinutes(start, order.state.durationInMinutes);
    end.setHours(end.getHours(), end.getMinutes(), 0, 0);

    setOrderDuration({ startDate: start, endDate: end });
  }, [order.state]);

  useEffect(() => {
    setStartTimeInput(
      time?.start.toLocaleString(locale, {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h24',
      }),
    );

    setEndTimeInput(
      time?.end.toLocaleString(locale, {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h24',
      }),
    );
  }, [time, locale]);

  // Functions
  // const openAvailableTimes = () => {
  //   if (dateDisabled) return;
  //   setShowAvailableTimes(!showAvailableTimes);
  // };

  const getDateToString = () => {
    let date = time.start.toLocaleDateString(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });

    let weekday = time.start.toLocaleDateString(locale, {
      weekday: 'short',
    });
    weekday = toSentenceCase(weekday);

    return date + ' ' + weekday;
  };

  const handleInput = (value, type, id) => {
    let orderStartTime, orderEndTime;
    const orderCopy = structuredClone(order.state);

    if (orderCopy.id === orderId) {
      orderStartTime = orderCopy.startTime;
      orderEndTime = orderCopy.endTime;
    }

    let durationInMinutes = getMinutesBetweenDates(
      orderStartTime,
      orderEndTime,
    );

    let hour = value.split(':')[0];
    let minute = value.split(':')[1];

    switch (type) {
      case 'start':
        handleStartTimeInput(
          orderId,
          orderStartTime,
          orderEndTime,
          hour,
          minute,
          durationInMinutes,
        );

        break;
      case 'end':
        handleEndTimeInput(orderId, orderStartTime, hour, minute);
        break;
      default:
        return;
    }
  };

  const handleStartTimeInput = (
    id,
    orderStartTime,
    orderEndTime,
    hour,
    minute,
    durationInMinutes,
  ) => {
    if (hour && minute) {
      orderStartTime.setHours(hour, minute, 0, 0);
    }
    if (durationInMinutes > 0) {
      orderEndTime = new Date(new Date(orderStartTime));
      orderEndTime.setMinutes(orderStartTime.getMinutes() + durationInMinutes);
    }

    order.setState((prev) => {
      // TODO: do I need this > recalculatedTimeInOrders ?
      // const recalculatedTimeInOrders =
      //   functions.updateOrdersTime(updatedOrders);
      // return recalculatedTimeInOrders;

      return prev.id === id
        ? {
            ...prev,
            startTime: orderStartTime,
            endTime: orderEndTime,
            durationInMinutes: getMinutesBetweenDates(
              orderStartTime,
              orderEndTime,
            ),
          }
        : prev;
    });
  };

  const handleEndTimeInput = (id, orderStartTime, hour, minute) => {
    let endTime = new Date(orderStartTime);
    if (hour && minute) {
      endTime.setHours(hour, minute, 0, 0);
    }

    if (orderStartTime.getTime() + 15 * 60 * 1000 <= endTime.getTime()) {
      order.setState((prev) => {
        // const recalculatedTimeInOrders =
        //   functions.updateOrdersTime(updatedOrders);
        // return recalculatedTimeInOrders;

        return order.state.id === id
          ? {
              ...order.state,
              endTime: endTime,
              durationInMinutes: getMinutesBetweenDates(
                order.state.startTime,
                endTime,
              ),
            }
          : order.state;
      });
    } else {
      let newEndTime = new Date(orderStartTime);
      newEndTime.setMinutes(newEndTime.getMinutes() + 15);

      order.setState((prev) => {
        // const recalculatedTimeInOrders =
        //   functions.updateOrdersTime(updatedOrders);
        // return recalculatedTimeInOrders;

        return order.state.id === id
          ? { ...order.state, endTime: newEndTime }
          : order.state;
      });
    }
  };

  const updateOrderTime = (x) => {
    order.setState((prev) => {
      return { ...prev, startTime: prev.startDate, endTime: prev.endDate };
    });
  };

  const toggleReminder = () => {
    order.setState((prev) => {
      return {
        ...prev,
        sendVisitReminder: !prev.sendVisitReminder,
      };
    });
  };

  return (
    <S.OrderHeader
      bg={currStatus?.background}
      // showAvailableTimes={showAvailableTimes}
      dateDisabled={dateDisabled}
    >
      <div className="order-header">
        <Badge
          data-tooltip-id="tooltip"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            tr[isOrderPaid ? 'paid' : 'debt'],
          )}
          icon={<Euro />}
          md
          color={colors[isOrderPaid ? 'paidStatus' : 'debtStatus']}
        />
        <span className="order-header__status">{currStatus?.title}</span>
        <span># {orderId}</span>
        <Checkbox
          text="SMS:"
          value={{
            state: order.state.sendVisitReminder,
            action: toggleReminder,
            // state: demoToggleSMS,
            // action: () => setDemoToggleSMS(!demoToggleSMS),
          }}
        />
        {!!employeeId && (
          <>
            <div className="order-header__date-time">
              <BaseInputDate
                value={(order?.state?.startTime
                  ? new Date(order?.state?.startTime)
                  : new Date()
                ).toString()}
                onChange={(date) =>
                  order.setState((prev) => ({
                    ...prev,
                    startTime: new Date(
                      new Date(date).setHours(
                        order.state.startTime.getHours(),
                        order.state.startTime.getMinutes(),
                      ),
                    ),
                  }))
                }
              />
              {/* <span className="input">{getDateToString()}</span> */}
              <div className="time">
                <span className="input">
                  <InputFieldTime
                    value={startTimeInput}
                    onChange={(value) => handleInput(value, 'start')}
                  />
                </span>
                <span className="dash">-</span>
                <div className="input">
                  <InputFieldTime
                    value={endTimeInput}
                    onChange={(value) => handleInput(value, 'end')}
                    disabled={!servicesLength}
                  />
                </div>
              </div>
            </div>
            {/* <TheButton
              className="vacant-times-btn"
              title={tr['vacant-times']}
              icon={<Check inCircle bold color={colors.text_Secondary} />}
              customBackgroundColor="#fff"
              action={openAvailableTimes}
            /> */}
          </>
        )}
      </div>
      {/* <S.AvailableTimes
        bg={currStatus?.background}
        ref={availableTimesRef}
        grow={showAvailableTimes}
      >
        <AvailableTimeCalendar
          id={orderId}
          workSchedule={workSchedule}
          orderDuration={{ state: orderDuration, setState: setOrderDuration }}
          updateOrderTime={updateOrderTime}
        />
      </S.AvailableTimes> */}
    </S.OrderHeader>
  );
};

export default OrderHeader;
