import * as S from '../SVG.styled';

export default ({ color, width, height, bold }) => {
  const content = bold ? (
    <g>
      <path d="m18.417 16.374c-3.9735 0-7.2043-3.6709-7.2043-8.1854 0-4.5145 3.2308-8.1885 7.2043-8.1885 3.9735 0 7.2043 3.674 7.2043 8.1885 0 4.5145-3.2308 8.1854-7.2043 8.1854zm0-13.929c-2.6256 0-4.759 2.5767-4.759 5.7433 0 3.1666 2.1335 5.7402 4.759 5.7402 2.6256 0 4.759-2.5736 4.759-5.7402 0-3.1666-2.1335-5.7433-4.759-5.7433z" />
      <path d="m28.776 29.997h-20.717c-0.6755 0-1.2226-0.54712-1.2226-1.2226v-5.5293c0-3.4692 2.2038-6.5593 5.4865-7.6872l0.29954-0.10087c0.39124-0.13143 0.82221-0.06113 1.1462 0.19562 1.3724 1.0728 2.9801 1.6414 4.649 1.6414 1.6689 0 3.2736-0.56852 4.649-1.6414 0.32399-0.25369 0.75802-0.32705 1.1462-0.19562l0.29648 0.10087c3.2858 1.1309 5.4896 4.2211 5.4896 7.6903v5.5293c0 0.6755-0.54712 1.2226-1.2226 1.2226zm-19.495-2.4452h18.272v-4.3067c0-2.3169-1.4091-4.3953-3.5364-5.2634-1.6811 1.1493-3.6037 1.7545-5.5996 1.7545-1.9959 0-3.9185-0.6052-5.5996-1.7545-2.1274 0.86806-3.5364 2.9435-3.5364 5.2634z" />
      <path d="m4.8064 13.317c-2.65 0-4.8049-1.7667-4.8049-3.9399h2.4452c0 0.70606 0.96893 1.4947 2.3597 1.4947 1.3907 0 2.3597-0.78859 2.3597-1.4947 0-0.70606-0.96893-1.4947-2.3597-1.4947h-0.064188l-1.2562 0.033622v-2.5003l1.3204 0.021396c1.3907 0 2.3597-0.78859 2.3597-1.4947 0-0.70606-0.96893-1.4947-2.3597-1.4947-1.3907 0-2.3597 0.78859-2.3597 1.4947h-2.4452c0-2.1763 2.1549-3.9429 4.8049-3.9429 2.65 0 4.8049 1.7667 4.8049 3.9399 0 1.0515-0.50739 2.0112-1.3296 2.7173 0.82221 0.70606 1.3296 1.6658 1.3296 2.7173 0 2.1732-2.1549 3.9399-4.8049 3.9399z" />
    </g>
  ) : (
    <g>
      <path d="m18.56 15.797c-3.789 0-6.8738-3.5437-6.8738-7.8967 0-4.3531 3.0848-7.8999 6.8738-7.8999 3.789 0 6.8738 3.5437 6.8738 7.8999 0 4.3563-3.0848 7.8967-6.8738 7.8967zm0-14.522c-3.088 0-5.5991 2.9732-5.5991 6.6252s2.5112 6.6221 5.5991 6.6221 5.5991-2.97 5.5991-6.6221-2.5112-6.6252-5.5991-6.6252z" />
      <path d="m29.359 30h-21.6c-0.35054 0-0.63735-0.28681-0.63735-0.63735v-5.7648c0-3.3461 2.1256-6.3225 5.29-7.4124l0.3123-0.10516c0.20395-0.07011 0.42702-0.03187 0.59911 0.10198 1.5456 1.2078 3.3556 1.8451 5.239 1.8451s3.6934-0.63735 5.239-1.8451c0.1689-0.13384 0.39516-0.1689 0.59911-0.10198l0.30911 0.10516c3.1644 1.0899 5.2932 4.0695 5.2932 7.4124v5.7648c0 0.35054-0.28681 0.63735-0.63735 0.63735zm-20.962-1.2747h20.325v-5.1275c0-2.7948-1.775-5.2868-4.4168-6.2014-1.7081 1.2492-3.6902 1.9057-5.7457 1.9057s-4.0376-0.65966-5.7457-1.9057c-2.6418 0.9146-4.4168 3.4066-4.4168 6.2014z" />
      <path d="m4.369 12.61c-2.4124 0-4.3722-1.5583-4.3722-3.4704h1.2747c0 1.211 1.3894 2.1957 3.0975 2.1957s3.0975-0.9847 3.0975-2.1957c0-1.211-1.3894-2.1957-3.0975-2.1957l-0.73932 0.012747v-1.297l0.73932 0.00956c1.7081 0 3.0975-0.9847 3.0975-2.1957s-1.3894-2.1957-3.0975-2.1957-3.0975 0.9847-3.0975 2.1957h-1.2747c0-1.9152 1.963-3.4735 4.3722-3.4735s4.3722 1.5583 4.3722 3.4704c0 1.1695-0.72976 2.2052-1.8483 2.833 1.1185 0.62779 1.8483 1.6635 1.8483 2.833 0 1.9152-1.963 3.4704-4.3722 3.4704z" />
    </g>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
