import * as S from '../SVG.styled';

export default ({ color, width, height, bold }) => {
  const content = bold ? (
    <g>
      <path d="m18.416 16.374c-3.9735 0-7.2043-3.6709-7.2043-8.1854 0-4.5145 3.2308-8.1885 7.2043-8.1885s7.2043 3.674 7.2043 8.1885c0 4.5145-3.2308 8.1854-7.2043 8.1854zm0-13.929c-2.6256 0-4.759 2.5767-4.759 5.7433 0 3.1666 2.1335 5.7402 4.759 5.7402s4.759-2.5736 4.759-5.7402c0-3.1666-2.1335-5.7433-4.759-5.7433z" />
      <path d="m28.774 29.997h-20.717c-0.6755 0-1.2226-0.54712-1.2226-1.2226v-5.5293c0-3.4692 2.2038-6.5593 5.4865-7.6872l0.3026-0.10087c0.39124-0.13143 0.82221-0.06113 1.1462 0.19562 1.3724 1.0728 2.9801 1.6414 4.649 1.6414s3.2736-0.56852 4.649-1.6414c0.32399-0.25369 0.75802-0.32705 1.1462-0.19562l0.29648 0.10087c3.2858 1.1309 5.4896 4.2211 5.4896 7.6903v5.5293c0 0.6755-0.54712 1.2226-1.2226 1.2226zm-19.495-2.4452h18.272v-4.3067c0-2.3169-1.4091-4.3953-3.5364-5.2634-1.6811 1.1493-3.6037 1.7545-5.5996 1.7545-1.9959 0-3.9185-0.6052-5.5996-1.7545-2.1274 0.86806-3.5364 2.9435-3.5364 5.2634z" />
      <path d="m8.3963 13.382h-8.2282l5.728-7.6964c0.54712-0.80998 0.68772-1.7239 0.36984-2.3933-0.22618-0.47376-0.64799-0.73968-1.2593-0.78859-0.064188-0.00611-0.12837-0.00917-0.19562-0.00917-1.3051 0-2.3658 1.0606-2.3658 2.3658h-2.4452c0-2.6531 2.1579-4.811 4.811-4.811 0.13143 0 0.26286 0.0061131 0.39124 0.015283 1.4763 0.11921 2.6684 0.91391 3.2705 2.1763 0.69384 1.458 0.47376 3.3133-0.57463 4.8446l-0.027509 0.039735-2.8365 3.8115h3.3622z" />
    </g>
  ) : (
    <>
      <g transform="matrix(.31867 0 0 .31867 -.0031865 2.283e-7)">
        <path d="m58.25 49.57c-11.89 0-21.57-11.12-21.57-24.78s9.68-24.79 21.57-24.79 21.57 11.12 21.57 24.79-9.68 24.78-21.57 24.78zm0-45.57c-9.69 0-17.57 9.33-17.57 20.79s7.88 20.78 17.57 20.78 17.57-9.32 17.57-20.78-7.88-20.79-17.57-20.79z" />
        <path d="m92.14 94.14h-67.78c-1.1 0-2-0.9-2-2v-18.09c0-10.5 6.67-19.84 16.6-23.26l0.98-0.33c0.64-0.21 1.34-0.1 1.88 0.32 4.85 3.79 10.53 5.79 16.44 5.79s11.59-2 16.44-5.79c0.53-0.42 1.24-0.54 1.88-0.32l0.97 0.33c9.94 3.42 16.61 12.77 16.61 23.26v18.09c0 1.1-0.9 2-2 2zm-65.78-4h63.78v-16.09c0-8.77-5.57-16.59-13.86-19.46-5.36 3.92-11.57 5.98-18.03 5.98s-12.67-2.07-18.03-5.98c-8.29 2.87-13.86 10.69-13.86 19.46z" />
        <path d="m25.48 39.78h-22.95l16.41-22.06c2.18-3.18 2.72-6.97 1.36-9.81-1.04-2.18-3.08-3.5-5.76-3.71-0.27-0.02-0.53-0.03-0.8-0.03-5.37 0-9.74 4.37-9.74 9.74h-4c0-7.58 6.16-13.75 13.74-13.75 0.38 0 0.76 0.01 1.13 0.05 4.09 0.33 7.39 2.51 9.05 5.98 1.97 4.13 1.31 9.44-1.72 13.86l-11.7 15.73h14.98z" />
        <path d="m58.25 49.57c-11.89 0-21.57-11.12-21.57-24.78s9.68-24.79 21.57-24.79 21.57 11.12 21.57 24.79-9.68 24.78-21.57 24.78zm0-45.57c-9.69 0-17.57 9.33-17.57 20.79s7.88 20.78 17.57 20.78 17.57-9.32 17.57-20.78-7.88-20.79-17.57-20.79z" />
        <path d="m92.14 94.14h-67.78c-1.1 0-2-0.9-2-2v-18.09c0-10.5 6.67-19.84 16.6-23.26l0.98-0.33c0.64-0.21 1.34-0.1 1.88 0.32 4.85 3.79 10.53 5.79 16.44 5.79s11.59-2 16.44-5.79c0.53-0.42 1.24-0.54 1.88-0.32l0.97 0.33c9.94 3.42 16.61 12.77 16.61 23.26v18.09c0 1.1-0.9 2-2 2zm-65.78-4h63.78v-16.09c0-8.77-5.57-16.59-13.86-19.46-5.36 3.92-11.57 5.98-18.03 5.98s-12.67-2.07-18.03-5.98c-8.29 2.87-13.86 10.69-13.86 19.46z" />
        <path d="m25.48 39.78h-22.95l16.41-22.06c2.18-3.18 2.72-6.97 1.36-9.81-1.04-2.18-3.08-3.5-5.76-3.71-0.27-0.02-0.53-0.03-0.8-0.03-5.37 0-9.74 4.37-9.74 9.74h-4c0-7.58 6.16-13.75 13.74-13.75 0.38 0 0.76 0.01 1.13 0.05 4.09 0.33 7.39 2.51 9.05 5.98 1.97 4.13 1.31 9.44-1.72 13.86l-11.7 15.73h14.98z" />
      </g>
      <g>
        <path d="m18.56 15.797c-3.789 0-6.8738-3.5437-6.8738-7.8967 0-4.3531 3.0848-7.8999 6.8738-7.8999 3.789 0 6.8738 3.5437 6.8738 7.8999 0 4.3563-3.0848 7.8967-6.8738 7.8967zm0-14.522c-3.088 0-5.5991 2.9732-5.5991 6.6252s2.5112 6.6221 5.5991 6.6221 5.5991-2.97 5.5991-6.6221-2.5112-6.6252-5.5991-6.6252z" />
        <path d="m29.359 30h-21.6c-0.35054 0-0.63735-0.28681-0.63735-0.63735v-5.7648c0-3.3461 2.1256-6.3225 5.29-7.4124l0.3123-0.10516c0.20395-0.06692 0.42702-0.03187 0.59911 0.10198 1.5456 1.2078 3.3556 1.8451 5.239 1.8451s3.6934-0.63735 5.239-1.8451c0.1689-0.13384 0.39516-0.17208 0.59911-0.10198l0.30911 0.10516c3.1676 1.0899 5.2932 4.0695 5.2932 7.4124v5.7648c0 0.35054-0.28681 0.63735-0.63735 0.63735zm-20.962-1.2747h20.325v-5.1275c0-2.7948-1.775-5.2868-4.4168-6.2014-1.7081 1.2492-3.6871 1.9057-5.7457 1.9057s-4.0376-0.65966-5.7457-1.9057c-2.6418 0.9146-4.4168 3.4066-4.4168 6.2014z" />
        <path d="m8.1166 12.677h-7.3136l5.2294-7.03c0.69471-1.0134 0.86679-2.2212 0.4334-3.1262-0.33142-0.69471-0.98152-1.1154-1.8356-1.1823-0.086042-0.00637-0.1689-0.00956-0.25494-0.00956-1.7113 0-3.1039 1.3926-3.1039 3.1039h-1.2747c0-2.4156 1.963-4.3818 4.3786-4.3818 0.1211 0 0.24219 0.0031867 0.3601 0.015934 1.3034 0.10516 2.355 0.79987 2.884 1.9057 0.62779 1.3161 0.41746 3.0083-0.54812 4.4168l-3.7285 5.0127h4.7737z" />
      </g>
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
