import { useNavigate } from 'react-router';

// style
import { colors } from 'common/colors/colors';

// icons
import {
  CopySVG,
  Euro,
  PasteSVG,
  TrashCan,
  User1SVG,
  User2SVG,
  User3SVG,
} from 'icons/dynamic';
import SendSVG from 'icons/bold/SendSVG';
import RecommendationSVG from 'icons/bold/RecommendationSVG';

// components
import Badge from 'components/Badge/Badge';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const useFunctionList = (contents) => {
  const navigate = useNavigate();
  const tr = useTranslate().use().global;

  const functions = [
    {
      id: 'send-sms-reminder',
      title: tr.sendSMSreminder,
      icon: <SendSVG />,
      action: () => console.log(tr.sendSMSreminder),
      acl: 1,
      scope: 3,
      hide: () => true,
    },
    {
      id: 'send-sms-link',
      title: tr.sendSMSlink,
      icon: <SendSVG />,
      action: () => console.log(tr.sendSMSlink),
      acl: 1,
      scope: 3,
      hide: () => true,
    },
    {
      id: 'delete-order',
      title: tr.removeOrder,
      icon: <TrashCan />,
      color: colors.mandyRed,
      action: () => console.log(tr.removeOrder),
      acl: 1,
      scope: 3,
      hide: () => true,
    },
    {
      id: 'save-recommendation',
      title: tr['save-recommendation'],
      icon: <RecommendationSVG />,
      action: () => console.log(tr['save-recommendation']),
      acl: 1,
      scope: 3,
      hide: () => true,
    },
    {
      id: 'write-an-email',
      title: tr['write-an-email'],
      action: () => console.log(tr['write-an-email']),
      acl: 1,
      scope: 3,
      hide: () => true,
    },
    {
      id: 'deactivate-record',
      title: tr['deactivate-record'],
      action: () => console.log(tr['deactivate-record']),
      acl: 1,
      scope: 3,
      hide: () => true,
      color: colors.mandyRed,
    },
    {
      id: 'copy-schedule-record',
      title: tr['copy-schedule-record'],
      action: () => console.log(tr['copy-schedule-record']),
      acl: 1,
      scope: 3,
      hide: () => true,
      color: colors.text_Secondary,
      icon: <CopySVG width={16} height={16} bold />,
    },
    {
      id: 'paste-schedule-record',
      title: tr['paste-schedule-record'],
      action: () => console.log(tr['paste-schedule-record']),
      acl: 1,
      scope: 3,
      hide: () => true,
      color: colors.text_Secondary,
      icon: <PasteSVG width={16} height={16} bold />,
    },
    {
      id: 'clear-schedule-record',
      title: tr['clear-schedule-record'],
      action: () => console.log(tr['clear-schedule-record']),
      acl: 1,
      scope: 3,
      hide: () => true,
      color: colors.mandyRed,
      icon: <TrashCan width={16} height={16} />,
    },
    {
      id: 'client-card',
      title: tr['client-card'],
      action: () => console.log(tr['client-card']),
      acl: 1,
      scope: 3,
      hide: () => true,
    },
    // {
    //   id: 'orders-history',
    //   title: tr['orders-history'],
    //   action: () => console.log(tr['orders-history']),
    //   acl: 1,
    //   scope: 3,
    //   hide: () => true,
    // },
    {
      id: 'show-one-professional',
      acl: 1,
      icon: <User1SVG bold />,
      name: tr['show-one-professional'],
      action: () => console.log(tr['show-one-professional']),
      hide: () => true,
      value: 1,
    },
    {
      id: 'show-two-professionals',
      acl: 1,
      icon: <User2SVG bold />,
      name: tr['show-two-professionals'],
      action: () => console.log(tr['show-two-professionals']),
      hide: () => true,
      value: 2,
    },
    {
      id: 'show-three-professionals',
      acl: 1,
      icon: <User3SVG bold />,
      name: tr['show-three-professionals'],
      action: () => console.log(tr['show-three-professionals']),
      hide: () => true,
      value: 3,
    },
    {
      id: 'order-paid',
      acl: 1,
      icon: <Badge icon={<Euro />} sm color={colors.paidStatus} />,
      title: tr['paid-for-known'],
      action: () => console.log('order-paid'),
      hide: () => true,
    },
    {
      id: 'order-unpaid',
      acl: 1,
      icon: <Badge icon={<Euro />} sm color={colors.debtStatus} />,
      title: tr['unpayed-for-known'],
      action: () => console.log('order-unpaid'),
      hide: () => true,
    },
  ];

  return contents.map((item) => functions.find((func) => func.id === item));
};

export default useFunctionList;
