import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';
import { Search_Container } from 'components/Search/Search.styled';
import { Avatar } from 'components/Avatar/Avatar.styled';
import { EmployeesSelector } from 'pages/Order/partials/EmployeesSelector/EmployeesSelector.styled';

export const ServiceMenu = styled.div`
  /* min-width: ${(props) => (props.show ? 400 : 0)}px;
  max-width: 540px; */
  /* width: ${(props) => (props.show ? '30%' : '0px')}; */
  width: ${(props) => (props.show ? 500 : 0)}px;
  flex: none;
  overflow: hidden;

  transition: 0.3s ease-in-out;
  transition-delay: ${(props) => props.showEmerging && 0.1}s;

  height: 100vh;

  position: sticky;
  top: 0;
  left: 0;

  box-shadow: ${shadow.lg};
  z-index: 7;
  background-color: white;

  .menu__header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 20px 40px;
    .header_burger {
      height: 25px;
      margin-right: 30px;
      cursor: pointer;
      svg {
        fill: ${colors.bg_Primary};
      }
    }
    h2 {
      font-size: 30px;
      margin: 0;
      padding-bottom: 2px;
      font-weight: 600;
    }
  }

  .menu__content {
    padding: 10px 40px;
    gap: 18px;
    display: flex;
    flex-direction: column;
  }

  ${EmployeesSelector} {
    .row-content {
      width: calc(100% + 10px);
      margin-left: -5px;
    }
  }

  .service-container {
    height: calc(100vh - 250px);

    border-top: 1px solid ${colors.bg_grey_2};

    overflow: auto;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .services__wrapper {
    height: calc(100vh - 250px);

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      font-family: ${fontFamilies.family_Secondary};

      li {
        margin-bottom: 5px;

        :last-child {
          margin-bottom: 0;
        }
      }

      li .context-menu-icon-container {
        height: auto;
      }
    }

    overflow: auto;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &-empty {
      border: 1px solid red;

      text-align: center;

      font-size: 1rem;

      padding: 10px;

      border: 1px solid ${colors.bg_grey_2};
      border-radius: 5px;
    }
  }

  .content_actions {
    display: flex;
    flex-direction: column;
    gap: 30px;

    ${Search_Container} {
      height: 30px;

      .searchIcon,
      .searchIcon svg {
        height: 16px;
        width: 16px;
      }

      .searchInput {
        font-size: 0.875rem;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    width: ${(props) => (props.show ? 400 : 0)}px;
  }
`;

export const EmptyEmployee = styled.div`
  height: 30px;

  border-radius: 100px !important;
  background-color: ${colors.bg_grey_1};

  padding: 0 10px;

  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;

  > span {
    font-family: ${fontFamilies.family_Secondary};
    font-size: 12px;
    color: ${colors.bg_grey_3};
  }

  /* .iconContainer {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translate(50%, -40%);
    width: 14px;
  } */

  ${Avatar} {
    width: 20px;
    height: 20px;
  }
`;
