import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const StyledInputErrorMessage = styled.span`
  color: ${colors.mandyRed};
  font-size: 12px;
  font-family: ${fontFamilies.family_Secondary};
  position: absolute;
  bottom: -2px;
  left: 10px;
  transform: translate3d(0, 100%, 0);
  line-height: 10px;
`;
