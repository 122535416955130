// style
import * as S from './BasicTableRecord.styled';

export const BasicTableRecord = ({ columns, record, navigation, ...rest }) => (
  <S.BasicTableRecord {...rest}>
    {columns.map((column) => (
      <td
        onDoubleClick={() => navigation(record)}
        key={column.id + '-' + record.id}
      >
        {record[column.id]}
      </td>
    ))}
  </S.BasicTableRecord>
);
