import * as S from '../SVG.styled';

const InterchangeSVG = ({ color, width, height, sm, lg }) => {
  let content;
  let size;

  switch (true) {
    case sm:
      size = 16;
      content = (
        <>
          <path d="m15.348 8.454h-6.2425c-0.3603 0-0.65213 0.2918-0.65213 0.65206v5.5898h-2.8139c-0.67006 0-1.2146-0.54447-1.2146-1.2145v-2.5675l1.0092 1.0107c0.20216 0.20214 0.54942 0.05869 0.54942-0.22659v-0.96831c0-0.08477-0.034237-0.16628-0.094559-0.22659l-2.1162-2.1159-2.1162 2.1159c-0.060322 0.06032-0.094559 0.14182-0.094559 0.22659v0.96831c0 0.28691 0.34726 0.43036 0.54942 0.22659l1.0092-1.0107v2.5675c2e-7 1.3889 1.1298 2.5186 2.5189 2.5186h9.7086c0.3603 0 0.65213-0.2918 0.65213-0.65206v-6.2419c0-0.36026-0.29183-0.65206-0.65213-0.65206zm-0.65213 6.2419h-4.9383v-4.9377h4.9383z" />
          <path d="m7.5468 6.8939v-5.5898h2.8139c0.67006 0 1.2146 0.54447 1.2146 1.2145v2.5675l-1.0108-1.0107c-0.20216-0.20214-0.54942-0.058686-0.54942 0.22659v0.96668c0 0.084768 0.03424 0.16628 0.09456 0.22822l2.1162 2.1159 2.1162-2.1159c0.06032-0.060316 0.09456-0.14182 0.09456-0.22822v-0.96668c0-0.28691-0.34563-0.43036-0.54942-0.22659l-1.0092 1.0107v-2.5675c0-1.3889-1.1298-2.5186-2.5188-2.5186h-9.7069c-0.3603 0-0.65213 0.2918-0.65213 0.65206v6.2419c0 0.36026 0.29183 0.65206 0.65213 0.65206h6.2425c0.3603 0 0.65213-0.2918 0.65213-0.65206zm-1.3043-0.65206h-4.9383v-4.9377h4.9383z" />
        </>
      );
      break;
    case lg:
    default:
      size = 30;
      content = (
        <>
          <path d="m29.36 16.523h-12.196c-0.35047 0-0.63721 0.28675-0.63721 0.63721v11.562h-6.1364c-1.6599 0-3.0108-1.3509-3.0108-3.0108v-7.4873l1.0578 1.0578c0.17205 0.17205 0.46517 0.05098 0.46517-0.19116v-1.0641c0-0.07328-0.028675-0.14019-0.079652-0.19116l-2.0805-2.0805-2.0805 2.0805c-0.050977 0.05098-0.079652 0.12107-0.079652 0.19116v1.0641c0 0.24214 0.29312 0.36321 0.46517 0.19116l1.0578-1.0578v7.4904c3e-7 2.3641 1.9212 4.2853 4.2853 4.2853h18.97c0.35047 0 0.63721-0.28675 0.63721-0.63721v-12.203c0-0.35047-0.28675-0.63721-0.63721-0.63721zm-0.63721 12.199h-10.922v-10.925h10.925v10.925z" />
          <path d="m0.6404 13.474h12.199c0.35047 0 0.63721-0.28675 0.63721-0.63721v-11.562h6.1364c1.6599 0 3.0108 1.3509 3.0108 3.0108v7.4873l-1.0578-1.0578c-0.17205-0.17205-0.46517-0.05098-0.46517 0.19116v1.0641c0 0.07328 0.02867 0.14019 0.07965 0.19116l2.0805 2.0805 2.0805-2.0805c0.05098-0.05098 0.07965-0.12107 0.07965-0.19116v-1.0641c0-0.24214-0.29312-0.36321-0.46517-0.19116l-1.0578 1.0578v-7.4873c4e-6 -2.3641-1.9212-4.2853-4.2853-4.2853h-18.973c-0.35047 0-0.63721 0.28675-0.63721 0.63721v12.199c0 0.35047 0.28675 0.63721 0.63721 0.63721zm0.63721-12.199h10.925v10.925h-10.925z" />
        </>
      );
      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : size}
      height={height ? height : size}
      version="1.1"
      viewBox={`0 0 ${size} ${size}`}
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default InterchangeSVG;
