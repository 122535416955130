import { useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

// style
import * as S from './ColumnsVisibility.styled';

// icons
import { Check, Circle } from 'icons/dynamic';

// contexts
import { TableContext } from 'context/TableContext';

export const ColumnsVisibility = () => {
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');

  const {
    state: { columns },
    dispatch: tableDispatch,
  } = useContext(TableContext);

  const checkIfVisible = useCallback(
    (column) => (column?.isSectorSpecific ? !!sectorId : true),
    [sectorId],
  );

  const enabledColumnsAmount = columns.filter(
    (column) => checkIfVisible(column) && column.isVisible,
  ).length;

  const disabled = enabledColumnsAmount <= 1;

  const handleClick = useCallback(
    (column) => {
      if (disabled && column.isVisible) {
        return;
      }

      tableDispatch({
        type: 'SET_COLUMN',
        payload: { ...column, isVisible: !column.isVisible },
      });
    },
    [disabled],
  );

  return (
    <S.ColumnsVisibility>
      {columns?.map(
        (column) =>
          checkIfVisible(column) && (
            <S.Option
              key={column.id}
              onClick={() => handleClick(column)}
              disabled={disabled}
            >
              {column.isVisible ? (
                <Check inCircle black width={16} height={16} />
              ) : (
                <Circle bold width={16} height={16} />
              )}

              <label htmlFor={column.id}>{column.label}</label>
            </S.Option>
          ),
      )}
    </S.ColumnsVisibility>
  );
};
