import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Style
import * as S from './CustomerInfo.styled';

// SVG
import CreateNewBoldSVG from 'icons/CreateNewBoldSVG';
import DebtSVG from 'icons/DebtSVG';
import RecommendationSVG from 'icons/bold/RecommendationSVG';

// Translation
import { useTranslate } from 'hooks/useTranslate';

// Components
import TheButton from 'components/Buttons/TheButton';
import PendingOrderCard from 'components/PendingOrderCard/PendingOrderCard';
import Overlay from 'components/Overlay/Overlay';
import DialogBox from 'components/DialogBox/DialogBox';
import RecommendationModal from 'components/RecommendationModal/RecommendationModal';

// Hooks
import useModal from 'hooks/useModal';
import TrashcanBoldSVG from 'icons/TrashcanBoldSVG';
import useMediaQuery from 'hooks/useMediaQuery';
import { ORDER_COMPLETION_STATUS } from 'common/Constants';

const CartCard = ({ icon, topTooltip, botTooltip, action }) => {
  return (
    <S.CartCard onClick={action}>
      <div className="icon">{icon}</div>
      <span className="tooltip">
        <span>
          <span>{topTooltip}</span>
          <span>{botTooltip}</span>
        </span>
      </span>
    </S.CartCard>
  );
};

const CustomerInfo = ({
  customer,
  createOrder,
  setButtons,
  employeeIds,
  selectedClient,
}) => {
  const tr = useTranslate().use().global;
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 960px)');

  // ----- TEMP DATA ------
  const futureOrdersArr = [
    {
      id: 0,
      title: 'Watercolor Landscape',
      startDate: new Date(2022, 8, 29, 11, 35),
      endDate: new Date(2022, 8, 29, 12, 35),
      category: 'calendar',
      members: [4],
      status: ORDER_COMPLETION_STATUS.CONFIRMED,
      services: [
        'Kanalo apdorojimas šalia užlūžusio instrumento sudėtingas labai labai labai labai labai',
        'Kanalo apdorojimas šalia užlūžusio instrumento sudėtingas labai labai labai labai labai',
      ],
      note: 'Klientą atsiuntė kliniko Dantukas gyd. Vardenis Pavardenis',
    },
    {
      id: 1,
      title: 'Watercolor Landscape',
      startDate: new Date(2022, 8, 29, 11, 35),
      endDate: new Date(2022, 8, 29, 12, 0),
      category: 'calendar',
      members: [3],
      status: ORDER_COMPLETION_STATUS.ORDERED,
      services: ['Trumpas tekstas', 'Trumpas tekstas'],
      note: 'Trumpas tekstas',
    },
    // {
    //   id: 2,
    //   title: 'Watercolor Landscape',
    //   startDate: new Date(2022, 8, 29, 11, 35),
    //   endDate: new Date(2022, 8, 29, 12, 0),
    //   category: 'calendar',
    //   members: [3],
    //   status: ORDER_COMPLETION_STATUS.ARRIVED_AND_WAITING,
    //   services: [
    //     'Kanalo apdorojimas šalia užlūžusio instrumento sudėtingas labai labai labai labai labai',
    //   ],
    //   note: 'Klientą atsiuntė kliniko Dantukas gyd. Vardenis Pavardenis ',
    // },
  ];

  const pendingOrdersArr = [
    {
      id: 0,
      title: 'Watercolor Landscape',
      startDate: new Date(2022, 8, 29, 11, 35),
      endDate: new Date(2022, 8, 29, 12, 35),
      category: 'calendar',
      members: [2],
      status: ORDER_COMPLETION_STATUS.WILL_ARRIVE,
      services: [
        'Kanalo apdorojimas šalia užlūžusio instrumento sudėtingas labai labai labai labai labai',
        'Kanalo apdorojimas šalia užlūžusio instrumento sudėtingas labai labai labai labai labai',
      ],
      note: 'Klientą atsiuntė kliniko Dantukas gyd. Vardenis Pavardenis',
    },
  ];

  const clientCartArr = [
    {
      id: 'break-1',
      icon: <DebtSVG />,
      topTooltip: 'Skola: 245,56',
      botTooltip: 'Galioja: 2021-09-03',
    },
    {
      id: 'recomendation',
      icon: <RecommendationSVG />,
      topTooltip: 'Vardas Pavarde',
      botTooltip: 'Rekomendacija',
      action: () => toggle(),
    },
    {
      id: 'break-2',
      icon: <DebtSVG />,
      topTooltip: 'Skola: 245,56',
      botTooltip: 'Galioja: 2021-09-03',
    },
  ];
  const [futureOrders, setFutureOrders] = useState(futureOrdersArr);
  const [pendingOrders, setPendingOrders] = useState(pendingOrdersArr);
  const [clientCart, setClientCart] = useState(clientCartArr);

  // ----------------------
  // recommendation modal buttons
  const recommendationButtons = [
    {
      title: tr['delete-recommendation'],
      icon: <TrashcanBoldSVG />,
      inverted: true,
      action: () => {
        console.log(tr['delete-recommendation']);
      },
    },
    {
      title: tr['order-services'],
      icon: <CreateNewBoldSVG />,
      action: () => {
        console.log(tr['order-services']);
      },
    },
  ];

  // Hooks
  // -- state
  // --- local
  const { isShowing, toggle } = useModal();

  // side effects
  useEffect(() => {
    if (!isMobile) return;
    if (!createOrder) return;

    setButtons({
      type: 'setup',
      value: [
        {
          id: 'edit',
          icon: <CreateNewBoldSVG />,
          action: () => navigateToCreateOrder(),
          show: true,
        },
      ],
    });
  }, []);

  useEffect(() => {
    if (customer?.firstName === 'Neymar') {
      setFutureOrders([]);
      setClientCart([]);
    } else {
      setFutureOrders(futureOrdersArr);
      setClientCart(clientCartArr);
    }
  }, [customer]);

  // Functions
  const navigateToCreateOrder = () => {
    navigate('/functions/tables/orders/new', {
      state: {
        ...location.state,
        employeeIds,
        selectedClient,
      },
    });
  };

  return (
    <>
      <S.CustomerInfo cart={clientCart.length}>
        <div className="selected-client__header" style={{ paddingTop: '20px' }}>
          <h2>
            {customer.name} {customer.lastName}
          </h2>

          {createOrder && (
            <TheButton
              title={tr['create-order']}
              primary
              action={navigateToCreateOrder}
              Icon={CreateNewBoldSVG}
            />
          )}
        </div>
        {/* <div className="selected-client__main">
          <div className="client__pending-orders-container">
            <div className="orders__wrapper">
              <h4>{tr['futureOrders']}</h4>
              <div className="pending-orders">
                {futureOrders.length ? (
                  futureOrders.map((obj, i) => (
                    <PendingOrderCard
                      key={i}
                      order={obj}
                      updateOrders={setFutureOrders}
                    />
                  ))
                ) : (
                  <span className="none">{tr.none}</span>
                )}
              </div>
            </div>
            <div className="orders__wrapper">
              <h4>{tr['pending-orders']}</h4>
              <div className="pending-orders">
                {pendingOrders.length ? (
                  pendingOrders.map((obj, i) => (
                    <PendingOrderCard
                      key={i}
                      order={obj}
                      updateOrders={setFutureOrders}
                      pending
                    />
                  ))
                ) : (
                  <span className="none">{tr.none}</span>
                )}
              </div>
            </div>
          </div>

          <div className="client__info-container">
            <div className="client__cart-container">
              <h4>{tr['client-cart']}</h4>
              <div className="cart">
                {clientCart.length ? (
                  clientCart.map((obj, i) => (
                    <CartCard
                      key={i}
                      icon={obj.icon}
                      topTooltip={obj.topTooltip}
                      botTooltip={obj.botTooltip}
                      action={obj?.action}
                    />
                  ))
                ) : (
                  <span className="none">{tr.none}</span>
                )}
              </div>
            </div>
            <div className="client__card-container">
              <h4>{tr['client-card']}</h4>
              <div className="card">
                <span>{tr['general-information']}</span>
                <span>{tr['orders']}</span>
              </div>
            </div>
          </div>
        </div> */}
      </S.CustomerInfo>
      <Overlay isVisible={isShowing} hide={toggle}>
        <div className="modal-wrapper">
          <DialogBox
            headerIcon={<RecommendationSVG />}
            headerText={tr['recommendation']}
            close={() => toggle()}
            buttons={recommendationButtons}
          >
            <RecommendationModal />
          </DialogBox>
        </div>
      </Overlay>
    </>
  );
};

export default CustomerInfo;
