import { StyledSVG } from './SVG.styled';

const ItemAdd = ({ color, width, height }) => {
  return (
    <StyledSVG
      width={width ? width : '20'}
      height={height ? height : '20'}
      version="1.0"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="matrix(1.0526 0 0 1.0526 -352.63 -565.26)"
        data-name="Group 3210"
      >
        <g
          transform="translate(335,537)"
          fill="#ffa35f"
          stroke={color ? color : '#ffa35f'}
          data-name="Ellipse 88"
        >
          <circle cx="9.5" cy="9.5" r="9.5" stroke="none" />
          <circle cx="9.5" cy="9.5" r="9" fill="none" />
        </g>
        <path
          transform="translate(-7539.4 -2583.6)"
          d="m7883.7 3122.6a0.786 0.786 0 0 0-0.786 0.787v5.7h-5.7a0.7865 0.7865 0 1 0 0 1.573h5.7v5.7a0.786 0.786 0 1 0 1.572 0v-5.7h5.7a0.787 0.787 0 0 0 0-1.573h-5.7v-5.7a0.786 0.786 0 0 0-0.786-0.787z"
          fill="#fff"
          data-name="Path 804"
        />
      </g>
    </StyledSVG>
  );
};

export default ItemAdd;
