import { useContext, useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

// Service
import { useSectorsGetAll } from 'services/sectorService';

// Context
import { UserContext } from 'context/UserContext';

// hooks
import useTranslate from './useTranslate';

export const useSectorsTabs = (employeeId, includeAllTab = false) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const tr = useTranslate().use().global;
  const { employeeIds } = useContext(UserContext);

  const [tabs, setTabs] = useState([]);

  const sectorId = searchParams.get('sectorId');

  // -- api
  const sectorsBE = useSectorsGetAll({
    searchParams: { employeeIds: employeeIds },
    queryParams: { enabled: !!employeeIds },
  });

  useEffect(() => {
    if (sectorsBE.isSuccess) {
      const sectors = sectorsBE.data.map((sector) => {
        const newItem = {
          ...sector,
          name: sector.name,
          params: { sectorId: sector.id },
        };

        delete newItem.id;

        return newItem;
      });

      if (includeAllTab) {
        sectors?.unshift({ name: tr['all'], end: true });
      }

      setTabs(sectors);
    }
  }, [sectorsBE.isSuccess, sectorsBE.data]);

  useEffect(() => {
    const sectorId = searchParams.get('sectorId');

    if (sectorId || !tabs.length) return;

    // const params = createSearchParams({
    //   ...tabs[0].params,
    //   ...(employeeId && { employeeId }),
    // }).toString();

    setSearchParams((old) => {
      console.log(old.toString());
      return {
        ...old,
        ...tabs[0].params,
        ...(employeeId && employeeId !== 0 && { employeeId }),
      };
    });

    // navigate({ to: './', search: params }, { state: location.state });
  }, [tabs, searchParams, setSearchParams, employeeId, sectorId]);

  return tabs;
};

export default useSectorsTabs;
