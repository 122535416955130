import { useEffect, useState } from 'react';
import { useDelete, useFetch, useFetchMulti, usePost } from './useApi2';

export const useEmployeesFind = (params = {}) => {
  const DB_SLICE = 'employees/find';

  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
  });

  return context;
};

export const useEmployeesGet = (params = []) => {
  const DB_SLICE = 'employees';

  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      staleTime: 1000 * 60 * 60,
      ...params.queryParams,
    },
  });

  return context;
};

export const useEmployeesGetMultiple = (params = []) => {
  const DB_SLICE = 'employees';

  return useFetchMulti(
    params.map((el) => {
      return {
        endpoint: DB_SLICE,
        ...el,
      };
    }),
  );
};

export const useEmployeesGetOneSectorSettings = (params = {}) => {
  const DB_SLICE = 'employees/sectorSettings';

  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      staleTime: 1000 * 60 * 60,
      ...params.queryParams,
    },
  });

  return context;
};

export const useEmployeesBrowsePost = (params = {}) => {
  const DB_SLICE = 'employees/browse';

  return usePost({
    endpoint: DB_SLICE,
    reqObjName: null,
    ...params,
  });
};

export const useEmployeesPost = (params = {}) => {
  const DB_SLICE = 'employees';

  return usePost({
    endpoint: DB_SLICE,
    ...params,
  });
};

export const useEmployeesDelete = (params = {}) => {
  const DB_SLICE = 'employees';

  return useDelete({
    endpoint: DB_SLICE,
    ...params,
  });
};

export const useEmployeesSectorSettingsPost = (params = {}) => {
  const DB_SLICE = 'employees/sectorSettings';

  return usePost({
    endpoint: DB_SLICE,
    reqObjName: null,
    ...params,
  });
};

export const useEmployeesGetScheduleById = (params = {}) => {
  const DB_SLICE = 'employees/schedules';

  // get specific employee data from B/E
  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: { retry: true, ...params.queryParams },
  });

  // get label according to value
  const getLabel = (record, header) => {
    const options = header?.options;
    const value = record[header?.id];

    if (options) {
      if (Array.isArray(options)) {
        return options.find((option) => option.value === value).label;
      } else {
        return header?.display(record, header);
      }
    } else return header?.display(record, header);
  };

  // set state variable for processed data
  const [data, setData] = useState();

  const extractTime = (timePair) => {
    return {
      start: {
        hours: timePair?.split(',')[0].split(':')[0],
        minutes: timePair?.split(',')[0].split(':')[1],
      },
      end: {
        hours: timePair?.split(',')[1].split(':')[0],
        minutes: timePair?.split(',')[1].split(':')[1],
      },
    };
  };

  const getUTCDate = (date) =>
    new Date(
      Date.UTC(
        date.split('-')[0],
        date.split('-')[1],
        date.split('-')[2],
        0,
        0,
        0,
      ),
    );

  // post-processing
  useEffect(() => {
    if (context.isSuccess) {
      // console.log({ data: context.data.days });
      setData(() => {
        const result = context.data.days.map((day) => {
          const date = new Date(day.day);
          date.setHours(0, 0, 0, 0);

          let hours0 = new Date(date);
          let hours1 = new Date(date);
          if (day.schedule[0]) {
            hours0.setHours(extractTime(day.schedule[0]).start.hours);
            hours0.setMinutes(extractTime(day.schedule[0]).start.minutes);
            hours1.setHours(extractTime(day.schedule[0]).end.hours);
            hours1.setMinutes(extractTime(day.schedule[0]).end.minutes);
          } else {
            hours0 = undefined;
            hours1 = undefined;
          }

          let hours2 = new Date(date);
          let hours3 = new Date(date);
          if (day.schedule[1]) {
            hours2.setHours(extractTime(day.schedule[1])?.start.hours);
            hours2.setMinutes(extractTime(day.schedule[1])?.start.minutes);
            hours3.setHours(extractTime(day.schedule[1])?.end.hours);
            hours3.setMinutes(extractTime(day.schedule[1])?.end.minutes);
          } else {
            hours2 = undefined;
            hours3 = undefined;
          }

          return {
            date,
            hours: {
              ...(day.schedule?.[0] && { 0: hours0 }),
              ...(day.schedule?.[0] && { 1: hours1 }),
              ...(day.schedule?.[1] && { 2: hours2 }),
              ...(day.schedule?.[1] && { 3: hours3 }),
            },
          };
        });

        // console.log({ result });
        return result;
      });
    }
  }, [context.isSuccess, context.data]);

  // ...return the data object
  return {
    ...context,
    isSuccess: context.isSuccess && data !== undefined,
    data,
  };
};

export const useEmployeesModifySchedule = (params = {}) => {
  const DB_SLICE = 'employees/schedules';

  return usePost({
    endpoint: DB_SLICE,
    reqObjName: null,
    ...params,
    queryParams: {
      invalidate: ['employees/sectorSettings'],
      ...params.queryParams,
    },
  });
};

export const useEmployeesDeleteSchedule = (params = {}) => {
  const DB_SLICE = 'employees/schedules';

  return useDelete({
    endpoint: DB_SLICE,
    reqObjName: null,
    ...params,
    queryParams: {
      invalidate: ['employees/sectorSettings'],
      ...params.queryParams,
    },
  });
};
