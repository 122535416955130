import { smsDuration } from 'pages/Order/partials/OrderTable/partials/OrderRowEntries/OrderRowEntrie/OrderRowEntrie';

export const generateNewService = (service) => {
  const smsPeriod = smsDuration.find(
    (item) => item.value === service?.smsPeriod,
  );
  const composition = (service.composition || service.osComposition)?.map(
    (item) => generateNewService(item),
  );

  return {
    ...service,
    id: service?.id || service.serviceId,
    serviceId: service.id || service.serviceId,
    name: service.name || service.serviceName,
    unitPriceWithVat:
      service?.empUnitPriceWithVat ??
      service?.sectorUnitPriceWithVat ??
      service?.unitPriceWithVat ??
      0,
    discountWithVat: service?.discountWithVat || 0.0,
    // sectorUnitPriceWithVat: 0.99,
    // sectorVatPercent: 9.0,
    vat: {
      label: service?.vatPercent ? service.vatPercent + '.00%' : '-',
      value: service?.vatPercent ? service.vatPercent : null,
    },
    hasComp: service.hasComp || service.hasComposition,
    quantity: service.compServiceCount || service.quantity || 1,
    composition,
    ...(!!service.unitVolume && { unitVolume: service.unitVolume }),
    ...(service.unitId !== undefined && { unitId: service.unitId }),

    // FXIME: refactor
    ...(service.id !== 155 && {
      // unitDurationInMinutes: service.baseDurationInMinutes || 155,
      ...((service.baseDurationInMinutes !== undefined ||
        service.unitDurationInMinutes !== undefined) && {
        unitDurationInMinutes:
          service.baseDurationInMinutes || service.unitDurationInMinutes,
      }),
      ...(service.empDurationInMinutes && {
        unitDurationInMinutes: service.empDurationInMinutes,
      }),

      sms: smsPeriod || smsDuration[1],
      breakInMinutes: service.empBreakInMinutes || service.baseBreakInMinutes,
    }),
    baseDurationInMinutes: service.unitDurationInMinutes,
  };
};

export const modifyOrderCopy = (order, item, breakId, override) => {
  if (!item) return;
  const orderCopy = structuredClone(order);
  orderCopy.savedProducts = override ? [] : orderCopy.savedProducts;

  const addToOrderCopy = (item) => {
    // console.log(orderCopy.savedProducts);
    const duplicatedServiceIndex = orderCopy.savedProducts.findIndex(
      (service) => service.id === item.id,
    );

    if (duplicatedServiceIndex >= 0) {
      orderCopy.savedProducts[duplicatedServiceIndex].quantity++;

      return;
    }

    orderCopy.savedProducts.push(item);

    if (item.breakInMinutes) {
      orderCopy.savedProducts.push({
        serviceId: breakId,
        unitDurationInMinutes: item.breakInMinutes,
        quantity: 1,
      });
    }
  };

  if (Array.isArray(item)) {
    item.forEach((el) => addToOrderCopy(el));
  } else {
    addToOrderCopy(item);
  }

  return orderCopy;
};
