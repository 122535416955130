// components
import DialogBox from 'components/DialogBox/DialogBox';
import Overlay from 'components/Overlay/Overlay';

// hooks
import useTranslate from 'hooks/useTranslate';

// helpers
import { DIALOG_BOX_TYPE } from 'common/Constants';

export const RecordDeleteConfirmationDialog = ({
  isVisible,
  onReject,
  onConfirm,
  recordDeleteWarning,
}) => {
  const tr = useTranslate().use().global;

  const confirmationButtons = [
    {
      id: 'cancel',
      title: tr['cancel'],
      message: recordDeleteWarning,
      action: onReject,
      inverted: true,
    },
    {
      id: 'confirm',
      title: tr['delete'],
      action: onConfirm,
    },
  ];

  return (
    <Overlay isVisible={isVisible} hide={onReject}>
      <div className="modal-wrapper">
        <DialogBox
          close={onReject}
          headerText={tr['warning']}
          buttons={confirmationButtons}
          type={DIALOG_BOX_TYPE.CONFIRMATION}
        >
          <>{confirmationButtons[0]?.message}</>
        </DialogBox>
      </div>
    </Overlay>
  );
};
