import { useContext, useEffect, useState } from 'react';

// style
import * as S from './SetListItem.styled';

// icons
import { Clock } from 'icons/dynamic';
import PriceTagSVG from 'icons/PriceTagSVG';
import ItemRemoveSVG from 'icons/ItemRemove';
import CubeSVG from 'icons/CubeSVG';
import RulerSVG from 'icons/RulerSVG';

// components
import { InputFieldNumber } from 'components/InputFieldNumber/InputFieldNumber';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import useNumber from 'hooks/useNumber';
import { useSystemGetUnits } from 'services/systemService';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';

export const SetListItem = ({
  id,
  name,
  parentName,
  durationInMinutes,
  price,
  count = 1,
  deleteAction,
  updateQuantity,
  hasComp,
  unit,
}) => {
  const { locale } = useContext(UserContext);
  const { getUnitsById } = useSystemGetUnits();
  const { formatPrice, formatUnits } = useNumber({ price: true });

  const time = getTimeFromMinutes(durationInMinutes);

  const [quantity, setQuantity] = useState();
  useEffect(() => setQuantity(count), [count]); // update local value according to parent value

  // update upstream value
  const handleAmountInputBlur = (value) => {
    if (+value <= 0 || value === '') {
      value = 1;
    }
    updateQuantity(id, value);
  };

  return (
    <S.SetListItem>
      <div className="setListItem__info-container">
        <div className="setListItem__title">
          {hasComp && (
            <div className="setListItem__title-set">
              <CubeSVG />
            </div>
          )}
          <div>
            <span className="setListItem__title-name">{name}</span>
            {parentName && <span>({parentName})</span>}
          </div>
        </div>
        <div className="setListItem__details-container">
          {durationInMinutes !== undefined && (
            <div className="setListItem__details">
              <div className="setListItem__details-icon">
                <Clock />
              </div>

              <div className="setListItem__details-label">{time}</div>
            </div>
          )}

          {unit && (
            <div className="setListItem__details">
              <div className="setListItem__details-icon">
                <RulerSVG />
              </div>

              <div className="setListItem__details-label">
                {formatUnits(unit.volume)} {getUnitsById?.(unit.id)?.label}
              </div>
            </div>
          )}

          <div className="setListItem__details">
            <div className="setListItem__details-icon">
              <PriceTagSVG />
            </div>

            <div className="setListItem__details-label">
              {formatPrice(price || 0)}
            </div>
          </div>
        </div>
      </div>

      <div className="setListItem__action-container">
        <div
          className="setListItem__amount"
          onBlur={() => handleAmountInputBlur(quantity)}
        >
          <InputFieldNumber
            id={'serviceAmount'}
            state={{
              value: { value: quantity, label: quantity },
              setValue: (_, value) => setQuantity(value?.value),
            }}
            placeholder={(1).toLocaleString(locale, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}
          />
        </div>

        {deleteAction && (
          <div className="setListItem__trash-wrapper">
            <div className="setListItem__trash-icon" onClick={deleteAction}>
              <ItemRemoveSVG />
            </div>
          </div>
        )}
      </div>
    </S.SetListItem>
  );
};

export default SetListItem;
