import { useCallback, useEffect } from 'react';

// style
import * as S from './GeneralCalendarSettings.styled';

// icons
import { Check } from 'icons/dynamic';
import SimpleClockBoldSVG from 'icons/SimpleClockBoldSVG';

// components
import { InputField } from 'components/InputField/InputField';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useFormState } from 'hooks/useFormState';

export const GeneralCalendarSettings = ({ setButtons }) => {
  // Hooks
  const tr = useTranslate().use().global;

  const hoursOfDay = Array.apply(null, { length: 24 }).map((item, index) => {
    const hour = (index < 10 ? '0' : '') + index;
    return { label: hour + ':00', value: index };
  });

  const setupFields = useCallback(() => {
    return [
      {
        id: 'general-calendar-time-start',
        type: 'select',
        default: { value: 7, label: '07:00' },
        options: hoursOfDay,
      },
      {
        id: 'general-calendar-time-end',
        type: 'select',
        default: { value: 21, label: '21:00' },
        options: hoursOfDay,
      },
      {
        id: 'break-between-services',
        type: 'select',
        default: { value: 1, label: 'Is' },
        options: [
          { value: 1, label: 'Is' },
          { value: 0, label: "Isn't" },
        ],
      },
      {
        id: 'services',
        type: 'select',
        default: { value: 1, label: 'Show' },
        options: [
          { value: 1, label: 'Show' },
          { value: 0, label: "Don't show" },
        ],
      },
      {
        id: 'duplicate-orders',
        type: 'select',
        default: { value: 1, label: 'Allow' },
        options: [
          { value: 1, label: 'Allow' },
          { value: 0, label: 'Prevent' },
        ],
      },
      {
        id: 'order-card',
        type: 'select',
        default: { value: 0, label: tr['add-as-set'] },
        options: [
          { value: 0, label: tr['add-as-set'] },
          { value: 1, label: tr['add-exploded'] },
          { value: 2, label: tr['ask-during-addition'] },
        ],
      },
    ];
  }, []);

  const { state, options } = useFormState(setupFields());

  // setup/cleanup header buttons
  useEffect(() => {
    setButtons &&
      setButtons({
        type: 'setup',
        value: [
          {
            id: 'save',
            icon: <Check bold />,
            action: () => console.log('Settings saved'),
            borderless: true,
            show: true,
          },
        ],
      });

    return () => setButtons && setButtons({ type: 'cleanup' });
  }, [setButtons]);

  return (
    <S.GeneralCalendarSettings>
      <form>
        <div className="container">
          <div className="row">
            <div className="double-inputs-wrapper">
              <p className="label">{tr['general-calendar-time']}</p>

              <div className="double-inputs">
                <InputField
                  id="general-calendar-time-start"
                  state={state?.['general-calendar-time-start']}
                  options={options?.['general-calendar-time-start']}
                  notClearable
                  isSearchable={false}
                  prepend={{
                    label: tr['from'] + ':',
                  }}
                />
                <InputField
                  id="general-calendar-time-end"
                  state={state?.['general-calendar-time-end']}
                  options={options?.['general-calendar-time-end']}
                  notClearable
                  isSearchable={false}
                  prepend={{
                    label: tr['to'] + ':',
                  }}
                />
              </div>
            </div>

            <div className="single-input">
              <InputField
                id="break-between-services"
                label={tr['break-between-services']}
                state={state?.['break-between-services']}
                options={options?.['break-between-services']}
                isSearchable={false}
                notClearable
                prepend={{
                  icon: <SimpleClockBoldSVG />,
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="single-input">
              <InputField
                id="services"
                label={`${tr['orderCard']} | ${tr['services']} | ${tr['button-to-order-all']}`}
                state={state?.['services']}
                options={options?.['services']}
                isSearchable={false}
                notClearable
              />
            </div>
            <div className="single-input">
              <InputField
                id="duplicate-orders"
                label={tr['duplicate-orders']}
                state={state?.['duplicate-orders']}
                options={options?.['duplicate-orders']}
                isSearchable={false}
                notClearable
              />
            </div>
          </div>

          <div className="container">
            <div className="single-row">
              <div className="single-input">
                <InputField
                  id="order-card"
                  label={`${tr['orderCard']} | ${tr['service-set']}`}
                  state={state?.['order-card']}
                  options={options?.['order-card']}
                  isSearchable={false}
                  notClearable
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </S.GeneralCalendarSettings>
  );
};

export default GeneralCalendarSettings;
